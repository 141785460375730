import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { DatePicker, Day } from '@material-ui/pickers'
import { colors } from '../../../styles/styleConstants'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { CurrentDateString, DateRangeString } from '../FilterInfo'

const DateRangeSelector = (props) => {
  function renderDay(date) {
    let DayComponent = Day

    if (moment(date).isSame(moment(props.from).startOf('day'))) {
      DayComponent = StartDay
    } else if (moment(date).isSame(moment(props.to).startOf('day'))) {
      DayComponent = EndDay
    } else if (moment(date).isAfter(moment(props.from)) && moment(date).isBefore(moment(props.to))) {
      DayComponent = DayInRange
    }

    return <DayComponent className='MuiPickersDay-day'>{moment(date).format('D')}</DayComponent>
  }

  return (
    <Container className='dialogContent__inner'>
      <DatePicker
        variant='inline'
        format='MMMM D, YYYY'
        margin='normal'
        label='Start Date'
        value={props.from}
        maxDate={props.to}
        maxDateMessage='Must be before end date'
        onChange={props.onChangeFrom}
        renderDay={renderDay}
      />
      <To>to</To>
      <DatePicker
        variant='inline'
        format='MMMM D, YYYY'
        margin='normal'
        label='End Date'
        value={props.to}
        minDate={props.from}
        minDateMessage='Must be after start date'
        onChange={props.onChangeTo}
        renderDay={renderDay}
      />
    </Container>
  )
}

class DateDialog extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)

    this.state = {
      current: props.data ? props.data.current : true,
      from: props.data ? new Date(props.data.from) : new Date(),
      to: props.data ? new Date(props.data.to) : new Date(),
    }
  }

  onChangeFrom = (date) => {
    this.setState({ from: date.toDate() })
  }

  onChangeTo = (date) => {
    this.setState({ to: date.toDate() })
  }

  submit = () => {
    this.props.submit({
      current: this.state.current,
      from: this.state.from.toISOString().split('T')[0],
      to: this.state.to.toISOString().split('T')[0],
    })
  }

  handleChange(event, value) {
    this.setState({
      current: value === CurrentDateString,
    })
  }

  selectedString = () => {
    if (this.state.current === true) {
      return CurrentDateString
    } else {
      return DateRangeString
    }
  }

  render() {
    const { from, to } = this.state

    return (
      <div className='dialogContent__inner'>
        <RadioGroup onChange={this.handleChange} value={this.selectedString()}>
          <FormControlLabel
            value={CurrentDateString}
            control={<Radio />}
            label={CurrentDateString}
            key={CurrentDateString}
          />
          <FormControlLabel value={DateRangeString} control={<Radio />} label={DateRangeString} key={DateRangeString} />
          {this.state.current ? null : (
            <DateRangeSelector from={from} to={to} onChangeFrom={this.onChangeFrom} onChangeTo={this.onChangeTo} />
          )}
        </RadioGroup>
      </div>
    )
  }
}

class DateRangeDialog extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)

    this.state = {
      from: props.data ? new Date(props.data.from) : new Date(),
      to: props.data ? new Date(props.data.to) : new Date(),
    }
  }

  onChangeFrom = (date) => {
    this.setState({ from: date.toDate() })
  }

  onChangeTo = (date) => {
    this.setState({ to: date.toDate() })
  }

  submit = () => {
    this.props.submit({
      from: this.state.from.toISOString().split('T')[0],
      to: this.state.to.toISOString().split('T')[0],
    })
  }

  render() {
    const { from, to } = this.state

    return (
      <div className='dialogContent__inner'>
        <DateRangeSelector from={from} to={to} onChangeFrom={this.onChangeFrom} onChangeTo={this.onChangeTo} />
      </div>
    )
  }
}

class DateSelectDialog extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)

    this.state = {
      date: props.data ? new Date(props.data.date) : new Date(),
    }
  }

  onChange = (date) => {
    this.setState({ date: date.toDate() })
  }

  submit = () => {
    this.props.submit({
      date: this.state.date.toISOString().split('T')[0],
    })
  }

  render() {
    return (
      <div className='dialogContent__inner'>
        <DatePicker
          variant='inline'
          format='MMMM D, YYYY'
          margin='normal'
          label='Date'
          value={this.state.date}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

const Container = styled.div`
  display: flex;
`

const To = styled.span`
  display: flex;
  width: 70px;
  justify-content: center;
  align-items: center;
`

const DayInRange = styled(Day)`
  background-color: ${colors.green} !important;
  opacity: 0.6;
  border-radius: 0 !important;
  width: 40px !important;
  margin: 0 !important;
  padding: 0 2px !important;
`

const StartDay = styled(Day)`
  background-color: ${colors.green} !important;
  width: 38px !important;
  margin: 0 0 0 2px !important;
  padding: 0 2px 0 0 !important;
  border-radius: 50% 0 0 50% !important;
`

const EndDay = styled(Day)`
  background-color: ${colors.green} !important;
  width: 38px !important;
  margin: 0 2px 0 0 !important;
  padding: 0 0 0 2px !important;
  border-radius: 0 50% 50% 0 !important;
`

export { DateDialog, DateRangeDialog, DateSelectDialog }
