import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import DialogHeader from '../../../components/Dialog/DialogHeader'
import { DialogContainer, DialogContent } from '../../../components/Dialog/FormDialog'

const useStyles = makeStyles({
  header: {
    // by default no background so can see items underneath
    backgroundColor: 'white',
  },
})

export default ({
  data,
  setColumnsAndClose,
  onClose,
  columns,
  currentColumns,
  hiddenColumns,
  ...props
}): JSX.Element => {
  // temporarily hold state here, only save if "change" is clicked and not just cancelled
  const [selection, setSelection] = useState(new Set())
  const [isChanged, setIsChanged] = useState(false)
  const styles = useStyles()
  const allSelected = columns.length === selection.size

  // set current selection on dialog open
  useEffect(() => {
    if (props.open) {
      setSelection(new Set(currentColumns))
      setIsChanged(false)
    }
  }, [currentColumns, props.open])

  // row click
  const handleClick = (value) => () => {
    const newSelection = new Set(selection)

    if (selection.has(value)) {
      newSelection.delete(value)
    } else {
      newSelection.add(value)
    }

    setSelection(newSelection)
    setIsChanged(true)
  }

  // top header click
  const handleSelectAllClick = () => {
    if (allSelected) {
      setSelection(new Set())
    } else {
      setSelection(new Set(columns.map((col) => col.id)))
    }

    setIsChanged(true)
  }

  return (
    <DialogContainer onClose={onClose} width='md' {...props}>
      <DialogHeader
        title='Columns'
        onClose={() => setColumnsAndClose(selection)}
        hasSubmit={false}
        hasClose={true}
        closeDisabled={!isChanged}
        closeMessage='Change'
      />
      <DialogContent>
        <List component='div' role='list'>
          <ListSubheader className={styles.header} component='div' disableGutters>
            <ListItem divider button role='listitem' onClick={handleSelectAllClick}>
              <ListItemIcon>
                <Checkbox checked={allSelected} edge='start' disableRipple tabIndex={-1} />
              </ListItemIcon>
              <ListItemText primary='Select All' />
            </ListItem>
          </ListSubheader>
          {columns
            .filter((column) => !hiddenColumns.has(column.id))
            .map((column) => (
              <ListItem button divider role='listitem' onClick={handleClick(column.id)} key={column.id}>
                <ListItemIcon>
                  <Checkbox edge='start' checked={selection.has(column.id)} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={column.label} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </DialogContainer>
  )
}
