import { getAuthHeaders } from '../helpers/firebase'
import { get, put, post } from '../helpers/fetch'
import config from 'config'
import { useState, useEffect } from 'react'

export const getHealthTreatmentsForAccount = async (accountId) => {
  const url = `${config.backend.url}/account/${accountId}/health-treatments`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const addHealthTreatment = async (accountId, accountHealthTreatment) => {
  const url = `${config.backend.url}/account/${accountId}/health-treatment`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, accountHealthTreatment, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const editHealthTreatment = async (accountId, treatmentId, accountHealthTreatment) => {
  const url = `${config.backend.url}/account/${accountId}/health-treatment/${treatmentId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, accountHealthTreatment, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const enableHealthTreatment = async (accountId, treatmentId) => {
  const url = `${config.backend.url}/account/${accountId}/health-treatment/${treatmentId}/enable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const disableHealthTreatment = async (accountId, treatmentId) => {
  const url = `${config.backend.url}/account/${accountId}/health-treatment/${treatmentId}/disable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)

    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const addAccountHealthTreatmentsToFarm = async (accountId, farmId, accountHealthTreatments) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatments`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, { accountHealthTreatments }, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const getHealthTreatmentsForFarm = async (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatments`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const addHealthTreatmentToFarm = async (farmId, healthTreatment) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, healthTreatment, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const editHealthTreatmentForFarm = async (farmId, treatmentId, healthTreatment) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/${treatmentId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, healthTreatment, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const addPurchaseToHealthTreatment = async (farmId, treatmentId, data) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/${treatmentId}/purchase`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const editHealthTreatmentPurchase = async (farmId, purchaseId, healthTreatmentPurchase) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/purchase/${purchaseId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, healthTreatmentPurchase, headers)

    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const enableHealthTreatmentForFarm = async (farmId, treatmentId, data = {}) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/${treatmentId}/enable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const disableHealthTreatmentForFarm = async (farmId, treatmentId, data = {}) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/${treatmentId}/disable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const enableHealthTreatmentPurchaseForFarm = async (farmId, purchaseId, data = {}) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/purchase/${purchaseId}/enable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const disableHealthTreatmentPurchaseForFarm = async (farmId, purchaseId, data = {}) => {
  const url = `${config.backend.url}/farm/${farmId}/health-treatment/purchase/${purchaseId}/disable`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const useHealthTreatmentTypes = () => {
  const url = `${config.backend.url}/health-treatment/types`
  const [types, setTypes] = useState()

  const update = async () => {
    const headers = await getAuthHeaders()
    const data = await get(url, headers)

    setTypes(data.healthTreatmentTypes)
  }

  // just retrieve once on mount for now as these won't change often
  useEffect(() => {
    update()
  }, [])

  return types
}
