import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default class CheckboxSelectDialog extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onChangeSelectAll = this.onChangeSelectAll.bind(this)
    this.submit = this.submit.bind(this)

    this.state = {
      values: [],
      selected: [],
    }
  }

  componentDidMount() {
    this.props.items.then((result) => {
      let selected = []
      if (this.props.data) {
        selected = Array.from(this.props.data)
      }

      this.setState({
        values: result,
        selected: selected,
      })
    })
  }

  onChangeSelectAll = () => {
    let allSelected = this.allSelected()
    let selected = []

    if (!allSelected) {
      selected = Array.from(this.state.values)
    }

    this.setState({
      selected: selected,
    })
  }

  onChange = (value) => (event, checked) => {
    let selected = this.state.selected

    if (checked) {
      selected.push(value)
    } else {
      selected.splice(selected.indexOf(value), 1)
    }

    this.setState({
      selected: selected,
    })
  }

  submit() {
    this.props.submit(this.state.selected)
  }

  render() {
    if (this.state.values.length === 0) {
      return null
    }

    return (
      <div className='dialogContent__inner'>
        <FormControlLabel
          control={<Checkbox checked={this.allSelected()} onChange={this.onChangeSelectAll} value='Select All' />}
          label='Select All'
        />
        <hr />
        {this.state.values.map((item) => (
          <div key={item}>
            <FormControlLabel
              control={
                <Checkbox checked={this.state.selected.includes(item)} onChange={this.onChange(item)} value={item} />
              }
              label={item}
            />
          </div>
        ))}
      </div>
    )
  }

  allSelected = () => {
    return this.state.values.length === this.state.selected.length
  }
}
