import Container from '@material-ui/core/Container'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import MaterialTable from '../../components/Table/Table'
import {
  DraftingEventLimits,
  DraftingEventRow,
  getDraftingEvents,
  getDraftingLimitsWeights,
  loadWeightDistributionSettings,
  Metric,
  saveWeightDistributionSettings,
  WeightDistributionMetric,
  WeightDistributionSettings,
} from '../../data/dashboard/weight_distribution'
import { AnimalType } from '../../data/types'
import { colors } from '../../styles/styleConstants'
import { AnimalTypeSelector } from './AnimalTypeSelector'
import { WeightDistributionSettingsView } from './WeightDistributionSettings'

interface WeightDistributionChartProps {
  readonly metric: WeightDistributionMetric
  readonly farmId: string
  readonly onChange: (metric: WeightDistributionMetric) => void
  readonly data: any | null
  readonly position: number
}

interface Mark {
  readonly value: number
  readonly label?: string
}

const red = '#cc0000' // colors.red is too dark, inconsistent with charts
const sliderStep = 1
const updateDelay = 250 // don't flood server with requests

const columns = [
  { id: 'metric', label: 'Metric' },
  { id: 'totals', label: 'Totals' },
  { id: 'light', label: 'Light' },
  { id: 'medium', label: 'Medium' },
  { id: 'heavy', label: 'Heavy' },
]

const makeSliderStyles = makeStyles({
  rail: {
    background: (props) =>
      `linear-gradient(90deg, ${red} 0%, ${red} ${props.pct}%, ${colors.yellow} ${props.pct}%, ${colors.yellow} 100%)`,
    opacity: 1,
  },
  mark: {
    width: 1,
    height: 10,
    color: colors.lightGrey,
    marginTop: 2,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})

const makeMarks = (min: number, max: number): Mark[] => {
  const output: Mark[] = []
  const step = 5
  const labelStep = Math.ceil((max - min) / 100) * step
  let curr = Math.ceil(min / step) * step

  while (curr <= max) {
    const mark = { value: curr }
    if (curr % labelStep === 0) {
      mark.label = `${curr}`
    }

    output.push(mark)
    curr += step
  }

  return output
}

const formatPct = (value: number): string => (value * 100).toFixed(2) + '%'
const formatNumber = (value: number): string => (value === null ? '-' : value.toFixed())

export const WeightDistributionChart: React.FunctionComponent<WeightDistributionChartProps> = ({
  metric,
  farmId,
  onChange,
  data,
  position,
}: WeightDistributionChartProps): JSX.Element => {
  const [value, setValue] = useState([])
  const [events, setEvents] = useState([])
  const [eventMap, setEventMap] = useState({})
  const [showSettings, setShowSettings] = useState(false)
  const [loadedSettings, setLoadedSettings] = useState({})
  const timerRef = useRef(null)

  // only set metric data after a delay because user could be dragging
  // avoids hundreds of requests to backend
  // but still needs to save the value so it displays properly, which
  // is why this is saved in two places
  const onSliderChange = (event: any, newValue: number[]): void => {
    const [first, second] = newValue

    // doesn't make sense to split twice in one place
    if (first !== second) {
      setValue([Math.min(first, second), Math.max(first, second)])
    }

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => onChange({ ...metric, limitLow: value[0], limitHigh: value[1] }), updateDelay)
  }

  const onAnimalTypeChange = (animalType: AnimalType): void => {
    onChange({ ...metric, animalType, eventId: undefined })
  }

  const onSettingsSave = (eventId: string, min: number, max: number): void => {
    setShowSettings(false)
    // default placement of limit sliders is to evenly split range in 3 parts

    if (eventId) {
      const third = (max - min) / 3
      const limitLow = Math.floor(min + third)
      const limitHigh = Math.floor(min + third * 2)
      setValue([limitLow, limitHigh])
      onChange({ ...metric, eventId, min, max, limitLow, limitHigh })
    } else {
      setValue([])
      onChange({
        ...metric,
        eventId: undefined,
        min: undefined,
        max: undefined,
        limitLow: undefined,
        limitHigh: undefined,
      })
    }
  }

  // all events for this farm, no need to refetch per animal type
  useEffect(() => {
    getDraftingEvents(farmId).then((result: DraftingEventRow[]) => {
      setEvents(result)
    })
  }, [farmId])

  // filter events by animal type on tab switch, for setting modal
  useEffect(() => {
    setEventMap(Object.fromEntries(events.map((row) => [row.id, row.name])))
  }, [metric.animalType, events])

  // load setting for widget when animal type changes
  useEffect(() => {
    loadWeightDistributionSettings(farmId, position).then((result: WeightDistributionSettings) => {
      const settings = result[metric.animalType]
      setLoadedSettings(settings)

      // have some settings to load
      if (!_.isEmpty(settings)) {
        const { limitLow, limitHigh, eventId } = settings

        // data could have change since last loaded so fetch new min/max for saved event
        getDraftingLimitsWeights(farmId, eventId).then((result: DraftingEventLimits) => {
          const { min, max } = result
          const value = [Math.max(min, limitLow), Math.min(max, limitHigh)]
          onChange({
            ...metric,
            min,
            max,
            limitLow: value[0],
            limitHigh: value[1],
            eventId,
          })
          setValue(value)
        })
        // no settings, reset to pristine state and require user to pick event
      } else {
        onChange({
          ...metric,
          min: undefined,
          max: undefined,
          limitLow: undefined,
          limitHigh: undefined,
          eventId: undefined,
        })
      }
    })
  }, [metric.animalType, farmId])

  // save settings when anything other than animal type changes
  useEffect(() => {
    if (
      metric.eventId !== loadedSettings.eventId ||
      metric.limitLow !== loadedSettings.limitLow ||
      metric.limitHigh !== loadedSettings.limitHigh
    ) {
      const settings = {}

      if (metric.eventId) {
        settings[metric.animalType] = {
          eventId: metric.eventId,
          limitLow: metric.limitLow,
          limitHigh: metric.limitHigh,
        }
      } else {
        settings[metric.animalType] = {}
      }

      saveWeightDistributionSettings(farmId, settings, position)
    }
  }, [metric.eventId, metric.limitLow, metric.limitHigh])

  const formattedData = (data || []).map((row) => {
    const formatter = row.metric === Metric.PctTotal ? formatPct : formatNumber
    return Object.fromEntries(Object.entries(row).map(([key, val]) => [key, key === 'metric' ? val : formatter(val)]))
  })

  let pct = 0
  let marks = []

  if (data) {
    marks = makeMarks(metric.min, metric.max)
    const [first, second] = value
    pct = (((first + second) / 2 - metric.min) / (metric.max - metric.min)) * 100
  }

  const sliderStyles = makeSliderStyles({ pct })

  return (
    <>
      <ChartWrapper onSettingsClick={() => setShowSettings(true)}>
        <AnimalTypeSelector animalType={metric.animalType} onSelect={onAnimalTypeChange} />
        {metric.eventId && (
          <Typography variant='subtitle1' align='center'>
            {eventMap[metric.eventId]}
          </Typography>
        )}
        {data && (
          <Container>
            <MaterialTable columns={columns} data={formattedData} disableSort />
            <Slider
              value={value}
              onChange={onSliderChange}
              valueLabelDisplay='auto'
              classes={sliderStyles}
              marks={marks}
              step={sliderStep}
              min={metric.min}
              max={metric.max}
            />
          </Container>
        )}
        {!data && (
          <Typography variant='subtitle1' align='center'>
            Please select an Event in Settings.
          </Typography>
        )}
      </ChartWrapper>
      <WeightDistributionSettingsView
        open={showSettings}
        handleRequestClose={() => setShowSettings(false)}
        onSettingsSave={onSettingsSave}
        events={events}
        metric={metric}
        farmId={farmId}
      />
    </>
  )
}

const ChartWrapper = ({ children, onSettingsClick }): JSX.Element => (
  <div className='dashboardChartInner'>
    {children}
    <div className='dashboardChartFooter'>
      <div></div>
      <p className='dashboardChartSettings' onClick={onSettingsClick}>
        Settings
      </p>
    </div>
  </div>
)
