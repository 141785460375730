import './ImportTags.css'

import React from 'react'

import BackHeading from '../../components/BackHeading'
import MaterialTable from '../../components/Table/Table'

const columns = [
  { id: 'tagColor', numeric: false, label: 'Tag Colour' },
  { id: 'unassigned', numeric: true, label: '# Unassigned' },
  { id: 'startVid', numeric: true, label: 'Start VID' },
  { id: 'endVid', numeric: true, label: 'End VID' },
  { id: 'animalType', numeric: false, label: 'Animal Type' },
  { id: 'vidYear', numeric: false, label: 'Year' },
  { id: 'assign', numeric: false, label: 'Assign' },
]

function formatData(data: any[], assignDetails: (data: any) => void): any[] {
  return data.map((row) => {
    return {
      ...row,
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      assign: <a onClick={() => assignDetails(row)}>Assign Tag Details</a>,
    }
  })
}

interface Props {
  readonly data: any[]
  readonly backURL: string
  readonly assignDetails: (data: any) => void
}

const CompleteImport: React.FunctionComponent<Props> = ({ data, assignDetails, backURL }) => (
  <div>
    <BackHeading withBorder text='Complete Import' backURL={backURL} />
    {data && data.length > 0 && <MaterialTable columns={columns} data={formatData(data, assignDetails)} />}
  </div>
)

export default CompleteImport
