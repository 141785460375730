import React from 'react'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'

/**
 * Container component which retrieves and prepares data for the dashboard
 * @type {Object}
 */
class SupportContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }

    // POC for content loading - demonstrates how a component may defer render
    // until it is ready
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 2000)
  }

  render() {
    const user = this.props.user

    // show loading until user prop is passed and component is ready
    const loaded = this.state.loaded && user

    // must be able to render without the user prop
    return (
      <Layout title='Support' loaded={loaded}>
        {user ? <SupportContent user={this.props.user} /> : ''}
      </Layout>
    )
  }
}

/**
 * Pure component which renders the dashboard content
 * @param {Object} props
 */
const SupportContent = () => {
  return <div>Coming soon.</div>
}

export default requireAuth(SupportContainer, { loading: null })
