import localforage from 'localforage'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { AccessAccount, AccessFarm, getAccess } from '../data/access'
import { addAuthHandler, removeAuthHandler } from '../helpers/auth'
import Loading from './Loading'

// see set_role_score function in database
export const farmRoleMap = {
  Super: 100,
  Clinic: 90,
  Admin: 80,
  Vet: 70,
  Account: 60,
  Farm: 50,
  Staff: 40,
  Viewer: 30,
  Group: 20,
}

// see account_role_on_upsert_set_role_score in database
export const accountRoleMap = {
  Clinic: 90,
  Admin: 80,
  Vet: 75,
  Retail: 70,
  Account: 60,
}

enum SubscriptionType {
  Full,
  Web,
  Standard,
}

export interface AuthUserInfo {
  readonly authenticated: true
  readonly email: string
  readonly displayName: string
  readonly uid: string
}
interface AccountContext {
  readonly user: AuthUserInfo
  readonly accounts: AccessAccount[]
  readonly farms: AccessFarm[]
  readonly currentAccountId?: number
  readonly setCurrentAccountId: (id: number) => void
  readonly refetch: () => void
  readonly hasFarmRole: (role: keyof typeof farmRoleMap) => boolean
  readonly hasExplicitFarmRole: (roles: (keyof typeof farmRoleMap)[]) => boolean
  readonly hasAccountRole: (role: keyof typeof accountRoleMap) => boolean
  readonly hasExplicitAccountRole: (roles: (keyof typeof accountRoleMap)[]) => boolean
  readonly hasExplicitSubscriptionType: (subscriptions: (keyof typeof SubscriptionType)[]) => boolean
}

const AccountContext = React.createContext<AccountContext | null>(null)

const ACCOUNT_SESSION_KEY = 'current-account-id'

const LoadingWrapper = styled.div`
  height: 100vh;
  padding-top: calc(50vh - 20px);
`

interface AccountProviderProps {
  children: React.ReactNode
}

export const AccountProvider: React.FunctionComponent<AccountProviderProps> = ({ children }) => {
  const [details, setDetails] = useState<Partial<AccountContext>>({})
  const [currentAccountId, setCurrentAccountId] = useState<number | undefined>()

  // on initial mount only, get the current account id from the session
  useEffect(() => {
    localforage.getItem(ACCOUNT_SESSION_KEY).then((sessionAccountId) => {
      if (typeof sessionAccountId === 'number') {
        setCurrentAccountId((current) => current || sessionAccountId)
      }
    })
  }, [])

  // update the session value each time the current account changes
  useEffect(() => {
    localforage.setItem(ACCOUNT_SESSION_KEY, currentAccountId)
  }, [currentAccountId])

  const fetchUserDetails = useCallback(async (user) => {
    const authenticated = user && user.authenticated

    const access = authenticated ? await getAccess() : {}

    setDetails({
      user,
      ...access,
    })
  }, [])

  useEffect(() => {
    addAuthHandler(fetchUserDetails)
    return () => {
      removeAuthHandler(fetchUserDetails)
    }
  }, [fetchUserDetails])

  const refetch = useCallback(() => {
    fetchUserDetails(details.user)
  }, [fetchUserDetails, details.user])

  const context = useMemo(() => {
    // currentAccountId may become stale if the user switches account. Only
    // allow a valid id, and if only one to choose from use it by default
    const validIds = (details.accounts || []).map((a) => a.id)
    const validAccountId =
      currentAccountId && validIds.includes(currentAccountId)
        ? currentAccountId
        : validIds.length === 1
        ? validIds[0]
        : undefined

    const hasFarmRole = (role: keyof typeof farmRoleMap) =>
      !!details.farms?.filter((f) => f.userRoleScore >= farmRoleMap[role]).length

    const hasExplicitFarmRole = (roles: (keyof typeof farmRoleMap)[]) =>
      !!details.farms?.filter((f) => roles.indexOf(f.userRole) !== -1).length

    const hasAccountRole = (role: keyof typeof accountRoleMap) =>
      !!details.accounts?.filter((a) => a.accountRoleScore >= accountRoleMap[role]).length

    const hasExplicitAccountRole = (roles: (keyof typeof accountRoleMap)[]) =>
      !!details.accounts?.filter((f) => roles.indexOf(f.accountRole) !== -1).length

    const hasExplicitSubscriptionType = (subscriptions: (keyof typeof SubscriptionType)[]) =>
      !!details.farms?.filter((f) => subscriptions.indexOf(f.subscriptionType) !== -1).length

    return {
      ...details,
      currentAccountId: validAccountId,
      setCurrentAccountId,
      refetch,
      hasFarmRole,
      hasExplicitFarmRole,
      hasAccountRole,
      hasExplicitAccountRole,
      hasExplicitSubscriptionType,
    }
  }, [details, refetch, currentAccountId])

  if (!context || !context.user || !context.setCurrentAccountId) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    )
  }

  return (
    <AccountContext.Provider
      // cast here as we know all the maybe properties are now set
      value={context as AccountContext}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = (farmRole?: keyof typeof farmRoleMap | ((f: AccessFarm) => boolean)): AccountContext => {
  const context = useContext(AccountContext)
  if (!context) {
    throw new Error('useAccount may only be used inside an AccountProvider component')
  }

  // filter allowed farms
  const farms =
    context.farms &&
    context.farms.filter(
      typeof farmRole === 'function' ? farmRole : (farm) => !farmRole || farm.userRoleScore >= farmRoleMap[farmRole],
    )

  return {
    ...context,
    farms,
  }
}
