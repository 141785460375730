import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { withFormsy } from 'formsy-react'
import React from 'react'

const useEmptyStateStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& label': {
      color: 'white',
      paddingLeft: `${theme.spacing(1)}px`,
      textTransform: 'uppercase',
      zIndex: 1,
      '&.Mui-focused': {
        color: theme.palette.primary.dark,
        textTransform: 'initial',
        zIndex: 0,
      },
    },
  },
  tag: {
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  },
  inputRoot: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2px',
    '&.MuiInputBase-root': {
      paddingBottom: '6px',
      paddingTop: '2px',
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:hover': {
      '&:before': {
        borderBottom: 'none !important',
      },
    },
    '& ::placeholder': {
      paddingLeft: `${theme.spacing(1.5)}px`,
      color: 'white',
      fontWeight: 'normal',
      fontSize: 14,
      textTransform: 'uppercase',
      opacity: 1,
    },
  },
  popupIndicator: {
    color: 'white',
  },
}))

const useDefaultStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
  tag: {
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  },
}))

const useFilledStateStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& label': {
      color: theme.palette.primary.dark,
      paddingLeft: `${theme.spacing(1)}px`,
      textTransform: 'initial',
      '&.Mui-focused': {
        color: theme.palette.primary.dark,
        textTransform: 'initial',
        zIndex: 0,
      },
    },
  },
  tag: {
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  },
  inputRoot: {
    '&.MuiInputBase-root': {
      paddingBottom: `${theme.spacing(0.5)}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
    },
    '& ::placeholder': {
      textTransform: 'uppercase',
      fontSize: 14,
      opacity: 1,
    },
  },
  popupIndicator: {
    color: theme.palette.primary.dark,
  },
}))

export interface Option {
  readonly title: string
  readonly value: string | number
}

interface Props {
  readonly options: Option[]
  readonly defaultValues?: Option[]
  readonly placeholder?: string
  readonly label?: string
  readonly disabled?: boolean
  readonly setValue: (values: Option[]) => void
  readonly onChange?: (values: Option[]) => void
  readonly variant?: 'input' | 'select'
  readonly isRequired: boolean
  readonly showValidation?: boolean
  readonly isPristine: boolean
  readonly showError: boolean
  readonly showRequired: boolean
  readonly errorMessage: string | React.ReactNode
}

const getOptionLabel = (option: Option): string => option.title
const getOptionSelected = (all: Option, selected: Option): boolean => all.value === selected.value

const Autocomplete: React.FunctionComponent<Props> = ({
  options,
  defaultValues,
  placeholder,
  label,
  setValue,
  onChange,
  disabled,
  variant = 'select',
  isRequired,
  showValidation = false,
  isPristine,
  showError,
  errorMessage,
}) => {
  let error = false
  let message: string | React.ReactNode = ''

  const [emptyState, setEmptyState] = React.useState(defaultValues ? defaultValues.length === 0 : true)
  const emptyStateClasses = useEmptyStateStyles()
  const filledStateClasses = useFilledStateStyles()
  const defaultClasses = useDefaultStyles()

  const classes = React.useMemo(() => {
    if (emptyState && variant === 'select') return emptyStateClasses
    else if (variant === 'select') return filledStateClasses
    else return defaultClasses
  }, [defaultClasses, emptyState, emptyStateClasses, filledStateClasses, variant])

  const onChangeOptions = (_event: object, values: Option[], _reason: string): void => {
    setValue(values)
    if (values.length === 0) {
      setEmptyState(true)
    } else {
      setEmptyState(false)
    }

    if (onChange) {
      onChange(values)
    }
  }

  // Don't show errors until the user starts entering input
  if (!isPristine && showError) {
    error = true
    message = errorMessage
  }

  // if the user has been to a required input but not entered anything,
  // show an error
  if (isRequired && !error && !isPristine && emptyState) {
    error = true
    message = 'This field is required'
  }

  return (
    <Box display='flex' flexDirection='column'>
      <MuiAutocomplete
        classes={classes}
        multiple
        disabled={disabled}
        options={options}
        onChange={onChangeOptions}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        defaultValue={defaultValues ? defaultValues : undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            label={label}
            error={error}
            required={isRequired && emptyState}
            placeholder={placeholder}
          />
        )}
      />
      {(isRequired || showValidation) && <FormHelperText error>{message}</FormHelperText>}
    </Box>
  )
}

export default withFormsy<Props, Option[]>(Autocomplete)
