/**
 * Custom form components for specific fields in the StockManager web app.
 * @type {Object}
 */

import React from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { addValidationRule } from 'formsy-react'
import { TextField, SelectField, PasswordField } from '../../components/Form/Form.js'
import { FARMNAME_MAX_LENGTH } from '../../data/farm'
import { nzRegionChoices } from '../../constants'
import { COMPANYNAME_MAX_LENGTH, CONTACTNAME_MAX_LENGTH, ADDRESS_MAX_LENGTH, MISC_MAX_LENGTH } from '../../data/general'

/**
 * Field for entering farm name
 * @param {Object} props
 */
export const FarmNameField = (props) => (
  <TextField
    maxLength={FARMNAME_MAX_LENGTH}
    validations={'maxLength:' + FARMNAME_MAX_LENGTH}
    validationErrors={{
      maxLength: FARMNAME_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)

/**
 * Field for entering company name
 * @param {Object} props
 */
export const CompanyNameField = (props) => (
  <TextField
    maxLength={COMPANYNAME_MAX_LENGTH}
    validations={'maxLength:' + COMPANYNAME_MAX_LENGTH}
    validationErrors={{
      maxLength: COMPANYNAME_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)

/**
 * Field for entering animal EID
 * @param {Object} props
 */
export const EIDField = (props) => (
  <TextField
    type={'number'}
    maxLength={16}
    validations={'maxLength:' + 16}
    validationErrors={{
      maxLength: 16 + ' characters maximum',
    }}
    {...props}
  />
)

addValidationRule('isInRange', (values, value, max) => {
  if (!value || value === '') {
    return true
  }

  if (isNaN(value)) {
    return false
  }

  return Number(value) <= Number(max)
})

addValidationRule('isGreaterThanMin', (values, value, min) => {
  if (!value || value === '') {
    return true
  }

  if (isNaN(value)) {
    return false
  }

  return Number(value) >= Number(min)
})

export const VisualTagField = (props) => {
  const validations = { maxLength: props.manual ? 6 : 20, isNumeric: true }

  if (props.max) {
    validations.isInRange = props.max
  }
  if (props.min) {
    validations.isGreaterThanMin = props.min
  }
  return (
    <TextField
      validations={!props.disabled && validations}
      validationErrors={
        !props.disabled && {
          maxLength: `${props.manual ? 6 : 20} digits maximum`,
          isInRange: 'VID number entry is larger than provided on tag order file. Please adjust to file range',
          isGreaterThanMin: 'VID # (To) value is smaller than the start VID. Please adjust to file range',
          isNumeric: 'Should be number',
        }
      }
      {...props}
    />
  )
}

/**
 * Field for entering contact name
 * @param {Object} props
 */
export const ContactNameField = (props) => (
  <TextField
    maxLength={CONTACTNAME_MAX_LENGTH}
    validations={'maxLength:' + CONTACTNAME_MAX_LENGTH}
    validationErrors={{
      maxLength: CONTACTNAME_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)

export const NameField = (props) => (
  <TextField
    maxLength={MISC_MAX_LENGTH}
    validations={'maxLength:' + MISC_MAX_LENGTH}
    validationErrors={{
      maxLength: MISC_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)

/**
 * Field for entering address
 * @param {Object} props
 */
export const AddressField = (props) => (
  <TextField
    maxLength={ADDRESS_MAX_LENGTH}
    validations={'maxLength:' + ADDRESS_MAX_LENGTH}
    validationErrors={{
      maxLength: ADDRESS_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)

export const RegionField = (props) => (
  <SelectField
    validations='isExisty'
    validationErrors={{ isExisty: 'Please select a region' }}
    name='region'
    choices={nzRegionChoices}
    value={props.value ? props.value : null}
    placeholder={props.label}
    showPlaceholder
    {...props}
  />
)

const countries = [['New Zealand', 'New Zealand']]

export const CountryField = (props) => (
  <SelectField
    validations='isExisty'
    validationErrors={{ isExisty: 'Please select a country' }}
    name='country'
    choices={countries}
    value='New Zealand'
    label={props.label}
    {...props}
  />
)

addValidationRule('isValidNait', (values, value) => {
  if (!value || value === '') {
    return true
  }

  if (isNaN(value)) {
    return false
  }

  const length = String(value).length
  return (length >= 2 && length <= 6) || length === 8
})

addValidationRule('isValidNaitPassword', (values, value) => {
  if (!value || value === '') {
    return true
  }

  return value.length >= 8 && /\d+/.test(value) && /[a-zA-Z]/.test(value)
})

export const NaitField = (props) => (
  <TextField
    name='naitNumber'
    validations='isValidNait'
    validationError='Must be 2-6 or 8 digits'
    pattern='\d*'
    {...props}
  />
)

export const NaitUsername = (props) => (
  <TextField
    name='naitUsername'
    label='NAIT System Username'
    validations='isAlphanumeric,maxLength:50'
    validationErrors={{
      isAlphanumeric: 'Must be alphanumeric',
      maxLength: '50 characters maximum',
    }}
    {...props}
  />
)

export const NaitPassword = (props) => (
  <PasswordField
    name='naitPassword'
    label='NAIT System Password'
    validations='isValidNaitPassword'
    validationError='Must be 8 characters and contain letters and numbers'
    {...props}
  />
)

export const CheckboxWithLabel = styled(FormControlLabel)`
  margin-bottom: -8px;
  flex-grow: 1;

  span:last-of-type {
    width: ${(props) => props.width || '108px'};
    color: #555555;
  }
`
