import { getAuthHeaders } from '../helpers/firebase'
import { post, put, get, del } from '../helpers/fetch'
import config from 'config'

export const ROLES = {
  ACCOUNT: 'Account',
  FARM: 'Farm',
  VIEWER: 'Viewer',
}

export const FARMNAME_MAX_LENGTH = 30

export const roleDisplayValues = {}
roleDisplayValues[ROLES.ACCOUNT] = 'Account owner'
roleDisplayValues[ROLES.FARM] = 'Farm Manager'
roleDisplayValues[ROLES.VIEWER] = 'Farm Viewer'

/**
 * get the farms associated with the user
 * @param   {string} userId
 * @return  {Promise}
 */
export const getAllFarmsForUser = () => {
  const url = config.backend.url + '/farms'

  return getAuthHeaders().then((headers) => {
    return get(url, headers).then((data) => data.farms)
  })
}

/**
 * save the given data to farms/farmId
 * @param  {string} farmId
 * @param  {object} data
 * @return {Promise}
 */
export const saveFarm = (farmId, data) => {
  const url = config.backend.url + '/farm/' + farmId

  return getAuthHeaders()
    .then((headers) => {
      return put(url, data, headers)
    })
    .then((res) => {
      return {
        success: true,
        farm: res.farmName,
        farmId: res.id,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export const getFarm = (farmId) => {
  const url = config.backend.url + '/farm/' + farmId

  return getAuthHeaders().then((headers) => {
    return get(url, headers)
  })
}

/**
 * create a new farm for the system
 * @param   {Object} details  farm details
 * @param   {Object} firebase userInfo for farm owner
 * @return  {Promise}
 */
export const createFarm = (data) => {
  const url = config.backend.url + '/farm'

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers)
    })
    .then((res) => {
      return {
        success: true,
        farm: res.farmName,
        farmId: res.id,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

/**
 * invite a user by email address to have the given role for a farmId
 * @param  {String} farmId
 * @param  {String} email  user's email address
 * @param  {String} role   must be in ROLES, and not "Account"
 * @return {Promise}
 */
export const inviteFarmUser = (farmId, email, role) => {
  const url = `${config.backend.url}/farm/${farmId}/invite`

  const data = {
    email: email,
    role: role,
    roleDisplay: ROLES.role,
  }

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers)
    })
    .then(() => {
      return {
        success: true,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

/**
 * remove a user's role for a farmId
 * @param  {String} farmId
 * @param  {[type]} email  users's email address
 * @param  {String} role   must be in ROLES, and not "Account"
 * @return {Promise}
 */
export const removeFarmUser = (farmId, userId) => {
  const url = `${config.backend.url}/farm/${farmId}/role/${userId}`

  return getAuthHeaders().then((headers) => {
    return del(url, headers)
  })
}

/**
 * get all assigned roles for the given farm
 * @param  {String} farmId
 * @return {Promise}
 */
export const getFarmRoles = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/roles`

  return getAuthHeaders().then((headers) => {
    return get(url, headers).then((data) => data.roles)
  })
}

/**
 * get all pending invitations for the given farm
 * @param  {String} farmId
 * @return {Promise}
 */
export const getFarmInvitations = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/invites`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res.invitations
    })
}

/**
 * delete an invitation for a farmId
 * @param  {String} farmId
 * @param  {int} id of invitation to delete
 * @return {Promise}
 */
export const deleteInvitation = (farmId, inviteId) => {
  const url = `${config.backend.url}/farm/${farmId}/invite/${inviteId}`

  return getAuthHeaders().then((headers) => {
    return del(url, headers)
  })
}

export const disableFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/disable`

  return getAuthHeaders().then((headers) => {
    return put(url, null, headers)
  })
}

export const enableFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/enable`

  return getAuthHeaders().then((headers) => {
    return put(url, null, headers)
  })
}

export const setUserRole = (farmId, firebaseUserId, role) => {
  const url = `${config.backend.url}/farm/${farmId}/role/${firebaseUserId}`

  return getAuthHeaders().then((headers) => {
    return put(url, { role }, headers)
  })
}

export const setInviteRole = (farmId, inviteId, role) => {
  const url = `${config.backend.url}/farm/${farmId}/invite/${inviteId}`

  return getAuthHeaders().then((headers) => {
    return put(url, { role }, headers)
  })
}
