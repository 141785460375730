import React from 'react'
import Box from '@material-ui/core/Box'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import { PageTitle, ActionHeader, ButtonContainer, ActionHeaderButton } from '../../components/PageHeaders'
import FarmSelector from '../../components/FarmSelector'
import Search from './Search'
import History from './History'
import { getAllFarmsForUser } from '../../data/farm'

const defaultState = {
  selectedFarmId: null,
  selectedFarmName: null,
  historyData: [],
  farmData: [],
  showAnimalSection: false,
  showGroupSection: false,
}

class AnimalGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  async componentDidMount() {
    const farms = await getAllFarmsForUser(this.props.user.uid)

    this.setState({ farms })
  }

  componentDidUpdate(prevProps) {
    // switch between animals and non-id groups
    if (prevProps.type !== this.props.type) {
      this.setState({
        ...defaultState,
        farms: this.state.farms,
      })
    }
  }

  openAnimalDialog = () => {
    this.setState({
      animalDialogOpen: true,
      showGroupSection: false,
    })
  }

  openGroupDialog = () => {
    this.setState({
      groupDialogOpen: true,
      showAnimalSection: false,
    })
  }

  handleFarmSelected = (farmId, farmName) => {
    this.setState({
      ...defaultState,
      selectedFarmId: farmId,
      selectedFarmName: farmName,
    })
  }

  handleAnimalSelected = (results) => {
    if (results && results.error) {
      this.setState({
        historyData: [],
      })
    } else if (results && results.records) {
      this.setState({
        showAnimalSection: true,
        historyData: results.records,
      })
    }

    this.setState({ animalDialogOpen: false })
  }

  handleGroupSelected = (results) => {
    if (results && results.error) {
      this.setState({
        historyData: [],
      })
    } else if (results && results.records) {
      this.setState({
        showGroupSection: true,
        historyData: results.records,
      })
    }

    this.setState({ groupDialogOpen: false })
  }

  deselectFarm = () => this.setState(defaultState)

  render() {
    const typeDisplay = this.props.type === 'animal' ? 'Animals' : 'Non-ID Groups'

    return (
      <Layout>
        <PageTitle onClick={this.deselectFarm}>{typeDisplay}</PageTitle>
        <ActionHeader>
          <ButtonContainer align='left'>
            <FarmSelector
              user={this.props.user}
              farms={this.state.farms}
              handleFarmSelected={this.handleFarmSelected}
              farmId={this.state.selectedFarmId}
              deselectFarm={this.deselectFarm}
            />
            <Box marginLeft={3} />
            <ActionHeaderButton
              ml='100px'
              width='200px'
              disabled={!this.state.selectedFarmId}
              onClick={this.props.type === 'animal' ? this.openAnimalDialog : this.openGroupDialog}
            >
              Select {typeDisplay}
            </ActionHeaderButton>
          </ButtonContainer>
        </ActionHeader>
        <Search
          user={this.props.user}
          animalDialogOpen={this.state.animalDialogOpen}
          groupDialogOpen={this.state.groupDialogOpen}
          showAnimalSection={this.state.showAnimalSection}
          showGroupSection={this.state.showGroupSection}
          handleAnimalSelected={this.handleAnimalSelected}
          handleGroupSelected={this.handleGroupSelected}
          farmId={this.state.selectedFarmId}
          farmData={this.state.farmData}
          animalNotFound={this.state.animalNotFound}
        />
        <History user={this.props.user} historyData={this.state.historyData} />
      </Layout>
    )
  }
}

export default requireAuth(AnimalGroup)
