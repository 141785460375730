/* eslint-disable react/no-string-refs */
/**
 * Reusable form components for the StockManager web app.
 * @type {Object}
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Formsy, { addValidationRule, withFormsy } from 'formsy-react'
import { Link } from 'react-router-dom'
import MaterialTextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import {
  // MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers'
import styled from 'styled-components'
import { width, maxWidth, height, flex, space } from 'styled-system'
import { colors } from '../../styles/styleConstants'
import { POSTALCODE_LENGTH, MISC_MAX_LENGTH, EMAIL_MAX_LENGTH, SUPPORTED_COUNTRIES } from '../../data/general'
import { isValidNumber, AsYouType } from 'libphonenumber-js'
import './Form.css'
import { datePickerFormat } from '../../constants'

/**
 * Base form field which integrates with formsy's validation hooks, and
 * standardises display properties
 * @type {Object}
 */
export class BaseTextField extends React.Component {
  constructor(props) {
    super(props)

    this.changeValue = this.changeValue.bind(this)
    this.onBlur = (this.props.onBlur || (() => {})).bind(this)
    this.inputField = null
  }

  changeValue(event) {
    this.props.setValue(event.target.value)

    if (this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }

  render() {
    const inputType = this.props.type || 'text'
    const required = this.props.showRequired
    const label = this.props.label
    const multiline = this.props.multiline
    const value = this.props.value || ''
    const disabled = this.props.disabled || false

    let error = false
    let message = ' '

    // Don't show errors until the user starts entering input
    if (!this.props.isPristine && this.props.showError) {
      error = true
      message = this.props.errorMessage
    }

    // if the user has been to a required input but not entered anything,
    // show an error
    if (required && !error && !this.props.isPristine) {
      error = true
      message = 'This field is required'
    }

    const baseInputProps =
      inputType === 'number'
        ? {
            step: 'any',
          }
        : {}

    return (
      <div>
        <MaterialTextField
          // fullWidth={true}
          multiline={multiline}
          disabled={disabled}
          type={inputType}
          onChange={this.changeValue}
          onBlur={this.onBlur}
          value={value}
          error={error}
          helperText={message}
          required={required}
          inputRef={(elem) => (this.inputField = elem)}
          label={label}
          inputProps={{ ...baseInputProps, ...(this.props.extra || {}) }}
          InputProps={this.props.muiExtra || {}}
        />
      </div>
    )
  }
}

class BaseHiddenField extends React.Component {
  render() {
    return (
      <input
        type='hidden'
        name={this.props.name}
        value={this.props.value}
        disabled={this.props.disabled || false}
        required={this.props.showRequired}
      />
    )
  }
}

export const HiddenField = withFormsy(BaseHiddenField)

const BaseSimpleSelect = ({ label, name, value, setValue, multiple, choices, disabled }) => (
  <div>
    <InputLabel style={{ marginBottom: '10px' }}>{label}</InputLabel>
    <Select
      name={name}
      multiple={multiple}
      disabled={disabled}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      {choices.map(([value, text]) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </Select>
  </div>
)

export const SimpleSelect = withFormsy(BaseSimpleSelect)

/**
 * Standard text input
 * @param {Object} props
 */
export const TextField = withFormsy(BaseTextField)

/**
 * Standard email input
 * @param {Object} props
 */
export const EmailField = (props) => (
  <TextField
    type='email'
    maxLength={EMAIL_MAX_LENGTH}
    validations={{
      isEmail: true,
      maxLength: EMAIL_MAX_LENGTH,
    }}
    validationErrors={{
      isEmail: 'Invalid email address',
      maxLength: EMAIL_MAX_LENGTH + ' characters maximum',
    }}
    {...props}
  />
)
EmailField.propTypes = BaseTextField.propTypes

const StyledDatePicker = styled(DatePicker)`
  margin-top: 0 !important;
`

const ErrorText = styled.p`
  color: #f44336;
`

export const DateField = ({ label, format, ...props }) => {
  return (
    <StyledDatePicker
      variant='inline'
      format={format || datePickerFormat}
      margin='normal'
      id='date picker inline'
      label={label || 'Date'}
      {...props}
    />
  )
}

export const FormsyDateField = withFormsy(({ setValue, value, name, label, format, ...props }) => {
  const error = props.getErrorMessage

  return (
    <div>
      <DateField
        onChange={() => {}}
        onAccept={(m) => setValue(m.toDate())}
        value={moment(value)}
        name={name}
        label={label}
        format={format}
        {...props}
      />
      {error && <ErrorText className='MuiFormHelperText-root Mui-error'>{error}</ErrorText>}
    </div>
  )
})

addValidationRule('isValidPhone', (values, value) => {
  if (typeof value !== 'string') {
    return false
  }
  return SUPPORTED_COUNTRIES.map((c) => isValidNumber(value, c)).find((p) => p)
})

class BasePhoneField extends React.Component {
  constructor(props) {
    super(props)

    this.changeValue = this.changeValue.bind(this)
  }

  changeValue(event) {
    this.props.setValue(new AsYouType('NZ').input(event.target.value))
  }

  render() {
    const inputType = 'tel'
    const required = this.props.showRequired
    const label = this.props.label
    const value = this.props.value || ''
    const disabled = this.props.disabled || false

    let error = false
    let message = ''

    // Don't show errors until the user starts entering input
    if (!this.props.isPristine && this.props.showError) {
      error = true
      message = this.props.getErrorMessage
    }

    // if the user has been to a required input but not entered anything,
    // show an error
    if (required && !error && !this.props.isPristine) {
      error = true
      message = 'This field is required'
    }

    return (
      <div>
        <MaterialTextField
          fullWidth={true}
          disabled={disabled}
          type={inputType}
          onChange={this.changeValue}
          value={value}
          error={error}
          helperText={message}
          required={required}
          label={label}
        />
      </div>
    )
  }
}

/**
 * Standard phone number input
 * @param {Object} props
 */
export const PhoneFieldHOC = withFormsy(BasePhoneField)

/**
 * Validated phone input
 * @param {Object} props
 */
export const PhoneField = (props) => (
  <PhoneFieldHOC validations='isValidPhone' validationError='Invalid phone number' {...props} />
)

/**
 * Standard postal code input
 * @param {Object} props
 */
export const PostalCodeField = (props) => (
  <TextField
    type='number'
    length={POSTALCODE_LENGTH}
    validations={'isLength:' + POSTALCODE_LENGTH + ',isNumeric:true'}
    validationErrors={{
      isLength: 'must be ' + POSTALCODE_LENGTH + ' digits',
      isNumeric: 'must be digits',
    }}
    {...props}
  />
)

/**
 * Standard password input
 * @param {Object} props
 */
export const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      maxLength={MISC_MAX_LENGTH}
      validations={'maxLength:' + MISC_MAX_LENGTH}
      validationErrors={{
        maxLength: MISC_MAX_LENGTH + ' characters maximum',
      }}
      muiExtra={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
PasswordField.propTypes = BaseTextField.propTypes

addValidationRule('isNAITNumber', function(values, value) {
  var length = value ? value.toString().length : 0
  return (length <= 6 && length >= 2) || length === 8
})

/**
 * Standard postal code input
 * @param {Object} props
 */
export const NAITNumberField = (props) => (
  <TextField
    type='number'
    validations={'isNAITNumber:'}
    validationErrors={{
      isLength: 'must be 2-6 or 8 digits',
      isNumeric: 'must be digits',
    }}
    {...props}
  />
)

export const FOLNumberField = (props) => <TextField type='text' {...props} />

addValidationRule('withinRange', function(values, value, limits) {
  return Number(value) <= Number(limits[1]) && Number(value) >= Number(limits[0])
})
addValidationRule('maximumValue', function(values, value, maximum) {
  return Number(value) <= Number(maximum)
})
addValidationRule('minimumValue', function(values, value, minimum) {
  return Number(value) >= Number(minimum)
})

export const PositiveNumberField = (props) => (
  <TextField
    type='number'
    validations={{
      minimumValue: 0,
    }}
    validationErrors={{
      minimumValue: 'Must be zero or more',
    }}
    {...props}
  />
)

// addValidationRule('isMoreThan', function (values, value, otherField) {
//   // The this context points to an object containing the values
//   // {childAge: "", parentAge: "5"}
//   // otherField argument is from the validations rule ("childAge")
//   return Number(value) > Number(values[otherField]);
// });

export const IntRangeField = (props) => (
  <TextField
    type='number'
    extra={{ step: props.step || '1' }}
    validations={`withinRange: [${props.low}, ${props.high}]`}
    validationErrors={{
      withinRange: `need to be within ${props.low} - ${props.high}`,
    }}
    {...props}
  />
)

export const VIDField = (props) => (
  <TextField
    type='text'
    validations={'maxLength:' + 20}
    validationErrors={{
      maxLength: '20 characters maximum',
    }}
    {...props}
  />
)

export const EIDField = (props) => (
  <TextField
    type='number'
    validations={'maxLength:' + 16}
    validationErrors={{
      maxLength: '16 digits maximum',
    }}
    {...props}
  />
)

/**
 * Disabled input for information
 * @param {Object} props
 */
export const ReadOnlyField = (props) => <TextField type='text' disabled={true} {...props} />

const menuItemStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'uppercase',
    fontSize: 14,
    '&:hover': {
      backgroundColor: `${colors.lightGrey} !important`,
      color: 'black',
    },
  },
  selected: {
    backgroundColor: `${colors.grey} !important`,
    color: 'white',
  },
})

const selectStyles = makeStyles({
  icon: {
    color: (props) => (props.color ? props.color : 'black'),
  },
})

const menuStyles = makeStyles({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const backgroundColorStyle = (props) => (props.backgroundColor ? props.backgroundColor : colors.green)

export const inputBaseStyles = makeStyles({
  focused: {
    borderRadius: 4,
    backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : colors.green),
    color: (props) => `${props.color ? props.color : colors.white} !important`,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: backgroundColorStyle,
    color: (props) => `${props.color ? props.color : colors.white}`,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 'normal',
    padding: '10px 12px',
    textTransform: 'uppercase',
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: backgroundColorStyle,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    '&:focus': {
      borderRadius: 4,
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  disabled: {
    color: `${colors.white} !important`,
    backgroundColor: `${colors.grey} !important`,
    borderRadius: 4,
  },
})

// standard styled button
export const PushButton = (props) => {
  const inputClasses = inputBaseStyles({ ...props })
  inputClasses.root = inputClasses.input // button doesn't have an 'input' type, see docs
  return <Button classes={inputClasses} {...props} />
}

export const SELECT_PLACEHOLDER_OPTION_VALUE = 'placeholder'

export const SelectInput = ({
  onChange,
  value,
  error,
  helperText,
  required,
  label,
  choices,
  backgroundColor,
  color,
  disabled,
  fullWidth = false,
  withErrors = false,
  showPlaceholder = false,
  placeholder = 'Select',
}) => {
  const [selectChoices, setSelectChoices] = React.useState([])
  const inputClasses = inputBaseStyles({ backgroundColor, color, disabled })
  const menuItemClasses = menuItemStyles({ backgroundColor, color })
  const selectClasses = selectStyles({ color })
  const menuClasses = menuStyles()

  const menuProps = {
    classes: menuClasses,
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  }

  React.useEffect(() => {
    // make copy to avoid modifying original array
    const allChoices = [...choices]
    if (showPlaceholder && !allChoices.some((option) => option[0] === SELECT_PLACEHOLDER_OPTION_VALUE)) {
      allChoices.splice(0, 0, [SELECT_PLACEHOLDER_OPTION_VALUE, placeholder])
    }

    setSelectChoices(allChoices)
  }, [showPlaceholder, placeholder, choices])

  return (
    <div className={'SelectInput' + (error ? ' error' : '')}>
      <Select
        label={label + (required ? '*' : '')}
        data-cy='select-input'
        value={showPlaceholder && !value ? SELECT_PLACEHOLDER_OPTION_VALUE : value}
        onChange={onChange}
        classes={selectClasses}
        MenuProps={menuProps}
        fullWidth={fullWidth}
        disabled={disabled}
        input={<InputBase classes={inputClasses} />}
      >
        {selectChoices.map(([optionValue, optionLabel]) => (
          <MenuItem
            value={optionValue}
            key={optionValue}
            classes={menuItemClasses}
            disabled={optionValue === SELECT_PLACEHOLDER_OPTION_VALUE}
          >
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
      {withErrors && helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </div>
  )
}

/**
 * Base select field which integrates with formsy's validation hooks, and
 * standardises display properties
 * @type {Object}
 */
class BaseSelectField extends React.Component {
  // TODO abstract common parts of this and BaseTextField into a reusable
  // component. Probably need to separate the props which come from the
  // top-level HOC and pass those through as inputProps or something,
  // so they can just be spread to the root element
  constructor(props) {
    super(props)

    // TODO make this a stateless component as per acf150a1b4
    this.state = {
      focused: false,
      updated: false,
    }
    this.changeValue = this.changeValue.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
  }

  changeValue(event) {
    this.props.setValue(event.target.value)
    if (this.props.setOption) {
      this.props.setOption(event.target.value)
    }
  }

  onBlur() {
    this.setState({
      focused: false,
      updated: true,
    })
  }

  onFocus() {
    this.setState({
      focused: true,
    })
  }

  render() {
    const required = this.props.showRequired
    const label = this.props.label && `${this.props.label} ${this.props.required ? ' *' : ''}`
    const value = this.props.value || ''
    const disabled = this.props.disabled || false

    let error = false

    // not empty so that things don't jump around when the helperText is shown
    // or hidden in the UI
    let message = ' '

    // Don't show errors until the input has been updated
    if (this.state.updated && this.props.showError) {
      error = true
      message = this.props.getErrorMessage
    }

    // if the user has focused a required input but not entered anything,
    // show an error
    if (required && !error && this.state.updated) {
      error = true
      message = 'This field is required'
    }

    return (
      <div>
        <SelectInput
          {...this.props}
          choices={this.props.choices}
          onChange={this.changeValue}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          disabled={disabled}
          value={value}
          error={error}
          helperText={message}
          required={required}
          label={label}
        />
      </div>
    )
  }
}

/**
 * Standard select input
 * @param {Object} props
 */
export const SelectField = withFormsy(BaseSelectField)

/**
 * Material UI styles and formsy implementation of a
 * file input.
 * Takes a setSelectedFile prop which should be used to
 * handle the file in the parent component
 */
class BaseFileInput extends React.Component {
  render() {
    const required = this.props.showRequired
    const value = this.props.value || ''

    let label = 'Select File'
    if (this.props.selectedFile && this.props.selectedFile.name) {
      label =
        this.props.selectedFile.name.length > 16
          ? `${this.props.selectedFile.name.slice(0, 16)}...`
          : this.props.selectedFile.name
    }

    return (
      <div>
        <Button className='uploadButton' variant='contained' name='selectFile' disabled={this.props.disabled}>
          {label}
          <input
            type='file'
            accept={this.props.accept}
            required={required}
            value={value}
            onInput={(event) => {
              this.props.setSelectedFile(event.target.files[0])
            }}
          />
        </Button>
      </div>
    )
  }
}

/**
 * Standard file input
 * @param {Object} props
 */
export const FileInput = withFormsy(BaseFileInput)

export const SimpleFileInput = ({ setSelectedFile, selectedFile, required = false, disabled = false, accept = '' }) => {
  const label =
    selectedFile && selectedFile.name
      ? selectedFile.name.length > 16
        ? `${selectedFile.name.slice(0, 16)}...`
        : selectedFile.name
      : 'Select File'

  return (
    <Button className='uploadButton' raised name='selectFile' disabled={disabled}>
      {label}
      <input
        type='file'
        accept={accept}
        disabled={disabled}
        required={required}
        // value={value}
        onChange={(event) => {
          setSelectedFile(event.target.files[0])
        }}
      />
    </Button>
  )
}

/**
 * Base checkbox field which integrates with formsy's validation hooks, and
 * standardises display properties
 * @type {Object}
 */
class BaseCheckboxField extends React.Component {
  onChange = (event, checked) => {
    // return true or undefined, because formsy considers false to be a valid
    // value for a required field, so otherwise an unchecked checkbox doesn't
    // trigger required field validation
    if (this.props.onChange) {
      this.props.onChange(event.target.checked)
    }
    return this.props.setValue(checked)
  }

  componentDidMount() {
    // without this formsy for some reason doesn't accept the initial state
    this.props.setValue(this.props.checked || undefined)
  }

  render() {
    const { error, disabled, checked, value, isPristine, label } = this.props

    return (
      <div className={'CheckboxField ' + (error ? 'error' : '')}>
        <FormControlLabel
          disabled={disabled || false}
          label={label}
          control={<Checkbox checked={!!value || (checked && isPristine)} onChange={this.onChange} />}
        />
        <p>{error || ' '}</p>
      </div>
    )
  }
}

/**
 * Standard checkbox input
 * @param {Object} props
 */
export const CheckboxField = withFormsy(BaseCheckboxField)

export const SimpleCheckboxField = ({ label, error, checked, onChange }) => (
  <div className={'CheckboxField ' + (error ? 'error' : '')}>
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={checked}
          onChange={(event) => {
            onChange(event.target.checked)
          }}
        />
      }
    />
    <p>{error || ' '}</p>
  </div>
)

const BaseCheckboxFieldWithId = (props) => {
  const onChange = (event, checked) => {
    props.onChange(checked, props.id)
  }
  return (
    <div className={'CheckboxField ' + (props.error ? 'error' : '')}>
      <label>
        <Checkbox checked={props.checked} onChange={onChange} />
        <span className='label'>{props.label}</span>
      </label>
      <p>{props.error || ' '}</p>
    </div>
  )
}

export const CheckboxFieldWithId = withFormsy(BaseCheckboxFieldWithId)

/**
 * Wrap a Form component to provide Formsy validation hooks
 * @param {Component} FormContent
 */
export const withFormValidation = (FormContent) => {
  // TODO look into traversing children, the way formsy does it, so that this
  // can just be a stateful component rather than a HOC
  class WrappedForm extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        canSubmit: false,
        initialised: false,
      }
      this.disableSubmit = this.disableSubmit.bind(this)
      this.enableSubmit = this.enableSubmit.bind(this)

      // TODO use propTypes to define the required props (submit, etc?)
    }

    enableSubmit() {
      this.setState({
        canSubmit: true,
      })
    }

    disableSubmit() {
      this.setState({
        canSubmit: false,
      })
    }

    validateForm = (data) => {
      if (this.props.validateOnMount) {
        this.refs.form.setFormPristine(false)
        this.props.validate({ ...this.refs.form.getCurrentValues(), ...data })
        this.props.resetForm && this.props.resetForm()
      }
    }

    componentDidMount() {
      if (this.props.getInitialData) {
        this.props.getInitialData().then((data) => {
          this.setState(
            {
              initialised: true,
            },
            () => {
              this.refs.form.reset(data)
              this.validateForm(data)
            },
          )
        })
      } else {
        this.setState({ initialised: true }, this.validateForm)
      }
    }

    render() {
      // extract props for Formsy; spread any others to the root element
      const { submit, validate = undefined, validationErrors = undefined, ...props } = this.props

      if (this.state.initialised) {
        return (
          <Formsy
            ref='form'
            className='Form'
            onValidSubmit={submit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
            onChange={validate}
            validationErrors={validationErrors}
          >
            <FormContent canSubmit={this.state.canSubmit} {...props} />
          </Formsy>
        )
      } else {
        return null
      }
    }
  }

  return WrappedForm
}

/**
 * Wrap material's submit button to standardise styling
 * @param {Object} props
 */
export const SubmitButton = ({ children, ...props }) => {
  return (
    <div className='SubmitButton'>
      <Button type='submit' variant='contained' {...props}>
        {children}
      </Button>
    </div>
  )
}

SubmitButton.propTypes = {
  ...Button.propTypes,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),
}

/** A link styled to look like a submit button */
export const LinkButton = ({ children, to, buttonType = '', ...props }) => {
  return (
    <Button variant='contained' component={Link} to={to} className={'LinkButton ' + buttonType} {...props}>
      {children}
    </Button>
  )
}

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.alignItems ? props.alignItems : '')};
  border-bottom: ${(props) => (props.borderBottom ? `1px solid ${colors.borderGrey}` : '')};
  ${space}
  ${height}
  ${width}
`

export const FormField = styled.div`
  display: flex;
  ${width}
  ${maxWidth}
  ${flex}
  ${space}

  > div {
    width: 100%;

    > div {
      width: 100%;
    }
  }
`
