import React from 'react'
import { SliderField } from '../../../components/Slider/Slider'

const max = 50000
const min = 500
const step = 500
const perAnimalCost = 0.02

export const getSubscriptionCost = (val) => {
  return '$' + val * perAnimalCost + ' per month'
}

export const getSubscriptionRange = (val) => {
  if (val >= max) {
    return max + '+'
  } else {
    return (val === 500 ? 0 : Math.max(100, val - step) + 1) + '–' + val
  }
}

const getDisplayValue = (val) => {
  const amount = getSubscriptionRange(val)
  const cost = getSubscriptionCost(val)

  return (
    <span>
      {amount} — <strong>{cost}</strong>
    </span>
  )
}

/**
 * Slider form field for approximate animal count
 * @param {Object} props
 */
const AnimalSlider = ({ value, onChange }) => {
  return (
    <div>
      <SliderField
        value={value}
        onChange={onChange}
        name='animalCount'
        label='Number of active animals'
        min={min}
        max={max}
        step={step}
        getDisplayValue={getDisplayValue}
      />
    </div>
  )
}

export default AnimalSlider
