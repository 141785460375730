import React from 'react'
import MaterialTable from '../../components/Table/Table.js'
import { formatDisplayProperty, formatDisplayValue, formatTimestamp } from '../../helpers/utils'

import './Analysis.css'

const columns = [
  { id: 'element', numeric: false, label: 'Data Element' },
  { id: 'value_trunc', numeric: false, label: 'Value' },
  { id: 'entryType', numeric: false, label: 'Entry Type' },
  { id: 'eventName_trunc', numeric: false, label: 'Event Name' },
  { id: 'createDate', numeric: false, label: 'Date' },
]

class History extends React.Component {
  formatHistoryData(data) {
    return data.map((item) => {
      const value = formatDisplayValue(item)
      const truncateValue = value && value.length > 30

      const truncateEventName = item.eventName && item.eventName.length > 20

      return {
        element: formatDisplayProperty(item.element),
        value_trunc: [truncateValue, truncateValue ? value.substring(0, 20) + '...' : value, value],
        entryType: item.entryType,
        eventName_trunc: [
          truncateEventName,
          truncateEventName ? item.eventName.substring(0, 13) + '...' : item.eventName,
          item.eventName,
        ],
        createDate: formatTimestamp(item.createDate),
      }
    })
  }

  render() {
    return this.props.historyData && this.props.historyData.length > 0 ? (
      <div className='farmSelectorContainer'>
        <MaterialTable expand columns={columns} data={this.formatHistoryData(this.props.historyData)} />
      </div>
    ) : null
  }
}

export default History
