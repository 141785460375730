// @ts-ignore
import config from 'config'

import { get, put } from '../../helpers/fetch'
import { getAuthHeaders } from '../../helpers/firebase'
import { calculateAnimalCount } from './animal_count'
import { AverageScanResultMetric, averageScanResultMetric, calculateAverageScanResult } from './average_scan_result'
import { calculateAverageWeight } from './average_weight'
import { calculateAverageWeightAtWeaning } from './average_weight_at_weaning'
import { calculateDamCondition, DamConditionMetric, damConditionMetric } from './dam_condition'
import { calculateGroupWeight, groupWeightMetric } from './group_weight'
import { calculateSurvivalRate } from './survival_rate'
import { calculateWeightDistribution, weightDistributionMetric } from './weight_distribution'

export const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export type TimePeriod = 'Last Month' | 'Last Year' | 'Last 2 Years' | 'Last 3 Years' | 'Last 5 Years' | 'Last 10 Years'

export type DashboardMetricType =
  | 'Dam Condition'
  | 'Average Scan Result'
  | 'Survival Rate'
  | 'Average Weight at Weaning'
  | 'Offspring Weight'
  | 'Animal Count'
  | 'Average Weight'
  | 'Weight Distribution'
  | 'Group Weight'
export const dashboardMetricTypes: readonly DashboardMetricType[] = [
  'Dam Condition',
  'Average Scan Result',
  // 'Survival Rate',
  // 'Average Weight at Weaning',
  // 'Offspring Weight',
  // 'Animal Count',
  // 'Average Weight',
  'Weight Distribution',
  'Group Weight',
]
export const dashboardMetricTypeMap = {
  DamCondition: 'Dam Condition',
  AverageScanResult: 'Average Scan Result',
  WeightDistribution: 'Weight Distribution',
  GroupWeight: 'Group Weight',
}

export interface DashboardMetric {
  readonly type: DashboardMetricType
}

export interface DashboardMetricChartSettings {
  readonly type: string
  readonly options: any
}

export interface DateRange {
  readonly from: string
  readonly to: string
}

export function defaultMetricForType(type: DashboardMetricType | null): DashboardMetric | null {
  switch (type) {
    case 'Dam Condition': {
      return damConditionMetric
    }
    case 'Average Scan Result': {
      return averageScanResultMetric
    }
    case 'Weight Distribution': {
      return weightDistributionMetric
    }
    case 'Group Weight': {
      return groupWeightMetric
    }
  }
  return null
}

export function calculateMetric(farmId: string, metric: DashboardMetric, position: number): Promise<any> {
  switch (metric.type) {
    case 'Dam Condition': {
      return calculateDamCondition(farmId, metric as DamConditionMetric, position)
    }
    case 'Average Scan Result': {
      return calculateAverageScanResult(farmId, metric as AverageScanResultMetric, position)
    }
    case 'Survival Rate': {
      return calculateSurvivalRate(farmId, metric)
    }
    case 'Average Weight at Weaning': {
      return calculateAverageWeightAtWeaning(farmId, metric)
    }
    case 'Animal Count': {
      return calculateAnimalCount(farmId, metric)
    }
    case 'Average Weight': {
      return calculateAverageWeight(farmId, metric)
    }
    case 'Weight Distribution': {
      return calculateWeightDistribution(farmId, metric)
    }
    case 'Group Weight': {
      return calculateGroupWeight(farmId, metric)
    }
  }
}

export function timePeriodToDateFrom(period: TimePeriod): Date {
  const from: Date = new Date()

  switch (period) {
    case 'Last Month':
      return from
    case 'Last Year':
      from.setMonth(from.getMonth() - 11)
      return from
    case 'Last 2 Years':
      from.setMonth(from.getMonth() - 23)
      return from
    case 'Last 3 Years':
      from.setMonth(from.getMonth() - 35)
      return from
    case 'Last 5 Years':
      from.setMonth(from.getMonth() - 59)
      return from
    case 'Last 10 Years':
      from.setMonth(from.getMonth() - 119)
      return from
  }
}

export function formatDateRange(from: Date, to: Date): DateRange {
  return {
    from: from.toISOString().split('T')[0],
    to: to.toISOString().split('T')[0],
  }
}

export function formatMonth(date: string): string {
  const parts: string[] = date.split('-')
  const year: string = parts[0].slice(2, 4)
  const month: string = months[parseFloat(parts[1]) - 1]

  return `${month} '${year}`
}

// TODO remove when not needed
export function processFakeData(input: string): any {
  const result = []

  input.split('\n').forEach((line) => {
    const elements = line.split(',')
    result.push(
      elements.map((element) => {
        if (!isNaN(element)) {
          if (element.length === 0) {
            return null
          } else {
            return Number(element)
          }
        } else {
          return element
        }
      }),
    )
  })

  return result
}

interface ChartPosition {
  readonly position: number
  readonly type: DashboardMetricType
}

export async function getPositions(farmId: string): Promise<ChartPosition[]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/positions`
  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export async function setPosition(farmId: string, position: number, metricType: DashboardMetricType): Promise<void> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/positions/${position}/${metricType.replace(/ /g, '')}`
  const headers = await getAuthHeaders()
  put(url, {}, headers)
}
