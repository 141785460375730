import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Edit from '@material-ui/icons/Edit'
import _ from 'lodash'
import React from 'react'

import FormDialog from '../../../components/Dialog/FormDialog'
import { FarmNameField, NaitField, NaitPassword, NaitUsername } from '../../../components/Form/CustomFields.js'
import { ReadOnlyField } from '../../../components/Form/Form'
import { requireAuth } from '../../../components/withAuth'
import { saveFarm } from '../../../data/farm'
import { clearNaitCredentialsForFarm, getNaitCredentialsForFarm, setNaitCredentialsForFarm } from '../../../data/nait'

type Props = {
  open: boolean
  farmId: string
  farmName: string
  naitNumber: string
  onRequestClose: () => void
}

type Data = {
  farmName?: string
  naitNumber?: string
  naitUsername?: string
  naitPassword?: string
}

type NaitCredentials = {
  username: string
  password?: string
}

type ValidationErrors = {
  naitPassword?: string
}

type State = {
  hasChanged: boolean
  data: Data
  editingNaitPassword: boolean
  naitPasswordExists: boolean
  loaded: boolean
  validationErrors: ValidationErrors
}

type FormProps = { data: Data; editingNaitPassword: boolean; naitPasswordExists: boolean; editNaitPassword: () => void }

const editNaitForm = ({ data, editingNaitPassword, naitPasswordExists, editNaitPassword }: FormProps): JSX.Element => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <FarmNameField name='farmName' label='Farm Name' value={data.farmName} required />
    </Grid>

    <Grid item xs={6}>
      <NaitField name='naitNumber' value={data.naitNumber} label='NAIT # (2-6 or 8 digits)' />
    </Grid>

    <Grid item xs={6}>
      <NaitUsername name='naitUsername' value={data.naitUsername} />
    </Grid>

    <Grid item xs={6}>
      {editingNaitPassword || !naitPasswordExists ? (
        <NaitPassword />
      ) : (
        <ReadOnlyField
          className='Description'
          name='naitPasswordPlaceholder'
          label='NAIT System Password'
          value='******'
          muiExtra={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='edit password' onClick={editNaitPassword} edge='end'>
                  <Edit />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Grid>
  </Grid>
)

class EditNaitDetailsDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hasChanged: false,
      data: {},
      editingNaitPassword: false,
      naitPasswordExists: false,
      loaded: false,
      validationErrors: {},
    }
  }

  componentDidMount(): void {
    this.getNaitCreds()
    this.setState({
      data: { ...this.state.data, farmName: this.props.farmName, naitNumber: this.props.naitNumber },
    })
  }

  getNaitCreds = async (): Promise<void> => {
    const creds = await getNaitCredentialsForFarm(this.props.farmId)
    this.setState({
      data: {
        ...this.state.data,
        naitUsername: creds.username,
        // password is never returned by API for security reasons
        // password exists iff username is set
      },
      naitPasswordExists: creds.username && creds.username.length > 2,
      loaded: true,
    })
  }

  handleRequestClose = (): void => {
    this.props.onRequestClose()
  }

  validate = (data: Data): void => {
    const validationErrors: ValidationErrors = {}
    const { naitUsername, naitPassword } = data

    if (naitUsername && !naitPassword && !this.state.naitPasswordExists) {
      validationErrors.naitPassword = 'Both NAIT username and password must be present'
    }

    this.setState({ data, hasChanged: true, validationErrors })
  }

  submit = (data: Data): void => {
    const promises = []
    const onSave = (results: { success: boolean }[]) => {
      if (!results.every(({ success }) => success)) {
        console.log(results)
      } else {
        this.handleRequestClose()
      }
    }

    promises.push(saveFarm(this.props.farmId, _.pick(data, ['farmName', 'naitNumber'])))

    const { naitUsername, naitPassword } = data

    // have some data, could be just username
    if (naitUsername) {
      const credentials: NaitCredentials = { username: naitUsername }
      if (this.state.editingNaitPassword || !this.state.naitPasswordExists) {
        credentials.password = naitPassword
      }

      promises.push(setNaitCredentialsForFarm(this.props.farmId, credentials))
    }
    // no data, but there was data when we started, so delete
    else if (this.state.naitPasswordExists) {
      promises.push(clearNaitCredentialsForFarm(this.props.farmId))
    }

    Promise.all(promises).then(onSave)
  }

  render(): JSX.Element {
    const { open } = this.props
    const { loaded, data, hasChanged, naitPasswordExists, editingNaitPassword, validationErrors } = this.state

    return (
      <>
        <FormDialog
          title='Nait Details'
          hasSubmit
          maxWidth='sm'
          open={open && loaded}
          submit={this.submit}
          validate={this.validate}
          hasChanged={hasChanged}
          onClose={this.handleRequestClose}
          FormComponent={editNaitForm}
          data={data}
          validationErrors={validationErrors}
          naitPasswordExists={naitPasswordExists}
          editingNaitPassword={editingNaitPassword}
          editNaitPassword={() => this.setState({ editingNaitPassword: true })}
        />
      </>
    )
  }
}

export default requireAuth(EditNaitDetailsDialog)
