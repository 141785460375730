import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { PushButton } from '../../components/Form/Form'

const makeCountStyles = makeStyles({
  root: {
    float: 'left',
    width: 0,
    whiteSpace: 'nowrap',
    fontSize: '80%',
    marginTop: '0.5em',
  },
})

const ViewList = (props) => {
  const countStyles = makeCountStyles()
  const haveCount = typeof props.count === 'number'

  return (
    <Box paddingTop={1} paddingBottom={2}>
      {haveCount && <p className={countStyles.root}>Count: {props.count}</p>}
      <Box display='flex' justifyContent='center'>
        <PushButton width='100px' onClick={props.onClickViewList} disabled={!haveCount || props.count === 0}>
          View List
        </PushButton>
      </Box>
    </Box>
  )
}

export default ViewList
