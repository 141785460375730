import React from 'react'
import { withFormValidation, SelectField, EmailField, SelectInput } from '../../../components/Form/Form'
import {
  setInviteRole,
  getAccountInvitations,
  deleteInvitation,
  inviteAccountUser,
  removeAccountUser,
  setAccountUserRole,
  getAccountRoles,
} from '../../../data/billing'
import { requireAuth } from '../../../components/withAuth'
import { Dialog, Button } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import PersonAdd from '@material-ui/icons/PersonAdd'
import MaterialTable from '../../../components/Table/Table'
import { ActionHeaderButton } from '../../../components/PageHeaders'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import { DialogContent } from '../../../components/Dialog/FormDialog'

import { roleChoices } from '../../../constants'
import { colors } from '../../../styles/styleConstants'
import '../../../components/Dialog/Dialog.css'

// some choices are valid, but cannot be selected by user
const allowedRoles = new Set(['Account', 'Vet', 'Admin', 'Retail'])
const getRoleChoices = (choices) => choices.filter(([key]) => allowedRoles.has(key))
const getRoleOptions = (choices, current) => {
  let disabled = !allowedRoles.has(current)
  if (!disabled) choices = getRoleChoices(choices)
  disabled = disabled || choices.length < 2
  return [disabled, choices]
}

const AddUserFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <EmailField name='email' label='Email Address' required />
    </div>
    <div className='FormRow'>
      <SelectField
        name='role'
        label='Select Role'
        choices={props.roleChoices}
        value={props.roleChoices[props.roleChoices.length - 1][0]}
        backgroundColor={colors.green}
        color={colors.white}
        required
        showPlaceholder
      />
    </div>

    <div className='ButtonWrap'>
      <ActionHeaderButton variant='contained' className='Back dialogBack' onClick={props.back}>
        Cancel
      </ActionHeaderButton>
      <ActionHeaderButton disabled={!props.canSubmit} type='submit'>
        Add User
      </ActionHeaderButton>
    </div>
  </div>
)

const AddUserForm = withFormValidation(AddUserFormContent)

const UserTable = ({ data }) => {
  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role', style: { paddingRight: '50px', paddingBottom: '10px' } },
    { id: 'status', label: 'Status' },
    { id: 'resend', label: '' },
    { id: 'deleteButton', label: '' },
  ].map((column) => ({
    ...column,
    disableSort: true,
    style: { ...column.style, whiteSpace: 'nowrap', verticalAlign: 'middle' },
  }))

  return <MaterialTable columns={columns} data={data} disableSort />
}

const RoleSelect = (props) => (
  <SelectInput
    name='role'
    backgroundColor={props.disabled ? colors.grey : colors.green}
    color={colors.white}
    size='small'
    {...props}
  />
)

class EditUsersDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      validationErrors: {},
      accountUsers: [],
      accountInvitations: [],
    }
  }

  componentDidMount() {
    this.updateAccountUsers()
  }

  validate = () => {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose = () => {
    this.back()
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          errorMessage: result.error,
        })
      } else {
        this.updateAccountUsers()
        this.back()
      }
    }

    this.setState({ errorMessage: '' })
    inviteAccountUser(this.props.accountId, data.email, data.role).then(onSave)
  }

  removeUser = (uid) => {
    return () => {
      removeAccountUser(this.props.accountId, uid).then(() => {
        this.updateAccountUsers()
      })
    }
  }

  deleteInvite = (inviteId) => {
    return () => {
      deleteInvitation(this.props.accountId, inviteId).then(() => {
        this.updateAccountUsers()
      })
    }
  }

  resendInvite = (inviteId, inviteeEmail, inviteeRole) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          errorMessage: result.error,
        })
      } else {
        this.updateAccountUsers()
        this.back()
      }
    }

    deleteInvitation(this.props.accountId, inviteId).then(() => {
      inviteAccountUser(this.props.accountId, inviteeEmail, inviteeRole).then(onSave)
    })
  }

  changeUserRole = (userId, role) => {
    setAccountUserRole(this.props.accountId, userId, role)

    this.state.accountUsers.forEach((user) => {
      if (user.firebaseUserId === userId) user.role = role
    })

    this.forceUpdate()
  }

  changeInviteRole = (inviteId, role) => {
    setInviteRole(this.props.accountId, inviteId, role)

    this.state.accountInvitations.forEach((invite) => {
      if (invite.id === inviteId) invite.role = role
    })

    this.forceUpdate()
  }

  updateAccountUsers = () => {
    Promise.all([getAccountRoles(this.props.accountId), getAccountInvitations(this.props.accountId)]).then(
      ([accountUsers, accountInvitations]) => {
        this.setState({
          accountUsers,
          accountInvitations,
        })
      },
    )
  }

  onAddUserClicked = () => {
    this.setState({
      addUser: true,
    })
  }

  back = () => {
    this.setState({
      addUser: false,
    })
  }

  formatData = (users, invitations) => [
    ...users.map((user) => {
      const [disabled, choices] = getRoleOptions(roleChoices[this.props.accountType], user.role)
      const ownRole = this.props.uid === user.firebaseUserId

      return {
        name: user.user.name || '-',
        email: user.user.email,
        role: (
          <RoleSelect
            choices={choices}
            value={user.role}
            disabled={disabled || ownRole}
            onChange={(e) => this.changeUserRole(user.firebaseUserId, e.target.value)}
          />
        ),
        status: 'Active',
        resend: '',
        deleteButton:
          allowedRoles.has(user.role) && !ownRole ? (
            <a onClick={this.removeUser(user.firebaseUserId)}>
              <Delete />
            </a>
          ) : (
            ''
          ),
      }
    }),
    ...invitations.map((invite) => {
      const [disabled, choices] = getRoleOptions(roleChoices[this.props.accountType], invite.role)

      return {
        name: '-',
        email: invite.email,
        role: (
          <RoleSelect
            choices={choices}
            value={invite.role}
            disabled={disabled}
            onChange={(e) => this.changeInviteRole(invite.id, e.target.value)}
          />
        ),
        status: 'Invite Pending',
        resend: (
          <ActionHeaderButton
            onClick={() => {
              this.resendInvite(invite.id, invite.email, invite.role)
            }}
            size='small'
            type='submit'
            variant='contained'
          >
            Resend
          </ActionHeaderButton>
        ),
        deleteButton: (
          <a onClick={this.deleteInvite(invite.id)}>
            <Delete size='small' />
          </a>
        ),
      }
    }),
  ]

  render() {
    const data = this.formatData(this.state.accountUsers, this.state.accountInvitations)
    const title = this.state.addUser ? 'Add User' : 'Edit Users'
    let addUserButton = undefined

    if (!this.state.addUser) {
      addUserButton = (
        <Button className='dialogHeaderButtons' onClick={this.onAddUserClicked} aria-label='Add User'>
          <PersonAdd />
          &nbsp;ADD
        </Button>
      )
    }

    return (
      <Dialog className='dialog edit-users-dialog' fullWidth onClose={this.handleRequestClose} open maxWidth='md'>
        <DialogHeader title={title} hasSubmit={false} headerRight={addUserButton} />

        <DialogContent>
          <>
            {this.state.errorMessage && <div className='errorMessage'>{this.state.errorMessage}</div>}
            {this.state.addUser ? (
              <AddUserForm
                roleChoices={getRoleChoices(roleChoices[this.props.accountType])}
                submit={this.submit}
                back={this.back}
                validate={this.validate}
                validationErrors={this.state.validationErrors}
                error={this.state.error}
              />
            ) : (
              <div>
                <UserTable data={data} />
              </div>
            )}
          </>
        </DialogContent>
      </Dialog>
    )
  }
}

export default requireAuth(EditUsersDialog)
