import React from 'react'
import { Delete, DeleteOutlined } from '@material-ui/icons'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { Checkbox } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import moment from 'moment'

import { colors } from '../../../../../styles/styleConstants'
import { eventTypeChoicesMap } from '../../../../../constants'

export default ({ data, onToggle, setEventToEdit, setEventToDelete }) => {
  let backgroundColor = colors.green
  let color = '#ffffff'
  const today = moment().startOf('day')

  const { id, eventName, eventType, dueDate, completed, dependentEventId, hasChildren } = data
  const eventTypeName = eventTypeChoicesMap[eventType]

  // TODO remove dueDate._i hack
  // somehow day of month get reset to 1
  // seems like it occurs when moment(moment()) happens which looks like a bug
  // but I can't find any place where that would happen
  const realDueDate = moment(dueDate._i)

  if (completed) {
    backgroundColor = '#a1a1a1'
    color = '#555555'
  } else if (realDueDate.isBefore(today)) {
    backgroundColor = colors.red
  } else if (realDueDate.isBefore(today.add(15, 'days'))) {
    backgroundColor = colors.yellow
  }

  let DeleteIcon
  let deleteIconTooltip

  if (dependentEventId) {
    DeleteIcon = DeleteDot
    deleteIconTooltip = 'Date dependent on other events'
  } else {
    if (hasChildren) {
      DeleteIcon = DeleteOutlined
      deleteIconTooltip = 'Fixed date, dependent events'
    } else {
      DeleteIcon = Delete
      deleteIconTooltip = 'Fixed date, no dependencies'
    }
  }

  return (
    <EventCardContainer key={id}>
      <EventCard backgroundColor={backgroundColor} color={color}>
        <Section className='--left'>
          <Checkbox checked={completed} onClick={() => onToggle(id, !completed)} color='#ffffff' icon={CheckboxIcon} />
          <EventTypeBox>
            <Tooltip title={eventTypeName} enterDelay={100}>
              <div>{eventTypeName.slice(0, 1)}</div>
            </Tooltip>
          </EventTypeBox>
        </Section>
        <Section className='--center'>
          <Tooltip title={eventName} enterDelay={300}>
            <a onClick={() => setEventToEdit({ ...data, dueDate: realDueDate })}>{eventName}</a>
          </Tooltip>
        </Section>
        <Section className='--right'>
          <Tooltip title={deleteIconTooltip} enterDelay={100}>
            <DeleteIcon onClick={() => setEventToDelete(data)} />
          </Tooltip>
          <p>{realDueDate.format('D/M')}</p>
        </Section>
      </EventCard>
    </EventCardContainer>
  )
}

const EventCardContainer = styled.div`
  width: 164px;
  height: 50px;
`

const EventCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 3px;
  width: 160px;
  height: 46px;
`

const EventTypeBox = styled.div`
  display: block;
  height: 1.2em;
  width: 1.2em;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  font-size: 12px;
  margin-top: 3px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;

  a {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  p {
    font-size: 11px;
  }

  &.--left {
    padding-left: 5px;
    margin-right: 4px;
    align-items: center;
    justify-content: flex-start;

    svg {
      color: white;
      height: 18px;
    }

    span {
      padding: 0;
    }
  }

  &.--center {
    flex-grow: 1;
    align-items: flex-start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 64px;
    line-height: 16px;
  }

  &.--right {
    align-items: flex-end;
    svg {
      color: white;
      cursor: pointer;
      height: 16px;
    }

    p {
      padding: 4px 6px 0 0;
    }
  }
`

const CheckboxIcon = (
  <svg className='MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' ariaHidden='true' role='presentation'>
    <path
      id='svg_1'
      d='m19,5l0,14l-14,0l0,-14l14,0m0,-2l-14,0c-1.1,0 -2,0.9 -2,2l0,14c0,1.1 0.9,2 2,2l14,0c1.1,0 2,-0.9 2,-2l0,-14c0,-1.1 -0.9,-2 -2,-2z'
    />
    <path id='svg_2' fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z' />
    <rect
      id='svg_3'
      height='14.47759'
      width='14.328336'
      y='4.720394'
      x='4.798517'
      fillOpacity='null'
      strokeOpacity='null'
      strokeWidth='null'
      stroke='null'
      fill='#ffffff'
    />
  </svg>
)

// regular material-ui delete-outlined icon (trash) with dot in middle
const DeleteDot = createSvgIcon(
  <>
    <path
      id='svg_1'
      d='M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 z M8,9 L16,9 L16,19 L8,19 L8,9 z M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 z'
    />
    <path
      id='svg_2'
      d='M12,16.862 C10.388,16.862 9.082,15.555 9.082,13.944 C9.082,12.332 10.388,11.026 12,11.026 C13.612,11.026 14.918,12.332 14.918,13.944 C14.918,15.555 13.612,16.862 12,16.862 z'
    />
  </>,
  'DeleteDot',
)
