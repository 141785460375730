import React from 'react'
import { withFormValidation, SelectField, SubmitButton } from '../../components/Form/Form'
import { VisualTagField } from '../../components/Form/CustomFields'
import { animals, tagColours } from '../../helpers/constants'
import { colors } from '../../styles/styleConstants'

const colourOptions = tagColours.map((c) => [c, c])

const animalTypeOptions = animals.map((a) => [a, a])

const yearOptions = (manual) => {
  let range = []
  for (var i = new Date().getFullYear(); i >= 2007; i--) {
    range.push([i, i])
  }

  if (manual) {
    range.unshift([9999, 9999])
  }

  return range
}

const TagDetailsFormContent = (props) => (
  <div className='tagDetailsForm'>
    <div className='FormRow inline'>
      <VisualTagField
        name='startVid'
        label='VID # (From)'
        disabled={!props.manual}
        manual={props.manual}
        required={props.manual}
      />
      <VisualTagField
        name='endVid'
        label='VID # (To)'
        disabled={
          !(props.manual || (props.data.allowRangeAssign && props.data && props.data.endVid !== props.data.startVid)) ||
          !props.endVidEnabled
        }
        min={props.data && props.data.startVid}
        max={props.data && props.data.endVid}
        manual={props.manual}
        required={props.manual}
      />
    </div>
    <div className='FormRow'>
      <SelectField
        name='animalType'
        placeholder='Animal Type'
        choices={animalTypeOptions}
        disabled={props.data && props.data.animalType}
        backgroundColor={colors.green}
        color={colors.white}
        required
        showPlaceholder
      />
    </div>
    <div className='FormRow'>
      <SelectField
        name='vidYear'
        placeholder='Year'
        choices={yearOptions(props.manual)}
        disabled={props.data && props.data.vidYear}
        backgroundColor={colors.green}
        color={colors.white}
        required
        showPlaceholder
      />
    </div>
    <div className='FormRow'>
      <SelectField
        name='tagColor'
        placeholder='Tag Colour'
        choices={colourOptions}
        disabled={props.data && props.data.tagColor}
        backgroundColor={colors.green}
        color={colors.white}
        required
        showPlaceholder
      />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Complete</SubmitButton>
    </div>
  </div>
)

const TagDetailsForm = withFormValidation(TagDetailsFormContent)

export default class DetailsForm extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)

    this.state = {
      validationErrors: {},
      endVidEnabled: false,
    }
  }

  getInitialData = async () => {
    // values could be ints, but VID field is text and it refuses to validate if value is not text
    const data = { ...this.props.data }
    data.startVid = data.startVid ? data.startVid.toString() : undefined
    data.endVid = data.endVid ? data.endVid.toString() : undefined
    return data
  }

  validate(data) {
    const errors = {}
    const startVid = parseInt(data.startVid, 10)
    const endVid = parseInt(data.endVid, 10)

    if (
      ((this.props.data && !(this.props.data.startVid && this.props.data.endVid)) || !this.props.data) &&
      startVid > endVid
    ) {
      errors.endVid = 'End VID must be greater than start VID'
    }

    this.setState({
      validationErrors: errors,
      endVidEnabled: startVid > 0,
    })

    this.props.setHasChanged && this.props.setHasChanged()
  }

  render() {
    return (
      <TagDetailsForm
        {...this.props}
        endVidEnabled={this.state.endVidEnabled}
        validate={this.validate}
        validationErrors={this.state.validationErrors}
        getInitialData={this.getInitialData}
      />
    )
  }
}
