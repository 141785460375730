import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useAccount } from '../../components/AccountContext'
import WarningDialog from '../../components/Dialog/WarningDialog'
import FarmSelector from '../../components/FarmSelector'
import { SimpleFileInput } from '../../components/Form/Form'
import Layout from '../../components/Layout'
import { ActionHeader, ActionHeaderButton, ButtonContainer, PageTitle } from '../../components/PageHeaders'
import { AccessDenied, requireAuth } from '../../components/withAuth'
import {
  getDuplicatesForRecordImport,
  getErrorsForRecordImport,
  getRecordImportsForFarm,
  postRecordImport,
  RecordImport,
  RecordImportDuplicate,
  RecordImportError,
} from '../../data/import_records'
import DuplicatesTable from './DuplicatesTable'
import ErrorReportTable from './ErrorReportTable'
import { ImportHistoryTable } from './ImportHistoryTable'

interface ImportRecordsProps {
  match: { params: { farmId?: string; importId?: string; importMode?: string } }
}

const FileInputContainer = styled.div`
  margin-right: 20px;
  position: relative;
`

interface Warning {
  readonly title: string
  readonly message: string
}

export const ImportRecords: React.FunctionComponent<ImportRecordsProps> = requireAuth(
  (props: ImportRecordsProps) => {
    const { farms, user, hasFarmRole } = useAccount('Farm')
    const history = useHistory()
    const { farmId, importId, importMode } = props.match.params
    const [loading, setLoading] = useState(false)
    const [warningInfo, setWarningInfo] = useState<Warning>()
    const [imports, setImports] = useState<RecordImport[]>()
    const [recordErrors, setRecordErrors] = useState<{ [importId: string]: RecordImportError[] }>({})
    const [duplicateRecords, setDuplicateRecords] = useState<{ [importId: string]: RecordImportDuplicate[] }>({})
    const [selectedFile, setSelectedFile] = useState<File>()

    const getData = useCallback(
      (clear = false) => {
        if (!farmId) {
          return
        }

        setLoading(true)
        if (clear) {
          setImports([])
        }
        getRecordImportsForFarm(farmId).then((data) => {
          setLoading(false)
          setImports(data.imports)
        })
      },
      [farmId],
    )

    // retrieve error and duplicate reports as needed
    useEffect(() => {
      if (farmId && importId && importMode === 'duplicates' && !duplicateRecords[importId]) {
        getDuplicatesForRecordImport(farmId, importId).then((data) => {
          setDuplicateRecords((existing) => ({
            ...existing,
            [importId]: data.records,
          }))
        })
      }
    }, [duplicateRecords, farmId, importId, importMode])

    useEffect(() => {
      if (farmId && importId && importMode === 'errors' && !recordErrors[importId]) {
        getErrorsForRecordImport(farmId, importId).then((data) => {
          setRecordErrors((existing) => ({
            ...existing,
            [importId]: data.records,
          }))
        })
      }
    }, [farmId, importId, importMode, recordErrors])

    useEffect(() => {
      getData(true)
    }, [farmId, getData])

    const submitImport = useCallback(
      (file: File) => {
        if (!farmId) {
          return
        }

        const formData = new FormData()
        formData.append('data', file)

        if (importId) {
          // return to main screen to show import progress
          history.push(`/import-records/${farmId}`)
        }

        // make a dummy record which will be replaced by the real thing
        setImports((existing) =>
          [
            {
              id: '',
              created: new Date(),
              filename: file.name,
              status: 'Processing',
              numRecords: 0,
              duplicateCount: 0,
            } as RecordImport,
          ].concat(existing || []),
        )

        postRecordImport(farmId, formData).then((res) => {
          if (!res.success) {
            setWarningInfo({
              title: 'Warning',
              message: 'error' in res ? res.error : 'The file could not be imported. Unknown server error.',
            })
          }
          setSelectedFile(undefined)
          getData()
        })
      },
      [farmId, getData, history, importId],
    )

    if (!hasFarmRole('Farm')) {
      return <AccessDenied user={user} />
    }

    return (
      <Layout>
        <PageTitle>Import Records</PageTitle>
        <ActionHeader>
          <FarmSelector
            farms={farms}
            handleFarmSelected={(newFarmId) => {
              history.push(`/import-records/${newFarmId}`)
            }}
            farmId={farmId}
          />
          {farmId && farmId.length > 0 && (
            <ButtonContainer>
              <FileInputContainer>
                <SimpleFileInput accept='.csv' setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
              </FileInputContainer>
              <ActionHeaderButton
                disabled={!selectedFile}
                onClick={() => {
                  if (selectedFile) {
                    submitImport(selectedFile)
                  }
                }}
              >
                Import
              </ActionHeaderButton>
            </ButtonContainer>
          )}
        </ActionHeader>

        {farmId && importId && importMode === 'duplicates' ? (
          <DuplicatesTable data={duplicateRecords[importId]} backURL={`/import-records/${farmId}`} />
        ) : null}

        {farmId && importId && importMode === 'errors' ? (
          <ErrorReportTable data={recordErrors[importId]} backURL={`/import-records/${farmId}`} />
        ) : null}

        {farmId && !importId ? (
          <>
            {farmId && <ImportHistoryTable data={imports || []} baseURL={`/import-records/${farmId}`} />}
            {farmId && loading && <CircularProgress className='loader' />}
          </>
        ) : null}
        <WarningDialog
          open={!!warningInfo}
          warning={warningInfo}
          handleRequestClose={() => {
            setWarningInfo(undefined)
            setLoading(false)
          }}
          handleConfirm={() => {
            history.push(`/import-records/${farmId}`)
            setWarningInfo(undefined)
            setLoading(false)
          }}
        />
      </Layout>
    )
  },
  { noRef: true },
)
