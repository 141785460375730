import React from 'react'
import { PageTitle } from '../../../components/PageHeaders'
import MaterialTable from '../../../components/Table/Table'

const columns = [
  { id: 'type', label: 'Notification Type' },
  { id: 'name', label: 'Notification Name' },
  { id: 'description', label: 'Description' },
  { id: 'status', label: 'Status', selectableColumn: true },
].map((column) => ({
  ...column,
  style: { whiteSpace: 'nowrap' },
}))

export default ({ data, selected, updateSelected }) => (
  <div>
    <PageTitle>Notifications</PageTitle>
    <MaterialTable
      columns={columns}
      data={data}
      selectable
      selected={selected}
      updateSelected={updateSelected}
      switches
    />
  </div>
)
