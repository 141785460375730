import '../../../styles/calendar.css'

import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import FarmSelector from '../../../components/FarmSelector'
import Layout from '../../../components/Layout'
import { ActionHeader, ActionHeaderButton, ButtonContainer, Divider, PageTitle } from '../../../components/PageHeaders'
import { requireAuth } from '../../../components/withAuth'
import { getAllFarmsForUser } from '../../../data/farm'
import { getHealthTreatmentsForFarm } from '../../../data/health_treatments'
import { getAllFarmPlans } from '../../../data/plans'
import { colors } from '../../../styles/styleConstants'
import ViewEventDialog from './components/ViewEventDialog'
import { selectableFarmRoles } from './constants'

const calendarHeader = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayGridMonth,listWeek',
}

const calendarViews = {
  timeGrid: {
    eventLimit: 3,
  },
}

const calendarPlugins = [dayGridPlugin, timeGridPlugin, listPlugin]

type RouterParams = { farmId: string }

class CalendarView extends React.Component<RouteComponentProps<RouterParams>, {}> {
  calendarComponentRef = React.createRef<FullCalendar>()

  constructor(props: {}) {
    super(props)
    this.state = {
      events: [],
      currentDate: new Date(0),
      farmHealthTreatments: {},
      farms: [],
    }
  }

  componentDidMount(): void {
    this.getData()
    this.getFarmHealthTreatments()
    this.getFarms()
  }

  componentDidUpdate(prevProps: RouteComponentProps<RouterParams>): void {
    if (prevProps.match.params.farmId !== this.props.match.params.farmId) {
      this.setState({ switchToFarm: undefined }, () => this.getData(true))
      this.getFarmHealthTreatments()
    }
  }

  getFarmHealthTreatments = (): void => {
    const farmId = this.props.match.params.farmId

    getHealthTreatmentsForFarm(farmId).then((data) => {
      this.setState({
        farmHealthTreatments: data.healthTreatments.reduce((o, row) => ({ ...o, [row.id]: row }), {}),
      })
    })
  }

  getFarms = async (): Promise<void> => {
    const farms = (await getAllFarmsForUser(this.props.user.uid)).filter((f) => selectableFarmRoles.has(f.userRole))
    this.setState({ farms })
  }

  getHealthTreatmentOptions = (): void => {
    return Object.fromEntries([
      ...Object.entries(this.state.farmHealthTreatments).map(([key, val]) => [key, { ...val }]),
    ])
  }

  getData = async (force = false): Promise<void> => {
    // first render before ref is set, or switching farms
    if (!this.calendarComponentRef.current) {
      return
    }

    const api = this.calendarComponentRef.current.getApi()
    const selectedDate = api.getDate()
    const currentDate = this.state.currentDate

    if (
      selectedDate.getFullYear() === currentDate.getFullYear() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      !force
    ) {
      // if navigating by week or something and still same month,
      // then no need to fetch month again
      return
    }

    const events = await getAllFarmPlans(
      this.props.match.params.farmId,
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1, // 0 indexed
      1,
    )

    this.setState({ events, currentDate: selectedDate })
  }

  eventClick = (eventId): void => {
    const event = this.state.events.find((e) => e.id === eventId)
    this.setState({
      eventToEdit: {
        ...event,
        scheduleType: event.dependentEventId ? 'dependent' : 'fixed',
      },
    })
  }

  handleFarmSelected = (farmId): void => {
    this.setState({ switchToFarm: farmId })
  }

  handleGoToPlan = (): void => this.setState({ goToFarmPlan: true })

  onEventDialogClose = (reload: boolean): void => {
    this.setState({ eventToEdit: undefined })

    if (reload) {
      this.getData(true)
    }
  }

  render(): JSX.Element {
    if (this.state.goToFarmPlan) {
      return <Redirect to={`/farm-plans/${this.props.match.params.farmId}`} />
    }

    if (this.state.switchToFarm) {
      return <Redirect to={`/farm-plans/${this.state.switchToFarm}/calendar`} />
    }

    const displayEvents = this.state.events.map((e) => ({
      id: e.id,
      title: e.eventName,
      start: new Date(e.dueDate),
      allDay: true,
    }))

    return (
      <Layout>
        <PageTitle>Farm Plan</PageTitle>

        <ActionHeader>
          <ButtonContainer>
            <FarmSelector
              user={this.props.user}
              farms={this.state.farms}
              handleFarmSelected={this.handleFarmSelected}
              farmId={this.props.match.params.farmId}
              disabled={this.state.farms.length < 2}
            />
            <Divider />
            <ActionHeaderButton onClick={this.handleGoToPlan} className='hide-on-mobile'>
              Plan View
            </ActionHeaderButton>
          </ButtonContainer>
        </ActionHeader>

        <CalendarWrapper>
          <FullCalendar
            defaultView='dayGridMonth'
            plugins={calendarPlugins}
            ref={this.calendarComponentRef}
            events={displayEvents}
            eventColor={colors.green}
            datesRender={this.getData}
            header={calendarHeader}
            eventLimit={true}
            views={calendarViews}
            eventClick={(info) => this.eventClick(info.event.id)}
          />
        </CalendarWrapper>

        {this.state.eventToEdit && (
          <ViewEventDialog
            farmId={this.props.match.params.farmId}
            onRequestClose={this.onEventDialogClose}
            data={this.state.eventToEdit}
            healthTreatments={this.getHealthTreatmentOptions()}
          />
        )}
      </Layout>
    )
  }
}

const CalendarWrapper = styled.div`
  margin-top: 20px;

  .fc-button {
    .fc-icon {
      margin-top: -3px; /* looks better in chrome and worse in ff */
    }
  }

  .fc-button-primary {
    background-color: ${colors.grey};
    border: 0 !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.75;
    font-weight: 500;
    padding: 6px 20px;

    &[disabled] {
      background-color: ${colors.lightGrey} !important;
      opacity: 1;
    }

    &:not(disabled).fc-button-active,
    &:hover {
      background-color: ${colors.green};
    }
  }

  .fc-header-toolbar {
    margin-right: 20px;

    h2 {
      font-size: 1.25em;
    }
  }

  .fc-list-item-time {
    display: none; /* hide the "all-day" text because all events are all day */
  }

  .fc-today-button {
    display: none; /* don't see a setting to disable it */
  }
`

export default requireAuth(CalendarView)
