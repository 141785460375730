import * as React from 'react'
import { RouteProps } from 'react-router'

import FarmSelector from '../../components/FarmSelector'
import { SELECT_PLACEHOLDER_OPTION_VALUE } from '../../components/Form/Form'
import Layout from '../../components/Layout'
import { requireAuth } from '../../components/withAuth'
import {
  ChartPosition,
  DashboardMetric,
  dashboardMetricTypeMap,
  defaultMetricForType,
  getPositions,
} from '../../data/dashboard/dashboard'
import { getAllFarmsForUser } from '../../data/farm'
import { Farm } from '../../data/types'
import { ChartContainer } from './DashboardElements'

const numPositions = 4

interface State {
  readonly selectedFarm?: Farm | null
  readonly farms: Farm[]
  readonly metrics: (DashboardMetric | null)[]
  readonly loaded: boolean
}

class Dashboard extends React.Component<RouteProps, State> {
  constructor(props: RouteProps) {
    super(props)

    this.state = {
      selectedFarm: null,
      farms: [],
      metrics: [],
      loaded: false,
    }

    this.loadFarms()
  }

  async loadFarms(): Promise<void> {
    const farms = await getAllFarmsForUser()

    this.setState({
      farms: farms,
      loaded: true,
    })
  }

  loadMetrics = (farmId: string): void => {
    // reset display before some weird stuff happens while old data is present
    this.setState({ metrics: [] })

    getPositions(farmId).then((data: ChartPosition[]) => {
      const metrics = []
      const positions = Object.fromEntries(data.map((row) => [row.position, row.type]))

      // fills unset charts with null instead of skipping over
      for (let i = 0; i < numPositions; i++) {
        metrics.push(positions[i])
      }

      this.setState({
        metrics: metrics.map((type) => defaultMetricForType(dashboardMetricTypeMap[type])),
      })
    })
  }

  onFarmSelected = (farmId: string): void => {
    const selectedFarm = this.state.farms.find((farm) => farm.id === farmId)
    this.setState({
      selectedFarm: selectedFarm,
    })

    this.loadMetrics(farmId)
  }

  onFarmDeselected = (): void => {
    this.setState({
      selectedFarm: null,
    })
  }

  onMetricChange = (position: number, metric: DashboardMetric | null): void => {
    const metrics = this.state.metrics
    metrics[position] = metric

    this.setState({
      metrics: metrics,
    })
  }

  render(): JSX.Element {
    return (
      <Layout title='Dashboard' loaded={this.state.loaded}>
        <FarmSelector
          farms={this.state.farms}
          farmId={this.state.selectedFarm ? this.state.selectedFarm.id : SELECT_PLACEHOLDER_OPTION_VALUE}
          handleFarmSelected={this.onFarmSelected}
          deselectFarm={this.onFarmDeselected}
        />

        <div className='dashboardTopDivider' />

        {this.state.selectedFarm ? (
          <ChartContainer
            farmId={this.state.selectedFarm.id}
            metrics={this.state.metrics}
            onChange={this.onMetricChange}
          />
        ) : null}
      </Layout>
    )
  }
}

export default requireAuth(Dashboard)
