import React, { Component } from 'react'
import { logout } from '../../helpers/auth'
import Dropdown from '../Header/Dropdown'
import { AccountBox } from '@material-ui/icons'

export default class AccountDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: undefined,
      open: false,
      rotated: 'down',
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  handleClick(event) {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      rotated: 'up',
    })
  }

  handleRequestClose() {
    this.setState({
      open: false,
      rotated: 'down',
    })
  }

  render() {
    return (
      <Dropdown
        category={this.props.username}
        hasArrow
        pages={[
          { name: 'Account', path: '/account' },
          { name: 'Billing Accounts', path: '/billing-accounts' },
          { name: 'Farms', path: '/farms' },
          { name: 'Settings', path: '/settings' },
          { name: 'Log out', onClick: logout },
        ]}
        path={this.props.path}
      />
    )
  }
}

export class MobileAccountDropdown extends AccountDropdown {
  render() {
    return (
      <Dropdown
        category={<AccountBox className='dropdown-icon' />}
        pages={[{ name: 'Log out', onClick: logout }]}
        path={this.props.path}
      />
    )
  }
}
