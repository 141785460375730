import React from 'react'
import MaterialTable from '../../components/Table/Table'
import _ from 'lodash'

import { PushButton } from '../../components/Form/Form'
import './Nait.css'

const columns = [
  { id: 'animalType', label: 'Animal Type' },
  { id: 'animalStatus', label: 'Animal Status' },
  { id: 'receiver', label: 'Receiver' },
  { id: 'totalAnimals', label: '# of Animals' },
  { id: 'remainingAnimals', label: 'Remaining Animals' },
]

const formatData = (data, goToAssignment) =>
  _.values(
    _.mapValues(data, (d) => {
      const remainingAnimals = d.length - d.filter((animal) => animal.assigned).length

      return {
        animalType: d[0].animal,
        animalStatus: d[0].status,
        totalAnimals: d.length,
        remainingAnimals: remainingAnimals.toString(),
        receiver: remainingAnimals > 0 ? <a onClick={() => goToAssignment(d)}>Assign</a> : d[0].receiver,
      }
    }),
  )

class ReceiverAssignmentTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      assigning: false,
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.groupData(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data || nextProps.assigningData) {
      this.groupData(nextProps)
    }
  }

  groupData = (props) => {
    const { data, assignmentData } = props
    if (assignmentData.thirdParty) {
      assignmentData.thirdParty.forEach((id) => {
        const index = data.findIndex((animal) => animal.eid === id)
        data[index].receiver = 'Third Party'
      })
    }
    if (assignmentData.self) {
      _.map(assignmentData.self, (value, key) => {
        value.forEach((id) => {
          const index = data.findIndex((animal) => animal.eid === id)
          data[index].receiver = key
        })
      })
    }

    this.setState({
      groupedData: _.groupBy(data, (animal) => animal.animal + animal.status + animal.receiver),
    })
  }

  render() {
    return (
      <div>
        <div className='headerRow naitTableHeading'>
          <section>
            <div>Receiver Assignment</div>
          </section>
          <section className='buttonsContainer recordsButtonsContainer'>
            <div className='dialogButtons naitButtons inHeader'>
              <PushButton disabled={_.isEmpty(this.props.assignmentData)} onClick={this.props.sendToNait}>
                Send to Nait
              </PushButton>
            </div>
          </section>
        </div>
        {this.props.data && this.props.data.length > 0 && (
          <MaterialTable
            columns={columns}
            data={formatData(this.state.groupedData, (data) => {
              this.props.goToAssignment(data)
            })}
          />
        )}
      </div>
    )
  }
}

export default ReceiverAssignmentTable
