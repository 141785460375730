import styled from 'styled-components'
import { LinkButton } from './Form/Form'
import Button from '@material-ui/core/Button'
import { width, space, top, height } from 'styled-system'
import { colors } from '../styles/styleConstants'

export const PageTitle = styled.p`
  font-size: 18px;
  color: #555555;
  margin: 14px 0 26px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`

export const ActionHeader = styled.div`
  margin-top: -20px;
  display: flex;
  ${space}

  form {
    width: 100%;
    max-width: unset;
  }
`

export const ButtonContainer = styled.section`
  position: relative;
  display: flex;
  flex-grow: 2;
  justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  align-items: flex-end;
`

export const ActionHeaderLinkButton = styled(LinkButton)`
  background-color: #2e7d32 !important; // override MUI defaults
  color: #fff !important; // override MUI defaults
  width: 170px;
  margin-right: 20px;
`

export const ActionHeaderButton = styled(Button)`
  background-color: ${(props) => {
    if (props.disabled) {
      return colors.lightGrey
    }

    if (props.backgroundColor) {
      return props.backgroundColor
    }

    return colors.green
  }} !important; // override MUI defaults
  color: #fff !important; // override MUI defaults
  width: 180px;
  margin-right: ${(props) => props.margin || '20px'} !important;
  font-family: 'Open Sans';
  ${height}
  ${width};
  ${space};
  ${top};
`

export const Divider = styled.div`
  display: flex;
  flex-grow: 1;
`

export const From = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  color: #555555;
  text-align: right;
  margin-right: 4px;
`

export const SubHeader = styled.div`
  font-size: 20px;
  color: #2d2d2d;
  border-bottom: 2px solid ${colors.green};
  width: 100%;
  margin: 20px 0 12px;
`
