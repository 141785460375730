import { grey, red } from '@material-ui/core/colors'
import { createMuiTheme, Theme } from '@material-ui/core/styles'
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

import { colors } from './styles/styleConstants'

export interface SyledTheme {
  readonly theme: Theme
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.green,
      dark: '#555555',
    },
    secondary: grey,
    error: red,
    text: {
      primary: '#555555',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontSize: '2em',
      margin: '0.67em 0',
    },
    h2: {
      fontSize: '1.5em',
    },
    h3: {
      fontSize: '1.2em',
    },
    h4: {
      fontSize: '1.1em',
    },
    h5: {
      fontSize: 'inherit',
    },
    h6: {
      fontSize: 'inherit',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1em',
    },
    body2: {
      fontSize: '0.8em',
    },
    subtitle1: {
      fontSize: '1m',
    },
    subtitle2: {
      fontSize: '0.8em',
    },
  },
})

const themeObject: ThemeOptions = {
  ...theme,

  overrides: {
    MuiSvgIcon: {
      root: {
        height: 24, // 36
        width: 24, // 36
      },
    },
    MuiTextField: {
      root: {
        '& label[data-shrink="false"]': {
          transform: 'translate(0, 21px) scale(0.75)',
        },
        '& label': {
          whiteSpace: 'nowrap',
        },
      },
    },
  },
}

export default createMuiTheme(themeObject)
