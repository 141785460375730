/**
 * Demonstration form implementation
 * @type {module}
 */

import React from 'react'
import { TextField, EmailField, PhoneField, withFormValidation, SubmitButton } from '../components/Form/Form'

const DemoFormContent = (props) => (
  <div>
    <h4>Demonstration form</h4>
    <div className='FormRow'>
      <TextField name='hello' label='Type hello' required />
    </div>
    <div className='FormRow'>
      <EmailField name='email' label='Email' />
    </div>
    <div className='FormRow'>
      <PhoneField name='phone' label='Type 123' />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Save</SubmitButton>
    </div>
  </div>
)

const DemoForm = withFormValidation(DemoFormContent)

class Demo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validationErrors: {},
    }

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
  }

  validate(data) {
    // demonstrate synchronous form-level validation
    // TODO currently overrides field-level errors, need to work out how
    // we can combine the two
    const errors = {}
    if (data.hello !== 'hello') {
      errors.hello = `Please type hello - you typed "${data.hello}"`
    }
    this.setState({
      validationErrors: errors,
    })
  }

  submit(data, reset, invalidate) {
    // example of post-submit validation - this could be async
    if (data.phone !== '123') {
      invalidate({
        phone: 'Please type 123',
      })
      return
    }

    // save data here
    console.log(data)
    alert('Form submitted successfully')
  }

  render() {
    return <DemoForm validate={this.validate} validationErrors={this.state.validationErrors} submit={this.submit} />
  }
}

export default Demo
