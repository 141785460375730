import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Dashboard from './scenes/Dashboard/Dashboard'
import Login from './scenes/Login/Login'
import { Signup } from './scenes/Signup/index'
import Invite from './scenes/Signup/Invite'
import Demo from './scenes/Demo'
import NoMatch from './scenes/NoMatch/NoMatch'
import Account from './scenes/Account/Account'
import BillingAccounts from './scenes/BillingAccounts/BillingAccounts'
import Farms from './scenes/Farms/Farms'
import AnimalGroup from './scenes/AnimalGroupHistory/AnimalGroup'
import NaitReporting from './scenes/NAIT/Nait'
import ImportTags from './scenes/ImportTags/ImportTags'
import { ImportRecords } from './scenes/ImportRecords/ImportRecords'
import Analysis from './scenes/Analysis'
import AnalysisViewList from './scenes/Analysis/AnalysisViewList'
import HealthTreatments from './scenes/AnimalHealth/HealthTreatments/index'
import ManageTreatments from './scenes/AnimalHealth/ManageTreatments/index'
import ManageEvents from './scenes/AnimalHealth/ManageEvents/index'
import ManageTemplates from './scenes/AnimalHealth/ManageTemplates/index'
import { AccountProvider } from './components/AccountContext'
import Forecast from './scenes/Forecast'
import { ManagePlans, FarmPlans } from './scenes/AnimalHealth/Plans/index'
import CalendarView from './scenes/AnimalHealth/Plans/CalendarView'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import PasswordReset from './scenes/PasswordReset/PasswordReset'
import Support from './scenes/Support/Support'
import Settings from './scenes/Settings/Settings'
import Header from './components/Header'
import Footer from './components/Footer'
import MobileWrapper from './components/mobile'
import { getFirebase } from './helpers/firebase'

import theme from './theme'

const renderHome = () => (
  // temporary, while there's no homepage
  <Redirect to={{ pathname: '/dashboard' }} />
)

// TODO
const STRIPE_API_KEY = 'pk_test_ciEAnxCQHhEiRBrYVVB5t5wm'
const stripePromise = loadStripe(STRIPE_API_KEY)

const App = () => {
  if (window.location.hash && /^#token=/.test(window.location.hash)) {
    const token = window.location.hash.slice(7)
    getFirebase()
      .auth()
      .signInWithCustomToken(token)
  }

  //Double theme providers hack is from https://github.com/mui-org/material-ui/issues/10098#issuecomment-574162533
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <AccountProvider>
              <Router>
                <MobileWrapper className='contentWrap'>
                  <div className='contentWrap'>
                    <Header />
                    <main>
                      <Switch>
                        <Route path='/' render={renderHome} exact={true} />
                        <Route path='/dashboard' component={Dashboard} />
                        <Route path='/analysis' component={Analysis} />
                        <Route path='/analysisviewlist' component={AnalysisViewList} />
                        <Route path='/account' component={Account} />
                        <Route path='/billing-accounts' component={BillingAccounts} />
                        <Route path='/farms/:accountId' component={Farms} />
                        <Route path='/farms' component={Farms} />
                        <Route path='/nait/:farmId?' component={NaitReporting} />
                        <Route path='/import-tags/:farmId?/:orderId?' component={ImportTags} />
                        <Route path='/import-records/:farmId?/:importId?/:importMode?' component={ImportRecords} />
                        <Route path='/health-treatments/:farmId?' component={HealthTreatments} />
                        <Route path='/manage-treatments/:farmId?' component={ManageTreatments} />
                        <Route path='/manage-events' component={ManageEvents} />
                        <Route path='/manage-templates' component={ManageTemplates} />
                        <Route path='/manage-plans' component={ManagePlans} />
                        <Route path='/farm-plans/:farmId/calendar' component={CalendarView} />
                        <Route path='/forecast/:farmId?' component={Forecast} />
                        <Route path='/farm-plans' component={FarmPlans} />
                        <Route path='/invite/:token' component={Invite} />
                        <Route path='/animals' render={(props) => <AnimalGroup {...props} type='animal' />} />
                        <Route path='/non-id-groups' render={(props) => <AnimalGroup {...props} type='group' />} />
                        <Route path='/sign-in' component={Login} />
                        <Route path='/forgot-password' component={PasswordReset} />
                        <Route path='/demo-form' component={Demo} />
                        <Route path='/sign-up/top-secret' component={Signup} />
                        <Route path='/support' component={Support} />
                        <Route path='/settings' component={Settings} />
                        <Route component={NoMatch} />
                      </Switch>
                    </main>
                    <Footer />
                  </div>
                </MobileWrapper>
              </Router>
            </AccountProvider>
          </MuiPickersUtilsProvider>
        </Elements>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}

export default App
