import './../ImportTags.css'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import styled from 'styled-components'

import { TagImport } from '../../../data/import_tags'
import { colors } from '../../../styles/styleConstants'
import { SyledTheme } from '../../../theme'

const StyledTable = styled(Table)`
  ${({ theme }: SyledTheme) => `
  margin-top: ${theme.spacing(2)}px;
  border-top: 2px solid ${colors.lightGreen};
  `}
`

const DataTableHeader = styled(TableHead)`
  border-bottom: 1px solid #b6b6b6;

  th {
    color: #2d2d2d !important;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #b6b6b6;
  }
`

const StyledTableCell = styled(TableCell)`
  color: #7d7d7d !important;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap;

  a {
    color: ${colors.lightGreen};
  }
`

const TableHeaderRow = styled(TableRow)`
  color: #7d7d7d;
  height: 37px;
  border-bottom: 1px solid ${colors.lightGreen};
  white-space: nowrap;
`

const TableBodyRow = styled(TableRow)`
  height: 37px;
  white-space: nowrap;
`

export interface OrderDetailsTableProps {
  readonly orderDetails: TagImport | null
}

const OrderDetailsTable: React.FunctionComponent<OrderDetailsTableProps> = ({ orderDetails }) => {
  return (
    <React.Fragment>
      <Typography variant='h5'>Order Details</Typography>
      <StyledTable aria-label='order details table'>
        <DataTableHeader>
          <TableHeaderRow>
            <StyledTableCell padding='none'>Source</StyledTableCell>
            <StyledTableCell padding='none'>Order #</StyledTableCell>
            <StyledTableCell padding='none'>Order Date</StyledTableCell>
            <StyledTableCell padding='none'>Issue Date</StyledTableCell>
            <StyledTableCell padding='none'># of Tags</StyledTableCell>
            <StyledTableCell padding='none'># Completed</StyledTableCell>
            <StyledTableCell padding='none'># Inactive</StyledTableCell>
            <StyledTableCell padding='none'>Status</StyledTableCell>
          </TableHeaderRow>
        </DataTableHeader>
        <TableBody>
          {orderDetails && (
            <TableBodyRow>
              <StyledTableCell padding='none'>{orderDetails.supplier}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.orderNumber}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.orderDate}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.issueDate}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.total}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.completed}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.inactive}</StyledTableCell>
              <StyledTableCell padding='none'>{orderDetails.isComplete ? 'Completed' : 'Incomplete'}</StyledTableCell>
            </TableBodyRow>
          )}
        </TableBody>
      </StyledTable>
    </React.Fragment>
  )
}

export default OrderDetailsTable
