import React from 'react'
import { withFormValidation, TextField, PostalCodeField, SubmitButton } from '../../../components/Form/Form'
import { CompanyNameField, AddressField, RegionField } from '../../../components/Form/CustomFields.js'
import { requireAuth } from '../../../components/withAuth'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import { createBillingAccount } from '../../../data/billing'

import '../../../components/Dialog/Dialog.css'

const AddBillingFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <CompanyNameField name='businessName' label='Business Name' required />
    </div>
    <div className='FormRow'>
      <PostalCodeField name='postalCode' label='Postal Code' required />
    </div>
    <div className='FormRow'>
      <AddressField name='address1' label='Address Line 1' required />
    </div>
    <div className='FormRow'>
      <AddressField name='address2' label='Address Line 2' />
    </div>
    <div className='FormRow'>
      <TextField name='city' label='City' required />
    </div>
    <div className='FormRow'>
      <RegionField name='region' label='Region' required />
    </div>
    {/* <div className='FormRow'>
      <CountryField name='country' label='Select Country' required />
    </div> */}
    <div className='ButtonWrap'>
      <Button className='Back dialogBack' onClick={props.handleRequestClose}>
        Cancel
      </Button>
      <SubmitButton disabled={!props.canSubmit}>Add Account</SubmitButton>
    </div>
  </div>
)

const AddBillingForm = withFormValidation(AddBillingFormContent)

class AddBillingDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
    }
  }

  validate() {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.handleRequestClose()
        this.props.updateBillingAccountsList()
      }
    }

    createBillingAccount(this.props.user.uid, data).then(onSave)
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Add Billing Account' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <AddBillingForm
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(AddBillingDialog)
