import { Container } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import BackHeading from '../../../components/BackHeading'
import { PushButton } from '../../../components/Form/Form'
import Layout from '../../../components/Layout'
import { ActionHeader, ButtonContainer, Divider, PageTitle } from '../../../components/PageHeaders'
import { getFilteredAnimalInfo } from '../../../data/analysis-api'
import AnalysisViewList from './AnalysisViewList'
import { defaultColumns, requiredColumns } from './constants'

const ViewListDetailsControl = (props) => {
  const [showColumnDialog, setShowColumnDialog] = useState<bool>(false)
  const [animals, setAnimals] = useState([])
  const [columns, setColumns] = useState(new Set(defaultColumns))

  const animalType = _.get(props, 'location.appState.animalType')
  const filters = _.get(props, 'location.appState.filters')
  const farmIds = _.get(props, 'location.appState.farmIds')

  // on mount reset colums
  useEffect(() => {
    setColumns(new Set(defaultColumns))
  }, [])

  // refetch data if anything affecting output changes
  useEffect(() => {
    getFilteredAnimalInfo('list', {
      farmIds: farmIds,
      filters: filters,
      columns: [...Array.from(columns), ...requiredColumns],
    }).then((result) => {
      if (!result.error) {
        setAnimals(result.animals)
      }
    })
  }, [filters, farmIds, columns])

  if (!_.get(props, 'location.appState')) {
    return <Redirect to='/analysis' />
  }

  return (
    <Layout>
      <PageTitle>Analysis</PageTitle>
      <ActionHeader>
        <ButtonContainer>
          <BackHeading withBorder text='View List' backURL='/analysis' />
          <Divider />
          <PushButton onClick={() => setShowColumnDialog(true)}>Change Columns</PushButton>
        </ButtonContainer>
      </ActionHeader>
      <Container>
        <AnalysisViewList
          animals={animals}
          animalType={animalType}
          showColumnDialog={showColumnDialog}
          hideColumnDialog={() => setShowColumnDialog(false)}
          setColumns={setColumns}
          columns={columns}
        />
      </Container>
    </Layout>
  )
}

export default withRouter(ViewListDetailsControl)
