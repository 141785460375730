import React from 'react'
import { TextField, FormRow, FormField, HiddenField } from '../../../../components/Form/Form'
import Search from '../../../../components/Search'
import FormDialog from '../../../../components/Dialog/FormDialog'
import WarningDialog from '../../../../components/Dialog/WarningDialog'
import moment from 'moment'

import '../../../../components/Dialog/Dialog.css'

import { deleteEventFromFarmPlan, getAllFarmPlans } from '../../../../data/plans'
import { eventDateFormat } from '../../../../constants'
import { eventSearchBackMonths, eventSearchForwardMonths } from '../constants'

const DeleteEventForm = ({ currentData, dependentEventOptions, onDependentEventOptionClick, hasChildren }) => (
  <div>
    <FormRow mb='30px'>
      <FormField width='50%'>
        <FormField width='60%'>
          <TextField name='name' label='Name' value={currentData.eventName} disabled />
        </FormField>
      </FormField>
    </FormRow>
    {hasChildren ? (
      <>
        <div>
          Other event(s) are dependent on this event. Select another dependent event to replace dependencies for these
          events on the selected event.
        </div>
        <FormRow mb='50px'>
          <FormField width='40%' pt='18px'>
            <Search
              name='dependentEvent'
              label='Dependent Event Name'
              placeholder='Search events'
              options={dependentEventOptions}
              onOptionClick={onDependentEventOptionClick}
              noSort
            />
            <HiddenField name='dependentEventId' value={currentData.dependentEventId} required />
          </FormField>
        </FormRow>
      </>
    ) : (
      <div>No other events are dependent on this event.</div>
    )}
  </div>
)

export default class DeleteEventDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      hasChanged: false,
      data: props.data || {},
      showOverlapDialog: false,
    }
  }

  async componentDidMount() {
    let dependentEventOptions = []
    const data = this.props.data

    if (data.hasChildren) {
      const searchStart = moment().subtract('month', eventSearchBackMonths)
      const events = await getAllFarmPlans(
        this.props.farmId,
        searchStart.year(),
        searchStart.month() + 1,
        eventSearchBackMonths + eventSearchForwardMonths,
        data.planId,
      )
      const blacklist = new Set([data.id])
      let blacklistSize

      // find all events that are (in)directly children of the event being deleted
      do {
        blacklistSize = blacklist.size
        const filtered = events.filter((e) => {
          return (
            blacklist.has(e.dependentEventId) || blacklist.has(e.previousInstanceId) || blacklist.has(e.nextInstanceId)
          )
        })
        for (const event of filtered) {
          blacklist.add(event.id)
        }
      } while (blacklistSize != blacklist.size)

      dependentEventOptions = events
        .filter((e) => !blacklist.has(e.id))
        .sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate)))
        .map((o) => ({
          name: `${o.eventName} (${moment(o.dueDate).format(eventDateFormat)})`,
          value: o.id,
        }))
    }

    this.setState({ dependentEventOptions })
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  validate = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        ...data,
      },
    })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        if (result.data.dependentEventId) {
          this.setState({ showOverlapDialog: true })
        } else {
          this.setState({
            error: result.error,
          })
        }
      } else {
        this.handleRequestClose()
      }
    }

    deleteEventFromFarmPlan(this.props.farmId, this.props.data.id, { dependentEventId: data.dependentEventId }).then(
      onSave,
    )
  }

  onDependentEventOptionClick = (selected) => {
    this.validate({ dependentEventId: selected.value, dependentEventName: selected.name })
  }

  render() {
    return (
      <>
        <FormDialog
          wide
          width='70vw'
          title='Delete Event'
          open={this.props.open}
          onClose={this.handleRequestClose}
          hasSubmit
          submitMessage='Delete'
          FormComponent={DeleteEventForm}
          currentData={this.state.data}
          submit={this.submit}
          validate={this.validate}
          hasChildren={this.props.data.hasChildren}
          dependentEventOptions={this.state.dependentEventOptions}
          onDependentEventOptionClick={this.onDependentEventOptionClick}
        />
        {this.state.showOverlapDialog && (
          <WarningDialog
            open
            warning={{
              title: 'Event overlap',
              message:
                'The result of assigning the dependent events causes overlap of the same Type/Class/Category/Template on the same date. Please choose another target or adjust data to prevent overlap.',
            }}
            handleRequestClose={() => this.setState({ showOverlapDialog: false })}
            confirmButtonTitle='Dismiss'
          />
        )}
      </>
    )
  }
}
