import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../styles/styleConstants'

const makeBoxStyles = makeStyles({
  root: {
    padding: '12px 0',
    borderTop: `1px solid ${colors.borderGrey}`,

    '& > *': {
      flex: '1 1 0',
    },

    '& > * + *': {
      marginLeft: '1.5em !important',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
})

export default (props) => {
  const boxStyles = makeBoxStyles()
  return (
    <Box display='flex' className={boxStyles.root}>
      {props.children}
    </Box>
  )
}
