import React from 'react'
import { withFormValidation, SubmitButton } from '../../../components/Form/Form'
import { requireAuth } from '../../../components/withAuth'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from '../../../components/Dialog/DialogHeader'

import '../../../components/Dialog/Dialog.css'

const ToggleFarmFormContent = (props) => (
  <div className='ButtonWrap'>
    <Button variant='contained' className='Back dialogBack' onClick={props.handleRequestClose}>
      Cancel
    </Button>
    <SubmitButton onClick={props.submit}>{props.active ? 'Disable' : 'Enable'} Farm</SubmitButton>
  </div>
)

const ToggleFarmForm = withFormValidation(ToggleFarmFormContent)

class ToggleFarmDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
    }
  }

  validate() {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit() {
    this.props.toggleFarm()
    this.handleRequestClose()
  }

  render() {
    return (
      <Dialog onClose={this.handleRequestClose} open={this.props.open}>
        <DialogHeader
          title={this.props.active ? 'Disable Farm' : 'Enable Farm'}
          handleRequestClose={this.handleRequestClose}
        />
        <div className='dialogContent'>
          {this.props.active ? (
            <p>
              This action will <b>remove user access</b> to the farm&apos;s StockManager Android tablet application and
              the web portal farm information. In addition, this will remove the farm from the account&apos;s monthly
              billing.
            </p>
          ) : (
            <p>
              This action will <b>restore user access</b> to the farm&apos;s StockManager Android tablet application and
              the web portal farm information, and start billing for the farm.
            </p>
          )}
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <ToggleFarmForm
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            active={this.props.active}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(ToggleFarmDialog)
