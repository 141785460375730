import React from 'react'
import MaterialTable from '../../../../components/Table/Table'
import {
  enableHealthTreatmentForFarm,
  disableHealthTreatmentForFarm,
  enableHealthTreatmentPurchaseForFarm,
  disableHealthTreatmentPurchaseForFarm,
} from '../../../../data/health_treatments'
import _ from 'lodash'
import moment from 'moment'
import { InlineEditLink } from '../../../../components/Table/content'

const formatData = (data, expandedRows) => {
  let rows = []
  data.forEach((t, key) => {
    rows.push({ ...t, rowKey: key })
    if (expandedRows && expandedRows.includes(t.id)) {
      t.healthTreatmentPurchases.forEach((p, subKey) => {
        rows.push({
          ...p,
          rowKey: key,
          subRowKey: subKey,
          isSubRow: true,
        })
      })
    }
  })

  return rows
}

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expandedRows: [],
    }
  }

  render() {
    const { farmId, data, editTreatment, openInstructions, addPurchase, editPurchase } = this.props
    const { expandedRows } = this.state

    const columns = [
      { id: 'name', label: 'Treatment Name', style: { minWidth: '200px' } },
      { id: 'uom', label: 'UOM' },
      { id: 'rvm', label: 'RVM' },
      { id: 'meatWitholdingPeriod', label: 'Meat WHP', unit: ' days' },
      { id: 'milkWitholdingPeriod', label: 'Milk WHP', unit: ' days' },
      { id: 'produceWitholdingPeriod', label: 'Wool WHP', unit: ' days' },
      {
        id: 'alertQuantity',
        label: 'Alert Quantity',
        render: (item) => {
          return (
            <InlineEditLink
              onClick={() => {
                editTreatment(item)
              }}
            >
              {item.alertQuantity || 'add'}
            </InlineEditLink>
          )
        },
      },
      {
        id: 'currentQuantity',
        label: 'Current Quantity',
        style: {
          color: (row) => (parseInt(row.currentQuantity, 10) <= parseInt(row.alertQuantity, 10) ? '#ea5848' : null),
        },
      },
      { id: 'updateDate', label: 'Update Date', overflowColumn: true },
    ].map((column) => ({
      ...column,
      style: { ...column.style, whiteSpace: 'nowrap' },
    }))

    const subRowColumns = [
      {
        id: 'expiryDate',
        label: 'Expiration',
        width: '250px',
        style: {
          flexGrow: 1,
          color: (row) => (moment(row.expiryDate, 'DD/MM/YY').isBefore(moment()) ? '#ea5848' : null),
        },
      },
      { id: 'batchNumber', label: 'Batch #', width: '216px' },
      { id: 'purchaseQuantity', label: 'Purchase Qty', width: '280px' },
      {
        id: 'currentQuantity',
        label: 'Current Qty',
        width: '234px',
        render: (item) => {
          return (
            <InlineEditLink
              onClick={() => {
                editPurchase(item)
              }}
            >
              Current Qty: {item.currentQuantity}
            </InlineEditLink>
          )
        },
      },
      { id: 'updateDate', overflowColumn: true, width: '133px' },
    ].map((column) => ({
      ...column,
      style: { ...column.style, alignItems: 'flex-start' },
    }))

    return (
      <MaterialTable
        columns={columns}
        data={formatData(data, expandedRows)}
        hasSubRows
        subRowColumns={subRowColumns}
        expandedRows={expandedRows}
        expandRow={(id) => this.setState({ expandedRows: expandedRows.concat(id) })}
        collapseRow={(id) => {
          _.remove(expandedRows, (r) => r === id)
          this.setState({ expandedRows })
        }}
        actions={[
          {
            name: 'Use Instructions',
            onClick: (id) => openInstructions(data.find((t) => t.id === id).instructions),
            disabled: (id) => data.find((t) => t.id === id) && !data.find((t) => t.id === id).enabled,
          },
          {
            name: 'Label',
            onClick: (id) => {
              let link = data.find((t) => t.id === id).label

              if (/^http/.test(link)) {
                window.open(link, '_blank')
              } else {
                window.open(`http://${link}`, '_blank')
              }
            },
            disabled: (id) => data.find((t) => t.id === id) && !data.find((t) => t.id === id).enabled,
          },
          {
            name: 'Edit',
            onClick: (id) => editTreatment(data.find((t) => t.id === id)),
            disabled: (id) => data.find((t) => t.id === id) && !data.find((t) => t.id === id).enabled,
          },
          {
            name: (id) =>
              data.find((t) => t.id === id) && data.find((t) => t.id === id).enabled ? 'Disable' : 'Enable',
            onClick: async (id) => {
              if (data.find((t) => t.id === id).enabled) {
                const result = await disableHealthTreatmentForFarm(farmId, id)

                if (result.success) {
                  this.props.getData()
                }
              } else {
                const result = await enableHealthTreatmentForFarm(farmId, id)

                if (result.success) {
                  this.props.getData()
                }
              }
            },
          },
          {
            name: 'Add Purchase',
            onClick: (id) => addPurchase(data.find((t) => t.id === id)),
            disabled: (id) => data.find((t) => t.id === id) && !data.find((t) => t.id === id).enabled,
          },
        ]}
        subRowActions={[
          {
            name: (treatmentId, purchaseId) => {
              const treatment = data.find((t) => t.id === treatmentId)

              if (treatment) {
                const purchase = treatment.healthTreatmentPurchases.find((p) => p.id === purchaseId)

                return purchase.enabled ? 'Disable' : 'Enable'
              }
            },
            onClick: async (treatmentId, purchaseId) => {
              const treatment = data.find((t) => t.id === treatmentId)
              if (treatment) {
                const purchase = treatment.healthTreatmentPurchases.find((p) => p.id === purchaseId)

                if (purchase.enabled) {
                  const result = await disableHealthTreatmentPurchaseForFarm(farmId, purchaseId)

                  if (result.success) {
                    this.props.getData()
                  }
                } else {
                  const result = await enableHealthTreatmentPurchaseForFarm(farmId, purchaseId)

                  if (result.success) {
                    this.props.getData()
                  }
                }
              }
            },
          },
        ]}
      />
    )
  }
}
