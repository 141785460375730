/* eslint-disable no-unused-vars */
import RadioForm from './Dialogs/RadioDialog'
import CheckboxForm from './Dialogs/CheckboxDialog'
import CheckboxWithIdForm from './Dialogs/CheckboxWithIdDialog'
import { DateDialog, DateRangeDialog } from './Dialogs/DateDialog'
import InputForm from './Dialogs/InputDialog'
import NumberRangeForm from './Dialogs/NumberRangeDialog'

import { VIDField, EIDField, NAITNumberField, FOLNumberField, IntRangeField } from '../../components/Form/Form'

export const CurrentDateString = 'Current'
export const DateRangeString = 'Within Specific Date Range'

const EventTypeDraftingString = 'Configured Event'
const EventTypeTagReaderString = 'Eid Reader File Import'

export const EventTypeMap = {
  [EventTypeDraftingString]: 'Drafting',
  [EventTypeTagReaderString]: 'TagReader',
}

const YearList = (from, withDefault) => {
  let years = []
  const year = new Date().getFullYear() + 1

  if (withDefault) {
    years.push(9999)
  }

  from = from || year - 10
  for (var y = year; y >= from; y--) {
    years.push(y.toString())
  }
  return years
}

const BirthDate = [
  {
    key: 'value',
    title: 'Birth Date',
    form: DateRangeDialog,
  },
]

const BirthRank = [
  {
    key: 'value',
    title: 'Birth Rank',
    form: CheckboxForm,
    values: ['Single', 'Twin', 'Triplet', 'Quad', 'Multi'],
  },
]

const CattleBreed = [
  {
    key: 'value',
    title: 'Breed',
    form: CheckboxForm,
    values: [
      'Angus',
      'Angus Cross',
      'Ayrshire',
      'Belgian Blue',
      'Charolais',
      'Charolais Cross',
      'Cross Breed',
      'Devon',
      'Devon Red',
      'Dexter',
      'Friesian',
      'Friesian Cross',
      'Galloway-Belted',
      'Gelbvieh',
      'Hereford',
      'Hereford Cross',
      'HereFord Friesian X',
      'Hereford Horned',
      'Jersey',
      'Limousin',
      'Maine-Anjou',
      'Mixed Breed',
      'Murray Grey',
      'Murray Grey Cross',
      'Other',
      'Piedmontese',
      'Red Poll',
      'Saler',
      'Santa Gertrudis',
      'Shaver',
      'Shorthorn Beef',
      'Simmental',
      'Simmental Cross',
      'South Devon',
      'Stabilizer',
      'Wagyu',
      'Welsh Black',
    ],
  },
]

const DeerBreed = [
  {
    key: 'value',
    title: 'Breed',
    form: CheckboxForm,
    values: [
      'Elk (Wapiti)',
      'European Fallow',
      'European Red',
      'Mesopotamian',
      "Pere David's",
      'Rusa (Sundar Samba)',
      'Sambar',
      'Sika',
      'White-Tailed',
    ],
  },
]

const SheepBreed = [
  {
    key: 'value',
    title: 'Breed',
    form: CheckboxForm,
    values: [
      'Border Leicester',
      'Boderdale',
      'Cheviot',
      'Composite',
      'Coopworth',
      'Correidale',
      'Dorper',
      'Dorset Down',
      'Dorset Horn',
      'Drysdale',
      'East Friesian',
      'English Leicester',
      'Finnsheep',
      'Gotland',
      'Greeline',
      'Groplus',
      'Halfbred',
      'Hampshire',
      'Highlander',
      'Kelso',
      'Landmark',
      'Lamb Supreme',
      'L.Suprem x Perendale',
      'L.Supreme x Romney',
      'Lincoln',
      'Merino',
      'Oxford',
      'Perendale',
      'Poll Dorset',
      'Polwarth',
      'Primera',
      'Ranger',
      'Romney',
      'Romdale',
      'Romney X',
      'Ryeland',
      'Shropshire',
      'Snowline',
      'South Dorset',
      'South Suffolk',
      'Southdown',
      'Suffolk',
      'Suftex',
      'Texel',
      'Wiltshire',
      'Wilshire Horn',
    ],
  },
]

const CattleDeerBreederFarm = [
  {
    key: 'value',
    title: 'Breeder Farm',
    form: InputForm,
    values: 'Enter NAIT Number (2-6 or 8 digits)',
    type: NAITNumberField,
  },
]

const SheepBreederFarm = [
  {
    key: 'value',
    title: 'Breeder Farm',
    form: InputForm,
    values: 'Enter FOL Number (AA-####-####)',
    type: FOLNumberField,
  },
]

const ConceptionDate = [
  {
    key: 'value',
    title: 'Conception Date',
    form: DateRangeDialog,
  },
]

const ConceptionPhase = [
  {
    key: 'value',
    title: 'Conception Phase',
    form: CheckboxForm,
    values: ['Cycle1', 'Cycle2', 'Cycle3', 'Cycle4', 'Cycle5'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const CattleConditionScore = [
  {
    key: 'value',
    title: 'Condition Score',
    form: CheckboxForm,
    values: ['1.0', '2.0', '3.0', '4.0', '5.0', '6.0', '7.0', '8.0', '9.0', '10.0'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerSheepConditionScore = [
  {
    key: 'value',
    title: 'Condition Score',
    form: CheckboxForm,
    values: ['1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DamTag = {
  key: 'type',
  title: 'Dam Tag',
  form: RadioForm,
  values: {
    EID: [
      {
        key: 'value',
        title: 'EID',
        form: InputForm,
        values: 'Up to 16 digits',
        type: EIDField,
      },
    ],
    VID: [
      {
        key: 'year',
        title: 'Year',
        form: RadioForm,
        values: YearList(1970),
      },
      {
        key: 'value',
        title: 'VID',
        form: InputForm,
        values: 'Up to 20 characters',
        type: VIDField,
      },
    ],
  },
}

const DamGroup = [
  {
    key: 'value',
    title: 'Dam Group',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'groups',
    type: 'DamGroup',
  },
]

const DNAID = [
  {
    key: 'value',
    title: 'DNA ID',
    form: InputForm,
    values: 'Up to 20 characters',
    type: VIDField,
  },
]

const DraftingDirection = [
  {
    key: 'value',
    title: 'Drafting Direction',
    form: CheckboxForm,
    values: [
      '1. Forward',
      '2. First Left',
      '3. First Right',
      '4. Second Left',
      '5. Second Right',
      '6. Third Left',
      '7. Third Right',
    ],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const EID = [
  {
    key: 'value',
    title: 'EID',
    form: InputForm,
    values: 'Up to 16 digits',
    type: EIDField,
  },
]

const Event = [
  {
    key: 'type',
    title: 'Event Type',
    form: RadioForm,
    values: [EventTypeDraftingString, EventTypeTagReaderString],
  },
  {
    key: 'value',
    title: 'Event',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'events',
  },
]

const Feed = [
  {
    key: 'value',
    title: 'Feed',
    form: CheckboxForm,
    values: [
      'Concentrates - Barley',
      'Concentrates - Bran',
      "Concentrates - Brewer's Grains",
      'Concentrates - Linseed Cake',
      'Concentrates - Lucerne Meal',
      'Concentrates - Maize Meal',
      'Concentrates - Molasses',
      'Concentrates - Oats',
      'Concentrates - Palm Kernel Extract (PKE)',
      'Concentrates - Peas',
      'Concentrates - Pollard',
      'Concentrates - Sheep Nuts',
      'Concentrates - Wheat',
      'Crops - Carrots',
      'Crops - Lupins (sweet)',
      'Crops - Potatoes',
      'Hays - Lucerne',
      'Hays - Pasture Good Quality',
      'Hays - Pasture Poor Quality',
      'Silages - Lucerne',
      'Silages - Maize',
      'Silages - Pasture Good Quality',
      'Silages - Pasture Poor Quality',
      'Silages - Whole Crop Quality',
      'Straws - Barley',
      'Straws - Corn Stover',
      'Straws - Oats',
      'Straws - Peas',
      'Straws - Ryegrass',
      'Straws - Wheat',
    ],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const Forage = [
  {
    key: 'value',
    title: 'Forage',
    form: CheckboxForm,
    values: [
      'Crops - Fodder Beet',
      'Crops - Fodder Radish',
      'Crops - Green Maize',
      'Crops - Kale',
      'Crops - Lupins (sweet)',
      'Crops - Mangels',
      'Crops - Rape',
      'Crops - Swede Bulbs',
      'Crops - Swede Tops',
      'Crops - Turnip Bulbs',
      'Crops - Turnip Tops',
      'Herbs - Chicory',
      'Herbs - Plantain',
      'Green Feeds - Barley',
      'Green Feeds - Oats',
      'Green Feeds - Ryecorn',
      'Green Feeds - Triticale',
      'Green Feeds - Wheat',
      'Lucerne - Bud Formation',
      'Lucerne - Green Vegetative',
      'Lucerne - 10-20% Flowering',
      'Pasture - Mid-Length Leafy',
      'Pasture - Red Clover',
      'Pasture - Short Leafy',
      'Pasture - Stalky',
      'Pasture - White Clover',
    ],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const CattleGender = [
  {
    key: 'value',
    title: 'Gender',
    form: CheckboxForm,
    values: ['Bull', 'Cow/Heifer', 'Steer'],
  },
]

const DeerGender = [
  {
    key: 'value',
    title: 'Gender',
    form: CheckboxForm,
    values: ['Hind', 'Stag'],
  },
]

const SheepGender = [
  {
    key: 'value',
    title: 'Gender',
    form: CheckboxForm,
    values: ['Cryptorchid', 'Ewe', 'Ram', 'Wether'],
  },
]

const Group = [
  {
    key: 'value',
    title: 'Group',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'groups',
    type: 'Group',
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const HealthTreatment = [
  {
    key: 'value',
    title: 'Health Treatment',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'health-treatments',
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const Paddock = [
  {
    key: 'value',
    title: 'Paddock',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'paddocks',
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const ScanResult = [
  {
    key: 'value',
    title: 'Scan Result',
    form: CheckboxForm,
    values: ['Dry', 'Single', 'Twin', 'Triplet', 'Quad+'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SireTag = {
  key: 'type',
  title: 'Sire Tag',
  form: RadioForm,
  values: {
    EID: [
      {
        key: 'value',
        title: 'EID',
        form: InputForm,
        values: 'Up to 16 digits',
        type: EIDField,
      },
    ],
    VID: [
      {
        key: 'year',
        title: 'Year',
        form: RadioForm,
        values: YearList(1970),
      },
      {
        key: 'value',
        title: 'VID',
        form: InputForm,
        values: 'Up to 20 characters',
        type: VIDField,
      },
    ],
  },
}

const SireGroup = [
  {
    // need to be fixed for values (all fiters containing '[]')
    key: 'value',
    title: 'Sire Group',
    form: CheckboxWithIdForm,
    values: [],
    resource: 'groups',
    type: 'SireGroup',
  },
]

const Status = [
  {
    key: 'value',
    title: 'Status',
    form: CheckboxForm,
    values: [
      'Added',
      'Birth',
      'Died and Disposed',
      'Slaughtered and Disposed',
      'Home Kill',
      'Deleted',
      'Importd',
      'Grazed Out',
      'Grazed In',
      'Missing',
      'Purchased',
      'Recovered',
      'Sold',
    ],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const CattleDeerStudFarm = [
  {
    key: 'value',
    title: 'Stud Farm',
    form: InputForm,
    values: 'Enter NAIT Number (2-6 or 8 digits)',
    type: NAITNumberField,
  },
]

const SheepStudFarm = [
  {
    key: 'value',
    title: 'Stud Farm',
    form: InputForm,
    values: 'Enter FOL Number (AA-####-####)',
  },
]

const TagColor = [
  {
    key: 'value',
    title: 'Tag Color',
    form: CheckboxForm,
    values: [
      'Black',
      'Blue',
      'Brown',
      'Clear',
      'Dark Blue',
      'Dark Green',
      'Dark Pink',
      'Dark Purple',
      'Gray',
      'Green',
      'Light Blue',
      'Light Green',
      'Light Pink',
      'Light Purple',
      'Orange',
      'Pink',
      'Purple',
      'Red',
      'Salmon',
      'White',
      'Yellow',
      'Undefined',
    ],
  },
]

const VIDYear = [
  {
    key: 'value',
    title: 'VID Year',
    form: CheckboxForm,
    values: YearList(1970, true),
  },
]

const VID = [
  {
    key: 'year',
    title: 'Year',
    form: RadioForm,
    values: YearList(1970),
  },
  {
    key: 'value',
    title: 'VID',
    form: InputForm,
    values: 'Up to 20 characters',
    type: VIDField,
  },
]

const Weight = [
  {
    key: 'value',
    title: 'Weight',
    form: NumberRangeForm,
    values: { from: 'From (kg)[10-2000]', to: 'To (kg)[10-2000]', low: 10, high: 2000 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const YearBorn = [
  {
    key: 'value',
    title: 'Year Born',
    form: CheckboxForm,
    values: YearList(2007, true),
  },
]

const DeerHardAntlerGrade = [
  {
    key: 'value',
    title: 'Hard Antler Grade',
    form: CheckboxForm,
    values: ['HA1', 'HA2'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerHardVelvetGrade = [
  {
    key: 'value',
    title: 'Hard Velvet Grade',
    form: CheckboxForm,
    values: ['HV1', 'HV2', 'HV3', 'HV4'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetCircumference = [
  {
    key: 'value',
    title: 'Velvet Circumference',
    form: NumberRangeForm,
    values: { from: 'From (cm)[1-80]', to: 'To (cm)[1-80]', low: 1, high: 80 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetCircumferenceGrade = [
  {
    key: 'value',
    title: 'Velvet Circumference Grade',
    form: CheckboxForm,
    values: ['A', 'B', 'C', 'D'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetDamageGrade = [
  {
    key: 'value',
    title: 'Velvet Damage Grade',
    form: CheckboxForm,
    values: ['Dam 1', 'Dam 2', 'Dam 3'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetLengthGrade = [
  {
    key: 'value',
    title: 'Velvet Length Grade',
    form: CheckboxForm,
    values: ['Short', 'Long', 'Overgrown'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetManufacturingGrade = [
  {
    key: 'value',
    title: 'Velvet Manufacturing Grade',
    form: CheckboxForm,
    values: ['Man 1', 'Man 2', 'Man 3'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetOverallLength = [
  {
    key: 'value',
    title: 'Velvet Overall Length',
    form: NumberRangeForm,
    values: { from: 'From (cm)[1-150]', to: 'To (cm)[1-150]', low: 1, high: 150 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetRegrowthGrade = [
  {
    key: 'value',
    title: 'Velvet Regrowth Grade',
    form: CheckboxForm,
    values: ['RG1', 'RG2', 'RG3', 'RG4'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetSAGrade = [
  {
    key: 'value',
    title: 'Velvet SA Grade',
    form: CheckboxForm,
    values: ['SAP', 'SAT', 'SAL', 'SANT'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetSpikerGrade = [
  {
    key: 'value',
    title: 'Velvet Spiker Grade',
    form: CheckboxForm,
    values: ['SP1', 'SP2', 'SP3'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetTopLength = [
  {
    key: 'value',
    title: 'Velvet Top Length',
    form: NumberRangeForm,
    values: { from: 'From (cm)[1-50]', to: 'To (cm)[1-50]', low: 1, high: 50 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const DeerVelvetWeight = [
  {
    key: 'value',
    title: 'Velvet Weight',
    form: NumberRangeForm,
    values: { from: 'From (kg)[0-30]', to: 'To (kg)[0-30]', low: 0, high: 30 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepSkirtedWeight = [
  {
    key: 'value',
    title: 'Skirted Weight',
    form: NumberRangeForm,
    values: { from: 'From (kg)[0-25]', to: 'To (kg)[0-25]', low: 0, high: 25 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolColour = [
  {
    key: 'value',
    title: 'Wool Colour',
    form: CheckboxForm,
    values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolLengthClass = [
  {
    key: 'value',
    title: 'Wool Length Class',
    form: CheckboxForm,
    values: ['Extra Short', 'Short', 'Medium', 'Long', 'Extra Long'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolMicronClass = [
  {
    key: 'value',
    title: 'Wool Micron Class',
    form: CheckboxForm,
    values: ['(1) Ultra Fine', '(2) Super Fine', '(3) Fine', '(4) Medium', '(5) Strong'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolStyle = [
  {
    key: 'value',
    title: 'Wool Style',
    form: CheckboxForm,
    values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolTenderness = [
  {
    key: 'value',
    title: 'Wool Tenderness',
    form: CheckboxForm,
    values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

const SheepWoolWeight = [
  {
    key: 'value',
    title: 'Wool Weight',
    form: NumberRangeForm,
    values: { from: 'From (kg)[0-30]', to: 'To (kg)[0-30]', low: 0, high: 30 },
    type: IntRangeField,
  },
  {
    key: 'date',
    title: 'DATE',
    form: DateDialog,
  },
]

export const idFilters = new Set(['Group', 'Dam Group', 'Sire Group', 'Paddock', 'Health Treatment', 'Event'])

export const filterToDbValueRegex = {
  Breed: /[^\w]/gi,
  DraftingDirection: /[^a-zA-Z]/gi,
  Gender: /\/.*/g,
  ScanResult: /[^a-zA-Z]/gi,
  Status: /[^a-zA-Z]/gi,
  TagColor: /[^a-zA-Z]/gi,
  VelvetDamageGrade: /[^\w]/gi,
  VelvetManufacturingGrade: /[^\w]/gi,
  WoolLengthClass: /[^a-zA-Z]/gi,
  WoolMicronClass: /[^a-zA-Z]/gi,
}

export const filterToDbValueMap = {
  Feed: {
    'Concentrates - Barley': 'ConcentratesBarley',
    'Concentrates - Bran': 'ConcentratesBran',
    "Concentrates - Brewer's Grains": 'ConcentratesBrewerGrains',
    'Concentrates - Linseed Cake': 'ConcentratesLinseedCake',
    'Concentrates - Lucerne Meal': 'ConcentratesLucerneMeal',
    'Concentrates - Maize Meal': 'ConcentratesMaizeMeal',
    'Concentrates - Molasses': 'ConcentratesMolasses',
    'Concentrates - Oats': 'ConcentratesOats',
    'Concentrates - Palm Kernel Extract (PKE)': 'ConcentratesPalmKernelExtract',
    'Concentrates - Peas': 'ConcentratesPeas',
    'Concentrates - Pollard': 'ConcentratesPollard',
    'Concentrates - Sheep Nuts': 'ConcentratesSheepNuts',
    'Concentrates - Wheat': 'ConcentratesWheat',
    'Crops - Carrots': 'CropsCarrots',
    'Crops - Lupins (sweet)': 'CropsLupinsFeed',
    'Crops - Potatoes': 'CropsPotatoes',
    'Hays - Lucerne': 'HaysLucerne',
    'Hays - Pasture Good Quality': 'HaysPastureGoodQuality',
    'Hays - Pasture Poor Quality': 'HaysPoorQuality',
    'Silages - Lucerne': 'SilagesLucerne',
    'Silages - Maize': 'SilagesMaize',
    'Silages - Pasture Good Quality': 'SilagesPastureGoodQuality',
    'Silages - Pasture Poor Quality': 'SilagesPasturePoorQuality',
    'Silages - Whole Crop Quality': 'SilagesWholeCropQuality',
    'Straws - Barley': 'StrawsBarley',
    'Straws - Corn Stover': 'StrawsCornStover',
    'Straws - Oats': 'StrawsOats',
    'Straws - Peas': 'StrawsPeas',
    'Straws - Ryegrass': 'StrawsRyegrass',
    'Straws - Wheat': 'StrawsWheat',
  },
  Forage: {
    'Crops - Fodder Beet': 'CropsFodderBeet',
    'Crops - Fodder Radish': 'CropsFodderRadish',
    'Crops - Green Maize': 'CropsGreenMaize',
    'Crops - Kale': 'CropsKale',
    'Crops - Lupins (sweet)': 'CropsLupinsForage',
    'Crops - Mangels': 'CropsMangels',
    'Crops - Rape': 'CropsRape',
    'Crops - Swede Bulbs': 'CropsSwedeBulbs',
    'Crops - Swede Tops': 'CropsSwedeTops',
    'Crops - Turnip Bulbs': 'CropsTurnipBulbs',
    'Crops - Turnip Tops': 'CropsTurnipTops',
    'Herbs - Chicory': 'HerbsChicory',
    'Herbs - Plantain': 'HerbsPlantain',
    'Green Feeds - Barley': 'GreenFeedsBarley',
    'Green Feeds - Oats': 'GreenFeedsOats',
    'Green Feeds - Ryecorn': 'GreenFeedsRyecorn',
    'Green Feeds - Triticale': 'GreenFeedsTriticale',
    'Green Feeds - Wheat': 'GreenFeedsWheat',
    'Lucerne - Bud Formation': 'LucerneBudFormation',
    'Lucerne - Green Vegetative': 'LucerneGreenVegetative',
    'Lucerne - 10-20% Flowering': 'LucerneFlowering',
    'Pasture - Mid-Length Leafy': 'PastureMidLengthLeafy',
    'Pasture - Red Clover': 'PastureRedClover',
    'Pasture - Short Leafy': 'PastureShortLeafy',
    'Pasture - Stalky': 'PastureStalky',
    'Pasture - White Clover': 'PastureWhiteClover',
  },
}

export const filterToApiName = {
  'DNA ID': 'DnaId',
  EID: 'Eid',
  'VID Year': 'VidYear',
  VID: 'Vid',
  'Velvet SA Grade': 'VelvetSaGrade',
  'Health Treatment': 'HealthTreatment',
}

export const Key = {
  filter: 'filter',
  review: 'review',
}

export const filters = {
  Cattle: {
    key: Key.filter,
    title: 'Add Filter',
    form: RadioForm,
    values: {
      'Birth Date': BirthDate,
      'Birth Rank': BirthRank,
      Breed: CattleBreed,
      'Breeder Farm': CattleDeerBreederFarm,
      Event: Event,
      'Conception Date': ConceptionDate,
      'Conception Phase': ConceptionPhase,
      'Condition Score': CattleConditionScore,
      'DNA ID': DNAID,
      'Drafting Direction': DraftingDirection,
      'Health Treatment': HealthTreatment,
      Paddock: Paddock,
      Feed: Feed,
      Forage: Forage,
      Gender: CattleGender,
      Group: Group,
      Status: Status,
      'Scan Result': ScanResult,
      'Stud Farm': CattleDeerStudFarm,
      'Tag Color': TagColor,
      'VID Year': VIDYear,
      Weight: Weight,
      'Year Born': YearBorn,
      // 'Dam Tag': DamTag,
      // 'Dam Group': DamGroup,
      // EID: EID,
      // 'Sire Tag': SireTag,
      // 'Sire Group': SireGroup,
      // 'TAG': Tag,
      // VID: VID,
    },
  },
  Deer: {
    key: Key.filter,
    title: 'Add Filter',
    form: RadioForm,
    values: {
      'Birth Date': BirthDate,
      'Birth Rank': BirthRank,
      Breed: DeerBreed,
      'Breeder Farm': CattleDeerBreederFarm,
      'Conception Date': ConceptionDate,
      'Conception Phase': ConceptionPhase,
      'Condition Score': DeerSheepConditionScore,
      'DNA ID': DNAID,
      'Drafting Direction': DraftingDirection,
      Event: Event,
      Feed: Feed,
      Forage: Forage,
      Gender: DeerGender,
      Group: Group,
      'Hard Antler Grade': DeerHardAntlerGrade,
      'Hard Velvet Grade': DeerHardVelvetGrade,
      'Health Treatment': HealthTreatment,
      Paddock: Paddock,
      Status: Status,
      'Scan Result': ScanResult,
      'Stud Farm': CattleDeerStudFarm,
      'Tag Color': TagColor,
      'Velvet Circumference Grade': DeerVelvetCircumferenceGrade,
      'Velvet Circumference': DeerVelvetCircumference,
      'Velvet Damage Grade': DeerVelvetDamageGrade,
      'Velvet Length Grade': DeerVelvetLengthGrade,
      'Velvet Manufacturing Grade': DeerVelvetManufacturingGrade,
      'Velvet Overall Length': DeerVelvetOverallLength,
      'Velvet Regrowth Grade': DeerVelvetRegrowthGrade,
      'Velvet SA Grade': DeerVelvetSAGrade,
      'Velvet Spiker Grade': DeerVelvetSpikerGrade,
      'Velvet Top Length': DeerVelvetTopLength,
      'Velvet Weight': DeerVelvetWeight,
      'VID Year': VIDYear,
      Weight: Weight,
      'Year Born': YearBorn,
      // 'Dam Tag': DamTag,
      // 'Dam Group': DamGroup,
      // EID: EID,
      // 'Sire Tag': SireTag,
      // 'Sire Group': SireGroup,
      // // 'TAG': Tag,
      // VID: VID,
    },
  },
  Sheep: {
    key: Key.filter,
    title: 'Add Filter',
    form: RadioForm,
    values: {
      'Birth Date': BirthDate,
      'Birth Rank': BirthRank,
      Breed: SheepBreed,
      'Breeder Farm': SheepBreederFarm,
      'Conception Date': ConceptionDate,
      'Conception Phase': ConceptionPhase,
      'Condition Score': DeerSheepConditionScore,
      'DNA ID': DNAID,
      'Drafting Direction': DraftingDirection,
      Event: Event,
      Feed: Feed,
      Forage: Forage,
      Gender: SheepGender,
      Group: Group,
      'Health Treatment': HealthTreatment,
      'Scan Result': ScanResult,
      'Skirted Weight': SheepSkirtedWeight,
      Status: Status,
      'Stud Farm': SheepStudFarm,
      'Tag Color': TagColor,
      Paddock: Paddock,
      'Wool Colour': SheepWoolColour,
      'Wool Length Class': SheepWoolLengthClass,
      'Wool Micron Class': SheepWoolMicronClass,
      'Wool Style': SheepWoolStyle,
      'Wool Tenderness': SheepWoolTenderness,
      'VID Year': VIDYear,
      Weight: Weight,
      'Wool Weight': SheepWoolWeight,
      'Year Born': YearBorn,
      // 'Dam Tag': DamTag,
      // 'Dam Group': DamGroup,
      // EID: EID,
      // 'Sire Tag': SireTag,
      // 'Sire Group': SireGroup,
      // // 'TAG': Tag,
      // VID: VID,
    },
  },
}
