import React from 'react'
import styled from 'styled-components'
import { width } from 'styled-system'
import Button from '@material-ui/core/Button'
import { colors } from '../../styles/styleConstants'

import './Dialog.css'

const DialogHeader = ({
  title,
  headerRight,
  hasSubmit,
  submitDisabled,
  hasClose,
  onClose,
  closeDisabled,
  ...props
}) => (
  <Header>
    <Title>{title}</Title>
    <div>
      {headerRight && headerRight}
      {hasSubmit && (
        <DialogHeaderButton type='submit' disabled={submitDisabled}>
          {props.submitMessage || 'Save'}
        </DialogHeaderButton>
      )}
      {hasClose && (
        <DialogHeaderButton disabled={closeDisabled} onClick={onClose}>
          {props.closeMessage || 'Cancel'}
        </DialogHeaderButton>
      )}
    </div>
  </Header>
)

export default DialogHeader

const Header = styled.div`
  min-height: 54px;
  padding: 0 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.borderGrey};
`

const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
`

export const DialogHeaderButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled ? colors.lightGrey : colors.green} !important; // override MUI defaults
  color: #fff !important; // override MUI defaults
  width: 116px;
  margin-left: 20px !important; // override MUI defaults
  ${width}
`
