import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { requireAuth } from '../../../components/withAuth'
import Layout from '../../../components/Layout'
import moment from 'moment'
import styled from 'styled-components'
import Formsy from 'formsy-react'
import _ from 'lodash'

import { ActionHeader, ButtonContainer, ActionHeaderButton, PageTitle, Divider } from '../../../components/PageHeaders'
import AccountSelector from '../../../components/AccountSelector'
import FarmSelector from '../../../components/FarmSelector'
import WarningDialog from '../../../components/Dialog/WarningDialog'
import { SelectField } from '../../../components/Form/Form'
import { colors } from '../../../styles/styleConstants'
import {
  monthChoices,
  yearChoices,
  planningClassMap,
  planningCategoryMap,
  eventTypeChoicesMap,
  planTypes,
  planClasses,
  planCategories,
  sortingCollator,
  mobileScreenBreakpoint,
} from '../../../constants'
import Table from './components/Table/index'
import AddTemplateDialog from './components/AddTemplateDialog'
import AddEventDialog from './components/AddEventDialog'
import DeleteEventDialog from './components/DeleteEventDialog'
import CreateWorksheet from './components/CreateWorksheet'

import { getAllBillingAccounts } from '../../../data/billing'
import { getAllFarmsForUser } from '../../../data/farm'
import { getAllFarmPlans, completeFarmEvent, uncompleteFarmEvent, deleteFarmPlan } from '../../../data/plans'
import { getHealthTreatmentsForAccount, getHealthTreatmentsForFarm } from '../../../data/health_treatments'
import { selectableFarmRoles } from './constants'

const actionButtonWidth = '140px'

class BasePlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imports: {},
      loading: false,
      selected: [],
      accountHealthTreatments: {},
      farmHealthTreatments: {},
      searchStr: '',
      month: moment().format('M'),
      year: moment().format('YYYY'),
      farms: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirect) {
      this.setState({ redirect: false })
    }
  }

  getData = async () => {
    const data = {}

    this.setState({ loading: true })

    data.farms = (await getAllFarmsForUser(this.props.user.uid)).filter((f) => selectableFarmRoles.has(f.userRole))

    if (this.props.managePlans) {
      data.accounts = await getAllBillingAccounts(this.props.user.uid)
    }

    this.setState(data)

    // this really should be this.props.match.params.farmId but urls are messed up
    const urlFarmId = this.props.location.pathname.split('/')[2]

    if (_.get(data, 'accounts.length') === 1) {
      this.handleAccountSelected(data.accounts[0].id, data.accounts[0].businessName)
    } else if (!this.props.managePlans && data.farms.length === 1) {
      this.handleFarmSelected(data.farms[0].id)
    } else if (!this.props.managePlans && urlFarmId) {
      this.handleFarmSelected(urlFarmId)
    }
  }

  deselectAccount = () =>
    this.setState({
      redirect: 'main',
      accountId: null,
      selectedFarmId: null,
    })

  deselectFarm = () => this.setState({ redirect: 'main', selectedFarmId: null, farmHealthTreatments: [] })

  getFarms = () => this.state.farms.filter((f) => !this.props.managePlans || f.accountId === this.state.accountId)

  handleAccountSelected = async (accountId) => {
    this.setState(
      {
        accountId,
        loading: false,
        hasChanged: false,
      },
      () => {
        // auto select first farm if there's only one when account is selected
        // gotta do it after `accountId` is in state for filter to work
        let farmId = this.state.selectedFarmId

        if (!farmId) {
          const farmIds = this.getFarms().map((f) => f.id)

          if (farmIds.length === 1) {
            farmId = farmIds[0]
          }
        }

        if (farmId) {
          this.handleFarmSelected(farmId)
        }
      },
    )

    // user can choose any account-wide health treatment when managing all plans
    // otherwise, see handleFarmSelected()
    if (this.props.managePlans) {
      getHealthTreatmentsForAccount(accountId).then((data) =>
        this.setState({
          accountHealthTreatments: data.accountHealthTreatments.reduce((o, row) => ({ ...o, [row.id]: row }), {}),
        }),
      )
    } else {
      this.setState({ accountHealthTreatments: [] })
    }
  }

  handleFarmSelected = (farmId) => {
    this.setState(
      {
        selectedFarmId: farmId,
        hasChanged: false,
        redirect: { farmId },
        fromDisabled: false,
      },
      this.getPlanData,
    )

    this.getFarmHealthTreatments(farmId)

    if (window.innerWidth < mobileScreenBreakpoint) {
      this.setState({ goToCalendar: true })
    }
  }

  getFarmHealthTreatments = (farmId) => {
    // gotta load it separately so it can be reloaded on event save
    // it's entirely possible that a new option appeared (account level treatment copied to farm)
    // and without reloading app will crash
    getHealthTreatmentsForFarm(farmId).then((data) => {
      this.setState({
        farmHealthTreatments: data.healthTreatments.reduce((o, row) => ({ ...o, [row.id]: row }), {}),
      })
    })
  }

  getPlanData = async (data) => {
    const farmPlanData = await getAllFarmPlans(
      this.state.selectedFarmId,
      parseInt(this.state.year),
      parseInt(this.state.month),
    )

    this.setState({ farmPlanData, ...data })
  }

  onChangeFrom = (field, value) => {
    this.setState({ [field]: value }, this.getPlanData)
  }

  onToggleComplete = (eventId, isComplete) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.getPlanData()
      }
    }

    ;(isComplete ? completeFarmEvent : uncompleteFarmEvent)(this.state.selectedFarmId, eventId).then(onSave)
  }

  formatData = () => {
    const byPlanType = {}

    ;(this.state.farmPlanData || []).map((row) => {
      if (!byPlanType[row.planType]) {
        byPlanType[row.planType] = {}
      }

      let rowKey
      const className = planningClassMap[row.planType].get(row.planClass)
      const categoryName = planningCategoryMap[row.planType].get(row.planCategory)

      if (row.planCategory) {
        rowKey = `${className} - ${categoryName}`
      } else {
        rowKey = className
      }

      // can't use a tuple or something as a key
      rowKey = rowKey + '\t' + row.planClass + '\t' + row.planCategory + '\t' + row.planName

      if (!byPlanType[row.planType][rowKey]) {
        byPlanType[row.planType][rowKey] = []
      }

      const eventData = {
        ..._.pick(row, [
          'eventName',
          'eventType',
          'completed',
          'dependentEventId',
          'dependentEventName',
          'planCategory',
          'hasChildren',
          'healthTreatment',
          'id',
          'instructions',
          'leadLag',
          'planClass',
          'planType',
          'purpose',
          'repeat',
          'previousDueDate',
          'planId',
        ]),
        dueDate: moment(row.dueDate),
        type: eventTypeChoicesMap[row.type],
        scheduleType: row.dependentEventId ? 'dependent' : 'fixed',
      }

      byPlanType[row.planType][rowKey].push(eventData)
    })

    return Object.entries(byPlanType)
      .sort((a, b) => {
        return planTypes.indexOf(a[0]) - planTypes.indexOf(b[0])
      })
      .map((entry) => {
        const [planType, classData] = entry
        return {
          name: planType,
          classes: Object.entries(classData)
            .map((entry) => {
              const [rowKey, eventData] = entry
              const [rowName, planClass, planCategory, planName] = rowKey.split('\t')

              return {
                name: rowName,
                planName: planName || 'Default',
                realPlanName: planName,
                events: eventData,
                // used for sorting below
                planType,
                planClass,
                planCategory,
                planId: eventData[0].planId, // guaranteed to not be empty
              }
            })
            .sort((a, b) => {
              if (a.name === b.name) {
                return sortingCollator.compare(a.realPlanName, b.realPlanName)
              }

              if (a.planClass === b.planClass) {
                return (
                  planCategories[a.planType].indexOf(a.planCategory) -
                  planCategories[b.planType].indexOf(b.planCategory)
                )
              }

              return planClasses[a.planType].indexOf(a.planClass) - planClasses[b.planType].indexOf(b.planClass)
            }),
        }
      })
  }

  deletePlan = (planId) => {
    const onDelete = ({ success }) => {
      if (success) {
        this.getPlanData({ planToDeleteName: undefined, planToDelete: undefined })
      }
    }

    deleteFarmPlan(this.state.selectedFarmId, planId).then(onDelete)
  }

  onDialogClose = (reload, state) => {
    if (reload) {
      this.getPlanData(state)
      this.getFarmHealthTreatments(this.state.selectedFarmId)
    } else {
      this.setState(state)
    }
  }

  copyEvent = (eventData) => {
    // open event is guaranteed to have come from currently visible list
    const planName = this.state.farmPlanData.find((e) => e.id === eventData.id).planName

    this.setState({
      eventToEdit: {
        ...eventData,
        id: undefined,
        templateName: planName || 'Default',
        realTemplateName: planName,
        copy: true,
        scheduleType: undefined,
        dependentEventId: undefined,
        dependentEventName: undefined,
        leadLag: undefined,
      },
      addEventDialogOpen: true,
    })
  }

  setEventToEdit = (data) => {
    const eventData = _.cloneDeep(data)

    // editing existing event means it already has a farm-level treatment assigned
    if (eventData.healthTreatment) {
      eventData.healthTreatment.id = `f_${eventData.healthTreatment.id}`
    }

    this.setState({ eventToEdit: eventData, addEventDialogOpen: true })
  }

  getHealthTreatmentOptions = () => {
    // manage plans has access to both types of treatments so add prefix
    // farm plans only has farm level so no need to prefix, account treatments array is empty
    const farmPrefix = this.props.managePlans ? '[F] ' : ''

    return Object.fromEntries([
      ...Object.entries(this.state.accountHealthTreatments).map(([key, val]) => [
        `a_${key}`,
        { ...val, name: `[A] ${val.name}` },
      ]),
      ...Object.entries(this.state.farmHealthTreatments).map(([key, val]) => [
        `f_${key}`,
        { ...val, name: `${farmPrefix}${val.name}` },
      ]),
    ])
  }

  openWorksheetDialog = () => this.setState({ createWorksheetDialogOpen: true })
  openTemplateDialog = () => this.setState({ addTemplateDialogOpen: true })
  handleGoToCalendar = () => this.setState({ goToCalendar: true })
  openEventDialog = () => this.setState({ addEventDialogOpen: true })
  openDeleteEventDialog = (data) => this.setState({ eventToDelete: data, deleteEventDialogOpen: true })
  openDeletePlanDialog = (planName, planId) => this.setState({ planToDelete: planId, planToDeleteName: planName })
  onTemplateDialogClose = () => this.getPlanData({ addTemplateDialogOpen: false })
  onEventDialogClose = (reload) => this.onDialogClose(reload, { addEventDialogOpen: false, eventToEdit: null })
  onDeleteEventDialogClose = () => this.getPlanData({ deleteEventDialogOpen: false, eventToDelete: null })
  onDeletePlanDialogClose = () => this.setState({ planToDeleteName: undefined, planToDelete: undefined })
  onDeletePlan = () => this.deletePlan(this.state.planToDelete)
  onWorksheetDialogClose = () => this.getPlanData({ createWorksheetDialogOpen: false })

  onYearChange = (year) => this.onChangeFrom('year', year)
  onMonthChange = (month) => this.onChangeFrom('month', month)

  render() {
    if (this.state.redirect && this.state.redirect === 'main') {
      return <Redirect to={`/${this.props.path}`} />
    } else if (
      this.state.redirect &&
      this.state.redirect.farmId &&
      this.props.location.pathname.split('/')[2] !== this.state.redirect.farmId
    ) {
      return <Redirect to={`/${this.props.path}/${this.state.redirect.farmId}`} />
    }

    if (this.state.goToCalendar) {
      return <Redirect to={`/farm-plans/${this.state.selectedFarmId}/calendar`} />
    }

    const data = this.formatData()
    const farms = this.getFarms()
    const isMobile = window.innerWidth < mobileScreenBreakpoint
    const deletePlanWarning = {
      title: `Delete plan "${this.state.planToDeleteName}"`,
      message: 'You are about to delete this plan. This action cannot be undone. Are you sure?',
      hasCancel: true,
    }

    return (
      <Layout>
        <Route
          exact
          path={`/${this.props.path}/:farmId?`}
          render={() => (
            <>
              <PageTitle onClick={this.deselectAccount}>{this.props.title}</PageTitle>
              <ActionHeader>
                <Formsy>
                  <ButtonContainer>
                    {this.props.managePlans && (
                      <AccountSelector
                        accounts={this.state.accounts}
                        handleAccountSelected={this.handleAccountSelected}
                        accountId={this.state.accountId}
                        deselectAccount={this.deselectAccount}
                      />
                    )}
                    <FarmSelector
                      user={this.props.user}
                      farms={farms}
                      handleFarmSelected={this.handleFarmSelected}
                      farmId={this.state.selectedFarmId}
                      disabled={(!this.state.accountId && this.props.managePlans) || farms.length === 1}
                      deselectFarm={this.deselectFarm}
                    />
                    {!isMobile && (
                      <>
                        <Box marginRight={3} />
                        <From>FROM:</From>
                        <SelectField
                          name='month'
                          value={this.state.month}
                          backgroundColor={colors.green}
                          color={colors.white}
                          disabled={!this.state.selectedFarmId}
                          setOption={this.onMonthChange}
                          width='84px'
                          choices={monthChoices}
                        />
                        <Box marginRight={3} />
                        <SelectField
                          name='year'
                          value={this.state.year}
                          backgroundColor={colors.green}
                          color={colors.white}
                          disabled={!this.state.selectedFarmId}
                          setOption={this.onYearChange}
                          width='84px'
                          choices={yearChoices}
                        />
                        <Divider />
                        {this.props.managePlans && (
                          <>
                            <ActionHeaderButton
                              onClick={this.openWorksheetDialog}
                              disabled={!this.state.selectedFarmId}
                              width={actionButtonWidth}
                            >
                              Worksheet
                            </ActionHeaderButton>
                            <Divider />
                            <ActionHeaderButton
                              onClick={this.openTemplateDialog}
                              disabled={!this.state.selectedFarmId}
                              width={actionButtonWidth}
                            >
                              Add Template
                            </ActionHeaderButton>
                          </>
                        )}
                        {!this.props.managePlans && (
                          <>
                            <ActionHeaderButton
                              onClick={this.handleGoToCalendar}
                              disabled={!this.state.selectedFarmId}
                              width={actionButtonWidth}
                            >
                              Calendar View
                            </ActionHeaderButton>
                            <Divider />
                          </>
                        )}
                        <ActionHeaderButton
                          onClick={this.openEventDialog}
                          disabled={!this.state.selectedFarmId}
                          width={actionButtonWidth}
                        >
                          Add Event
                        </ActionHeaderButton>
                      </>
                    )}
                  </ButtonContainer>
                </Formsy>
              </ActionHeader>
              <Box marginTop={5} />
              <div className='hide-on-mobile'>
                {this.state.selectedFarmId && (
                  <Table
                    data={data}
                    onToggleComplete={this.onToggleComplete}
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    setEventToEdit={this.setEventToEdit}
                    setEventToDelete={this.openDeleteEventDialog}
                    deletePlan={this.openDeletePlanDialog}
                  />
                )}
                {this.state.addTemplateDialogOpen && (
                  <AddTemplateDialog
                    open
                    accountId={this.state.accountId}
                    farmId={this.state.selectedFarmId}
                    onRequestClose={this.onTemplateDialogClose}
                  />
                )}
                {this.state.addEventDialogOpen && (
                  <AddEventDialog
                    open
                    accountId={this.state.accountId}
                    farmId={this.state.selectedFarmId}
                    onRequestClose={this.onEventDialogClose}
                    data={this.state.eventToEdit}
                    healthTreatments={this.getHealthTreatmentOptions()}
                    managePlans={this.props.managePlans}
                    copyEvent={this.copyEvent}
                  />
                )}
                {this.state.deleteEventDialogOpen && (
                  <DeleteEventDialog
                    open
                    farmId={this.state.selectedFarmId}
                    onRequestClose={this.onDeleteEventDialogClose}
                    data={this.state.eventToDelete}
                  />
                )}
                {this.state.planToDelete && (
                  <WarningDialog
                    open
                    planName={this.state.planToDeleteName}
                    planId={this.state.planToDelete}
                    warning={deletePlanWarning}
                    handleRequestClose={this.onDeletePlanDialogClose}
                    handleConfirm={this.onDeletePlan}
                    confirmButtonTitle='Delete'
                  />
                )}
                {this.state.createWorksheetDialogOpen && (
                  <CreateWorksheet
                    open
                    farmId={this.state.selectedFarmId}
                    onRequestClose={this.onWorksheetDialogClose}
                  />
                )}
              </div>
            </>
          )}
        />
      </Layout>
    )
  }
}

const _ManagePlans = (props) => {
  return <BasePlans path='manage-plans' title='Manage Plans' managePlans={true} {...props} />
}

const _FarmPlans = (props) => {
  return <BasePlans path='farm-plans' title='Farm Plans' managePlans={false} {...props} />
}

const From = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  color: #555555;
  text-align: right;
  margin-right: 4px;
`

export const ManagePlans = requireAuth(_ManagePlans)
export const FarmPlans = requireAuth(_FarmPlans)
