export const formatChoices = (choices) => choices.map((c) => [c, c])
export const uomChoices = formatChoices(['bolus', 'capsule', 'dose', 'g', 'ml', 'tube'])
export const limitedUomChoices = formatChoices(['g', 'mg'])

export const monthChoices = [
  ['1', 'Jan'],
  ['2', 'Feb'],
  ['3', 'Mar'],
  ['4', 'Apr'],
  ['5', 'May'],
  ['6', 'Jun'],
  ['7', 'Jul'],
  ['8', 'Aug'],
  ['9', 'Sep'],
  ['10', 'Oct'],
  ['11', 'Nov'],
  ['12', 'Dec'],
]

const currentYear = new Date().getFullYear()
export const yearChoices = [-3, -2, -1, 0, 1, 2, 3].map((y) => [String(currentYear + y), String(currentYear + y)])

// sort strings using sortingCollator.compare()
// allows for natural sorting
export const sortingCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

export const choiceMap = {
  Farm: {
    planClass: [
      ['Admin', 'Admin'],
      ['HealthSafety', 'Health & Safety'],
      ['Land', 'Land'],
      ['Staff', 'Staff'],
      ['Stock', 'Stock'],
    ],
    planCategory: [
      ['Cattle', 'Cattle'],
      ['Deer', 'Deer'],
      ['Sheep', 'Sheep'],
    ],
  },
  Cattle: {
    planClass: [
      ['Calf', 'Calf'],
      ['R1', 'R1'],
      ['R2', 'R2'],
      ['MixedAge', 'Mixed Age'],
    ],
    planCategory: [
      ['Bull', 'Bull'],
      ['Cow', 'Cow/Heifer'],
      ['MixedCattle', 'Mixed'],
      ['Steer', 'Steer'],
    ],
  },
  Deer: {
    planClass: [
      ['Calf', 'Calf'],
      ['R1', 'R1'],
      ['R2', 'R2'],
      ['MixedAge', 'Mixed Age'],
    ],
    planCategory: [
      ['Hind', 'Hind'],
      ['MixedDeer', 'Mixed'],
      ['Stag', 'Stag'],
    ],
  },
  Sheep: {
    planClass: [
      ['Lamb', 'Lamb'],
      ['Hogget', 'Hogget'],
      ['TwoTooth', '2 Tooth'],
      ['MixedAge', 'Mixed Age'],
    ],
    planCategory: [
      ['Ewe', 'Ewe'],
      ['MixedSheep', 'Mixed'],
      ['Ram', 'Ram'],
      ['Wether', 'Wether'],
    ],
  },
}

// order is important
export const planTypes = ['Farm', 'Cattle', 'Deer', 'Sheep']
export const planClasses = {
  Farm: ['Admin', 'HealthSafety', 'Land', 'Staff', 'Stock'],
  Cattle: ['Calf', 'R1', 'R2', 'MixedAge'],
  Deer: ['Calf', 'R1', 'R2', 'MixedAge'],
  Sheep: ['Lamb', 'Hogget', 'TwoTooth', 'MixedAge'],
}
export const planCategories = {
  Farm: ['Cattle', 'Deer', 'Sheep'], // Stock only
  Cattle: ['Bull', 'Cow', 'MixedCattle', 'Steer'],
  Deer: ['Hind', 'MixedDeer', 'Stag'],
  Sheep: ['Ewe', 'MixedSheep', 'Ram', 'Wether'],
}

export const planningClassMap = Object.assign(
  {},
  ...Object.keys(choiceMap).map((k) => ({ [k]: new Map(choiceMap[k].planClass) })),
)
export const planningCategoryMap = Object.assign(
  {},
  ...Object.keys(choiceMap).map((k) => ({ [k]: new Map(choiceMap[k].planCategory) })),
)

export const eventTypeChoicesMap = {
  Farm: 'Farm',
  Service: 'Service',
  Treatment: 'Treatment',
}
export const eventTypeChoices = Object.entries(eventTypeChoicesMap)

export const dosageTypeChoicesMap = { per_animal: 'Per Animal', per_kg: 'Per kg' }

export const datePickerFormat = 'MMMM D, YYYY'
export const eventDateFormat = 'DD/MM/YYYY'
export const timestampFormat = 'DD/MM/YYYY hh:mm:ss A'

export const mobileScreenBreakpoint = 768

// role choices depend on account type
export const roleChoices = {
  Vet: formatChoices(['Clinic', 'Admin', 'Retail', 'Vet', 'Farm', 'Staff', 'Viewer']),
  Standard: formatChoices(['Account', 'Farm', 'Staff', 'Viewer']),
}

export const nzRegionChoices = [
  ['Northland', 'Northland'],
  ['Auckland', 'Auckland'],
  ['Waikato', 'Waikato'],
  ['Bay of Plenty', 'Bay of Plenty'],
  ['Gisborne', 'Gisborne'],
  ["Hawke's Bay", "Hawke's Bay"],
  ['Taranaki', 'Taranaki'],
  ['Manawatu-Wanganui', 'Manawatu-Wanganui'],
  ['Wellington', 'Wellington'],
  ['Tasman', 'Tasman'],
  ['Nelson', 'Nelson'],
  ['Marlborough', 'Marlborough'],
  ['West Coast', 'West Coast'],
  ['Canterbury', 'Canterbury'],
  ['Otago', 'Otago'],
  ['Southland', 'Southland'],
]
