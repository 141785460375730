import React, { useState, useEffect } from 'react'
import MaterialTable from '../../components/Table/Table'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

import Search from '../../components/Search'

import './Nait.css'

const columns = [
  { id: 'eid', label: 'EID' },
  { id: 'vid', label: 'Visual Tag' },
  { id: 'entryType', label: 'Entry Type' },
  { id: 'eventName', label: 'Event Name' },
  { id: 'date', label: 'Date/Time' },
  { id: 'groupName', label: 'Group Name' },
].map((column) => ({
  ...column,
  style: { whiteSpace: 'nowrap' },
}))

const AssignmentTable = (props) => {
  const [searchStr, setSearchStr] = useState('')
  const [allSelected, setAllSelected] = useState(false)

  let data = props.data.map((row, i) => ({ ...row, origIndex: i }))

  if (searchStr) {
    const re = new RegExp(_.escapeRegExp(searchStr), 'i')
    data = data.filter((row) => re.test(row.eventName || '') || re.test(row.groupName || ''))
  }

  const allVisibleIndexes = data.map((row) => row.origIndex)

  // set "Select All" checkbox state if selection or search string changed
  useEffect(() => {
    setAllSelected(_.isEqual(allVisibleIndexes, props.selected))
  }, [props.selected, searchStr])

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Search placeholder='Search Event or Group Names' onChange={(event) => setSearchStr(event.target.value)} />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <p style={{ lineHeight: 2.5 }}>Selected Count: {props.selected.length}</p>
        </Grid>
        <Grid item xs={2}>
          <p>
            Select All
            <Checkbox
              checked={allSelected}
              onClick={() => {
                if (allSelected) {
                  const currentIndexes = new Set(allVisibleIndexes)
                  props.updateSelected(props.selected.filter((x) => !currentIndexes.has(x)))
                } else {
                  props.updateSelected(allVisibleIndexes)
                }
              }}
            />
          </p>
        </Grid>
      </Grid>
      {data && props.data.length > 0 && (
        <MaterialTable
          columns={columns}
          data={data}
          selectable
          selected={props.selected}
          updateSelected={props.updateSelected}
        />
      )}
    </>
  )
}

export default AssignmentTable
