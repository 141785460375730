import React from 'react'
import { Redirect } from 'react-router-dom'
import { PasswordField, ReadOnlyField, withFormValidation, SubmitButton } from '../../components/Form/Form.js'
import { NameField } from '../../components/Form/CustomFields'
import { createUser, getInviteTokenData, confirmInvitation } from '../../data/user'
import { logout } from '../../helpers/auth'
import Layout from '../../components/Layout'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import { withUser } from '../../components/withAuth'

const InviteFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <NameField name='name' label='Name' required />
    </div>
    <div className='FormRow'>
      <PasswordField name='password' label='Password' required />
    </div>
    <div className='FormRow'>
      <PasswordField name='repeatPassword' label='Retype Password' required />
      <div name='error' color='red'></div>
    </div>
    <div className='FormRow'>
      <ReadOnlyField name='email' label='Email' value={props.email} />
    </div>

    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Submit</SubmitButton>
    </div>
  </div>
)

const InviteForm = withFormValidation(InviteFormContent)

const ConfirmationFormContent = () => (
  <div>
    <div className='ButtonWrap'>
      <SubmitButton>Accept</SubmitButton>
    </div>
  </div>
)

const ConfirmationForm = withFormValidation(ConfirmationFormContent)

class Invite extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      farm: '',
      validationErrors: {
        repeatPassword: '',
      },
      tokenData: '',
      snackbarOpen: false,
      loaded: false,
    }

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.confirm = this.confirm.bind(this)
    this.getInitialData = this.getInitialData.bind(this)
    this.handleRequestCloseSnackbar = this.handleRequestCloseSnackbar.bind(this)
  }

  componentDidMount() {
    const user = this.props.user
    getInviteTokenData(this.props.match.params.token)
      .then((data) => {
        if (data.hasAccount) {
          if (user && user.email === data.email) {
            this.setState({
              snackbarContent: 'User authenticated. Confirm Invitation',
              snackbarOpen: true,
              confirmation: true,
              tokenData: data,
              loaded: true,
            })
          } else {
            if (user) {
              logout()
            }
            this.setState({
              snackbarContent: 'Log in to accept invitation.',
              redirectSignIn: true,
              tokenData: data,
              loaded: true,
            })
          }
        } else {
          if (user) {
            logout()
          }
          this.setState({
            snackbarContent: 'Create an account to accept invitation.',
            snackbarOpen: true,
            tokenData: data,
            loaded: true,
          })
        }
      })
      .catch((err) => {
        this.setState({
          tokenData: {
            error: err,
          },
          loaded: true,
        })
      })
  }

  handleRequestCloseSnackbar() {
    this.setState({
      snackbarOpen: false,
    })
  }

  getInitialData() {
    return getInviteTokenData(this.props.match.params.token).then((data) => {
      this.setState({
        tokenData: data,
      })
    })
  }

  validate(data) {
    const password = data.password
    const repeatPassword = data.repeatPassword
    const errors = {}

    // check passwords match
    if (password && repeatPassword && password !== repeatPassword) {
      errors.repeatPassword = 'Passwords do not match'
    }

    this.setState({
      validationErrors: errors,
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.setState({
          confirmation: true,
        })
      }
    }

    delete data.repeatEmail
    delete data.repeatPassword

    createUser(data).then(onSave)
  }

  confirm() {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        // account context refresh() seems to crash because of new user state
        window.location = '/dashboard'

        /*
        this.setState({
          redirectDashboard: true,
          redirectSignIn: false,
        })
        */
      }
    }

    confirmInvitation(this.props.match.params.token).then(onSave)
  }

  render() {
    return (
      <Layout loaded={this.state.loaded}>
        {this.state.loaded ? (
          this.state.tokenData.error ? (
            <h4>This invitation has expired. Contact your farm&apos;s account manager for assistance.</h4>
          ) : this.state.redirectSignIn ? (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: {
                  from: '/invite/' + this.props.match.params.token,
                  email: this.state.tokenData.email,
                  snackbarContent: this.state.snackbarContent,
                  snackbarOpen: true,
                },
              }}
            />
          ) : this.state.redirectDashboard ? (
            <Redirect to='/dashboard' />
          ) : (
            <div className='Invite'>
              {this.state.confirmation ? (
                <div>
                  <h1>Accept Invitation</h1>
                  <h4>
                    You have been invited to view details for {this.state.tokenData.objectName} on stockmanager.co.nz.
                    Accept the invitation here.
                  </h4>
                  <ConfirmationForm submit={this.confirm} />
                </div>
              ) : (
                <div>
                  <h1>Sign Up</h1>
                  <h4>
                    You have been invited to view details for {this.state.tokenData.objectName} on stockmanager.co.nz.
                    Create your account here.
                  </h4>
                  <InviteForm
                    getInitialData={this.getInitialData}
                    email={this.state.tokenData.email}
                    validate={this.validate}
                    validationErrors={this.state.validationErrors}
                    submit={this.submit}
                  />
                </div>
              )}
              <BottomSnackbar
                open={this.state.snackbarOpen}
                handleRequestClose={this.handleRequestCloseSnackbar}
                message={this.state.snackbarContent}
              />
            </div>
          )
        ) : (
          <div>Loading...</div>
        )}
      </Layout>
    )
  }
}

export default withUser(Invite)
