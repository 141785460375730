import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import MaterialTable from '../../components/Table/Table'
import TableHeading from './TableHeading'

import moment from 'moment'

import '../../components/Dialog/Dialog.css'

const transmissionDetailsDataColumns = [
  { id: 'status', label: 'Status' },
  { id: 'date', label: 'Date' },
  { id: 'summary', label: 'Summary' },
]

const summaries = {
  Success: 'All records processed successfully with no warnings.',
  Partial: 'Transmission Records Errors. See below for failure detail.',
}

const formatTransmissionDetailsData = (data) =>
  data.map((row) => {
    let status = row.status
    if (status === 'Partial') {
      status = 'Partial Success'
    } else if (status === 'Failed' && !row.transmissionFailure) {
      status = 'Failed (Records)'
    }

    return {
      status,
      date: moment(row.date).format('DD/MM/YY hh:mm'),
      summary: row.summary || summaries[row.status] || '',
    }
  })

const transmissionRecordsDataColumns = [
  { id: 'eid', label: 'EID' },
  { id: 'vid', label: 'VID' },
  { id: 'vidYear', label: 'VID Year' },
  { id: 'animal', label: 'Type' },
  { id: 'status', label: 'Status' },
  { id: 'naitStatus', label: 'NAIT' },
  { id: 'date', label: 'Date' },
  { id: 'details_trunc', label: 'Details' },
]

const formatTransmissionRecordsData = (data) => {
  return data.map((row) => {
    const truncateDetails = row.details && row.details.length > 30

    return {
      ...row,
      date: moment(row.date).format('DD/MM/YY hh:mm'),
      details_trunc: [
        truncateDetails,
        truncateDetails ? row.details.substring(0, 25) + '...' : row.details,
        row.details,
      ],
    }
  })
}

export default ({ open, handleRequestClose, transmissionDetailsData, transmissionRecordsData }) => (
  <Dialog className='dialog transmissionDetailsDialog' onClose={handleRequestClose} open={open}>
    <div className='dialogContent'>
      <TableHeading text='Transmission Details' />
      {transmissionDetailsData && transmissionDetailsData.length > 0 && (
        <MaterialTable
          columns={transmissionDetailsDataColumns}
          data={formatTransmissionDetailsData(transmissionDetailsData)}
        />
      )}
      {transmissionRecordsData && transmissionRecordsData.length > 0 && (
        <div>
          <TableHeading text='Transmission Records Detail' />
          <MaterialTable
            columns={transmissionRecordsDataColumns}
            data={formatTransmissionRecordsData(transmissionRecordsData)}
          />
        </div>
      )}
    </div>
  </Dialog>
)
