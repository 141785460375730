import { sleep } from '../../helpers/utils'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings } from './dashboard'

export const AverageWeightAtWeaningMetric: DashboardMetric = {
  type: 'Average Weight at Weaning',
  animalType: AnimalType.Cattle,
  timePeriod: 'Last 3 Years',
}

export const AverageWeightAtWeaningChart: DashboardMetricChartSettings = {
  type: 'LineChart',
  options: {
    chartArea: { width: '80%', height: '80%' },
    legend: 'top',
    curveType: 'function',
    hAxis: {
      viewWindow: {
        min: 2019,
        max: 2021,
      },
      ticks: [
        { v: 2019, f: '2019' },
        { v: 2020, f: '2020' },
        { v: 2021, f: '2021' },
      ],
    },
    colors: ['#CC0000', '#F1C232', 'green'],
  },
}

export async function calculateAverageWeightAtWeaning(farmId: string, metric: DashboardMetric): Promise<[[]]> {
  sleep(1000)

  return [
    ['Year', 'Farm', 'Goal', 'Benchmark'],
    [2019, 32.1, 32.5, 33.2],
    [2020, 32.8, 33, 33.5],
    [2021, 33.6, 33.5, 33.6],
  ]
}
