// @ts-ignore
import config from 'config'

import { get, post, put } from '../../helpers/fetch'
import { getAuthHeaders } from '../../helpers/firebase'
import { AnimalType } from '../types'
import {
  DashboardMetric,
  DashboardMetricChartSettings,
  formatDateRange,
  formatMonth,
  TimePeriod,
  timePeriodToDateFrom,
} from './dashboard'

export interface DamConditionMetric extends DashboardMetric {
  readonly animalType: AnimalType
  readonly timePeriod: TimePeriod
}

export interface DamConditionMonthRange {
  readonly low: number
  readonly high: number
}

export interface DamConditionSetting {
  readonly monthRanges: DamConditionMonthRange[]
}

export interface DamConditionSettings {
  readonly includeNonId: boolean
  readonly Cattle: DamConditionSetting
  readonly Deer: DamConditionSetting
  readonly Sheep: DamConditionSetting
}

export interface DamConditionRequest {
  readonly animalType: string
  readonly dateFrom: string
  readonly dateTo: string
}

export interface DamConditionResult {
  readonly damScores: readonly DamConditionResultRow[]
}

export interface DamConditionResultRow {
  readonly month: string
  readonly below: number
  readonly above: number
}

export const damConditionMetric: DamConditionMetric = {
  type: 'Dam Condition',
  animalType: AnimalType.Cattle,
  timePeriod: 'Last Year',
}

export const damConditionChartSettings: DashboardMetricChartSettings = {
  type: 'ColumnChart',
  options: {
    chartArea: { width: '80%', height: '80%' },
    legend: { position: 'top', alignment: 'center' },
    isStacked: true,
    colors: ['#CC0000', '#F1C232'],
    vAxis: {
      textStyle: { color: '#919191' },
    },
    hAxis: {
      textStyle: { color: '#919191' },
    },
  },
}

export const damConditionTimePeriods: readonly TimePeriod[] = [
  'Last Month',
  'Last Year',
  'Last 2 Years',
  'Last 3 Years',
]

export async function calculateDamCondition(
  farmId: string,
  metric: DamConditionMetric,
  position: number,
): Promise<[string, string | number, string | number][]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/metric/DamCondition`

  const dateRange = formatDateRange(timePeriodToDateFrom(metric.timePeriod), new Date())

  const request: DamConditionRequest = {
    dateFrom: dateRange.from,
    dateTo: dateRange.to,
    animalType: metric.animalType,
    position: position,
  }

  const headers = await getAuthHeaders()
  const result: DamConditionResult = await post(url, request, headers)

  return [['Month', '# < Min Condition Score', '# > Max Condition Score']].concat(
    result.damScores.map((row) => {
      return [formatMonth(row.month), row.below, row.above]
    }),
  )
}

export async function loadDamConditionSettings(farmId: string, position: number): Promise<DamConditionSettings> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/DamCondition`

  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export async function saveDamConditionSettings(
  farmId: string,
  settings: DamConditionSettings,
  position: number,
): Promise<void> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/DamCondition`

  const headers = await getAuthHeaders()
  await put(url, settings, headers)
}
