import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import TagImportDetails from './TagImportDetails'

import { PageTitle, ActionHeader, ButtonContainer, ActionHeaderLinkButton } from '../../components/PageHeaders'
import FarmSelector from '../../components/FarmSelector'
import ImportTable from './ImportTable'
import CompleteImport from './CompleteImport'
import BackHeading from '../../components/BackHeading'
import DetailsForm from './DetailsForm'
import ImportForm from './ImportForm'
import WarningDialog from '../../components/Dialog/WarningDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

import { getAllFarmsForUser } from '../../data/farm'
import {
  getImportsForFarm,
  postFileImport,
  postExternalImport,
  postManualImport,
  getDetailsForOrder,
  postDetailsForGroup,
} from '../../data/import_tags'

import _ from 'lodash'
import queryString from 'query-string'

const StyledLoader = styled(CircularProgress)`
  margin-top: 20px;
`

class ImportTags extends React.Component {
  constructor(props) {
    super(props)

    const queryParams = queryString.parse(props.location.search)

    this.state = {
      data: {},
      orderDetails: {},
      selectedFarmId: queryParams.farmId,
      loading: true,
      warningDialogOpen: false,
    }

    this.handleFarmSelected = this.handleFarmSelected.bind(this)
    this.submitManualImport = this.submitManualImport.bind(this)
    this.submitAssignDetails = this.submitAssignDetails.bind(this)
    this.submitImportFile = this.submitImportFile.bind(this)
    this.redirectMain = this.redirectMain.bind(this)
  }

  componentDidMount() {
    getAllFarmsForUser(this.props.user.uid).then((farms) => {
      const permittedFarms = farms.filter((f) => ['Account', 'Farm', 'Vet', 'Clinic', 'Retail'].includes(f.userRole))
      this.setState({
        farms: permittedFarms,
        selectedFarmName: this.props.match.params.farmId
          ? permittedFarms.find((farm) => farm.id === this.props.match.params.farmId).farmName
          : null,
      })
    })

    if (this.state.selectedFarmId) {
      this.setState({
        redirect: { farmId: this.state.selectedFarmId },
      })
    }

    if (this.props.match.params.farmId) {
      this.getData(this.props.match.params.farmId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirect) {
      this.setState({ redirect: false })
    }

    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.props.match.params.orderId &&
      _.last(this.props.location.pathname.split('/')) === 'complete'
    ) {
      this.setState({ completeImportData: this.state.orderDetails[this.props.match.params.orderId].tagGroups })
    }
  }

  handleFarmSelected(farmId, farmName) {
    this.setState({
      selectedFarmName: farmName,
      redirect: { farmId },
    })

    this.getData(farmId)
  }

  getData(farmId) {
    this.setState({ loading: true })
    getImportsForFarm(farmId).then((data) => {
      this.setState({ tagData: data, loading: false })

      data.imports.forEach((order) => {
        const orderId = order.id

        getDetailsForOrder(farmId, orderId).then((data) => {
          this.setState({
            orderDetails: {
              ...this.state.orderDetails,
              [orderId]: data,
            },
          })

          if (
            this.props.match.params.orderId &&
            _.last(this.props.location.pathname.split('/')) === 'complete' &&
            orderId === this.props.match.params.orderId
          ) {
            this.setState({ completeImportData: data.tagGroups })
          }
        })
      })
    })
  }

  submitManualImport(data) {
    this.redirectMain()
    this.setState({ manualImportChanged: false, loading: true })

    const formattedData = {
      ...data,
      startVid: parseInt(data.startVid, 10),
      endVid: parseInt(data.endVid, 10),
    }

    postManualImport(this.props.match.params.farmId, formattedData).then((res) => {
      if (res.success) {
        this.getData(this.props.match.params.farmId)
      } else {
        this.setState({
          warningDialogOpen: true,
          warningInfo: {
            title: 'Warning',
            message: 'The data could not be imported. Please try again.',
          },
        })
      }
    })
  }

  submitAssignDetails(data) {
    this.setState({ loading: true })
    this.redirectMain()

    postDetailsForGroup(this.props.match.params.farmId, this.props.match.params.orderId, data).then((res) => {
      if (!res.success) {
        this.setState({
          warningDialogOpen: true,
          warningInfo: {
            title: 'Warning',
            message: res.error,
          },
        })
      }

      this.getData(this.props.match.params.farmId)
    })
  }

  submitImportFile(data) {
    this.redirectMain()
    this.setState({ importChanged: false, loading: true })

    if (data.source === 'Device File') {
      let formData = new FormData()
      formData.append('file', data.selectedFile)
      postFileImport(this.props.match.params.farmId, formData).then((res) => {
        if (!res.success) {
          let message = null
          if (res.details) {
            message = 'The file could not be imported. Please check the format of your data.'
            res.details.forEach((error) => {
              message = message.concat('\n' + error)
            })
          } else {
            message = res.error || 'The file could not be imported. Unknown server error.'
          }
          this.setState({
            warningDialogOpen: true,
            warningInfo: {
              title: 'Warning',
              message: message,
            },
          })
        }
        this.getData(this.props.match.params.farmId)
      })
    } else {
      const formattedData = {
        orderNumber: data.orderNumber,
        service: data.source.replace(' ', ''),
      }

      postExternalImport(this.props.match.params.farmId, formattedData).then((res) => {
        if (res.success) {
          this.getData(this.props.match.params.farmId)
        } else {
          this.setState({
            warningDialogOpen: true,
            warningInfo: {
              title: 'Warning',
              message: res.error,
            },
          })
        }
      })
    }
  }

  redirectMain() {
    this.setState({ redirect: 'main' })
  }

  render() {
    const { farmId, orderId } = this.props.match.params
    const { dataToComplete } = this.state

    if (this.state.redirect && this.state.redirect === 'main') {
      return <Redirect to={`/import-tags/${farmId}`} />
    } else if (
      this.state.redirect &&
      this.state.redirect.farmId &&
      this.props.location.pathname.split('/')[2] !== this.state.redirect.farmId
    ) {
      return <Redirect to={`/import-tags/${this.state.redirect.farmId}`} />
    } else if (
      this.state.redirect &&
      this.state.redirect === 'assign-details' &&
      _.last(this.props.location.pathname.split('/')) !== 'assign-details'
    ) {
      return <Redirect to={`/import-tags/${farmId}/${orderId}/assign-details`} />
    }

    // const hideTable = ['file', 'manual'].includes(_.last(this.props.location.pathname.split('/')))

    return (
      <Layout>
        <Route
          path='/import-tags/:farmId/:orderId/import-details'
          render={() => (
            <TagImportDetails
              farmId={farmId}
              orderDetails={this.state.tagData ? this.state.tagData.imports.find((tag) => tag.id === orderId) : null}
              backURL={`/import-tags/${farmId}`}
            />
          )}
        />
        <Route
          path='/import-tags/:farmId/:orderId/complete'
          render={() => (
            <CompleteImport
              data={this.state.completeImportData}
              assignDetails={(data) =>
                this.setState({
                  dataToComplete: data,
                  redirect: 'assign-details',
                })
              }
              backURL={`/import-tags/${farmId}`}
            />
          )}
        />
        <Route
          path='/import-tags/:farmId/:orderId/assign-details'
          render={() =>
            dataToComplete ? (
              <div>
                <BackHeading text='Assign Tag Details' backURL={`/import-tags/${farmId}/${orderId}/complete`} />
                <DetailsForm
                  submit={(data) => {
                    this.submitAssignDetails({
                      ...data,
                      // if endVid is null, all tags in the group will be assigned
                      startVid: dataToComplete.allowRangeAssign ? data.startVid : null,
                      endVid: dataToComplete.allowRangeAssign ? data.endVid : null,
                      tagIds: dataToComplete.allowRangeAssign ? null : dataToComplete.tagIds,
                    })
                  }}
                  data={dataToComplete}
                />
              </div>
            ) : (
              <Redirect to={`/import-tags/${farmId}/${orderId}/complete`} />
            )
          }
        />
        <Route
          path='/import-tags/:farmId/manual'
          render={() => (
            <div>
              <BackHeading
                text='Manual Tag Load'
                backURL={`/import-tags/${farmId}/manual`}
                goBack={() => {
                  if (this.state.manualImportChanged) {
                    this.setState({
                      warningDialogOpen: true,
                      warningInfo: {
                        title: 'Warning',
                        message: 'Selections have not been completed and will be lost.',
                        hasCancel: true,
                      },
                    })
                  } else {
                    this.redirectMain()
                  }
                }}
              />
              <DetailsForm
                manual
                submit={this.submitManualImport}
                setHasChanged={() => this.setState({ manualImportChanged: true })}
              />
            </div>
          )}
        />
        <Route
          path='/import-tags/:farmId/file'
          render={() => (
            <div>
              <BackHeading
                text='Import Tag Files'
                backURL={`/import-tags/${farmId}/file`}
                goBack={() => {
                  if (this.state.importChanged) {
                    this.setState({
                      warningDialogOpen: true,
                      warningInfo: {
                        title: 'Warning',
                        message: 'Selections have not been completed and will be lost.',
                        hasCancel: true,
                      },
                    })
                  } else {
                    this.redirectMain()
                  }
                }}
              />
              <ImportForm submit={this.submitImportFile} setHasChanged={() => this.setState({ importChanged: true })} />
            </div>
          )}
        />
        {
          <Route
            exact
            path='/import-tags/:farmId?'
            render={() => (
              <div>
                <PageTitle>Import Tags</PageTitle>
                <ActionHeader>
                  <FarmSelector
                    user={this.props.user}
                    farms={this.state.farms}
                    handleFarmSelected={this.handleFarmSelected}
                    farmId={this.props.match.params.farmId}
                  />
                  <ButtonContainer>
                    {this.props.match.params.farmId && this.props.match.params.farmId.length > 0 && (
                      <>
                        <ActionHeaderLinkButton
                          disabled={!this.props.match.params.farmId}
                          to={`/import-tags/${farmId}/file`}
                          style={{ marginRight: '10px' }}
                        >
                          Import Tag Files
                        </ActionHeaderLinkButton>

                        <ActionHeaderLinkButton
                          disabled={!this.props.match.params.farmId}
                          to={`/import-tags/${farmId}/manual`}
                        >
                          Manual Tag Load
                        </ActionHeaderLinkButton>
                      </>
                    )}
                  </ButtonContainer>
                </ActionHeader>

                {this.props.match.params.farmId && this.state.tagData && (
                  <ImportTable baseLinkURL={`/import-tags/${farmId}`} data={this.state.tagData.imports} />
                )}
                {this.props.match.params.farmId && this.state.loading && <StyledLoader className='loader' />}
              </div>
            )}
          />
        }
        <WarningDialog
          open={this.state.warningDialogOpen}
          warning={this.state.warningInfo}
          handleRequestClose={() =>
            this.setState({
              warningDialogOpen: false,
              loading: false,
            })
          }
          handleConfirm={() => {
            this.redirectMain()
            this.setState({
              warningDialogOpen: false,
              loading: false,
            })
          }}
        />
      </Layout>
    )
  }
}

export default requireAuth(ImportTags)
