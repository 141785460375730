export const colors = {
  black: '#000',
  grey: '#595955',
  lightGrey: '#e6e6e6',
  borderGrey: '#e0e0e0',
  green: '#2e7d32',
  lightGreen: '#629749',
  darkGreen: '#003d00',
  orange: '#f57c00',
  lightOrange: '#ffad42',
  darkOrange: '#bb4d00',
  white: '#fff',
  offWhite: '#f6f6f6',
  red: '#a21111',
  yellow: '#e7ac16',
}

export const maxContainerWidth = 1160
export const maxContentWidth = 1112
