import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import LoadingContainer from '../../../components/LoadingContainer'
import MaterialTable from '../../../components/Table/Table'
import { getDataElements } from '../../../data/analysis-api'
import ChangeColumnDialog from './ChangeColumnDialog'
import { defaultColumns, requiredColumns } from './constants'

const makeTableStyles = makeStyles({
  root: {
    overflowY: 'auto',
    overflowX: 'auto',

    '& tr:hover td:nth-child(-n+4)': {
      background: '#F5F5F5',
    },
    '& td:nth-child(-n+4), & th:nth-child(-n+4)': {
      position: 'sticky',
      zIndex: 1,
      background: 'white',
      backgroundClip: 'content-box',
    },
    // EID
    '& td:nth-child(1), & th:nth-child(1)': {
      left: 0,
      minWidth: 150,
      display: (props) => (props.eid ? 'table-cell' : 'none'),
    },
    // VID
    '& td:nth-child(2), & th:nth-child(2)': {
      left: (props) => (props.eid ? 150 : 0),
      minWidth: 75,
    },
    // Vid Year
    '& td:nth-child(3), & th:nth-child(3)': {
      left: (props) => (props.eid ? 225 : 75),
      minWidth: 100,
    },
    // Tag Color
    '& td:nth-child(4), & th:nth-child(4)': {
      left: (props) => (props.eid ? 325 : 175),
      minWidth: 100,
      boxShadow: (props) => (props.onlyStickyCols ? '' : 'inset -1px 0 0 #666'), // regular border travels with data
    },
    // first customizable column needs some extra padding
    '& td:nth-child(5), & th:nth-child(5)': {
      paddingLeft: '1em',
    },
  },
})

const formatValue = {
  Weight: (val) => val.toFixed(2) + ' kg',
  WeightGain: (val) => (val * 1000).toFixed(2) + ' g/day',
  TotalWeightGain: (val) => val.toFixed(2) + ' kg',
}

const getElementLabel = (dataElement) => {
  const rename = {
    Vid: 'VID',
    Eid: 'EID',
  }
  return dataElement
    .match(/[A-Z][a-z]+/g)
    .map((e) => rename[e] || e)
    .join(' ')
}

const AnalysisViewList = (props) => {
  const [dataElements, setDataElements] = useState([])
  const [formattedAnimalData, setFormattedAnimalData] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [loading, setLoading] = useState(true)

  const formatAnimalListData = (animals) => {
    setFormattedAnimalData(
      animals
        // .map((animal) => ({Eid: 'x', ...animal}))
        .map((animal) =>
          Object.fromEntries(
            Object.entries(animal).map(([key, val]) => {
              const formatter = formatValue[key]
              return [key, formatter && val !== null ? formatter(val) : val]
            }),
          ),
        ),
    )
  }

  // refetch available columns if data animal type changes
  useEffect(() => {
    try {
      getDataElements({ animalType: props.animalType }).then((dataElements) => {
        // sort here so everything else is in correct order
        const dataElementSet = new Set(dataElements)
        defaultColumns.forEach((e) => dataElementSet.delete(e))
        requiredColumns.forEach((e) => dataElementSet.delete(e))
        dataElementSet.delete('Eid') // special
        const orderedElements = [
          'Eid', // first
          ...requiredColumns,
          ...defaultColumns,
          ...Array.from(dataElementSet).sort(), // alpha
        ]

        setDataElements(orderedElements.map((column) => ({ id: column, label: getElementLabel(column) })))
        setLoading(false)
      })
    } catch (error) {
      console.error(error.message)
    }
  }, [props.animalType])

  // format animal data only once on change
  useEffect(() => {
    formatAnimalListData(props.animals)
    setLoading(false)
  }, [props.animals])

  const setColumnsAndClose = (columns) => {
    setLoading(true)
    props.setColumns(new Set(columns))
    props.hideColumnDialog()
  }

  // update table columns based on selected columns
  useEffect(() => {
    setTableColumns(
      dataElements.filter((e) => props.columns.has(e.id) || requiredColumns.indexOf(e.id) !== -1 || e.id === 'Eid'),
    )
  }, [props.columns, dataElements])

  // show or hide Eid column via CSS
  // because otherwise have to do some voodoo magic to make those nth-child selectors
  // work with a different number of columns
  const hasEid = props.columns.has('Eid')
  const tableStyles = makeTableStyles({
    eid: hasEid,
    onlyStickyCols: props.columns.size === (hasEid ? 1 : 0),
  })

  return (
    <LoadingContainer loading={formattedAnimalData.length === 0 || loading}>
      <Container classes={tableStyles} disableGutters>
        <MaterialTable expand columns={tableColumns} data={formattedAnimalData} />
      </Container>

      <ChangeColumnDialog
        open={props.showColumnDialog}
        onClose={props.hideColumnDialog}
        columns={dataElements}
        setColumnsAndClose={setColumnsAndClose}
        currentColumns={props.columns}
        hiddenColumns={new Set(requiredColumns)}
      />
    </LoadingContainer>
  )
}

export default AnalysisViewList
