import { getAuthHeaders } from '../helpers/firebase'
import { get, put, post } from '../helpers/fetch'
import config from 'config'

export const getTemplatesForAccount = async (accountId) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const addTemplateForAccount = async (accountId, templateData) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, templateData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const disableTemplateForAccount = async (accountId, templateId) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}/disable/${templateId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const enableTemplateForAccount = async (accountId, templateId) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}/enable/${templateId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const addTemplateEventForAccount = async (accountId, templateId, eventData) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}/add-event/${templateId}`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, eventData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const editTemplateEventForAccount = async (accountId, templateId, eventData) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}/edit-event/${templateId}/${eventData.id}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, eventData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const deleteTemplateEventForAccount = async (accountId, templateId, eventId, eventData) => {
  const url = `${config.backend.url}/planning/templates/account/${accountId}/delete-event/${templateId}/${eventId}`

  // POST instead of DELETE because need to send some extra data
  try {
    const headers = await getAuthHeaders()
    const result = await post(url, eventData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}
