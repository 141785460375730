import config from 'config'
import firebase from 'firebase/app'
import 'firebase/auth'

let initialised = false
let observers = []
let user = localStorage.getItem('mockUser') || null

/* mocked Firebase app */
let app = {
  auth: () => {
    return {
      signInWithEmailAndPassword: (email, password) => {
        if (email == config.backend.email && password == config.backend.password) {
          user = {
            email: email,
            displayName: email,
            uid: email,
          }
        } else {
          user = null
        }

        localStorage.setItem('mockUser', user)

        observers.forEach((callback) => callback(user))

        return Promise.resolve(user)
      },

      currentUser: user,

      onAuthStateChanged: (observer) => {
        observer(user)
        observers.push(observer)
      },

      signOut: () => {
        user = null
        localStorage.setItem('mockUser', user || '')
        observers.forEach((callback) => callback(user))
        return Promise.resolve()
      },
    }
  },
}

export const getFirebase = () => {
  if (config.firebase.token) {
    return app
  } else if (!initialised) {
    firebase.initializeApp(config.firebase)
    initialised = true
  }
  return firebase
}

/**
 * get header dict for making authorized requests to https firebase functions
 * @return {Promise}
 */
export const getAuthHeaders = () => {
  if (config.firebase.token) {
    return Promise.resolve({
      Authorization: 'Bearer ' + config.firebase.token,
    })
  } else {
    return getFirebase()
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return {
          Authorization: 'Bearer ' + token,
        }
      })
  }
}
