import React from 'react'
import { Link } from 'react-router-dom'
import MaterialTable from '../../components/Table/Table'
import moment from 'moment'

import './ImportRecords.css'

const columns = [
  { id: 'created', label: 'Date' },
  { id: 'filename', label: 'File Name' },
  { id: 'status', label: 'Status' },
  { id: 'report', label: 'Report' },
]

const formatReport = (row, baseURL) => {
  if (row.status === 'Duplicates') {
    return <Link to={`${baseURL}/${row.id}/duplicates`}>Duplicate Report</Link>
  }

  if (row.status === 'Failed') {
    return <Link to={`${baseURL}/${row.id}/errors`}>Error Report</Link>
  }

  return '-'
}

const formatData = ({ data, baseURL }) => {
  return data.map((row) => ({
    ...row,
    created: moment(row.created).format('DD/MM/YY hh:mm'),
    report: formatReport(row, baseURL),
  }))
}

export const ImportHistoryTable = (props) => (
  <div>{props.data && <MaterialTable columns={columns} data={formatData(props)} />}</div>
)
