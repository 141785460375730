import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { requireAuth } from '../../../components/withAuth'
import Layout from '../../../components/Layout'
import Checkbox from '@material-ui/core/Checkbox'
import moment from 'moment'
import _ from 'lodash'

import { ActionHeader, ButtonContainer, ActionHeaderButton, PageTitle } from '../../../components/PageHeaders'
import FarmSelector from '../../../components/FarmSelector'
import Search from '../../../components/Search'
import Table from './components/Table'
import AddTreatmentDialog from './components/AddTreatmentDialog'
import AddPurchaseDialog from './components/AddPurchaseDialog'
import InfoDialog from '../../../components/Dialog/InfoDialog'
import { CheckboxWithLabel } from '../../../components/Form/CustomFields'

import { getAllFarmsForUser } from '../../../data/farm'
import { editHealthTreatmentForFarm, getHealthTreatmentsForFarm } from '../../../data/health_treatments'
import { EditPurchaseDialog } from './components/EditPurchaseDialog'

class HealthTreatments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFarmId: props.match.params.farmId,
      loading: true,
      farmHealthTreatments: [],
      farmHealthTreatmentEdits: {},
      purchaseEdits: {},
      searchStr: '',
      purchaseToEdit: null,
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirect) {
      this.setState({ redirect: false })
    }

    if (prevState.selectedFarmId !== this.state.selectedFarmId) {
      this.getData()
    }
  }

  getData = () => {
    getAllFarmsForUser(this.props.user.uid).then((farms) => {
      const permittedFarms = farms.filter((f) =>
        ['Account', 'Farm', 'Vet', 'Clinic', 'Staff', 'Retail'].includes(f.userRole),
      )
      const farmFromParams = this.props.match.params.farmId
        ? permittedFarms.find((farm) => farm.id === this.props.match.params.farmId)
        : null
      this.setState({
        farms: permittedFarms,
        selectedFarmName: farmFromParams ? farmFromParams.farmName : null,
      })

      if (this.state.selectedFarmId) {
        const { selectedFarmId } = this.state
        getHealthTreatmentsForFarm(selectedFarmId).then((data) => {
          this.setState({
            redirect: { farmId: selectedFarmId },
            farmHealthTreatments: data.healthTreatments,
          })
        })

        this.handleFarmSelected(selectedFarmId)
      }
    })

    this.setState({ loading: false })
  }

  redirectMain = () => this.setState({ redirect: 'main', selectedFarmId: null, selectedFarmName: null })

  setFarmHealthTreatments = (farmHealthTreatments) => {
    this.setState({ farmHealthTreatments: farmHealthTreatments })
  }

  handleFarmSelected = (farmId, farmName) => {
    this.setState({
      farmId,
      hasChanged: false,
      selectedFarmId: farmId,
      selectedFarmName: farmName || this.state.selectedFarmName,
      redirect: { farmId },
    })
  }

  formatData = () =>
    this.state.farmHealthTreatments
      .map((t) => {
        const treatmentForValues = _.get(this.state.farmHealthTreatmentEdits, t.id) || t

        const { alertQuantity } = treatmentForValues

        const healthTreatmentPurchases = treatmentForValues.healthTreatmentPurchases.map((p, key) => ({
          ...p,
          parentId: t.id,
          expiryDate: moment(p.expiryDate).format('DD/MM/YY'),
          updateDate: moment(p.updateDate).format('DD/MM/YY'),
          currentQuantity: _.isFinite(p.currentQuantity) ? String(p.currentQuantity) : '',
          rowDisabled: !p.enabled,
          inputErrors: _.get(this.state, `inputErrors.${t.id}.healthTreatmentPurchases[${key}]`),
        }))

        return {
          ...t,
          rvm: t.rvm ? 'Y' : 'N',
          currentQuantity: _.isFinite(t.currentQuantity) ? String(t.currentQuantity) : '',
          alertQuantity: _.isFinite(alertQuantity) ? String(alertQuantity) : '',
          updateDate: moment(t.createDate).format('DD/MM/YY'),
          rowDisabled: !t.enabled,
          inputErrors: _.get(this.state, `inputErrors.${t.id}`, null),
          healthTreatmentPurchases,
        }
      })
      .filter((t) => {
        let match = true
        if (this.state.searchStr.length > 2) {
          const re = new RegExp(this.state.searchStr, 'gi')
          match = re.test(t.name)
        }
        return match && (this.state.showDisabled ? true : t.enabled)
      })

  editTreatment = (treatment) => {
    editHealthTreatmentForFarm(
      this.state.selectedFarmId,
      treatment.id,
      _.pick(treatment, [
        'rvm',
        'meatWitholdingPeriod',
        'milkWitholdingPeriod',
        'produceWitholdingPeriod',
        'packSize',
        'salePrice',
        'instructions',
        'label',
      ]),
    )
  }

  render() {
    const { farmId } = this.props.match.params
    if (this.state.redirect && this.state.redirect === 'main') {
      return <Redirect to={`/health-treatments/`} />
    } else if (
      this.state.redirect &&
      this.state.redirect.farmId &&
      this.props.location.pathname.split('/')[2] !== this.state.redirect.farmId
    ) {
      return <Redirect to={`/health-treatments/${this.state.redirect.farmId}`} />
    }

    const data = this.formatData()

    return (
      <Layout>
        <Route
          exact
          path='/health-treatments/:farmId?'
          render={() => (
            <div>
              <PageTitle onClick={() => this.setState({ redirect: 'main' })}>Health Treatments</PageTitle>
              {farmId && (
                <ActionHeader>
                  <ButtonContainer>
                    <Search
                      placeholder='Search treatments'
                      onChange={(event) => this.setState({ searchStr: event.target.value })}
                      flexGrow={1.62}
                    />
                    <CheckboxWithLabel
                      control={
                        <Checkbox
                          checked={this.state.showDisabled}
                          onClick={(event) => this.setState({ showDisabled: event.target.checked })}
                        />
                      }
                      label='Show disabled'
                    />
                    <ActionHeaderButton onClick={() => this.setState({ addTreatmentDialogOpen: true })}>
                      Add Treatment
                    </ActionHeaderButton>
                    <FarmSelector
                      user={this.props.user}
                      farms={this.state.farms}
                      handleFarmSelected={this.handleFarmSelected}
                      farmId={this.state.farmId}
                      deselectFarm={this.redirectMain}
                    />
                  </ButtonContainer>
                </ActionHeader>
              )}
              {!farmId && (
                <ActionHeader>
                  <FarmSelector
                    user={this.props.user}
                    farms={this.state.farms}
                    handleFarmSelected={this.handleFarmSelected}
                    farmId={this.state.farmId}
                    deselectFarm={this.redirectMain}
                  />
                </ActionHeader>
              )}
              {farmId && (
                <Table
                  farmId={this.state.selectedFarmId}
                  data={data}
                  getData={this.getData}
                  addPurchase={(treatment) => {
                    this.setState({
                      addPurchaseDialogOpen: true,
                      treatmentForPurchase: treatment,
                    })
                  }}
                  editPurchase={(purchase) => {
                    this.setState({
                      purchaseToEdit: purchase,
                    })
                  }}
                  editTreatment={(treatment) => {
                    this.setState({
                      addTreatmentDialogOpen: true,
                      treatmentToEdit: treatment,
                    })
                  }}
                  openInstructions={(instructions) =>
                    this.setState({
                      infoDialogOpen: {
                        title: 'Use Instructions',
                        info: instructions,
                      },
                    })
                  }
                  openLabel={(label) =>
                    this.setState({
                      infoDialogOpen: {
                        title: 'Label',
                        info: label,
                      },
                    })
                  }
                />
              )}
              {this.state.addTreatmentDialogOpen && (
                <AddTreatmentDialog
                  open
                  onRequestClose={() => this.setState({ addTreatmentDialogOpen: false, treatmentToEdit: null })}
                  farmId={this.state.selectedFarmId}
                  data={this.state.treatmentToEdit}
                  accountId={this.state.accountId}
                  reloadTreatments={this.getData}
                />
              )}
              {this.state.addPurchaseDialogOpen && (
                <AddPurchaseDialog
                  open
                  onRequestClose={() => this.setState({ addPurchaseDialogOpen: false, treatmentForPurchase: null })}
                  farmId={this.state.selectedFarmId}
                  accountId={this.state.farmId}
                  treatment={this.state.treatmentForPurchase}
                  reloadTreatments={this.getData}
                />
              )}
              {this.state.purchaseToEdit && (
                <EditPurchaseDialog
                  onRequestClose={() => this.setState({ purchaseToEdit: null })}
                  onComplete={() => {
                    this.getData()
                    this.setState({ purchaseToEdit: null })
                  }}
                  purchase={this.state.purchaseToEdit}
                  farmId={this.state.selectedFarmId}
                />
              )}
              {this.state.infoDialogOpen && (
                <InfoDialog
                  open
                  onClose={() => this.setState({ infoDialogOpen: null })}
                  title={this.state.infoDialogOpen.title}
                  info={this.state.infoDialogOpen.info}
                />
              )}
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default requireAuth(HealthTreatments)
