import { Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const makeLoadingStyles = makeStyles({
  container: {
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    zIndex: 10,
    background: 'rgba(255, 255, 255, 0.75)',
  },
})

export default ({ loading, children }) => {
  const classes = makeLoadingStyles()

  return (
    <Container disableGutters className={classes.container}>
      {children}
      <Backdrop open={loading} className={classes.spinner}>
        <CircularProgress />
      </Backdrop>
    </Container>
  )
}
