import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

export default () => {
  return (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  position: relative;
`

const Loading = styled(CircularProgress)`
  display: inline-block;
  position: relative;
  margin-left: calc(50% - 20px);
`
