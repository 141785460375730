import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import { colors } from '../styles/styleConstants'
import _ from 'lodash'

import { sortingCollator } from '../constants'

/**
 * @param {String} value - search string
 * @param {Object} options - { name: display name in suggestion list, value: value of object for use when selected }
 */
export default class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      optionSelected: !!props.value,
      focused: false,
      typing: false,
    }

    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.clear && this.props.clear) {
      this.setState({ value: '', optionSelected: false })
    }
  }

  onChange = (event) => {
    this.props.onChange && this.props.onChange(event)
    this.state.optionSelected &&
      this.props.onOptionClick &&
      this.props.onOptionClick({ name: event.target.value, value: undefined })

    this.setState({ value: event.target.value, optionSelected: false, typing: true })
  }

  onFocus = (event) => {
    event.target.select()
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false, typing: false })
  }

  onOptionClick = (option) => {
    this.props.onOptionClick && this.props.onOptionClick(option)

    this.setState({ value: option.name, optionSelected: true, focused: false, typing: false })
  }

  setSearchRef = (searchRef) => {
    if (searchRef) {
      this.setState({ searchRef })
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    // since this thing ignores props.value when it's updated, need to explicitly update
    // TODO rewrite so this is not needed
    if (props.value && !current_state.value && !current_state.typing) {
      return { value: props.value }
    }

    return null
  }

  render() {
    const { name, label, placeholder, options, disabled, error, filter, noSort } = this.props
    const { value, optionSelected, focused, searchRef, typing } = this.state

    let suggestionsTop = 0
    let suggestionsLeft = 0

    let suggestions
    if (!disabled && options && ((value.length > 2 && typing) || focused)) {
      let filterCallback

      if (filter) {
        filterCallback = filter(value)
      } else {
        // don't use g modifier!!
        // https://stackoverflow.com/a/3827500/5113242
        const re = new RegExp(_.escapeRegExp(value), 'i')
        filterCallback = (o) => re.test(o.name)
      }

      suggestions = options.filter(filterCallback)

      if (!noSort) {
        suggestions = suggestions.sort((a, b) => sortingCollator.compare(a.name, b.name))
      }
    }

    if (searchRef && suggestions) {
      const rect = searchRef.getBoundingClientRect()
      suggestionsTop = parseInt(rect.top) + parseInt(rect.height)
      suggestionsLeft = parseInt(rect.left)
    }

    return (
      <Container flexGrow={this.props.flexGrow}>
        {label && <Label>{label}</Label>}
        <SearchBar label={!!label} disabled={disabled} error={error} ref={this.setSearchRef}>
          <SearchIcon />
          <Input
            name={name}
            value={value}
            type='search'
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete='off'
          />
          {suggestions &&
            !optionSelected &&
            ReactDOM.createPortal(
              <SuggestionBox top={suggestionsTop} left={suggestionsLeft}>
                {suggestions.map((s) => (
                  <Suggestion
                    key={Math.random()}
                    onClick={() => this.onOptionClick(s)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {s.name}
                  </Suggestion>
                ))}
              </SuggestionBox>,
              this.el,
            )}
          {error && <p className='MuiFormHelperText-root Mui-error'>{error}</p>}
        </SearchBar>
      </Container>
    )
  }
}

const Container = styled.div`
  flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : '1')};
`

const SearchBar = styled.div`
  height: 30px;
  margin-right: 20px;
  margin-top: ${(props) => (props.label ? '3px' : '')};
  background-color: ${(props) => (props.disabled ? colors.grey : '#f6f6f6')};
  color: #aaaaaa;
  position: relative;
  border: ${(props) => (props.error ? '1px solid red' : '')};

  svg {
    margin-left: 4px;
    margin-top: 3px;
  }
`

const Input = styled.input`
  position: absolute;
  background: transparent;
  border: none;
  top: 4px;
  left: 40px;
  width: calc(100% - 48px);
`

const SuggestionBox = styled.div`
  position: absolute;
  z-index: 10000;
  background: #f6f6f6;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  max-height: 240px;
  overflow-y: auto;
`

const Suggestion = styled.div`
  padding: 0 20px 8px 20px;
  color: black;
  cursor: pointer;
`

const Label = styled.label`
  color: #616161;
  font-size: 11px;
`
