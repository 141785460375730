import React from 'react'
import MaterialTable from '../../components/Table/Table'
import BackHeading from '../../components/BackHeading'
import moment from 'moment'

import './ImportRecords.css'
import { CircularProgress } from '@material-ui/core'

const columns = [
  { id: 'line', numeric: false, label: 'File Line' },
  { id: 'eid', numeric: false, label: 'EID' },
  { id: 'animalType', numeric: false, label: 'Animal Type' },
  { id: 'vidYear', numeric: false, label: 'VID Year' },
  { id: 'vid', numeric: false, label: 'Visual Tag #' },
  { id: 'dataElement', numeric: false, label: 'Data Element' },
  { id: 'value_trunc', numeric: false, label: 'Value' },
  { id: 'date', numeric: false, label: 'Date' },
  {
    id: 'error_trunc',
    numeric: false,
    label: 'Error',
    style: { width: '200px' },
  },
]

const formatData = (data) =>
  data.map((row) => {
    const error = row.errors.join('\n')
    const truncateError = error.length > 50
    const truncateValue = row.value.length > 13

    return {
      ...row,
      date: moment(row.createDate).format('DD/MM/YY'),
      value_trunc: [truncateValue, truncateValue ? `${row.value.slice(0, 10)}...` : row.value, row.value],
      error_trunc: [truncateError, truncateError ? `${error.slice(0, 45)}...` : error, error],
    }
  })

export default ({ data, backURL }) => (
  <div>
    <BackHeading withBorder text='Error Report' backURL={backURL} />
    {data ? <MaterialTable columns={columns} data={formatData(data)} /> : <CircularProgress className='loader' />}
  </div>
)
