import './styles/main.css'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import { initAuthHandlers } from './helpers/auth'
import { unregister } from './registerServiceWorker'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
)

initAuthHandlers()
unregister()
