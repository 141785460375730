import React from 'react'
import MaterialTable from '../../components/Table/Table'
import BackHeading from '../../components/BackHeading'
import moment from 'moment'

import './ImportRecords.css'
import { CircularProgress } from '@material-ui/core'

const columns = [
  { id: 'eid', numeric: false, label: 'EID' },
  { id: 'animalType', numeric: false, label: 'Animal Type' },
  { id: 'vidYear', numeric: false, label: 'VID Year' },
  { id: 'vid', numeric: false, label: 'Visual Tag #' },
  { id: 'dataElement', numeric: false, label: 'Data Element' },
  { id: 'value', numeric: false, label: 'Value' },
  { id: 'date', numeric: false, label: 'Date' },
]

const formatData = (data) =>
  data.map((row) => ({
    ...row,
    date: moment(row.createDate).format('DD/MM/YY'),
  }))

export default ({ data, backURL }) => (
  <div>
    <BackHeading withBorder text='Duplicate Report' backURL={backURL} />
    {data ? <MaterialTable columns={columns} data={formatData(data)} /> : <CircularProgress className='loader' />}
  </div>
)
