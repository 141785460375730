import Formsy from 'formsy-react'
import React from 'react'

import { UserAccount } from '../data/types'
import { colors } from '../styles/styleConstants'
import DropdownContainer from './DropdownContainer'
import { SELECT_PLACEHOLDER_OPTION_VALUE, SelectField } from './Form/Form'

interface Props {
  readonly accounts: UserAccount[]
  readonly accountId: string
  readonly selectedFarmName: string
  readonly handleAccountSelected: (farmId: string) => void
  readonly deselectAccount: () => void
  readonly disabled: boolean
  readonly margin: string
}

const AccountSelector: React.FunctionComponent<Props> = ({
  accounts,
  accountId,
  handleAccountSelected,
  deselectAccount,
  disabled,
  margin,
}): JSX.Element => {
  if (!accounts) {
    return <p>Loading...</p>
  }

  const setOption = (accountId: string): void => {
    if (accountId === SELECT_PLACEHOLDER_OPTION_VALUE && deselectAccount) {
      deselectAccount()
      return
    }

    handleAccountSelected(accountId)
  }

  const options = accounts.map((account) => [account.id, account.businessName])

  return (
    <DropdownContainer margin={margin}>
      <Formsy>
        <SelectField
          name='accountSelector'
          choices={options}
          placeholder='Select Account'
          showPlaceholder
          setOption={setOption}
          disabled={disabled || (accounts.length === 1 && accountId !== undefined)}
          backgroundColor={colors.green}
          color={colors.white}
          value={accountId}
        />
      </Formsy>
    </DropdownContainer>
  )
}

export default AccountSelector
