import React from 'react'
import { withFormValidation, PostalCodeField, SelectField, SubmitButton } from '../../../components/Form/Form'
import { FarmNameField } from '../../../components/Form/CustomFields.js'
import { createFarm } from '../../../data/farm'
import { SUBSCRIPTION_STATUSES } from '../../../data/billing'
import { requireAuth } from '../../../components/withAuth'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from '../../../components/Dialog/DialogHeader'

import '../../../components/Dialog/Dialog.css'
import { colors } from '../../../styles/styleConstants'
import { formatChoices } from '../../../constants'

const allSubscriptionTypeChoices = {
  Vet: formatChoices(['Full', 'Web']),
  Standard: formatChoices(['Standard']),
}

const AddFarmFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <FarmNameField name='farmName' label='Farm Name' required />
    </div>
    <div className='FormRow'>
      <PostalCodeField name='farmPostalCode' label='Postal Code' required />
    </div>
    <div className='FormRow'>
      <SelectField
        name='billingAccount'
        label='Select Billing Account'
        choices={props.billingAccountChoices}
        value={props.billingAccountChoices[0][0]}
        color={colors.white}
        backgroundColor={colors.green}
        required
        showPlaceholder
      />
    </div>
    <div className='FormRow'>
      <SelectField
        name='subscriptionType'
        label='Select Subscription Type'
        choices={props.subscriptionTypeChoices}
        color={colors.white}
        value={props.subscriptionType}
        backgroundColor={props.subscriptionTypeChoices.length < 2 ? colors.lightGrey : colors.green}
        disabled={props.subscriptionTypeChoices.length < 2}
        required
        showPlaceholder
      />
    </div>

    <div className='ButtonWrap'>
      <Button variant='contained' className='Back dialogBack' onClick={props.handleRequestClose}>
        Cancel
      </Button>
      <SubmitButton disabled={!props.canSubmit}>Add Farm</SubmitButton>
    </div>
  </div>
)

const AddFarmForm = withFormValidation(AddFarmFormContent)

class AddFarmDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
      subscriptionTypeChoices: [],
    }
  }

  validate = (data) => {
    let subscriptionTypeChoices = []
    let subscriptionType = data.subscriptionType

    if (data.billingAccount) {
      const account = this.props.accountMap[data.billingAccount]
      subscriptionTypeChoices = allSubscriptionTypeChoices[account.accountType]

      if (account.accountType === 'Standard') {
        subscriptionType = 'Standard'
      }
    }

    this.setState({ subscriptionTypeChoices, subscriptionType })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.props.updateFarmsList()
        this.handleRequestClose()
      }
    }

    const farmData = {
      farmName: data.farmName,
      farmPostalCode: data.farmPostalCode,
      subscriptionType: data.subscriptionType,
      // set all all farms as valid, because the app checks this
      subscriptionStatus: SUBSCRIPTION_STATUSES.VALID,
      accountId: data.billingAccount,
    }

    createFarm(farmData, this.props.user).then(onSave)
  }

  render() {
    return (
      <Dialog onClose={this.handleRequestClose} open={this.props.open}>
        <DialogHeader title='Add Farm' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <AddFarmForm
            billingAccountChoices={this.props.billingAccountChoices}
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            subscriptionTypeChoices={this.state.subscriptionTypeChoices}
            subscriptionType={this.state.subscriptionType}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(AddFarmDialog)
