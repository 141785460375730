import * as React from 'react'

import { getTagDetailsForImport, TagDetails, TagImport } from '../../../data/import_tags'
import TagImportDetails, { TagImportDetailsProps } from './TagImportDetails'

interface Props extends Omit<TagImportDetailsProps, 'tagDetailsData'> {
  readonly farmId: string
}

const ImportDetailsControl: React.FunctionComponent<Props> = ({ farmId, backURL, orderDetails }) => {
  const [tagDetailsData, setTagDetailsData] = React.useState<readonly TagDetails[]>([])

  React.useEffect(() => {
    let isSubscribed = true

    async function getDetails(orderDetails: TagImport): Promise<void> {
      const response = await getTagDetailsForImport(farmId, orderDetails.id)

      if (isSubscribed) {
        if (response.success && response.tags) setTagDetailsData(response.tags)
      }
    }

    if (orderDetails) {
      getDetails(orderDetails)
    }

    return () => {
      isSubscribed = false
    }
  }, [farmId, orderDetails])

  return <TagImportDetails orderDetails={orderDetails} backURL={backURL} tagDetailsData={tagDetailsData} />
}

export default ImportDetailsControl
