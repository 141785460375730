import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from './DialogHeader'
import { withFormValidation } from '../Form/Form'

import styled from 'styled-components'
import _ from 'lodash'

const FormContent = withFormValidation(
  ({ FormComponent, title, onClose, hasSubmit = true, canSubmit, errorMessage, currentData, ...props }) => (
    <div>
      <DialogHeader
        title={title}
        onClose={onClose}
        hasSubmit={hasSubmit}
        submitDisabled={!canSubmit || (_.has(props, 'hasChanged') && !props.hasChanged)}
        {...props}
      />
      <DialogContent overflowVisible={props.overflowVisible}>
        <div className='errorMessage'>{errorMessage}</div>
        <FormComponent currentData={currentData} {...props} />
      </DialogContent>
    </div>
  ),
)

export default ({ maxWidth, open, onClose, ...props }) => (
  <DialogContainer
    onClose={onClose}
    open={open}
    onBackdropClick={props.onBackdropClick || onClose}
    wide={true}
    maxWidth={maxWidth}
  >
    <FormContent onClose={onClose} {...props} />
  </DialogContainer>
)

export const DialogContainer = styled(Dialog)`
  div[role='dialog'] {
    width: 100%;
  }
`

export const DialogContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: ${(props) => (props.overflowVisible ? 'visible' : 'auto')};
  font-size: 14px;
`

export const SimpleFormDialog = ({
  wide,
  width,
  open,
  onClose,
  canSubmit,
  onSubmit,
  title,
  errorMessage,
  children,
}) => (
  <DialogContainer onClose={onClose} open={open} onBackdropClick={onClose} wide={wide} width={width}>
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <DialogHeader title={title} onClose={onClose} hasSubmit={true} submitDisabled={!canSubmit} />
      <DialogContent>
        <div className='errorMessage'>{errorMessage}</div>
        {children}
      </DialogContent>
    </form>
  </DialogContainer>
)
