import React from 'react'
import { withFormValidation, EmailField, PasswordField, SubmitButton } from '../../components/Form/Form'
import { requireAuth } from '../../components/withAuth'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from './DialogHeader'
import { updateEmail } from '../../data/user'
import { updateUser } from '../../helpers/auth'

import './Dialog.css'

const EmailFormContent = (props) => (
  <div>
    {props.error ? <p className='error'>{props.error}</p> : null}
    <div className='FormRow'>
      <PasswordField name='password' label='Password' required />
    </div>
    <div className='FormRow'></div>
    <div className='FormRow'>
      <EmailField name='email' label='New Email' required />
    </div>
    <div className='FormRow'>
      <EmailField name='repeatEmail' label='Repeat New Email' required />
    </div>

    <div className='ButtonWrap'>
      <Button onClick={props.onRequestClose}>Cancel</Button>
      <SubmitButton disabled={!props.canSubmit}>Save</SubmitButton>
    </div>
  </div>
)

const EmailForm = withFormValidation(EmailFormContent)

class ChangeEmailDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
    }
  }

  validate(data) {
    const email = data.email
    const repeatEmail = data.repeatEmail
    const errors = {}

    console.log('validating')

    if (email && repeatEmail && email !== repeatEmail) {
      console.log('error')
      errors.repeatEmail = 'Emails do not match'
    }

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  handleRequestCloseSuccess(newEmail) {
    this.props.onRequestCloseSuccess(newEmail)

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.handleRequestCloseSuccess(data.email)
        updateUser()
      }
    }

    updateEmail(data.email, data.password).then(onSave)
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Change Email' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <EmailForm
            submit={this.submit}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            onRequestClose={this.handleRequestClose}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(ChangeEmailDialog)
