import React from 'react'
import _ from 'lodash'

import { withFormValidation, SelectField, PushButton } from '../../components/Form/Form'
import MaterialTable from '../../components/Table/Table'
import FilterDialog from './FilterDialog'
import { CurrentDateString, filters, idFilters } from './FilterInfo'
import { colors } from '../../styles/styleConstants'
import { SelectFiltersIcon, ClearFiltersIcon } from '../../styles/icons'
import ActionContainer from './ActionContainer'

const animalTypeChoices = [
  ['Cattle', 'Cattle'],
  ['Deer', 'Deer'],
  ['Sheep', 'Sheep'],
]

const columns = [
  {
    id: 'filter',
    numeric: false,
    disablePadding: false,
    label: 'Filter Name',
    style: { width: '100px', whiteSpace: 'nowrap' },
  },
  {
    id: 'values',
    numeric: false,
    disablePadding: false,
    label: 'Filter Value(s)',
  },
  {
    id: 'overflow',
    label: '',
    overflowColumn: true,
  },
].map((c) => ({
  ...c,
  style: {
    ...c.style,
    fontSize: '12px',
    color: '#555555',
  },
}))

const AnimalTypeDropdown = (props) => {
  return (
    <SelectField
      name='animalType'
      placeholder='Select Animal Type'
      value={props.value}
      choices={animalTypeChoices}
      setOption={props.setValue}
      disabled={props.disabled}
      backgroundColor={props.disabled ? colors.grey : colors.green}
      color='white'
      required
      showPlaceholder
    />
  )
}
const AnimalType = withFormValidation(AnimalTypeDropdown)

export default class Filters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogOpen: false,
      editingFilter: null,
    }
  }

  formatData = () =>
    _.map(this.props.filters, (data, filter) => {
      let formattedValues = ''
      if (idFilters.has(filter)) {
        formattedValues = _.values(data.value).join(', ')
      } else if (data.value) {
        const { value } = data
        if (data.value.constructor === Array) {
          formattedValues = value.join(', ')
        } else if (data.value.constructor === Object) {
          formattedValues = value.from + ' - ' + value.to
        } else {
          formattedValues = value
        }
      }
      if (data.date) {
        if (data.date.current) {
          formattedValues = `${formattedValues} - ${CurrentDateString}`
        } else {
          formattedValues = `${formattedValues} - ${data.date.from} to ${data.date.to}`
        }
      }

      return {
        filter,
        values: formattedValues,
        id: filter,
      }
    })

  render() {
    const data = this.formatData() || []
    return (
      <div>
        <ActionContainer mt='12px'>
          <AnimalType
            disabled={this.props.disabled}
            setValue={this.props.selectAnimalType}
            value={this.props.animalType}
          />
          <PushButton
            disabled={!this.props.animalType}
            onClick={() => this.setState({ dialogOpen: true })}
            endIcon={<SelectFiltersIcon />}
          >
            Select Filters
          </PushButton>
          <PushButton disabled={!this.props.animalType} onClick={this.props.reset} endIcon={<ClearFiltersIcon />}>
            Clear Filters
          </PushButton>
        </ActionContainer>
        <MaterialTable
          expand
          columns={columns}
          data={data}
          actions={[
            {
              name: 'Edit',
              onClick: (filterName) => {
                this.setState({
                  editingFilter: {
                    name: filterName,
                    data: this.props.filters[filterName],
                  },
                  dialogOpen: true,
                })
              },
            },
            {
              name: 'Delete',
              onClick: (filterName) => this.props.deleteFilter(filterName),
            },
          ]}
        />

        <FilterDialog
          open={this.state.dialogOpen}
          onRequestClose={() => {
            this.setState({
              dialogOpen: false,
              editingFilter: null,
            })
          }}
          title='Add Filter'
          submit={this.props.addEditFilter}
          root={filters[this.props.animalType]}
          animalType={this.props.animalType}
          filter={this.state.editingFilter}
          disabled={_.keys(this.props.filters).length !== 0}
          getResource={this.props.getResource}
        />
      </div>
    )
  }
}
