import React from 'react'
import { Redirect } from 'react-router-dom'
import { getPaymentMethods, getTransactionHistory } from '../../data/billing'
import DisableBillingDialog from './Dialogs/DisableBillingDialog'
import EditBillingAccountDialog from './Dialogs/EditBillingAccountDialog'
import EditPaymentMethodsDialog from './Dialogs/EditPaymentMethodsDialog'
import ViewTransactionsDialog from './Dialogs/ViewTransactionsDialog'
import EditUsersDialog from './Dialogs/EditUsersDialog'
import BillingAccountDropdown from './BillingAccountDropdown'
import AddBillingDialog from './Dialogs/AddBillingDialog'
import MaterialTable from '../../components/Table/Table.js'
import { PageTitle, ActionHeader, ActionHeaderButton } from '../../components/PageHeaders'

const columns = [
  { id: 'businessName', numeric: false, label: 'Business Name' },
  { id: 'billingAccountId', numeric: false, label: 'Billing Account ID' },
  { id: 'accountType', numeric: false, label: 'Account Type' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'numFarms', numeric: false, label: 'Active Farms' },
  { id: 'button', numeric: false, label: '' },
]

class BillingAccountsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addBillingOpen: false,
    }
  }

  updateSelectedAccount = (account) => {
    this.setState({
      selectedAccount: account,
    })
  }

  handleOpenDisableBilling = () => {
    this.setState({
      disableBillingOpen: true,
    })
  }

  handleRequestCloseDisableBilling = () => {
    this.setState({
      disableBillingOpen: false,
    })
    this.props.updateBillingAccountsList()
  }

  handleOpenEditBillingAccount = () => {
    this.setState({
      editBillingAccountOpen: true,
    })
  }

  handleRequestCloseEditBillingAccountDialog = () => {
    this.setState({
      editBillingAccountOpen: false,
    })
    this.props.updateBillingAccountsList()
  }

  handleOpenEditPaymentMethods = (accountId) => {
    getPaymentMethods(accountId).then((res, err) => {
      if (err) {
        console.log(err)
      } else {
        console.log(res)
        if (res.length) {
          this.setState({
            editPaymentMethodsOpen: true,
            paymentMethods: res,
          })
        } else {
          this.setState({
            editPaymentMethodsOpen: true,
            paymentMethods: [res],
          })
        }
      }
    })
  }

  handleRequestCloseEditPaymentMethodsDialog = () => {
    this.setState({
      editPaymentMethodsOpen: false,
    })
  }

  handleOpenViewTransactions = (accountId) => {
    getTransactionHistory(accountId).then((res, err) => {
      if (err) {
        console.log(err)
      } else {
        this.setState({
          viewTransactionsOpen: true,
          transactions: res.transactions,
        })
      }
    })
    this.setState({
      viewTransactionsOpen: true,
    })
  }

  handleRequestCloseViewTransactions = () => {
    this.setState({
      viewTransactionsOpen: false,
    })
  }

  handleOpenEditUsers = () => {
    this.setState({
      editUsers: true,
    })
  }

  handleCloseEditUsers = () => {
    this.setState({
      editUsers: false,
    })
  }

  redirectFarms = (accountId) => {
    this.setState({
      redirectFarms: accountId,
    })
  }

  handleRequestCloseAddBillingDialog = () => {
    this.setState({
      addBillingOpen: false,
    })
  }

  formattedAccounts = () => {
    const allowedRoles = ['Admin', 'Account', 'Clinic']

    return this.props.accounts
      .filter((account) => allowedRoles.indexOf(account.accountRole) !== -1)
      .map((account) => {
        return {
          businessName: account.businessName,
          billingAccountId: account.id,
          status: account.subscriptionStatus,
          numFarms: account.numFarms.toString(),
          accountType: account.accountType,
          button: (
            <div>
              <BillingAccountDropdown
                account={account}
                updateSelectedAccount={this.updateSelectedAccount}
                handleOpenDisableBilling={this.handleOpenDisableBilling}
                handleOpenEditBillingAccount={this.handleOpenEditBillingAccount}
                handleOpenEditPaymentMethods={this.handleOpenEditPaymentMethods}
                handleOpenViewTransactions={this.handleOpenViewTransactions}
                handleOpenEditUsers={this.handleOpenEditUsers}
                redirectFarms={() => this.redirectFarms(account.id)}
              />
            </div>
          ),
        }
      })
  }

  updatePaymentMethods = (accountId) => {
    getPaymentMethods(accountId).then((res, err) => {
      if (err) {
        console.log(err)
      } else {
        if (res.length) {
          this.setState({
            editPaymentMethodsOpen: true,
            paymentMethods: res,
          })
        } else {
          this.setState({
            editPaymentMethodsOpen: true,
            paymentMethods: [res],
          })
        }
      }
    })
  }

  render() {
    if (this.state.redirectFarms) {
      return <Redirect push to={'/farms/' + this.state.redirectFarms} />
    }
    return (
      <div className='tablePageContainer'>
        <div>
          <PageTitle>Billing Accounts</PageTitle>
          <ActionHeader>
            <ActionHeaderButton onClick={() => this.setState({ addBillingOpen: true })}>
              Add Billing Account
            </ActionHeaderButton>
          </ActionHeader>
        </div>

        {this.props.farms === null ? (
          <p>You haven&apos;t added any billing accounts yet.</p>
        ) : (
          <MaterialTable style={{ maxWidth: '960px' }} columns={columns} data={this.formattedAccounts()} />
        )}
        <AddBillingDialog
          open={this.state.addBillingOpen}
          onRequestClose={this.handleRequestCloseAddBillingDialog}
          updateBillingAccountsList={this.props.updateBillingAccountsList}
        />
        {this.state.selectedAccount ? (
          <div>
            <DisableBillingDialog
              open={this.state.disableBillingOpen}
              accountId={this.state.selectedAccount.id}
              onRequestClose={this.handleRequestCloseDisableBilling}
            />
            <EditBillingAccountDialog
              open={this.state.editBillingAccountOpen}
              account={this.state.selectedAccount}
              onRequestClose={this.handleRequestCloseEditBillingAccountDialog}
            />
            <EditPaymentMethodsDialog
              accountId={this.state.selectedAccount.id}
              open={this.state.editPaymentMethodsOpen}
              onRequestClose={this.handleRequestCloseEditPaymentMethodsDialog}
              paymentMethods={this.state.paymentMethods}
              updatePaymentMethods={this.updatePaymentMethods}
            />
            <ViewTransactionsDialog
              open={this.state.viewTransactionsOpen}
              transactions={this.state.transactions}
              onRequestClose={this.handleRequestCloseViewTransactions}
            />
          </div>
        ) : null}
        {this.state.editUsers && (
          <EditUsersDialog
            accountId={this.state.selectedAccount.id}
            onRequestClose={this.handleCloseEditUsers}
            accountType={this.state.selectedAccount.accountType}
            uid={this.props.user.uid}
          />
        )}
      </div>
    )
  }
}

export default BillingAccountsList
