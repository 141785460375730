import React from 'react'
import { withFormValidation, PasswordField, SubmitButton } from '../../components/Form/Form'
import { requireAuth } from '../../components/withAuth'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from './DialogHeader'
import { updatePassword } from '../../data/user'

import './Dialog.css'

const PasswordFormContent = (props) => (
  <div>
    {props.error ? <p className='error'>{props.error}</p> : null}
    <div className='FormRow'>
      <PasswordField name='oldPassword' label='Old Password' required />
    </div>
    <div className='FormRow'></div>
    <div className='FormRow'>
      <PasswordField name='newPassword' label='New Password' required />
    </div>
    <div className='FormRow'>
      <PasswordField name='repeatPassword' label='Repeat New Password' required />
    </div>

    <div className='ButtonWrap'>
      <Button onClick={props.onRequestClose}>Cancel</Button>
      <SubmitButton disabled={!props.canSubmit}>Save</SubmitButton>
    </div>
  </div>
)

const PasswordForm = withFormValidation(PasswordFormContent)

class ChangePasswordDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
    }
  }

  validate(data) {
    const newPassword = data.newPassword
    const repeatPassword = data.repeatPassword
    const errors = {}

    if (newPassword && repeatPassword && newPassword !== repeatPassword) {
      errors.repeatPassword = 'Passwords do not match'
    }

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  handleRequestCloseSuccess() {
    this.props.onRequestCloseSuccess()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.handleRequestCloseSuccess()
      }
    }

    updatePassword(data.newPassword, data.oldPassword).then(onSave)
    // if (data.newPassword === data.confirm) {
    //   updatePassword(data.newPassword, data.oldPassword)
    //   this.handleRequestClose()
    // } else {
    //   this.setState({
    //     errorMessage: 'Passwords do not match.'
    //   })
    // }
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Change Password' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <PasswordForm
            submit={this.submit}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            onRequestClose={this.handleRequestClose}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(ChangePasswordDialog)
