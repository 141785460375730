import React from 'react'
import styled from 'styled-components'
import memoize from 'memoize-one'
import {
  SelectField,
  TextField,
  FormRow,
  IntRangeField,
  FormField,
  HiddenField,
} from '../../../../components/Form/Form'
import { requireAuth } from '../../../../components/withAuth'
import FormDialog from '../../../../components/Dialog/FormDialog'
import { colors } from '../../../../styles/styleConstants'
import WarningDialog from '../../../../components/Dialog/WarningDialog'
import Search from '../../../../components/Search'
import { addEventForAccount, editEventForAccount } from '../../../../data/manage_events'
import { choiceMap, formatChoices, eventTypeChoices, uomChoices } from '../../../../constants'
import _ from 'lodash'

import '../../../../components/Dialog/Dialog.css'

const limitedUom = ['g', 'mg']
const alreadyExistsError = 'Duplicate.'
const duplicateCanEditMsg =
  'The event name and type is a duplicate. To view and edit the event details, select the EDIT ' +
  'button. To modify the event name and/or type, select the CANCEL button.'
const duplicateDisabledMsg =
  'The event name and type is a duplicate and is disabled. To edit, first enable the event from the event listing.'
const duplicateNotEditableMsg = 'The event name and type is a default event and can not be edited.'

const AddEventForm = (props) => (
  <div>
    <FormRow mb='30px'>
      <FormField width='24%'>
        <SelectField
          name='eventType'
          width='100%'
          choices={eventTypeChoices}
          backgroundColor={props.editing || props.copy ? colors.grey : colors.green}
          color={colors.white}
          value={props.currentData.eventType}
          label='Event Type'
          disabled={props.editing || props.copy}
          required
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='planType'
          placeholder='Plan type'
          label='Plan Type'
          width='100%'
          choices={props.typeChoices}
          backgroundColor={props.editing ? colors.grey : colors.green}
          color={colors.white}
          value={props.currentData.planType}
          disabled={props.editing}
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='planClass'
          placeholder='Class'
          label='Class'
          width='100%'
          choices={props.classChoices}
          backgroundColor={props.editing || props.classDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={props.classDisabled || props.editing}
          value={props.currentData.planClass}
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='planCategory'
          placeholder='Category'
          label='Category'
          width='100%'
          choices={props.categoryChoices}
          backgroundColor={props.categoryDisabled || props.editing ? colors.grey : colors.green}
          color={colors.white}
          disabled={props.categoryDisabled || props.editing}
          value={props.currentData.planCategory}
          showPlaceholder
        />
      </FormField>
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='60%'>
        <TextField
          name='name'
          label='Event Name'
          value={props.currentData.name}
          disabled={!!((props.initialData && !props.copy) || props.disableAll || !props.currentData.eventType)}
          required
          onBlur={props.onUnfocusName}
          innerRef={(c) => {
            props.dom.name = c
          }}
        />
      </FormField>
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='100%'>
        <TextField
          name='purpose'
          label='Purpose'
          value={props.currentData.purpose}
          disabled={!!(props.disableAll || !props.currentData.eventType) || props.copy}
          multiline
        />
      </FormField>
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='100%'>
        <TextField
          name='instructions'
          label='Instructions'
          value={props.currentData.instructions}
          disabled={!!(props.disableAll || !props.currentData.eventType) || props.copy}
          multiline
        />
      </FormField>
    </FormRow>
    {props.currentData.eventType === 'Treatment' && (
      <FormRow mb='30px'>
        <FormField width='40%'>
          <Search
            name='healthTreatmentName'
            label='Health Treatment'
            placeholder='Search treatments'
            options={props.healthTreatmentChoices}
            onOptionClick={props.onHealthTreatmentClick}
            autocomplete='off'
            value={_.get(props.currentData, 'healthTreatmentName')}
            filter={(value) => {
              const re = new RegExp(_.escapeRegExp(value), 'i')
              return (o) => re.test(o.name) || o.productTypes.filter((pt) => re.test(pt)).length
            }}
            disabled={props.copy}
          />
          <HiddenField
            name='healthTreatmentId'
            value={_.get(props.currentData, 'healthTreatment.id')}
            required={props.currentData.eventType === 'Treatment'}
          />
        </FormField>
        <FormField width='15%'>
          <DosageTextField
            name='dosage'
            label='Dosage Qty'
            value={_.get(props.currentData, 'healthTreatment.dosage')}
            disabled={props.disableAll || props.copy}
            required={props.currentData.eventType === 'Treatment'}
            low={0.01}
            high={10000}
            step='0.01'
          />
        </FormField>
        <FormField width='18%'>
          {props.uomEnabled && !props.disableAll && !props.copy ? (
            <SelectField
              name='uom'
              width='100%'
              choices={limitedUom.map((u) => [u, u])}
              backgroundColor={colors.green}
              color={colors.white}
              value={_.get(props.currentData, 'healthTreatment.uom')}
              label='Dosage UOM'
              fullWidth={false}
              showPlaceholder
            />
          ) : (
            <SelectField
              name='uom'
              width='100%'
              choices={uomChoices}
              backgroundColor={colors.grey}
              color={colors.white}
              value={_.get(props.currentData, 'healthTreatment.uom')}
              label='Dosage UOM'
              fullWidth={false}
              disabled
              showPlaceholder
            />
          )}
        </FormField>
        <FormField width='22%'>
          <SelectField
            name='dosageType'
            width='100%'
            choices={[
              ['per_animal', 'Per Animal'],
              ['per_kg', 'Per kg'],
            ]}
            backgroundColor={props.disableAll || props.copy ? colors.grey : colors.green}
            color={colors.white}
            value={_.get(props, 'currentData.healthTreatment.dosageType')}
            label='Dosage Type'
            fullWidth={false}
            disabled={props.disableAll || props.copy}
            required={props.currentData.eventType === 'Treatment'}
            showPlaceholder
          />
        </FormField>
      </FormRow>
    )}
  </div>
)

class AddEventDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      hasChanged: false,
      data: props.data || {},
      healthTreatmentChoices: [],
      typeChoices: formatChoices(_.keys(choiceMap)),
      classChoices: [],
      classDisabled: true,
      categoryChoices: [],
      categoryDisabled: true,
      validationErrors: {},
      preventLoop: false,
    }
    this.dom = {}
  }

  getHealthTreatmentChoices = memoize((healthTreatments) =>
    Object.entries(healthTreatments).map((entry) => ({
      value: entry[0],
      name: entry[1].name,
      productTypes: entry[1].productTypes, // also used in filtering
    })),
  )

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  onHealthTreatmentClick = (option) => {
    this.validate({ ...this.state.data, healthTreatmentId: option.value, healthTreatmentName: option.name })
  }

  validate = (data) => {
    // dumb method to prevent infinite render loop
    // formsy loves to re-validate every time a value changes or `required` flips
    // this causes `planClass` to flip back and forth all the time causing a loop
    // TODO remove when purging formsy
    if (this.state.preventLoop) {
      this.setState({ preventLoop: false })
      return
    }

    let preventLoop = false
    const updatedData = {
      ..._.pick(data, ['eventType', 'name', 'purpose', 'instructions', 'planType', 'planClass', 'planCategory']),
      healthTreatment: {
        id: data.healthTreatmentId,
        ..._.pick(data, ['dosage', 'uom', 'dosageType']),
      },
    }

    if (data.healthTreatmentId && !this.uomEnabled()) {
      updatedData.healthTreatment.uom = this.props.healthTreatments[data.healthTreatmentId].uom
    }

    if (updatedData.planType !== this.state.data.planType && updatedData.planType && this.state.data.planType) {
      delete updatedData.planCategory
      delete updatedData.planClass
      preventLoop = true
    }

    if (updatedData.planClass !== this.state.data.planClass && updatedData.planClass && this.state.data.planClass) {
      delete updatedData.planCategory
      preventLoop = true
    }

    const validationErrors = {}
    const extra = {}
    //const noCategory = data.planType === 'Farm' && data.planClass !== 'Stock'

    if (data.name && !_.get(this.props, 'data.id')) {
      const name = data.name.toLocaleLowerCase().trim()
      const planType = data.planType || null
      const planClass = data.planClass || null
      const planCategory = data.planCategory || null

      const duplicate = this.props.allEvents.find((row) => {
        return (
          row.eventType === data.eventType &&
          row.name === name &&
          row.planType === planType &&
          row.planClass === planClass &&
          row.planCategory === planCategory
        )
      })

      if (duplicate) {
        validationErrors.name = alreadyExistsError
        extra.duplicate = duplicate
      }
    }

    this.setState({
      data: updatedData,
      hasChanged: true,
      validationErrors,
      preventLoop,
      ...extra,
    })
  }

  // eslint-disable-next-line no-unused-vars
  submit = (data, reset, invalidate) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.props.setEvents(result.result)
        this.handleRequestClose()
      }
    }

    ;(_.get(this.props, 'data.id')
      ? editEventForAccount(this.props.accountId, this.props.data.id, this.state.data)
      : addEventForAccount(this.props.accountId, this.state.data)
    ).then(onSave)
  }

  uomEnabled = () => {
    return _.get(this, 'state.data.healthTreatment.id')
      ? this.props.healthTreatments[this.state.data.healthTreatment.id].uom === 'g'
      : false
  }

  onUnfocusName = () => {
    this.setState({
      existsDialog: this.state.validationErrors.name === alreadyExistsError,
    })
  }

  render() {
    const isCopy = _.get(this.props, 'data.copy')
    const disableAll = this.props.disableAll && !isCopy
    const dialogTitle = disableAll ? 'Event Details' : `${this.props.data ? (isCopy ? 'Copy' : 'Edit') : 'Add'} Event`
    const uomEnabled = this.uomEnabled()
    const healthTreatmentName = _.get(this, 'state.data.healthTreatment.id')
      ? this.props.healthTreatments[this.state.data.healthTreatment.id].name
      : undefined

    const classChoices = _.get(choiceMap, `${this.state.data.planType}.planClass`, [])
    const categoryChoices = _.get(choiceMap, `${this.state.data.planType}.planCategory`, [])

    const noCategory = this.state.data.planType === 'Farm' && this.state.data.planClass !== 'Stock'
    const classDisabled = !this.state.data.planType
    const categoryDisabled = !this.state.data.planClass || noCategory

    return (
      <>
        <FormDialog
          wide
          width='70vw'
          title={dialogTitle}
          open={this.props.open}
          onClose={this.handleRequestClose}
          hasClose={disableAll}
          closeMessage='Close'
          hasSubmit={!disableAll}
          disableAll={disableAll}
          uomEnabled={uomEnabled}
          FormComponent={AddEventForm}
          currentData={{ ...this.state.data, healthTreatmentName }}
          initialData={this.props.data}
          healthTreatmentChoices={this.getHealthTreatmentChoices(this.props.healthTreatments)}
          submit={this.submit}
          validate={this.validate}
          hasChanged={this.state.hasChanged}
          validationErrors={this.state.validationErrors}
          onUnfocusName={this.onUnfocusName}
          dom={this.dom}
          typeChoices={this.state.typeChoices}
          classDisabled={classDisabled}
          categoryDisabled={categoryDisabled}
          classChoices={classChoices}
          categoryChoices={categoryChoices}
          onHealthTreatmentClick={this.onHealthTreatmentClick}
          editing={!!_.get(this.props, 'data.id')}
          copy={isCopy}
          validateOnMount={isCopy}
          resetForm={() => this.setState({ hasChanged: false })}
        />
        {this.state.existsDialog && (
          <WarningDialog
            open
            warning={{
              title: 'Duplicate name/type',
              message: this.state.duplicate.disabled
                ? duplicateDisabledMsg
                : !this.state.duplicate.editable
                ? duplicateNotEditableMsg
                : duplicateCanEditMsg,
              hasCancel: !this.state.duplicate.disabled && this.state.duplicate.editable,
            }}
            handleRequestClose={() => {
              this.setState({ existsDialog: false }, () => this.dom.name.inputField.focus())
            }}
            handleConfirm={() => {
              this.handleRequestClose()

              setTimeout(() => this.props.reopenEdit(this.state.duplicate.id), 200)
            }}
            confirmButtonTitle={this.state.duplicate.disabled || !this.state.duplicate.editable ? 'Cancel' : 'Edit'}
          />
        )}
      </>
    )
  }
}

const DosageTextField = styled(IntRangeField)`
  margin-right: 12px;
`

export default requireAuth(AddEventDialog)
