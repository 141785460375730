import React from 'react'
import { Route } from 'react-router-dom'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

import { ActionHeader, ActionHeaderButton, PageTitle, Divider, From, SubHeader } from '../../components/PageHeaders'
import { withFormValidation } from '../../components/Form/Form'
import AccountSelector from '../../components/AccountSelector'
import FarmSelector from '../../components/FarmSelector'
import { SelectField } from '../../components/Form/Form'
import { colors } from '../../styles/styleConstants'
import { monthChoices, yearChoices } from '../../constants'
import { getAllBillingAccounts } from '../../data/billing'
import { getAllFarmsForUser } from '../../data/farm'
import {
  AccountTable,
  FarmTreatmentSalesTable,
  FarmServicesSalesTable,
  FarmTreatmentQuantityTable,
} from './components/index'

const scrollTable = (direction) => {
  const container = document.getElementsByClassName('Layout')[0]
  if (direction === 'left') {
    container.scrollLeft -= 100
  } else {
    container.scrollLeft += 100
  }
}

class Forecast extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      month: moment().format('M'),
      year: moment().format('YYYY'),
      farmTreatmentSalesEdits: {},
      farmServicesSalesEdits: {},
      farmTreatmentQuantityEdits: {},
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    const accounts = await getAllBillingAccounts(this.props.user.uid)
    const farms = await getAllFarmsForUser(this.props.user.uid)
    const permittedFarms = farms.filter((f) => ['Account', 'Farm'].includes(f.userRole))
    const farmFromParams = this.props.match.params.farmId
      ? permittedFarms.find((farm) => farm.id === this.props.match.params.farmId)
      : null

    this.setState({
      accounts,
      farms: permittedFarms,
      selectedFarmName: farmFromParams ? farmFromParams.farmName : null,
    })

    if (accounts.length === 1) {
      this.handleAccountSelected(accounts[0].id, accounts[0].businessName)
    }

    if (this.state.selectedFarmId) {
      const { selectedFarmId } = this.state
      this.handleFarmSelected(selectedFarmId)
    }
  }

  deselectAccount = () => this.setState({ accountId: null, selectedAccountName: null, templates: [] })

  handleAccountSelected = async (accountId, accountName) => {
    // const forecast = await getForecastForAccount(accountId)
    this.setState({
      accountId,
      selectedAccountName: accountName,
      // forecast
    })
  }

  handleFarmSelected = (farmId, farmName) => {
    // const forecast = await getForecastForFarm(farmId)
    this.setState({
      selectedFarmId: farmId,
      selectedFarmName: farmName || this.state.selectedFarmName,
      redirect: { farmId },
      // forecast
    })
  }

  formatData = (type, quantity) => {
    /**
      Expected format for account:
      [
        {
          id: string,
          name: (treatment/service name, incl. uom if applicable) string,
          forecast: [int] (array of 12 ints for next 12 months),
          farms: [
            {
              id: string,
              name: string,
              forecast: [int],
            }
          ],
        }
      ]

      Expected format for farm:
      [
        {
          id: string,
          name: (treatment/service name, incl. uom if applicable) string,
          forecast: [int] (array of 12 ints for next 12 months),
          events: [
            {
              id: string,
              date: date,
              name: string,
              class: string,
              animals: int,
              totalWeight: float,
              dose: string,
              doseType: string,
              costPerUOM: float`,
              eventCost: float,
              eventQuantity: float
            }
          ]
        }
      ]
     */

    // dummy data
    if (this.state.selectedFarmId) {
      return {
        treatmentSales: [
          ..._.times(5).map((i) => ({
            id: `id${i}`,
            name: `${type} name ${i}`,
            forecast: _.times(12).map(() => 10000),
            events: _.times(3).map((n) => ({
              id: `eventId${n}`,
              date: '03/03/20',
              name: `event name ${n}`,
              class: `event plan class ${n}`,
              animals: 3000,
              totalWeight: 90000,
              dose: `event dose ${n}`,
              doseType: `event dose type ${n}`,
              costPerUOM: `cost/uom ${n}`,
              eventCost: `cost ${n}`,
            })),
          })),
          {
            id: 'totals',
            name: 'TOTALS',
            forecast: _.times(12).map(() => 5000000),
          },
        ],
        servicesSales: [
          ..._.times(5).map((i) => ({
            id: `id${i}`,
            name: `${type} name ${i}${quantity ? ' - uom' : ''}`,
            forecast: _.times(12).map(() => 10000),
            events: _.times(3).map((n) => ({
              id: `eventId${n}`,
              date: '03/03/20',
              name: `event (service) name ${n}`,
              class: `event plan class ${n}`,
              animals: 3000,
              eventCost: 200,
            })),
          })),
          {
            id: 'totals',
            name: 'TOTALS',
            forecast: _.times(12).map(() => 5000000),
          },
        ],
        treatmentQuantity: [
          ..._.times(5).map((i) => ({
            id: `id${i}`,
            name: `${type} name ${i} - uom`,
            uom: `uom ${i}`,
            forecast: _.times(12).map(() => 10000),
            events: _.times(3).map((n) => ({
              id: `eventId${n}`,
              date: '03/03/20',
              name: `event name ${n}`,
              class: `event plan class ${n}`,
              animals: 3000,
              totalWeight: 90000,
              dose: `event dose ${n}`,
              doseType: `event dose type ${n}`,
              eventQuantity: `quantity ${n}`,
            })),
          })),
          {
            id: 'totals',
            name: 'TOTALS',
            forecast: _.times(12).map(() => 5000000),
          },
        ],
      }
    }

    return [
      ..._.times(5).map((i) => ({
        id: `id${i}`,
        name: `${type} name ${i}${quantity ? ' - uom' : ''}`,
        uom: `uom ${i}`,
        forecast: _.times(12).map(() => 8000000),
        farms: _.times(3).map((n) => ({
          farmId: `farmId${n}`,
          farmName: `farm name ${n}`,
          forecast: _.times(12).map(() => 10000),
        })),
      })),
      {
        id: 'totals',
        name: 'TOTALS',
        forecast: _.times(12).map(() => 32000000),
      },
    ]
  }

  goToTreatment = (id) => {
    console.log(id)
  }

  /* the following 4 functions are for handling inline
    edits for the farm tables;
    src/scenes/AnimalHealth/HealthTreatments/index.js has
    a good example of this
  */
  updateAnimalCount = (event) => {
    const updatedValue = _.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10)
    console.log('update animal count', updatedValue)

    // update animal count for this event in component state (farmTreatmentSalesEdits/farmServicesSalesEdits)
  }

  updateTotalWeight = (event) => {
    const updatedValue = _.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10)
    console.log('update weight', updatedValue)

    // update total weight for this event in component state (farmTreatmentSalesEdits)
  }

  updateEventCost = (event) => {
    const updatedValue = _.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10)
    console.log('update cost', updatedValue)

    // update total cost for this event in component state (farmServicesSalesEdits)
  }

  saveEdits = async () => {
    this.setState({ loading: true })
    // save edits from updateAnimalCount, updateTotalWeight, updateEventCost
    this.setState({ loading: false })
  }

  render() {
    return (
      <Layout>
        <Route
          exact
          path='/forecast'
          render={() => (
            <div>
              <PageTitle>Forecast</PageTitle>
              <ActionHeader>
                <AccountSelector
                  accounts={this.state.accounts}
                  handleAccountSelected={this.handleAccountSelected}
                  selectedAccountName={this.state.selectedAccountName}
                  deselectAccount={this.deselectAccount}
                />
                <FarmSelector
                  user={this.props.user}
                  farms={this.state.farms && this.state.farms.filter((f) => f.accountId === this.state.accountId)}
                  handleFarmSelected={this.handleFarmSelected}
                  selectedFarmName={this.state.selectedFarmName}
                  disabled={!this.state.accountId}
                  deselectFarm={this.deselectFarm}
                />
                <Divider />
                <From>FROM:</From>
                <SelectField
                  name='month'
                  value={this.state.month}
                  backgroundColor={!this.state.accountId ? colors.grey : colors.green}
                  color={colors.white}
                  disabled={!this.state.accountId}
                  onChange={this.onChangeFrom}
                  width='84px'
                  mr='20px'
                  choices={monthChoices}
                />
                <SelectField
                  name='year'
                  value={this.state.year}
                  backgroundColor={!this.state.accountId ? colors.grey : colors.green}
                  color={colors.white}
                  disabled={!this.state.accountId}
                  onChange={this.onChangeFrom}
                  width='84px'
                  mr='20px'
                  choices={yearChoices}
                />
                {this.state.selectedFarmId && (
                  <ActionHeaderButton
                    disabled={!this.state.hasChanged || this.state.loading}
                    onClick={() => this.saveEdits()}
                    width='100px'
                    height='36px'
                  >
                    {this.state.loading ? 'Loading...' : 'Save'}
                  </ActionHeaderButton>
                )}
              </ActionHeader>

              {this.state.accountId && !this.state.selectedFarmId && (
                <React.Fragment>
                  <ScrollButtons>
                    <ChevronLeft className='left' onClick={() => scrollTable('left')} />
                    <ChevronRight className='right' onClick={() => scrollTable('right')} />
                  </ScrollButtons>
                  <SubHeader>Treatment Sales Forecast</SubHeader>
                  <AccountTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('treatment')}
                    currency
                  />

                  <SubHeader>Service Sales Forecast</SubHeader>
                  <AccountTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('service')}
                    currency
                  />

                  <SubHeader>Treatment Quantity Forecast</SubHeader>
                  <AccountTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('treatment', true)}
                  />
                </React.Fragment>
              )}

              {this.state.accountId && this.state.selectedFarmId && (
                <React.Fragment>
                  <ScrollButtons>
                    <ChevronLeft className='left' onClick={() => scrollTable('left')} />
                    <ChevronRight className='right' onClick={() => scrollTable('right')} />
                  </ScrollButtons>
                  <SubHeader>Treatment Sales Forecast</SubHeader>
                  <FarmTreatmentSalesTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('treatment').treatmentSales}
                    currency
                    goToTreatment={this.goToTreatment}
                    updateAnimalCount={this.updateAnimalCount}
                    updateTotalWeight={this.updateTotalWeight}
                  />

                  <SubHeader>Service Sales Forecast</SubHeader>
                  <FarmServicesSalesTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('service').servicesSales}
                    currency
                    goToTreatment={this.goToTreatment}
                    updateAnimalCount={this.updateAnimalCount}
                    updateEventCost={this.updateEventCost}
                  />

                  <SubHeader>Treatment Quantity Forecast</SubHeader>
                  <FarmTreatmentQuantityTable
                    startMonth={this.state.month}
                    startYear={this.state.year}
                    farmId={this.state.selectedFarmId}
                    data={this.formatData('treatment', true).treatmentQuantity}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default requireAuth(withFormValidation(Forecast), { testOnly: true })

const ScrollButtons = styled.div`
  position: absolute;
  top: 50vh;

  svg {
    position: fixed;
    cursor: pointer;
    background-color: ${colors.lightGrey};
    border-radius: 20px;

    &.left {
      left: calc((100vw - 1210px) / 2);

      @media screen and (max-width: 1400px) {
        left: 10px;
      }
    }

    &.right {
      right: calc((100vw - 1210px) / 2);

      @media screen and (max-width: 1400px) {
        right: 10px;
      }
    }
  }
`
