import React from 'react'
import _ from 'lodash'
import Box from '@material-ui/core/Box'

import { PushButton } from '../../components/Form/Form'
import MaterialTable from '../../components/Table/Table.js'
import ActionContainer from './ActionContainer'
import FilterDialog from './FilterDialog'
import { metrics } from './MetricsInfo'
import { MetricsIcon } from '../../styles/icons'

const columns = [
  {
    id: 'metric',
    numeric: false,
    disablePadding: false,
    label: 'Metric Name',
    style: { width: '200px', whiteSpace: 'nowrap' },
  },
  {
    id: 'constraints',
    numeric: false,
    disablePadding: false,
    label: 'Constraints',
  },
  {
    id: 'values',
    numeric: false,
    disablePadding: false,
    label: 'Values',
  },
  {
    id: 'overflow',
    label: '',
    overflowColumn: true,
  },
].map((c) => ({
  ...c,
  style: {
    ...c.style,
    fontSize: '12px',
    color: '#555555',
  },
}))

export default class Metrics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogOpen: false,
    }
  }

  formatData = () =>
    _.map(this.props.metrics, (values, m) => {
      let constraints = ''
      if (values.dateRange) {
        constraints = values.dateRange
        if (constraints.constructor === Object) {
          constraints = values.dateRange.from + '..' + values.dateRange.to
        }
      } else if (values.date) {
        constraints = values.date.date
      } else {
        if (values.startDateRange && values.startEvents) {
          constraints +=
            'From [' +
            Object.values(values.startEvents).join(', ') +
            '] ' +
            values.startDateRange.from +
            '..' +
            values.startDateRange.to +
            ' '
        }
        if (values.endDateRange && values.endEvents) {
          constraints +=
            'To [' +
            Object.values(values.endEvents).join(', ') +
            '] ' +
            values.endDateRange.from +
            '..' +
            values.endDateRange.to
        }
      }
      let value = 0
      let count = 0
      let unit = ''
      if (this.props.metricsValues[m]) {
        if (m === 'Average Weight Gain Per Day') {
          value = Math.round(
            parseFloat(this.props.metricsValues[m].value ? this.props.metricsValues[m].value * 1000 : 0),
          )
        } else {
          value = parseFloat(this.props.metricsValues[m].value ? this.props.metricsValues[m].value : 0).toFixed(2)
        }
        count = this.props.metricsValues[m].count ? this.props.metricsValues[m].count : 0
        unit = this.props.metricsValues[m].unit ? this.props.metricsValues[m].unit : ''
      }
      let result = value + unit + ' in ' + count + ' entries'

      return {
        metric: m,
        constraints,
        values: result,
        id: m,
      }
    })

  render() {
    const data = this.formatData() || []
    return (
      <div>
        <ActionContainer>
          <Box></Box>
          <PushButton
            disabled={!this.props.animalType}
            onClick={() => this.setState({ dialogOpen: true })}
            endIcon={<MetricsIcon />}
          >
            Select Metrics
          </PushButton>
          <PushButton
            disabled={!this.props.animalType}
            onClick={this.props.resetButtonOnClick}
            endIcon={<MetricsIcon />}
          >
            Clear Metrics
          </PushButton>
        </ActionContainer>
        <MaterialTable
          expand
          columns={columns}
          data={data}
          actions={[
            {
              name: 'Delete',
              onClick: (metricName) => this.props.deleteMetric(metricName),
            },
          ]}
        />

        <FilterDialog
          open={this.state.dialogOpen}
          onRequestClose={() => {
            this.setState({
              dialogOpen: false,
            })
          }}
          title='Add Metric'
          submit={this.props.addMetric}
          root={metrics[this.props.animalType]}
          getResource={this.props.getResource}
        />
      </div>
    )
  }
}
