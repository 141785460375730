import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class RadioSelectDialog extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)

    this.state = {
      selected: undefined,
      values: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.refresh()
    }
  }

  refresh = () => {
    this.props.items.then((result) => {
      this.setState({
        values: result,
        selected: this.props.data,
      })
    })
  }

  handleChange(event, value) {
    this.setState({
      selected: value,
    })
  }

  submit() {
    this.props.submit(this.state.selected)
  }

  render() {
    let selected = this.state.selected || ''
    selected = selected.toString()

    return (
      <div className='dialogContent__inner'>
        <RadioGroup onChange={this.handleChange} value={selected}>
          {[...this.state.values].sort().map((value) => (
            <FormControlLabel value={value} control={<Radio />} label={value} key={value}></FormControlLabel>
          ))}
        </RadioGroup>
      </div>
    )
  }
}
