import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { requireAuth } from '../../../components/withAuth'
import Layout from '../../../components/Layout'
import Checkbox from '@material-ui/core/Checkbox'
import moment from 'moment'
import _ from 'lodash'
import memoizeOne from 'memoize-one'

import { ActionHeader, ButtonContainer, ActionHeaderButton, PageTitle } from '../../../components/PageHeaders'
import AccountSelector from '../../../components/AccountSelector'
import FarmSelector from '../../../components/FarmSelector'
import Search from '../../../components/Search'
import Table from './components/Table'
import AddTreatmentDialog from './components/AddTreatmentDialog'
import InfoDialog from '../../../components/Dialog/InfoDialog'
import { CheckboxWithLabel } from '../../../components/Form/CustomFields'
import {
  getHealthTreatmentsForAccount,
  editHealthTreatment,
  addAccountHealthTreatmentsToFarm,
  getHealthTreatmentsForFarm,
  editHealthTreatmentForFarm,
} from '../../../data/health_treatments'
import { useAccount } from '../../../components/AccountContext'
import { EditFarmTreatmentDialog } from './components/EditFarmTreatmentDialog'

const headerButtonSpacing = '10px'

const formatData = memoizeOne(
  (
    searchStr,
    showDisabled,
    farmHealthTreatments,
    accountHealthTreatments,
    selectedFarmId,
    farmHealthTreatmentEdits,
    accountHealthTreatmentsForFarm,
    accountHealthTreatmentEdits,
  ) => {
    let data = accountHealthTreatments || []
    if (farmHealthTreatments) {
      const addedByFarm = farmHealthTreatments
        .filter((t) => !t.accountHealthTreatmentId)
        .map((t) => ({ ...t, indeterminate: true, actionsOption: 'farmHealthTreatmentsActions', productTypes: [] }))

      data = data.concat(addedByFarm)
    }

    const filterDisabled = (rows) => {
      if (showDisabled) {
        return rows
      }

      return rows.filter((t) => t.enabled)
    }

    const filterSearch = (rows) => {
      if (!searchStr) {
        return rows
      }

      const normalised = searchStr.toLowerCase()

      return rows.filter(
        (t) =>
          t.name.toLowerCase().includes(normalised) ||
          t.productTypes.filter((p) => p.toLowerCase().includes(normalised)).length,
      )
    }

    return filterSearch(
      filterDisabled(
        data.map((t) => {
          const treatmentForValues =
            _.get(farmHealthTreatmentEdits, t.id) ||
            _.get(accountHealthTreatmentsForFarm, t.id) ||
            _.get(accountHealthTreatmentEdits, t.id) ||
            farmHealthTreatments.find((t2) => t2.accountHealthTreatmentId === t.id) ||
            t

          const { packSize, salePrice } = treatmentForValues

          return {
            ...t,
            rvm: t.rvm ? 'Y' : 'N',
            packSize,
            salePrice,
            unitPrice: salePrice / packSize,
            createDate: t.isDefault ? 'Default' : moment(t.createDate).format('DD/MM/YY'),
            rowDisabled: !t.enabled,
            editable:
              !selectedFarmId ||
              _.get(t, 'farmIds', []).includes(selectedFarmId) ||
              _.has(accountHealthTreatmentsForFarm, t.id) ||
              t.indeterminate,
            actionsOption: selectedFarmId ? 'farmHealthTreatmentsActions' : 'default',
          }
        }),
      ),
    )
  },
)

class ManageTreatments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imports: {},
      selectedFarmId: props.match.params.farmId,
      loading: false,
      accountHealthTreatments: [],
      accountHealthTreatmentEdits: {},
      farmHealthTreatments: [],
      farmHealthTreatmentEdits: {},
      selected: [],
      searchStr: '',
      tableKey: Math.random(),
    }
  }

  componentDidMount() {
    if (this.props.currentAccountId) {
      this.handleAccountUpdate()
    }
    if (this.state.selectedFarmId) {
      this.handleFarmUpdate()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirect) {
      this.setState({ redirect: false })
    }
    if (prevProps.currentAccountId !== this.props.currentAccountId) {
      this.handleAccountUpdate()
    }
    if (prevState.selectedFarmId !== this.state.selectedFarmId) {
      this.handleFarmUpdate()
    }
  }

  deselectAccount = () => {
    this.props.setCurrentAccountId(null)

    this.setState({
      redirect: 'main',
      selectedFarmId: null,
      accountHealthTreatments: [],
      farmHealthTreatments: [],
      tableKey: Math.random(),
    })
  }

  deselectFarm = () =>
    this.setState({
      redirect: 'main',
      selectedFarmId: null,
      farmHealthTreatments: [],
    })

  getCurrentAccountId = () => {
    return this.props.currentAccountId
  }

  selectAccount = async (accountId) => {
    this.props.setCurrentAccountId(accountId)
  }

  handleAccountUpdate = async () => {
    const accountId = this.props.currentAccountId

    if (!accountId) {
      return
    }

    this.setState({
      loading: true,
    })

    const { accountHealthTreatments } = await getHealthTreatmentsForAccount(accountId)

    this.setState({
      accountHealthTreatments,
      loading: false,
      hasChanged: false,
      accountHealthTreatmentEdits: {},
      farmHealthTreatmentEdits: {},
      tableKey: Math.random(),
    })

    if (this.state.selectedFarmId) {
      this.handleFarmSelected(this.state.selectedFarmId)
      this.setSelected()
    }
  }

  reloadAccountHealthTreatments = async () => {
    this.setState({
      loading: true,
    })

    const { accountHealthTreatments } = await getHealthTreatmentsForAccount(this.props.currentAccountId)

    this.setState({
      accountHealthTreatments,
      loading: false,
      tableKey: Math.random(),
    })
  }

  handleFarmSelected = async (selectedFarmId) => {
    this.setState({ selectedFarmId })
  }

  handleFarmUpdate = async () => {
    if (!this.state.selectedFarmId) {
      return
    }

    this.setState({
      loading: true,
    })

    const { selectedFarmId } = this.state

    const farmHealthTreatmentsData = await getHealthTreatmentsForFarm(selectedFarmId)

    this.setState({
      hasChanged: false,
      accountHealthTreatmentsForFarm: {},
      farmHealthTreatments: _.get(farmHealthTreatmentsData, 'healthTreatments', []),
      redirect: { farmId: selectedFarmId },
      loading: false,
    })

    this.setSelected()
  }

  setSelected = () => {
    // TODO don't store this in state
    const { selectedFarmId } = this.state
    const selected = this.state.accountHealthTreatments.map((t) => (t.farmIds.includes(selectedFarmId) ? t.id : null))
    this.setState({
      selected,
    })
  }

  updateSelectedFarmTreatments = (selected) => {
    const treatments = []
    selected.forEach((id) => {
      const treatment =
        this.state.farmHealthTreatments.find((t) => t.accountHealthTreatmentId === id) ||
        this.state.accountHealthTreatments.find((t) => t.id === id)

      if (id && treatment) {
        treatments.push({ id: id, packSize: treatment.packSize, salePrice: treatment.salePrice })
      }
    })

    this.setState({ selected, accountHealthTreatmentsForFarm: treatments, hasChanged: true })
  }

  saveEdits = async () => {
    if (this.state.hasChanged && this.state.selectedFarmId) {
      // Save additions to farm & changes to account treatments at the farm level
      this.setState({ loading: true })
      await addAccountHealthTreatmentsToFarm(
        this.getCurrentAccountId(),
        this.state.selectedFarmId,
        this.state.accountHealthTreatmentsForFarm,
      )
      this.setState({ loading: false })
    }
  }

  updateFarmTreatment = async (treatment) => {
    this.setState({ loading: true })

    const farmTreatment = this.state.farmHealthTreatments.find((t) => t.accountHealthTreatmentId === treatment.id)

    if (!farmTreatment) {
      await addAccountHealthTreatmentsToFarm(this.getCurrentAccountId(), this.state.selectedFarmId, [
        {
          id: [treatment.id],
          packSize: treatment['packSize'],
          salePrice: treatment['salePrice'],
        },
      ])
    } else {
      await editHealthTreatmentForFarm(
        this.state.selectedFarmId,
        farmTreatment.id,
        _.pick(treatment, ['packSize', 'salePrice']),
      )
    }
    this.setState({ loading: false })
  }

  editTreatment = async (treatment) => {
    const data = await editHealthTreatment(
      this.getCurrentAccountId(),
      treatment.id,
      _.pick(treatment, [
        'rvm',
        'meatWitholdingPeriod',
        'milkWitholdingPeriod',
        'produceWitholdingPeriod',
        'packSize',
        'salePrice',
        'instructions',
        'label',
      ]),
    )
    if (_.get(data, 'result.accountHealthTreatments')) {
      this.setState({ accountHealthTreatments: data.result.accountHealthTreatments })
    }
  }

  updateSearchStr = _.debounce((value) => {
    this.setState({ searchStr: value })
  }, 300)

  render() {
    const { farmId } = this.props.match.params
    if (this.state.redirect && this.state.redirect === 'main') {
      return <Redirect to='/manage-treatments' />
    } else if (
      this.state.redirect &&
      this.state.redirect.farmId &&
      this.props.location.pathname.split('/')[2] !== this.state.redirect.farmId
    ) {
      return <Redirect to={`/manage-treatments/${this.state.redirect.farmId}`} />
    }

    const {
      searchStr,
      showDisabled,
      farmHealthTreatments,
      accountHealthTreatments,
      selectedFarmId,
      farmHealthTreatmentEdits,
      accountHealthTreatmentsForFarm,
      accountHealthTreatmentEdits,
    } = this.state
    const data = formatData(
      searchStr,
      showDisabled,
      farmHealthTreatments,
      accountHealthTreatments,
      selectedFarmId,
      farmHealthTreatmentEdits,
      accountHealthTreatmentsForFarm,
      accountHealthTreatmentEdits,
    ).map((t) => ({
      ...t,
      inputErrors: _.get(this.state, `inputErrors.${t.id}`, null),
    }))
    const currentAccount = this.props.accounts.find((a) => a.id === this.getCurrentAccountId())
    const disableAddTreatment = currentAccount && !currentAccount.isOwner && currentAccount.accountRole === 'Vet'

    return (
      <Layout>
        <Route
          exact
          path='/manage-treatments/:farmId?'
          render={() => (
            <div>
              <PageTitle onClick={this.deselectAccount}>Manage Treatments</PageTitle>
              <ActionHeader>
                <ButtonContainer>
                  <Search
                    placeholder='Search treatments'
                    onChange={(event) => this.updateSearchStr(event.target.value)}
                    flexGrow={100}
                  />
                  <CheckboxWithLabel
                    control={
                      <Checkbox
                        checked={this.state.showDisabled}
                        onClick={(event) => this.setState({ showDisabled: event.target.checked })}
                      />
                    }
                    label='Show disabled'
                  />
                  <AccountSelector
                    accounts={this.props.accounts}
                    handleAccountSelected={this.selectAccount}
                    accountId={this.getCurrentAccountId()}
                    deselectAccount={this.deselectAccount}
                    margin={headerButtonSpacing}
                  />
                  <FarmSelector
                    user={this.props.user}
                    farms={this.props.farms.filter((f) => f.accountId === this.getCurrentAccountId())}
                    handleFarmSelected={this.handleFarmSelected}
                    farmId={this.state.selectedFarmId}
                    disabled={!this.getCurrentAccountId()}
                    deselectFarm={this.deselectFarm}
                    margin={headerButtonSpacing}
                  />
                  <ActionHeaderButton
                    onClick={() => this.setState({ addTreatmentDialogOpen: true })}
                    disabled={!this.getCurrentAccountId() || disableAddTreatment}
                    margin={headerButtonSpacing}
                  >
                    Add Treatment
                  </ActionHeaderButton>
                  <ActionHeaderButton
                    disabled={!this.state.hasChanged || this.state.loading}
                    onClick={() => this.saveEdits()}
                    width='100px'
                    margin={headerButtonSpacing}
                  >
                    {this.state.loading ? 'Loading...' : 'Save'}
                  </ActionHeaderButton>
                </ButtonContainer>
              </ActionHeader>

              {farmId ? (
                <Table
                  key={this.state.tableKey}
                  accountId={this.getCurrentAccountId()}
                  data={data}
                  selected={this.state.selected}
                  updateSelected={this.updateSelectedFarmTreatments}
                  disableSelectionForRows={this.state.accountHealthTreatments.map((t) =>
                    t.farmIds.includes(farmId) || !t.enabled ? t.id : null,
                  )}
                  editTreatment={(treatment) => {
                    // editable indicates it is added to the farm
                    if (treatment.editable) {
                      this.setState({
                        editFarmTreatmentDialogOpen: true,
                        farmTreatmentToEdit: treatment,
                      })
                    }
                  }}
                  openInstructions={(instructions) =>
                    this.setState({
                      infoDialogOpen: {
                        title: 'Use Instructions',
                        info: instructions,
                      },
                    })
                  }
                  openLabel={(label) =>
                    this.setState({
                      infoDialogOpen: {
                        title: 'Label',
                        info: label,
                      },
                    })
                  }
                  reloadTreatments={this.reloadAccountHealthTreatments}
                />
              ) : (
                <Table
                  accountId={this.getCurrentAccountId()}
                  data={data}
                  selected={[]}
                  disableAllSelection
                  editTreatment={(treatment) => {
                    this.setState({
                      addTreatmentDialogOpen: true,
                      treatmentToEdit: treatment,
                    })
                  }}
                  openInstructions={(instructions) =>
                    this.setState({
                      infoDialogOpen: {
                        title: 'Use Instructions',
                        info: instructions,
                      },
                    })
                  }
                  reloadTreatments={this.reloadAccountHealthTreatments}
                />
              )}

              {this.state.addTreatmentDialogOpen && (
                <AddTreatmentDialog
                  open
                  onRequestClose={() => this.setState({ addTreatmentDialogOpen: false, treatmentToEdit: null })}
                  data={this.state.treatmentToEdit}
                  accountId={this.getCurrentAccountId()}
                  reloadTreatments={this.reloadAccountHealthTreatments}
                />
              )}
              {this.state.farmTreatmentToEdit && (
                <EditFarmTreatmentDialog
                  onRequestClose={() => this.setState({ farmTreatmentToEdit: null })}
                  treatment={this.state.farmTreatmentToEdit}
                  doUpdate={async (treatment) => {
                    await this.updateFarmTreatment(treatment)
                    this.setState({ farmTreatmentToEdit: null })
                    this.handleFarmUpdate()
                  }}
                />
              )}
              {this.state.infoDialogOpen && (
                <InfoDialog
                  open
                  onClose={() => this.setState({ infoDialogOpen: null })}
                  title={this.state.infoDialogOpen.title}
                  info={this.state.infoDialogOpen.info}
                />
              )}
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default requireAuth((props) => {
  const account = useAccount()

  return <ManageTreatments {...props} {...account} />
})
