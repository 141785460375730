import React from 'react'
import styled from 'styled-components'
import { width } from 'styled-system'
import { colors } from '../../styles/styleConstants'
import ActionOverflow from './ActionOverflow'
import _ from 'lodash'

const SubRowCell = ({ parentId, item, column, value, actions, ...props }) => {
  if (actions) {
    return (
      <Cell width={column.width} {...props}>
        <Flex>
          {column.label ? `${column.label}: ` : ''}
          {value}
          <ActionOverflow actions={actions} parentId={parentId} childId={item.id} />
        </Flex>
      </Cell>
    )
  }

  return (
    <Cell width={column.width} {...props}>
      <Flex>
        {column.label ? `${column.label}: ` : ''}
        {value}
      </Flex>
    </Cell>
  )
}

export const SimpleSubRowCell = ({ children, ...props }) => (
  <Cell {...props} style={{ alignItems: 'flex-start' }}>
    <Flex>{children}</Flex>
  </Cell>
)

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: ${(props) => {
    if (_.get(props, 'style.color')) {
      return props.style.color
    }

    return props.disabled ? '#b6b6b6' : '#7d7d7d'
  }};
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid #e0e0e0;
  height: 37px;
  ${width}

  a {
    color: ${colors.lightGreen};
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export default SubRowCell
