import { getAuthHeaders } from '../helpers/firebase'
import { get, put, del, downloadFile } from '../helpers/fetch'
import config from 'config'
import { saveAs } from 'file-saver'
import querystring from 'querystring'

export const getAllFarmPlans = async (farmId, year, month, length = 6, planId = '') => {
  const periodStart = `${year}-${month}`
  const offset = month - 2 + length
  const year2 = year + Math.floor(offset / 12)
  const month2 = (offset % 12) + 1
  const periodEnd = `${year2}-${month2}`
  const url = `${config.backend.url}/planning/farm/${farmId}?start=${periodStart}&end=${periodEnd}&plan=${planId}`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const getAllFarmPlansShort = async (farmId) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/short`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const addTemplateToFarmPlan = async (farmId, templateId, templateStartEventId, startDate, repeat) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/add-template`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, { templateId, templateStartEventId, startDate, repeat }, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const completeFarmEvent = async (farmId, eventId) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/${eventId}/complete`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const uncompleteFarmEvent = async (farmId, eventId) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/${eventId}/uncomplete`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const addEventToFarmPlan = async (farmId, data) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/add-event`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const deleteEventFromFarmPlan = async (farmId, eventId, data) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/events/${eventId}/delete`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const editFarmEvent = async (farmId, eventId, data) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/events/${eventId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, data, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const deleteFarmPlan = async (farmId, planId) => {
  const url = `${config.backend.url}/planning/farm/${farmId}/plans/${planId}`

  try {
    const headers = await getAuthHeaders()
    const result = await del(url, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}

export const createWorksheet = async (farmId, data, name) => {
  const query = Object.entries(data)
    .filter((row) => row[1])
    .map((row) => `${row[0]}=${row[1]}`)
    .join('&')
  const url = `${config.backend.url}/planning/farm/${farmId}/plans/worksheet?${query}`

  const headers = await getAuthHeaders()
  const result = await downloadFile(url, headers)

  saveAs(result, name)
}

export const findDuplicateEvents = async (farmId, eventType, eventName) => {
  const data = { eventType, eventName }
  const query = querystring.stringify(data)
  const url = `${config.backend.url}/planning/farm/${farmId}/events/duplicates?${query}`

  try {
    const headers = await getAuthHeaders()
    const result = await get(url, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
      data: error.data,
    }
  }
}
