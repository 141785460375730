// @ts-ignore
import config from 'config'
import moment from 'moment'

import { get, post, put } from '../../helpers/fetch'
import { getAuthHeaders } from '../../helpers/firebase'
import { AnimalType } from '../types'
import { DashboardMetric } from './dashboard'

export interface WeightDistributionMetric extends DashboardMetric {
  readonly animalType: AnimalType
  readonly eventId?: string
  readonly limitLow?: number
  readonly limitHigh?: number
  readonly min?: number
  readonly max?: number
}

interface WeightDistributionRequest {
  readonly animalType: AnimalType
  readonly eventId: string
  readonly limitLow: number
  readonly limitHigh: number
}

interface WeightDistributionResponseRow {
  readonly avg?: number | null
  readonly count: number
  readonly max?: number | null
  readonly min?: number | null
}

interface WeightDistributionResponseData {
  readonly high: WeightDistributionResponseRow
  readonly low: WeightDistributionResponseRow
  readonly mid: WeightDistributionResponseRow
  readonly total: WeightDistributionResponseRow
}

export enum Metric {
  Count = 'Count',
  PctTotal = '% of Total',
  MinWeight = 'Minimum Weight',
  MaxWeight = 'Maximum Weight',
  AvgWeight = 'Average Weight',
}

interface WeightDistributionResultRow {
  readonly metric: Metric
  readonly totals?: number | null
  readonly light?: number | null
  readonly medium?: number | null
  readonly heavy?: number | null
}

interface WeightDistributionResult {
  weightDistribution: WeightDistributionResultData
}

export const weightDistributionMetric: WeightDistributionMetric = {
  type: 'Weight Distribution',
  animalType: AnimalType.Cattle,
}

export async function calculateWeightDistribution(
  farmId: string,
  metric: WeightDistributionMetric,
): Promise<WeightDistributionResultRow[]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/metric/WeightDistribution`

  if (!metric.eventId || !metric.limitLow || !metric.limitHigh) {
    return null
  }

  const request: WeightDistributionRequest = {
    eventId: metric.eventId,
    limitLow: metric.limitLow,
    limitHigh: metric.limitHigh,
    animalType: metric.animalType,
  }

  const headers = await getAuthHeaders()
  const result: WeightDistributionResponseData = await post(url, request, headers)
  const data: WeightDistributionResponseData = result.weightDistribution
  const total = data.total.count

  // transpose data, set correct order, and add % row
  return [
    {
      metric: Metric.Count,
      totals: data.total.count,
      light: data.low.count,
      medium: data.mid.count,
      heavy: data.high.count,
    },
    {
      metric: Metric.PctTotal,
      totals: total ? data.total.count / total : 0,
      light: total ? data.low.count / total : 0,
      medium: total ? data.mid.count / total : 0,
      heavy: total ? data.high.count / total : 0,
    },
    {
      metric: Metric.MinWeight,
      totals: data.total.min,
      light: data.low.min,
      medium: data.mid.min,
      heavy: data.high.min,
    },
    {
      metric: Metric.MaxWeight,
      totals: data.total.max,
      light: data.low.max,
      medium: data.mid.max,
      heavy: data.high.max,
    },
    {
      metric: Metric.AvgWeight,
      totals: data.total.avg,
      light: data.low.avg,
      medium: data.mid.avg,
      heavy: data.high.avg,
    },
  ]
}

interface DraftingEventResponseRow {
  readonly date: string
  readonly id: string
  readonly name: string
  readonly identification: string
  readonly animalType: AnimalType
}

interface DraftingEventResponseData {
  readonly events: DraftingEventResponseRow[]
}

export interface DraftingEventRow {
  readonly id: string
  readonly name: string
  readonly animalType: AnimalType
}

export async function getDraftingEvents(farmId: string): Promise<DraftingEventRow[]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/events`
  const headers = await getAuthHeaders()
  const result: DraftingEventResponseData = await get(url, headers)

  return result.events.map(
    (row: DraftingEventResponseRow): DraftingEventRow => ({
      id: row.id,
      name: `${row.name} - ${row.identification} - ${moment(row.date).format('M/D/Y')}`,
      animalType: row.animalType,
    }),
  )
}

interface DraftingEventLimits {
  readonly min: number
  readonly max: number
}

interface DraftingEventLimitsResponse {
  readonly weight: DraftingEventLimits
}

export async function getDraftingLimitsWeights(farmId: string, eventId: string): Promise<DraftingEventLimits> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/event/${eventId}`
  const headers = await getAuthHeaders()
  const result: DraftingEventLimitsResponse = await get(url, headers)

  return result.weight
}

interface WeightDistributionAnimalSettings {
  readonly eventId?: string
  readonly limitLow?: number
  readonly limitHigh?: number
  readonly min?: number
  readonly max?: number
}

export interface WeightDistributionSettings {
  readonly Cattle?: WeightDistributionAnimalSettings
  readonly Deer?: WeightDistributionAnimalSettings
  readonly Sheep?: WeightDistributionAnimalSettings
}

export async function loadWeightDistributionSettings(
  farmId: string,
  position: number,
): Promise<WeightDistributionSettings> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/WeightDistribution`

  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export async function saveWeightDistributionSettings(
  farmId: string,
  settings: WeightDistributionSettings,
  position: number,
): Promise<void> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/WeightDistribution`

  const headers = await getAuthHeaders()
  await put(url, settings, headers)
}
