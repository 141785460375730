import React from 'react'
import styled from 'styled-components'
import memoize from 'memoize-one'
import {
  SelectField,
  FormsyDateField,
  TextField,
  FormRow,
  CheckboxField,
  FormField,
  HiddenField,
  IntRangeField,
} from '../../../../components/Form/Form'
import Search from '../../../../components/Search'
import FormDialog from '../../../../components/Dialog/FormDialog'
import { DialogHeaderButton } from '../../../../components/Dialog/DialogHeader'
import WarningDialog from '../../../../components/Dialog/WarningDialog'
import { colors } from '../../../../styles/styleConstants'
import {
  choiceMap,
  formatChoices,
  uomChoices,
  limitedUomChoices,
  eventTypeChoices,
  planningClassMap,
  planningCategoryMap,
} from '../../../../constants'
import _ from 'lodash'
import moment from 'moment'

import { getEventsForAccount } from '../../../../data/manage_events'
import { addEventToFarmPlan, getAllFarmPlans, editFarmEvent, findDuplicateEvents } from '../../../../data/plans'
import { eventSearchBackMonths, eventSearchForwardMonths } from '../constants'
import { eventDateFormat } from '../../../../constants'

import '../../../../components/Dialog/Dialog.css'

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

const addEventForm = ({
  currentData,
  typeChoices,
  classDisabled,
  classChoices,
  categoryDisabled,
  categoryChoices,
  eventOptions,
  templateOptions,
  onEventOptionClick,
  onTemplateOptionClick,
  dependentEventOptions,
  onDependentEventOptionClick,
  onHealthTreatmentClick,
  scheduleDisabled,
  healthTreatmentChoices,
  newEvent,
  managePlans,
  uomEnabled,
  minDate,
  maxDate,
  templateNameDisabled,
  copy,
  disableAll,
}) => (
  <div>
    {newEvent && (
      <FormRow mb='30px' width='560px'>
        <FormField width='135px'>
          <SelectField
            name='eventType'
            width='100%'
            choices={eventTypeChoices}
            backgroundColor={colors.green}
            color={colors.white}
            defaultValue={currentData.eventType}
            placeholder='Event Type'
            fullWidth={false}
            value={currentData.eventType}
            disabled={copy}
            showPlaceholder
          />
        </FormField>
        <FormField width='135px'>
          <SelectField
            name='planType'
            placeholder='Plan type'
            width='100%'
            choices={typeChoices}
            backgroundColor={colors.green}
            color={colors.white}
            value={currentData.planType}
            required
            showPlaceholder
          />
        </FormField>
        <FormField width='135px'>
          <SelectField
            name='planClass'
            placeholder='Plan class'
            width='100%'
            choices={classChoices}
            backgroundColor={colors.green}
            color={colors.white}
            disabled={classDisabled}
            value={currentData.planClass}
            required
            showPlaceholder
          />
        </FormField>
        <FormField width='135px'>
          <SelectField
            name='planCategory'
            placeholder='Category'
            width='100%'
            choices={categoryChoices}
            backgroundColor={colors.green}
            color={colors.white}
            disabled={categoryDisabled}
            value={currentData.planCategory}
            required={!categoryDisabled}
            showPlaceholder
          />
        </FormField>
      </FormRow>
    )}
    {!newEvent && !disableAll && (
      <FormRow>
        <FormField>
          <CheckboxField name='applyFuture' label='Apply Edits To Future Events' checked={currentData.applyFuture} />
        </FormField>
      </FormRow>
    )}
    {newEvent && !managePlans && (
      <TemplateSearchField
        options={templateOptions}
        onOptionClick={onTemplateOptionClick}
        clear={!currentData.templateName}
        disabled={templateNameDisabled}
        templateName={currentData.templateName}
        realTemplateName={currentData.realTemplateName}
      />
    )}
    <FormRow mb='30px'>
      {!newEvent && (
        <FormField width='140px'>
          <SelectField
            name='eventType'
            width='100%'
            choices={eventTypeChoices}
            backgroundColor={colors.grey}
            disabled
            color={colors.white}
            value={currentData.eventType}
            placeholder='Event Type'
            fullWidth={false}
            showPlaceholder
          />
          <HiddenField name='planType' value={currentData.planType} />
          <HiddenField name='planClass' value={currentData.planClass} />
          <HiddenField name='planCategory' value={currentData.planCategory} />
        </FormField>
      )}
      {(!newEvent || !managePlans) && (
        <FormField width='65%'>
          <TextField
            name='eventName'
            label='Event Name'
            value={currentData.eventName}
            disabled={!newEvent || copy}
            required={!newEvent}
          />
        </FormField>
      )}
      {newEvent && managePlans && (
        <>
          <FormField width='48%'>
            <Search
              name='eventName'
              label='Event Name'
              placeholder='Search events'
              options={eventOptions}
              onOptionClick={onEventOptionClick}
              clear={!currentData.eventName}
              disabled={!currentData.eventType || templateNameDisabled}
            />
          </FormField>
          <TemplateSearchField
            options={templateOptions}
            onOptionClick={onTemplateOptionClick}
            clear={!currentData.templateName}
            disabled={currentData.templateNameDisabled}
            templateName={currentData.templateName}
            realTemplateName={currentData.realTemplateName}
          />
        </>
      )}
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='100%'>
        <TextField
          name='purpose'
          label='Purpose'
          value={currentData.purpose}
          multiline
          disabled={scheduleDisabled || disableAll}
        />
      </FormField>
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='100%'>
        <TextField
          name='instructions'
          label='Instructions'
          value={currentData.instructions}
          multiline
          disabled={scheduleDisabled || disableAll}
        />
      </FormField>
    </FormRow>
    {currentData.eventType === 'Treatment' && (
      <FormRow mb='30px'>
        <FormField width='25%'>
          <Search
            name='healthTreatmentName'
            options={healthTreatmentChoices}
            backgroundColor={colors.green}
            autocomplete='off'
            label='Health Treatment'
            placeholder='Search treatments'
            disabled={scheduleDisabled || disableAll}
            onOptionClick={onHealthTreatmentClick}
            clear={!currentData.healthTreatmentName}
            value={currentData.healthTreatmentName}
            filter={(value) => {
              const re = new RegExp(_.escapeRegExp(value), 'i')
              return (o) => re.test(o.name) || (o.productTypes || []).filter((pt) => re.test(pt)).length
            }}
          />
          <HiddenField
            name='healthTreatmentId'
            value={_.get(currentData, 'healthTreatment.id')}
            required={currentData.eventType === 'Treatment'}
          />
        </FormField>
        <FormField width='20%'>
          <DosageTextField
            name='dosage'
            label='Dosage Qty'
            type='number'
            value={_.get(currentData, 'healthTreatment.dosage')}
            disabled={scheduleDisabled || disableAll}
            required
          />
        </FormField>
        <FormField width='20%'>
          <SelectField
            name='uom'
            width='100%'
            choices={uomEnabled ? limitedUomChoices : uomChoices}
            backgroundColor={!uomEnabled || disableAll ? colors.grey : colors.green}
            color={colors.white}
            value={_.get(currentData, 'healthTreatment.uom')}
            label='Dosage UOM'
            fullWidth={false}
            disabled={!uomEnabled || disableAll}
            required={uomEnabled}
            showPlaceholder
          />
        </FormField>
        <FormField width='20%'>
          <SelectField
            name='dosageType'
            width='100%'
            choices={[
              ['per_animal', 'Per Animal'],
              ['per_kg', 'Per kg'],
            ]}
            backgroundColor={scheduleDisabled || disableAll ? colors.grey : colors.green}
            color={colors.white}
            value={_.get(currentData, 'healthTreatment.dosageType')}
            label='Dosage Type'
            fullWidth={false}
            disabled={scheduleDisabled || disableAll}
            required
            showPlaceholder
          />
        </FormField>
      </FormRow>
    )}
    <FormRow mb='30px' width='100%'>
      <FormField width='35%'>
        <SelectField
          name='scheduleType'
          placeholder='Schedule type'
          width='100%'
          choices={[
            ['fixed', 'Fixed Date'],
            ['dependent', 'Dependent Event'],
          ]}
          color={colors.white}
          disabled={scheduleDisabled || disableAll}
          value={_.get(currentData, 'scheduleType')}
          required
          showPlaceholder
        />
      </FormField>
      {currentData.scheduleType === 'fixed' && (
        <>
          <FormField width='30%'>
            <FormsyDateField
              name='dueDate'
              label='Event Date'
              value={currentData.dueDate}
              required
              minDate={minDate}
              maxDate={maxDate}
              disabled={scheduleDisabled || disableAll}
            />
          </FormField>
          <FormField width='25%'>
            <CheckboxField
              name='repeat'
              label='Repeat Event Annually'
              checked={currentData.repeat}
              disabled={scheduleDisabled || disableAll}
            />
          </FormField>
        </>
      )}
      {currentData.scheduleType === 'dependent' && (
        <>
          <FormField width='55%'>
            <Search
              name='dependentEventName'
              label='Dependent Event Name'
              placeholder='Search events'
              options={dependentEventOptions}
              onOptionClick={onDependentEventOptionClick}
              value={currentData.dependentEventName}
              disabled={scheduleDisabled || disableAll}
              clear={!currentData.dependentEventName}
              noSort
            />
            <HiddenField name='dependentEventId' value={currentData.dependentEventId} required />
          </FormField>
        </>
      )}
    </FormRow>
    {currentData.scheduleType === 'dependent' && (
      <FormRow mb='30px' width='100%'>
        <FormField width='35%'>
          <IntRangeField
            type='number'
            name='leadLag'
            label='Lead (-) or Lag (+) Days'
            value={currentData.leadLag == 0 ? String(currentData.leadLag) : currentData.leadLag}
            low={-365}
            high={365}
            required
            disabled={!currentData.dependentEventId || scheduleDisabled || disableAll}
          />
        </FormField>
      </FormRow>
    )}
  </div>
)

const resetKeys = (data, keys) => keys.forEach((key) => (data[key] = undefined))

export default class addEventDialog extends React.Component {
  constructor(props) {
    super(props)

    this.duplicateCheckTimeout = undefined
    this.state = {
      open: false,
      hasChanged: false,
      data: this.props.data || {},
      typeChoices: formatChoices(_.keys(choiceMap)),
      classChoices: [],
      classDisabled: true,
      categoryChoices: [],
      categoryDisabled: true,
      scheduleDisabled: !_.get(this.props, 'data.id'),
      eventOptions: [],
      templateOptions: [],
      events: [],
      farmEvents: [],
      showOverlapDialog: false,
    }
  }

  async componentDidMount() {
    const events = this.props.managePlans ? await getEventsForAccount(this.props.accountId) : []

    const searchStart = moment().subtract('month', eventSearchBackMonths)
    const farmEvents = await getAllFarmPlans(
      this.props.farmId,
      searchStart.year(),
      searchStart.month() + 1,
      eventSearchBackMonths + eventSearchForwardMonths,
    )
    const previousDueDate = _.get(this.props, 'data.previousDueDate')

    this.setState(
      {
        events: events.filter((e) => e.enabled),
        farmEvents,
        minDate: previousDueDate
          ? moment(previousDueDate)
          : moment()
              .add(_.get(this.props, 'data.id') ? -3 : -1, 'years')
              .toDate(),
        maxDate: moment()
          .add(_.get(this.props, 'data.id') ? 3 : 1, 'years')
          .toDate(),
      },
      () => this.setState(this.getExtraState(this.state.data)),
    ) // depends on farmEvents
  }

  getHealthTreatmentChoices = memoize((healthTreatments) =>
    Object.entries(healthTreatments).map((entry) => ({
      value: entry[0],
      name: entry[1].name,
      productTypes: entry[1].productTypes, // also used in filtering
    })),
  )

  onHealthTreatmentClick = (option) => {
    this.validate({ ...this.state.data, healthTreatmentId: option.value, preventLoop: true })
  }

  onEventOptionClick = (selected) => {
    const event = this.state.events.find((e) => e.id == selected.value)

    if (event) {
      const htId = _.get(event, 'healthTreatment.id')
      this.validate({
        ...this.state.data,
        eventName: selected.name,
        purpose: event.purpose,
        instructions: event.instructions,
        preventLoop: true,
        healthTreatmentId: htId ? `a_${htId}` : undefined, // a_ prefix for account ht
        ..._.pick(event.healthTreatment, ['dosage', 'uom', 'dosageType']),
      })
    } else {
      this.validate({
        ...this.state.data,
        eventName: selected.name,
        purpose: undefined,
        instructions: undefined,
        healthTreatmentId: undefined,
        dosage: undefined,
        uom: undefined,
        dosageType: undefined,
      })
    }
  }

  onTemplateOptionClick = (selected) => {
    this.validate({
      ...this.state.data,
      templateName: selected.name,
      realTemplateName: selected.value, // default has no name, but can't use empty string for a required field
      healthTreatmentId: _.get(this.state.data, 'healthTreatment.id'),
      ..._.pick(this.state.data.healthTreatment, ['dosage', 'uom', 'dosageType']),
    })
  }

  onDependentEventOptionClick = (selected) => {
    this.validate({
      ...this.state.data,
      dependentEventId: selected.value,
      dependentEventName: selected.name,
      healthTreatmentId: _.get(this.state.data, 'healthTreatment.id'),
      ..._.pick(this.state.data.healthTreatment, ['dosage', 'uom', 'dosageType']),
    })
  }

  handleRequestClose = (reload) => {
    this.props.onRequestClose(reload)

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  checkDuplicates = (eventType, eventName) => {
    clearTimeout(this.duplicateCheckTimeout)

    this.duplicateCheckTimeout = setTimeout(() => {
      findDuplicateEvents(this.props.farmId, eventType, eventName).then(({ success, result }) => {
        if (success && result.length) {
          this.setState({ validationErrors: { eventName: 'Duplicates found' }, farmDuplicateDialogData: result })
        }
      })
    }, 250)
  }

  validate = (data) => {
    // TODO remove when purging formsy
    if (this.state.preventLoop) {
      this.setState({ preventLoop: false })
      return
    }

    let preventLoop = data.preventLoop || false
    data.dueDate = data.dueDate || new Date()
    const copy = _.get(this.state, 'data.copy')
    let validationErrors = this.state.validationErrors

    if (
      !copy &&
      !this.props.managePlans &&
      data.eventName &&
      (data.eventType !== this.state.data.eventType || data.eventName !== this.state.data.eventName)
    ) {
      this.checkDuplicates(data.eventType, data.eventName)
      validationErrors = {}
    }

    // dosage is reset separately
    // 'required' validation kicks in when reset and no easy way to reset pristine state
    // however setting to 0 makes it happy and doesn't display
    if (data.eventType !== this.state.data.eventType) {
      resetKeys(data, [
        'scheduleType',
        'dependentEventName',
        'leadLag',
        'dependentEventId',
        'healthTreatmentId',
        'uom',
        'dosageType',
        'purpose',
        'instructions',
        'eventName',
        'templateName',
        'realTemplatename',
      ])
      data.dosage = 0
      preventLoop = true
    }

    if (data.planType !== this.state.data.planType) {
      resetKeys(data, ['planClass', 'planCategory'])

      if (!copy) {
        resetKeys(data, [
          'scheduleType',
          'dependentEventName',
          'leadLag',
          'dependentEventId',
          'healthTreatmentId',
          'uom',
          'dosageType',
          'purpose',
          'instructions',
          'eventName',
          'templateName',
          'realTemplatename',
        ])
        data.dosage = 0
      }
      preventLoop = true
    }

    if (data.planClass !== this.state.data.planClass && !copy) {
      resetKeys(data, ['planCategory'])

      if (!copy) {
        resetKeys(data, [
          'scheduleType',
          'dependentEventName',
          'leadLag',
          'dependentEventId',
          'healthTreatmentId',
          'uom',
          'dosageType',
          'purpose',
          'instructions',
          'eventName',
          'templateName',
          'realTemplatename',
        ])
        data.dosage = 0
      }
      preventLoop = true
    }

    if (data.planCategory !== this.state.data.planCategory && !copy) {
      resetKeys(data, [
        'scheduleType',
        'dependentEventName',
        'leadLag',
        'dependentEventId',
        'healthTreatmentId',
        'uom',
        'dosageType',
        'purpose',
        'instructions',
        'eventName',
        'templateName',
        'realTemplatename',
      ])
      data.dosage = 0
      preventLoop = true
    }

    if (
      data.healthTreatmentId &&
      !this.uomEnabled(data.healthTreatmentId) &&
      data.uom !== this.props.healthTreatments[data.healthTreatmentId].uom
    ) {
      data.uom = this.props.healthTreatments[data.healthTreatmentId].uom
      preventLoop = true
    }

    if (data.scheduleType === 'dependent' && this.state.data.scheduleType === 'fixed') {
      data.dependentEventName = data.leadLag = data.dependentEventId = undefined
    }

    if (!data.dependentEventId && data.leadLag) {
      data.leadLag = undefined
      preventLoop = true
    }

    if (_.get(this.props, 'data.id')) {
      data = {
        ...data,
        ..._.pick(this.props.data, ['planClass', 'planType', 'planCategory', 'eventType', 'eventName']),
      }
    }

    if (data.planType || data.eventType) {
      data = {
        ...this.state.data,
        ..._.pick(data, [
          'eventType',
          'planType',
          'planClass',
          'planCategory',
          'purpose',
          'instructions',
          'scheduleType',
          'dueDate',
          'dependentEventId',
          'dependentEventName',
          'leadLag',
          'eventName',
          'templateName',
          'realTemplateName',
          'repeat',
          'applyFuture',
        ]),
        healthTreatment: {
          id: data.healthTreatmentId,
          ..._.pick(data, ['dosage', 'uom', 'dosageType']),
        },
      }
      this.setState({
        data: data,
        validationErrors,
        preventLoop,
        hasChanged: true,
        ...this.getExtraState(data),
      })
    }
  }

  getExtraState = (data) => {
    const noCategory = data.planType === 'Farm' && data.planClass !== 'Stock'

    return {
      classChoices: _.get(choiceMap, `${data.planType}.planClass`, []),
      classDisabled: !data.planType,
      categoryChoices: _.get(choiceMap, `${data.planType}.planCategory`, []),
      categoryDisabled: !data.planClass || noCategory,
      addEventDisabled: !data.planClass || !(data.planCategory || !noCategory) || !this.state.initialSave,
      scheduleDisabled:
        !_.get(this.props, 'data.id') &&
        (!data.eventType ||
          !data.planType ||
          !data.planClass ||
          !data.eventName ||
          !(data.planCategory || noCategory) ||
          (!data.templateName && this.props.managePlans)),
      dependentEventOptions: this.getDependentEventOptions(data),
      eventOptions: this.state.events
        .filter((e) => {
          return (
            e.eventType == data.eventType &&
            e.enabled &&
            ((!e.planType && !e.planClass && !e.planCategory) ||
              (e.planType == data.planType && !e.planClass && !e.planCategory) ||
              (e.planType == data.planType && e.planClass == data.planClass && !e.planCategory) ||
              (e.planType == data.planType && e.planClass == data.planClass && e.planCategory == data.planCategory))
          )
        })
        .map((e) => ({
          name: e.name,
          value: e.id,
        })),
      templateOptions: Array.from(
        new Set(
          this.state.farmEvents
            .filter(
              (e) =>
                e.planClass == data.planClass &&
                e.planType == data.planType &&
                e.planCategory == (data.planCategory || null) &&
                e.planName,
            )
            .map((e) => e.planName),
        ),
      )
        .sort(collator.compare)
        .map((planName) => ({ name: planName, value: planName })),
    }
  }

  getDependentEventOptions(data) {
    const noCategory = data.planType === 'Farm' && data.planClass !== 'Stock'

    const currentId = _.get(this.props, 'data.id')
    const hasPlanName = currentId ? true : !!data.templateName
    const planName = currentId ? this.props.data.planName : data.realTemplateName

    if (data.planType && data.planClass && (data.planCategory || noCategory) && hasPlanName) {
      return this.state.farmEvents
        .filter((e) => {
          return (
            e.planType == data.planType &&
            e.planClass == data.planClass &&
            e.planCategory == (data.planCategory || null) &&
            e.planName == planName &&
            e.id !== currentId
          )
        })
        .sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate)))
        .map((e) => ({ name: `${e.eventName} (${moment(e.dueDate).format(eventDateFormat)})`, value: e.id }))
    }

    return []
  }

  uomEnabled = (id) => {
    const htId = _.get(this, 'state.data.healthTreatment.id') || id
    return htId ? this.props.healthTreatments[htId].uom === 'g' : false
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        const errors = result.data || {}

        if (errors.date || errors.leadLag) {
          this.setState({ showOverlapDialog: true })
        } else {
          this.setState({
            validationErrors: { dueDate: errors.date },
          })
        }
      } else {
        this.handleRequestClose(true)
      }
    }

    const submitData = {
      name: this.state.data.eventName,
      planType: data.planType,
      planClass: data.planClass,
      planCategory: data.planCategory,
      purpose: data.purpose,
      instructions: data.instructions,
      eventType: data.eventType,
      repeat: data.repeat || false,
      templateName: this.state.data.realTemplateName, // formsy is not aware of this
      applyFuture: data.applyFuture || false,
    }

    if (data.scheduleType === 'fixed') {
      submitData.date = moment(data.dueDate).format('YYYY-MM-DD')
    } else {
      submitData.dependentEventId = data.dependentEventId
      submitData.leadLag = data.leadLag
    }

    if (data.eventType === 'Treatment') {
      // detect if treatment is account or farm level, and set flag accordingly
      const idParts = this.state.data.healthTreatment.id.split('_')

      submitData.healthTreatment = {
        ...this.state.data.healthTreatment,
        id: idParts[1],
        forFarm: idParts[0] === 'f',
      }
    }

    if (_.get(this.props, 'data.id')) {
      editFarmEvent(this.props.farmId, this.props.data.id, submitData).then(onSave)
    } else {
      addEventToFarmPlan(this.props.farmId, submitData).then(onSave)
    }
  }

  copyEvent = () => {
    const data = { ...this.state.data }
    const copyEvent = this.props.copyEvent // props about to get destroyed
    this.handleRequestClose(false)

    setTimeout(() => copyEvent(data), 500)
  }

  render() {
    const { disableAll } = this.props
    const healthTreatmentName = _.get(this, 'state.data.healthTreatment.id')
      ? this.props.healthTreatments[this.state.data.healthTreatment.id].name
      : undefined
    const templateOptions = [{ name: 'Default', value: '' }, ...this.state.templateOptions] // copy since modifying

    const templateNameDisabled =
      !this.state.data.planType ||
      !this.state.data.planClass ||
      (!this.state.data.planCategory && !this.state.categoryDisabled)

    const newEvent = !_.get(this.props, 'data.id')
    let title = ''

    if (disableAll) {
      title = 'View Event'
    } else if (_.get(this.props, 'data.id')) {
      title = 'Edit Event'
    } else {
      title = 'Add Event'
    }

    return (
      <>
        <FormDialog
          wide
          width='70vw'
          title={title}
          open={this.props.open}
          onClose={() => this.handleRequestClose(false)}
          hasSubmit
          FormComponent={addEventForm}
          uomEnabled={this.uomEnabled()}
          currentData={{ ...this.state.data, healthTreatmentName }}
          submit={this.submit}
          validate={this.validate}
          hasChanged={this.state.hasChanged}
          newEvent={newEvent}
          managePlans={this.props.managePlans}
          onEventOptionClick={this.onEventOptionClick}
          onTemplateOptionClick={this.onTemplateOptionClick}
          onDependentEventOptionClick={this.onDependentEventOptionClick}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          validationErrors={this.state.validationErrors}
          healthTreatmentChoices={this.getHealthTreatmentChoices(this.props.healthTreatments)}
          onHealthTreatmentClick={this.onHealthTreatmentClick}
          templateOptions={templateOptions}
          templateNameDisabled={templateNameDisabled}
          copy={_.get(this.props, 'data.copy')}
          headerRight={
            this.props.managePlans || newEvent || disableAll ? false : <HeaderCopyButton onClick={this.copyEvent} />
          }
          disableAll={disableAll}
          {..._.pick(this.state, [
            'eventTypeChoices',
            'typeChoices',
            'classDisabled',
            'classChoices',
            'categoryDisabled',
            'categoryChoices',
            'eventOptions',
            'dependentEventOptions',
            'scheduleDisabled',
          ])}
        />
        {this.state.showOverlapDialog && (
          <WarningDialog
            open
            warning={{
              title: 'Duplicate event',
              message:
                'The event being added is a duplicate to an event already in the Type/Class/Category/Template selected on the same date based on the date selected or the the lead/lag time on the dependent event. Please adjust scheduling details to enable saving the event.',
            }}
            handleRequestClose={() => this.setState({ showOverlapDialog: false })}
            confirmButtonTitle='Edit Schedule'
          />
        )}
        {this.state.farmDuplicateDialogData && (
          <WarningDialog
            open
            warning={{
              title: 'Duplicate Event',
              jsx: this.renderFarmDuplicateMessage(),
            }}
            handleRequestClose={() => this.setState({ farmDuplicateDialogData: undefined })}
          />
        )}
      </>
    )
  }

  renderFarmDuplicateMessage = () => {
    const duplicates = this.state.farmDuplicateDialogData.map((row) => (
      <FarmDuplucateItem data={row} key={Math.random()} />
    ))

    return (
      <div>
        <p>Duplicate events were found. If you want to continue adding such an event, please make a copy instead.</p>

        <p>Found duplicates:</p>
        <ul>{duplicates}</ul>
      </div>
    )
  }
}

const DosageTextField = styled(TextField)`
  margin-right: 12px;
`

const TemplateSearchField = (props) => (
  <FormField width='48%'>
    <Search
      label='Template Name'
      placeholder='Search templates'
      options={props.options}
      onOptionClick={props.onOptionClick}
      clear={props.clear}
      value={props.templateName}
      disabled={props.disabled}
      required
      noSort
    />
    <HiddenField
      name='templateName'
      value={props.realTemplateName === undefined ? '' : props.templateName}
      disabled={props.disabled}
      required
    />
  </FormField>
)

const HeaderCopyButton = ({ onClick, ...props }) => (
  <DialogHeaderButton type='submit' onClick={onClick} {...props}>
    Copy
  </DialogHeaderButton>
)

const FarmDuplucateItem = ({ data, ...props }) => (
  <li {...props}>
    <span>
      {data.planType}/{planningClassMap[data.planType].get(data.planClass)}
      {data.planCategory && '/' + planningCategoryMap[data.planType].get(data.planCategory)} - {data.name || 'Default'}{' '}
      ({data.dates.join(', ')})
    </span>
  </li>
)
