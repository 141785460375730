import { getFirebase } from './firebase'

// types for when this file is ported to TS
// export interface AnonUserInfo {
//   readonly authenticated: false
// }
// export interface AuthUserInfo {
//   readonly authenticated: true
//   readonly email: string
//   readonly displayName: string
//   readonly uid: string
// }

export const logout = () => {
  return getFirebase()
    .auth()
    .signOut()
}

export const login = (email, pw) => {
  return getFirebase()
    .auth()
    .signInWithEmailAndPassword(email, pw)
    .then((user) => {
      return {
        success: true,
        user: user,
      }
    })
    .catch((error) => {
      // var errorCode = error.code
      return {
        success: false,
        error: error.message,
      }
    })
}

let user
let userInfo
const authCallbacks = []

export const updateUser = () => {
  if (user) {
    // signed in
    // TODO could grab additional user info from users node here?
    const currentUser = getFirebase().auth().currentUser
    userInfo = {
      authenticated: true,
      email: currentUser.email,
      displayName: currentUser.displayName,
      uid: currentUser.uid,
    }
  } else {
    // not signed in
    userInfo = {
      authenticated: false,
    }
  }
  authCallbacks.forEach((callback) => callback(userInfo))
}

export const initAuthHandlers = () => {
  getFirebase()
    .auth()
    .onAuthStateChanged((authUser) => {
      user = authUser
      updateUser()
    })
}

export const addAuthHandler = (func, once = false) => {
  // call once, straight away
  func(userInfo)

  if (!once) {
    // then call again whenever the auth state changes
    authCallbacks.push(func)
  }
}

export const removeAuthHandler = (func) => {
  const index = authCallbacks.indexOf(func)
  if (index !== -1) {
    authCallbacks.splice(index, 1)
  }
}

export const getAuthenticatedUser = () => {
  return getFirebase().auth().currentUser
}

export function resetPassword(email) {
  return getFirebase()
    .auth()
    .sendPasswordResetEmail(email)
    .then((user) => {
      return {
        success: true,
        user: user,
      }
    })
    .catch((error) => {
      // var errorCode = error.code
      return {
        success: false,
        // errorCode: error.code,
        error: error.message,
      }
    })
}

export const verifyPasswordResetCode = (actionCode) => {
  return getFirebase()
    .auth()
    .verifyPasswordResetCode(actionCode)
    .then((email) => {
      return {
        success: true,
        email: email,
      }
    })
    .catch((error) => {
      // var errorCode = error.code
      return {
        success: false,
        // errorCode: error.code,
        error: error.message,
      }
    })
}

export const confirmPasswordReset = (actionCode, newPassword) => {
  return getFirebase()
    .auth()
    .confirmPasswordReset(actionCode, newPassword)
    .then(() => {
      return {
        success: true,
      }
    })
    .catch((error) => {
      // var errorCode = error.code
      return {
        success: false,
        // errorCode: error.code,
        error: error.message,
      }
    })
}
