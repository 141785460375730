import React from 'react'
import { Route } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import { PageTitle, ActionHeader, ButtonContainer } from '../../components/PageHeaders'
import { PushButton } from '../../components/Form/Form'
import FarmSelector from '../../components/FarmSelector'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import Notifications from './components/Notifications'

import { getAllFarmsForUser } from '../../data/farm'
import { getNotificationSettings, setNotificationSettings } from '../../data/settings'

import queryString from 'query-string'

class Settings extends React.Component {
  constructor(props) {
    super(props)

    const queryParams = queryString.parse(props.location.search)

    this.state = {
      imports: {},
      selectedFarmId: queryParams.farmId,
      loading: true,
    }
  }

  componentDidMount() {
    getAllFarmsForUser(this.props.user.uid).then((farms) => {
      this.setState({
        farms,
      })
    })

    if (this.state.selectedFarmId) {
      this.setState({
        redirect: { farmId: this.state.selectedFarmId },
      })
    }

    if (this.props.match.params.farmId) {
      this.getData(this.props.match.params.farmId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirect) {
      this.setState({ redirect: false })
    }
  }

  handleFarmSelected = (farmId) => {
    this.setState({
      selectedFarmId: farmId,
      redirect: { farmId },
    })

    this.getData(farmId)
  }

  formatData = (notifications) => {
    const notificationData = [
      {
        id: 'feed_planning',
        type: 'Feed Planning',
        name: 'Feed Days',
        description: 'The number of feed days for an assigned animal group is less than 2 days',
        status: 'COMING',
      },
      {
        id: 'health_treatment',
        type: 'Health Treatments',
        name: 'Expiration',
        description: 'An enabled Health Treatment purchase has passed its expiration date',
        status: notifications && notifications.health_treatment,
      },
      {
        id: 'health_treatment_2',
        type: 'Health Treatments',
        name: 'Low Inventory',
        description: 'Current quantity of an enabled Health Treatment is below defined alert quantity',
        status: 'COMING',
      },
    ] // TODO: use this after other notifications are implemented .map(n => ({ ...n, status: notifications[n.id] }))

    const selectedNotifications = notificationData.map((n, key) => notifications && notifications[n.id] && key)

    this.setState({ notifications, notificationData, selectedNotifications })
  }

  getData = async (farmId) => {
    const notifications = await getNotificationSettings(farmId)
    this.formatData(notifications)
  }

  onSave = async () => {
    const updatedNotifications = {}
    this.state.notificationData.forEach((n, key) => {
      updatedNotifications[n.id] = this.state.selectedNotifications.includes(key)
    })

    const notifications = await setNotificationSettings(this.state.selectedFarmId, {
      notifications: updatedNotifications,
    })
    this.formatData(notifications)

    this.setState({
      snackbarOpen: true,
      snackbarContent: 'Notifications successfully updated',
    })
  }

  render() {
    return (
      <Layout>
        <Route
          path='/settings'
          render={() => (
            <div>
              <PageTitle>Settings</PageTitle>
              <ActionHeader>
                <FarmSelector
                  user={this.props.user}
                  farms={this.state.farms}
                  handleFarmSelected={this.handleFarmSelected}
                />
                <Box marginLeft={3} />
                <ButtonContainer align='left'>
                  <PushButton onClick={this.onSave}>Save</PushButton>
                </ButtonContainer>
              </ActionHeader>
              {this.state.selectedFarmId && (
                <Notifications
                  farmId={this.state.selectedFarmId}
                  data={this.state.notificationData}
                  selected={this.state.selectedNotifications}
                  updateSelected={(selectedNotifications) => this.setState({ selectedNotifications })}
                />
              )}
            </div>
          )}
        />
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={() => this.setState({ snackbarOpen: false })}
          message={this.state.snackbarContent}
        />
      </Layout>
    )
  }
}

export default requireAuth(Settings)
