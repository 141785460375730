import React, { useState } from 'react'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { colors } from '../../../../../styles/styleConstants'
import ChildRow from './ChildRow'

export default ({
  data,
  expanded,
  onToggle,
  startMonth,
  startYear,
  setEventToEdit,
  setEventToDelete,
  onToggleComplete,
  deletePlan,
}) => {
  const startDate = moment(`${startYear}-${startMonth}`, 'YYYY-M')
  const [expandedRows, setExpandedRows] = useState([])

  return (
    <React.Fragment>
      <ParentRow>
        <Name onClick={onToggle}>
          {data.name}
          {expanded ? <ChevronLeft /> : <ChevronRight />}
        </Name>
        {expanded && (
          <React.Fragment>
            {_.times(6).map((n) => (
              <Month key={n}>
                {startDate
                  .clone()
                  .add(n, 'months')
                  .format('MMM YY')}
              </Month>
            ))}
          </React.Fragment>
        )}
      </ParentRow>
      {expanded && (
        <React.Fragment>
          {data.classes.map((classData) => (
            // eslint-disable-next-line react/jsx-key
            <ChildRow
              category={data.name}
              data={classData}
              expanded={expandedRows.includes(classData.name)}
              onToggle={() => {
                if (expandedRows.includes(classData.name)) {
                  setExpandedRows(expandedRows.filter((c) => c !== classData.name))
                } else {
                  setExpandedRows(expandedRows.concat(classData.name))
                }
              }}
              startMonth={startMonth}
              startYear={startYear}
              setEventToEdit={setEventToEdit}
              setEventToDelete={setEventToDelete}
              onToggleComplete={onToggleComplete}
              deletePlan={deletePlan}
            />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const ParentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid ${colors.lightGrey};
`

const Name = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #555555;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }
`

const Month = styled.div`
  font-size: 14px;
  color: #2a2a2a;
  text-align: center;
  width: 164px;
`
