import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import DialogHeader from '../../components/Dialog/DialogHeader'
import { DialogContainer, DialogContent } from '../../components/Dialog/FormDialog'
import { SelectInput } from '../../components/Form/Form'
import { formatChoices } from '../../constants'
import { Group, GroupWeightMetric } from '../../data/dashboard/group_weight'

interface GroupWeightSettingsModalProps {
  readonly open: boolean
  readonly handleRequestClose: () => void
  readonly handleSaveSettings: (metric: GroupWeightMetric) => void
  readonly metric: GroupWeightMetric
  readonly farmId: string
  readonly groups: Group[]
}

const defaultStartDate = null
const defaultEndDate = null
const minWeight = 50
const maxWeight = 1000
const defaultStdDev = 2
const groupTypes = {
  Id: 'Id',
  NonId: 'Non-Id',
}

const thisYear = new Date().getFullYear()
const yearChoices = formatChoices(
  Array.from(
    (function*() {
      for (let i = thisYear; i >= 2000; i--) yield i
      yield 9999
    })(),
  ),
)

const stdevChoices = formatChoices([1, 2])

const validate = ({ startDate, endDate, startWeight, endWeight }) => {
  const validationErrors = {}

  if (startWeight < minWeight) {
    validationErrors.startWeight = `Cannot be less than ${minWeight}.`
  }

  if (endWeight < minWeight) {
    validationErrors.endWeight = `Cannot be less than ${minWeight}.`
  }

  if (startWeight > maxWeight) {
    validationErrors.startWeight = `Cannot be greater than ${maxWeight}.`
  }

  if (endWeight > maxWeight) {
    validationErrors.endWeight = `Cannot be greater than ${maxWeight}.`
  }

  if (startWeight >= endWeight) {
    validationErrors.endWeight = 'End must be greater than start.'
  }

  if (startDate && endDate && startDate >= endDate) {
    validationErrors.endDate = 'End must be greater than start.'
  }

  return validationErrors
}

export const GroupWeightSettingsModal: React.FunctionComponent<WeightDistributionSettingsProps> = ({
  open,
  handleRequestClose,
  handleSaveSettings,
  metric,
  farmId,
  groups,
}: GroupWeightSettingsModalProps): JSX.Element => {
  const [groupType, setGroupType] = useState<string>(undefined)
  const [groupYear, setGroupYear] = useState<string>(undefined)
  const [group, setGroup] = useState<string>(undefined)
  const [startDate, setStartDate] = useState<date>(defaultStartDate)
  const [startWeight, setStartWeight] = useState<number>(undefined)
  const [endDate, setEndDate] = useState<date>(defaultEndDate)
  const [endWeight, setEndWeight] = useState<number>(undefined)
  const [stdDev, setStdDev] = useState<number>(defaultStdDev)

  useEffect(() => {
    if (!open) return

    setGroupType(undefined)
    setGroupYear(undefined)
    setGroup(undefined)
    setStartWeight(metric.startWeight)
    setEndWeight(metric.endWeight)
    setStartDate(metric.startDate || defaultStartDate)
    setEndDate(metric.endDate || defaultEndDate)
    setStdDev(metric.stdDev || defaultStdDev)

    if (metric.groupId) {
      setGroup(metric.groupId)

      const group = groups.find((row) => row.id === metric.groupId)

      if (group) {
        setGroupType(group.type)
        setGroupYear(group.year)
      } else {
        console.log('Error, group not found with id', metric.groupId)
      }
    }
  }, [open])

  const onSave = () => {
    handleSaveSettings({
      ...metric,
      groupId: group,
      startWeight,
      endWeight,
      startDate,
      endDate,
      stdDev,
    })
  }

  const groupChoices = groups
    .filter((group) => group.year === groupYear && group.type === groupType)
    .map((group) => [group.id, group.name])
  const validationErrors = validate({ startDate, endDate, startWeight, endWeight })
  const canSave = group && startWeight && endWeight && _.isEmpty(validationErrors) && stdDev

  return (
    <DialogContainer
      onClose={handleRequestClose}
      onBackdropClick={handleRequestClose}
      maxWidth='md'
      fullWidth
      open={open}
    >
      <DialogHeader
        title='Settings - Group Weight'
        onClose={onSave}
        hasSubmit={false}
        hasClose={true}
        closeMessage='Save'
        closeDisabled={!canSave}
      />
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 3 }}>
          <Grid item xs={3}>
            <SelectInput
              choices={Object.entries(groupTypes)}
              showPlaceholder
              placeholder='Group Type'
              value={groupType}
              onChange={(event) => {
                setGroupType(event.target.value)
                setGroup(undefined)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              choices={yearChoices}
              showPlaceholder
              placeholder='Group Year'
              value={groupYear}
              onChange={(event) => {
                setGroupYear(event.target.value)
                setGroup(undefined)
              }}
              fullWidth
              disabled={!groupType}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              choices={groupChoices}
              showPlaceholder
              placeholder='Group Name'
              fullWidth
              disabled={!groupYear}
              onChange={(event) => setGroup(event.target.value)}
              value={group}
            />
          </Grid>
        </Grid>

        <Divider variant='middle' />

        <Grid container spacing={2} style={{ marginTop: 3 }}>
          <Grid item xs={2}>
            <DatePicker
              label='Goal Start Date'
              onChange={(m) => setStartDate(m.toDate())}
              value={startDate}
              format='LL'
              maxDate={
                endDate
                  ? moment(endDate)
                      .subtract(1, 'days')
                      .toDate()
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              value={startWeight}
              onChange={(event) => setStartWeight(parseInt(event.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position='start'>kg</InputAdornment>,
              }}
              label='Goal Start Weight'
              helperText={validationErrors.startWeight}
              error={!!validationErrors.startWeight}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              label='Goal End Date'
              onChange={(m) => setEndDate(m.toDate())}
              value={endDate}
              helperText={validationErrors.endDate}
              error={!!validationErrors.endDate}
              format='LL'
              minDate={
                startDate
                  ? moment(startDate)
                      .add(1, 'days')
                      .toDate()
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              value={endWeight}
              onChange={(event) => setEndWeight(parseInt(event.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position='start'>kg</InputAdornment>,
              }}
              label='Goal End Weight'
              helperText={validationErrors.endWeight}
              error={!!validationErrors.endWeight}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              choices={stdevChoices}
              showPlaceholder
              placeholder='Range Std Deviation'
              fullWidth
              onChange={(event) => setStdDev(event.target.value)}
              value={stdDev}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DialogContainer>
  )
}
