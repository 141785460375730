import { getAuthHeaders } from '../helpers/firebase'
import { get } from '../helpers/fetch'
import config from 'config'

export const getRecordsForEID = (farmId, eid) => {
  const url = `${config.backend.url}/farm/${farmId}/animal/eid/${eid}`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res
    })
}

export const getRecordsForVID = (farmId, type, year, vid) => {
  const url = `${config.backend.url}/farm/${farmId}/animal/vid/${type}/${year}/${vid}`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res
    })
}

export const getRecordsForNonIdGroup = (farmId, groupId) => {
  const url = `${config.backend.url}/farm/${farmId}/animal/group/${groupId}`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res
    })
}

export const getAllNonIdGroupsForUser = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/groups/NonId`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res.groups
    })
}
