import { post, get } from '../helpers/fetch'
import { getAuthHeaders } from '../helpers/firebase'
import config from 'config'

export const getFilteredAnimalInfo = (resource, data) => {
  const url = config.backend.url + '/analysis/animals/' + resource

  return getAuthHeaders().then((headers) => {
    return post(url, data, headers)
  })
}

export const getMetrics = (data) => {
  const url = config.backend.url + '/analysis/metrics'

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers)
    })
    .then((res) => {
      return res.metrics
    })
}

export const getResource = (resource, data) => {
  const url = config.backend.url + '/analysis/' + resource

  // url is foo-bar-baz but returned data is under fooBarBaz key
  const responseKey = resource
    .split('-')
    .map((part, index) => {
      if (index === 0) return part

      return part.charAt(0).toUpperCase() + part.slice(1)
    })
    .join('')

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers)
    })
    .then((res) => {
      return res[responseKey]
    })
}

export const getDataElements = (data) => {
  const url = config.backend.url + '/analysis/data-elements/' + data.animalType

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res.dataElements
    })
}
