import '../../components/Dialog/Dialog.css'

import React, { useState } from 'react'

import FormDialog from '../../components/Dialog/FormDialog'
import { CheckboxField, FormRow, TextField } from '../../components/Form/Form'
import {
  AverageScanResultGoal,
  AverageScanResultSettings,
  loadAverageScanResultSettings,
  saveAverageScanResultSettings,
} from '../../data/dashboard/average_scan_result'
import { AnimalType, AnimalTypes } from '../../data/types'
import { CrossIcon, PlusIcon } from '../../styles/icons'

function settingsTitle(animalType: AnimalType): string {
  return `${animalType} Goal - Year`
}

function settingsSubtitle(animalType: AnimalType): string {
  switch (animalType) {
    case AnimalType.Cattle: {
      return 'Av Scan Result (1-10)'
    }
    case AnimalType.Deer: {
      return 'Av Scan Result (1-5)'
    }
    case AnimalType.Sheep: {
      return 'Av Scan Result (1-5)'
    }
  }
}

function settingsMax(animalType: AnimalType): number {
  switch (animalType) {
    case AnimalType.Cattle: {
      return 10
    }
    default: {
      return 5
    }
  }
}

interface SettingsFormProps {
  readonly settings: AverageScanResultSettings
  readonly onChange: (settings: AverageScanResultSettings) => void
  readonly position: number
}

const SettingsForm: React.FunctionComponent<SettingsFormProps> = ({ settings, onChange, position }): JSX.Element => {
  const [key, setKey] = useState(Math.random())

  const onChangeGoal = (animalType: AnimalType, value: string, index: number): void => {
    const scanResult = parseValue(value)
    if (!scanResult) return

    const goal = findGoal(animalType, index)
    settings.goals[animalType][index] = { ...goal, scanResult }

    onChange(settings)
  }

  const onChangeYear = (animalType: AnimalType, value: string, index: number): void => {
    const year = parseValue(value)
    if (!year) return

    const goal = findGoal(animalType, index)
    settings.goals[animalType][index] = { ...goal, year }

    onChange(settings)
  }

  const parseValue = (value: string): number | null => {
    const result = parseFloat(value)
    return isNaN(result) ? null : result
  }

  const findGoal = (animalType: AnimalType, index: number): AverageScanResultGoal => {
    return settings.goals[animalType][index]
  }

  const onAddGoal = (animalType: AnimalType): void => {
    settings.goals[animalType].push({
      year: new Date().getFullYear(),
      scanResult: 1.0,
    })

    onChange(settings)
    setKey(Math.random())
  }

  const onDeleteGoal = (animalType: AnimalType, index: number): void => {
    settings.goals[animalType].splice(index, 1)
    onChange(settings)
    setKey(Math.random())
  }

  const onChangeIncludeNonId = (includeNonId: boolean): void => {
    onChange({ ...settings, includeNonId })
  }

  return (
    <React.Fragment key={key}>
      <FormRow>
        <div className='dashboardSettingsCheckboxContainer'>
          <CheckboxField
            name='includeNonId'
            label='Include Non-ID Animals'
            checked={settings.includeNonId}
            onChange={onChangeIncludeNonId}
          />
        </div>
      </FormRow>
      <hr style={{ marginRight: 0, marginTop: 0 }} />
      <div>
        {AnimalTypes.map((animalType, index) => (
          <div key={animalType}>
            {index > 0 ? <hr style={{ marginRight: 0 }} /> : null}
            <FormRow>
              <div className='dashboardSettingsHeaderColumn'>
                <p className='dashboardSettingsHeader'>{settingsTitle(animalType)}</p>
                <p className='dashboardSettingsHeaderColumnLabel'>{settingsSubtitle(animalType)}</p>
              </div>
              <div className='dashboardSettingsHorizontalContainer'>
                {settings.goals[animalType].map((goal, index) => (
                  <div key={`${animalType}-${index}`} className='dashboardSettingsContentColumnDeletable'>
                    <div className='dashboardSettingsTextFieldWrapper'>
                      <TextField
                        validations={`withinRange: [${0}, ${2099}]`}
                        validationErrors={{ withinRange: `` }}
                        name={`year-${index}`}
                        label=''
                        value={goal.year}
                        extra={{ style: { textAlign: 'center', fontWeight: 'bold' } }}
                        onChange={(changed: string) => {
                          onChangeYear(animalType, changed, index)
                        }}
                      />
                    </div>
                    <div className='dashboardSettingsTextFieldWrapper'>
                      <TextField
                        validations={`withinRange: [${0}, ${settingsMax(animalType)}]`}
                        validationErrors={{ withinRange: `` }}
                        name={`value-${index}`}
                        label=''
                        value={goal.scanResult}
                        extra={{ style: { textAlign: 'center' } }}
                        onChange={(changed: string) => {
                          onChangeGoal(animalType, changed, index)
                        }}
                      />
                    </div>
                    <CrossIcon
                      className='dashboardSettingsContentColumnDelete'
                      onClick={() => onDeleteGoal(animalType, index)}
                    />
                  </div>
                ))}
                <div className='dashboardSettingsContentColumnAdd' onClick={() => onAddGoal(animalType)}>
                  <PlusIcon />
                </div>
              </div>
            </FormRow>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

interface Props {
  readonly open: boolean
  readonly farmId: string
  readonly onSave: () => void
  readonly onRequestClose: () => void
}

export const AverageScanResultSettingsDialog: React.FunctionComponent<Props> = (props): JSX.Element => {
  const [data, setData] = useState<AverageScanResultSettings>()
  const [hasChanged, setHasChanged] = useState(false)
  const [valid, setValid] = useState(false)

  const handleRequestClose = (): void => {
    props.onRequestClose()
    setHasChanged(false)
    setData(undefined)
  }

  const submit = (): void => {
    if (!data) return

    const sortByYear = (a, b) => a.year - b.year

    data.goals.Cattle = data.goals.Cattle.sort(sortByYear)
    data.goals.Sheep = data.goals.Sheep.sort(sortByYear)
    data.goals.Deer = data.goals.Deer.sort(sortByYear)

    setHasChanged(false)
    saveAverageScanResultSettings(props.farmId, data, props.position).then(() => {
      props.onSave()
      handleRequestClose()
    })
  }

  const onChange = (settings: AverageScanResultSettings): void => {
    setData(settings)
    setHasChanged(true)
  }

  const validate = (): void => {
    if (!data) {
      setHasChanged(false)
      return
    }

    const findInvalid = (max: number) => {
      return (goal) => goal.scanResult < 0 || goal.scanResult > 10
    }

    const yearsUnique = (goals) => new Set(goals.map((goal) => goal.year)).size === goals.length

    const valid =
      !data.goals.Cattle.some(findInvalid(10)) &&
      !data.goals.Deer.some(findInvalid(5)) &&
      !data.goals.Sheep.some(findInvalid(5)) &&
      yearsUnique(data.goals.Cattle) &&
      yearsUnique(data.goals.Deer) &&
      yearsUnique(data.goals.Sheep)

    setHasChanged(true)
    setValid(valid)
  }

  const loadSettings = (): void => {
    loadAverageScanResultSettings(props.farmId, props.position).then(setData)
  }

  if (data && props.open) {
    return (
      <FormDialog
        wide
        maxWidth='md'
        width='70vw'
        title={'Settings - Average Scan Result'}
        open={props.open}
        onClose={handleRequestClose}
        FormComponent={SettingsForm}
        settings={data}
        onChange={onChange}
        submit={submit}
        validate={validate}
        hasChanged={hasChanged}
        canSubmit={valid && hasChanged}
      />
    )
  } else if (props.open) {
    loadSettings()
  }

  return null
}
