import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import styled from 'styled-components'

export default class ActionOverflow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: undefined,
      open: false,
    }
  }

  handleClick = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  onClose = () => {
    this.setState({ open: false, anchorEl: undefined })
  }

  render() {
    return (
      <Overflow>
        <Button
          aria-owns={this.state.open ? 'menu' : null}
          aria-haspopup='true'
          className='menuButtonSmall right'
          onClick={this.handleClick}
        >
          <MoreVert width='24px' />
        </Button>
        <Menu id='menu' anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.onClose}>
          {this.props.actions
            .filter((action) => {
              return action.hidden === undefined || !action.hidden(this.props.id)
            })
            .map((action) => {
              let name
              if (typeof action.name === 'function') {
                if (this.props.parentId && this.props.childId) {
                  name = action.name(this.props.parentId, this.props.childId)
                } else {
                  name = action.name(this.props.id)
                }
              } else {
                name = action.name
              }

              return (
                <MenuItem
                  key={`action-${Math.random()}`}
                  onClick={() => {
                    this.onClose()
                    if (this.props.parentId && this.props.childId) {
                      action.onClick(this.props.parentId, this.props.childId)
                    } else {
                      action.onClick(this.props.id)
                    }
                  }}
                  disabled={action.disabled && action.disabled(this.props.id)}
                >
                  {name}
                </MenuItem>
              )
            })}
        </Menu>
      </Overflow>
    )
  }
}

const Overflow = styled.div`
  padding-top: 6px;
  padding-bottom: 6px;

  button {
    padding: 0;
    min-width: unset;

    svg {
      width: 24px;
    }
  }
`
