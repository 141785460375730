import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'

class BillingAccountDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: undefined,
      open: false,
    }
  }

  handleClick = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
    this.props.updateSelectedAccount(this.props.account)
  }

  handleOpenDisableBilling = () => {
    this.props.handleOpenDisableBilling()
    this.handleRequestClose()
  }

  handleOpenEditBillingAccount = () => {
    this.props.handleOpenEditBillingAccount()
    this.handleRequestClose()
  }

  handleOpenEditPaymentMethods = () => {
    this.props.handleOpenEditPaymentMethods(this.props.account.id)
    this.handleRequestClose()
  }

  handleOpenViewTransactions = () => {
    this.props.handleOpenViewTransactions(this.props.account.id)
    this.handleRequestClose()
  }

  handleOpenEditUsers = () => {
    this.props.handleOpenEditUsers(this.props.account.id)
    this.handleRequestClose()
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const account = this.props.account
    // only owners, and since no standard type account so just clinic
    const showEditUsers = account.accountType === 'Vet' && account.accountRole === 'Clinic'

    return (
      <div className='BillingAccountDropdown'>
        <Button
          aria-owns={this.state.open ? 'farm-menu' : null}
          aria-haspopup='true'
          className='menuButtonSmall right'
          onClick={this.handleClick}
        >
          <MoreVert />
        </Button>
        <Menu id='farm-menu' anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleRequestClose}>
          {showEditUsers && <MenuItem onClick={this.handleOpenEditUsers}>Edit Users</MenuItem>}
          <MenuItem onClick={this.handleOpenViewTransactions}>View Transactions</MenuItem>
          <MenuItem onClick={this.handleOpenEditPaymentMethods}>Edit Payment Methods</MenuItem>
          <MenuItem onClick={this.redirectFarms}>Edit Farms</MenuItem>
          <MenuItem onClick={this.handleOpenEditBillingAccount}>Edit Billing Account</MenuItem>
          <MenuItem onClick={this.handleOpenDisableBilling}>Disable Billing</MenuItem>
        </Menu>
      </div>
    )
  }
}

export default BillingAccountDropdown
