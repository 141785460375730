import { getAuthHeaders } from '../helpers/firebase'
import { post, put, get, del } from '../helpers/fetch'
import config from 'config'

export const SUBSCRIPTION_STATUSES = {
  VALID: 'valid',
}

export const getAllBillingAccounts = () => {
  const url = config.backend.url + '/accounts'

  return getAuthHeaders().then((headers) => {
    return get(url, headers).then((data) => data.accounts)
  })
}

export const getBillingAccount = (accountId) => {
  const url = config.backend.url + '/account/' + accountId

  return getAuthHeaders().then((headers) => {
    return get(url, headers)
  })
}

export const createBillingAccount = (userId, data) => {
  const url = config.backend.url + '/account'

  return getAuthHeaders().then((headers) => {
    return post(url, data, headers)
      .then((res) => {
        return {
          success: true,
          account: res.businessName,
          id: res.id,
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error.message,
        }
      })
  })
}

export const saveBillingAccount = (accountId, data) => {
  console.log(data)
  const url = config.backend.url + '/account/' + accountId

  return getAuthHeaders().then((headers) => {
    return put(url, data, headers)
      .then((res) => {
        return {
          success: true,
          accountId: res.id,
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error.message,
        }
      })
  })
}

export const getPaymentMethods = (accountId) => {
  const url = config.backend.url + '/subscription/' + accountId

  console.log('trying', url)

  return getAuthHeaders().then((headers) => {
    return get(url, headers)
  })
}

export const getTransactionHistory = (accountId) => {
  const url = config.backend.url + '/subscription/' + accountId + '/transactions'

  return getAuthHeaders().then((headers) => {
    return get(url, headers)
  })
}

/**
 * update an account's description
 * @param  {Number} accountId
 * @param  {object} data      {animalCount: integer, token: stripeToken}
 * @return {Promise}
 */
export const updateSubscription = (accountId, data) => {
  const url = config.backend.url + '/subscription/' + accountId

  return getAuthHeaders().then((headers) => {
    return put(url, data, headers)
      .then((res) => {
        return {
          success: true,
          subscriptionId: res.id, // TODO
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error.message,
        }
      })
  })
}

export const createCard = (accountId, data) => {
  const url = `${config.backend.url}/subscription/${accountId}/card`

  return getAuthHeaders().then((headers) => {
    return post(url, data, headers)
      .then(() => {
        return {
          success: true,
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error.message,
        }
      })
  })
}

export const getAccountRoles = (accountId) => {
  const url = `${config.backend.url}/account/${accountId}/roles`

  return getAuthHeaders().then((headers) => {
    return get(url, headers).then((data) => data.roles)
  })
}

export const setAccountUserRole = (accountId, firebaseUserId, role) => {
  const url = `${config.backend.url}/account/${accountId}/role/${firebaseUserId}`

  return getAuthHeaders().then((headers) => {
    return put(url, { role }, headers)
  })
}

export const removeAccountUser = (accountId, userId) => {
  const url = `${config.backend.url}/account/${accountId}/role/${userId}`

  return getAuthHeaders().then((headers) => {
    return del(url, headers)
  })
}

export const getAccountInvitations = (accountId) => {
  const url = `${config.backend.url}/account/${accountId}/invites`

  return getAuthHeaders()
    .then((headers) => {
      return get(url, headers)
    })
    .then((res) => {
      return res.invitations
    })
}

export const deleteInvitation = (accountId, inviteId) => {
  const url = `${config.backend.url}/account/${accountId}/invite/${inviteId}`

  return getAuthHeaders().then((headers) => {
    return del(url, headers)
  })
}

export const inviteAccountUser = (accountId, email, role) => {
  const url = `${config.backend.url}/account/${accountId}/invite`

  const data = {
    email: email,
    role: role,
  }

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers)
    })
    .then(() => {
      return {
        success: true,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export const setInviteRole = (accountId, inviteId, role) => {
  const url = `${config.backend.url}/account/${accountId}/invite/${inviteId}`

  return getAuthHeaders().then((headers) => {
    return put(url, { role }, headers)
  })
}
