import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'

import {
  getGroups,
  groupWeightChartSettings,
  GroupWeightMetric,
  GroupWeightSettings,
  loadGroupWeightSettings,
  saveGroupWeightSettings,
} from '../../data/dashboard/group_weight'
import { AnimalType } from '../../data/types'
import { AnimalTypeSelector } from './AnimalTypeSelector'
import { GroupWeightSettingsModal } from './GroupWeightSettings'

interface GroupWeightChartProps {
  readonly metric: GroupWeightMetric
  readonly farmId: string
  readonly onChange: (metric: GroupWeightMetric) => void
  readonly data: any | null
  readonly position: number
}

const groupTypeMap = {
  Id: 'ID',
  NonId: 'Non-ID',
}

export const GroupWeightChart: React.FunctionComponent<GroupWeightChartProps> = ({
  metric,
  farmId,
  onChange,
  data,
  position,
}: GroupWeightChartProps) => {
  const [showSettings, setShowSettings] = useState(false)
  const [groups, setGroups] = useState([])
  const [groupMap, setGroupMap] = useState({})
  const [loadedSettings, setLoadedSettings] = useState({})
  const onAnimalTypeChange = (animalType: AnimalType): void => {
    onChange({
      ...metric,
      animalType,
      groupId: undefined,
      startDate: undefined,
      endDate: undefined,
      startWeight: undefined,
      endWeight: undefined,
      stdDev: undefined,
    })
  }

  useEffect(() => {
    getGroups(farmId, metric.animalType).then(setGroups)
  }, [farmId, metric.animalType])

  useEffect(() => {
    setGroupMap(
      Object.fromEntries(groups.map((row) => [row.id, `${groupTypeMap[row.type]} - ${row.year} - ${row.name}`])),
    )
  }, [groups])

  const chartSettings = data ? groupWeightChartSettings(metric, data) : null
  const handleSaveSettings = (metric: GroupWeightMetric): void => {
    setShowSettings(false)
    onChange(metric)
  }

  // load setting for widget when animal type changes
  useEffect(() => {
    loadGroupWeightSettings(farmId, position).then((result: GroupWeightSettings) => {
      const settings = result[metric.animalType]
      setLoadedSettings({
        ...settings,
        startDate: new Date(settings.startDate + ' 00:00:00'),
        endDate: new Date(settings.endDate + ' 00:00:00'),
      })

      // have some settings to load
      if (!_.isEmpty(settings)) {
        onChange({
          ...metric,
          groupId: settings.groupId,
          startDate: new Date(settings.startDate + ' 00:00:00'),
          endDate: new Date(settings.endDate + ' 00:00:00'),
          startWeight: settings.startWeight,
          endWeight: settings.endWeight,
          stdDev: settings.stdDev,
        })
        // no settings, reset to pristine state and require user to pick event
      } else {
        onChange({
          ...metric,
          min: undefined,
          max: undefined,
          limitLow: undefined,
          limitHigh: undefined,
          eventId: undefined,
          stdDev: undefined,
        })
      }
    })
  }, [metric.animalType, farmId])

  useEffect(() => {
    if (
      metric.startDate &&
      metric.endDate &&
      metric.groupId &&
      (metric.groupId !== loadedSettings.groupId ||
        metric.startDate.getTime() !== loadedSettings.startDate.getTime() ||
        metric.endDate.getTime() !== loadedSettings.endDate.getTime() ||
        metric.startWeight !== loadedSettings.startWeight ||
        metric.endWeight !== loadedSettings.endWeight)
    ) {
      const settings = {}
      settings[metric.animalType] = {
        groupId: metric.groupId,
        startDate: moment(metric.startDate).format('YYYY-MM-DD'),
        endDate: moment(metric.endDate).format('YYYY-MM-DD'),
        startWeight: metric.startWeight,
        endWeight: metric.endWeight,
        stdDev: metric.stdDev,
      }

      saveGroupWeightSettings(farmId, settings, position)
    }
  }, [metric.groupId, metric.startDate, metric.endDate, metric.startWeight, metric.endWeight, metric.stdDev])

  return (
    <>
      <ChartWrapper onSettingsClick={() => setShowSettings(true)}>
        <AnimalTypeSelector animalType={metric.animalType} onSelect={onAnimalTypeChange} />
        {metric.groupId && (
          <>
            <Typography variant='subtitle1' align='center'>
              {groupMap[metric.groupId]}
              {data && data.length === 3 && ' (no data)'}
            </Typography>
            {chartSettings && (
              <Chart
                chartType={chartSettings.type}
                width='100%'
                height='400px'
                options={chartSettings.options}
                data={data}
              />
            )}
          </>
        )}
        {!metric.groupId && (
          <Typography variant='subtitle1' align='center'>
            Please select a Group in Settings.
          </Typography>
        )}
      </ChartWrapper>
      <GroupWeightSettingsModal
        open={showSettings}
        handleRequestClose={() => setShowSettings(false)}
        handleSaveSettings={handleSaveSettings}
        farmId={farmId}
        metric={metric}
        groups={groups}
      />
    </>
  )
}

const ChartWrapper = ({ children, onSettingsClick }): JSX.Element => (
  <div className='dashboardChartInner'>
    {children}
    <div className='dashboardChartFooter'>
      <div></div>
      <p className='dashboardChartSettings' onClick={onSettingsClick}>
        Settings
      </p>
    </div>
  </div>
)
