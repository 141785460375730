import React from 'react'
import { useAccount } from '../../components/AccountContext'
import Layout from '../../components/Layout'
import BillingAccountsList from './BillingAccountsList'
import { requireAuth } from '../../components/withAuth'
import { useData } from '../../data/hooks'

import './BillingAccounts.css'

const processAccountsList = (accounts, farms) => {
  return accounts.map((account) => ({
    ...account,
    numFarms: farms.filter((farm) => farm.active && farm.accountId === account.id).length,
  }))
}

const BillingAccounts = () => {
  const { user } = useAccount()
  const {
    data: { accounts },
    loaded: accountsLoaded,
    refetch: refetchAccounts,
  } = useData('/accounts')
  const {
    data: { farms },
    loaded: farmsLoaded,
    refetch: refetchFarms,
  } = useData('/farms')

  if (!accountsLoaded || !farmsLoaded) {
    return null
  }

  return (
    <Layout>
      <BillingAccountsList
        user={user}
        accounts={processAccountsList(accounts, farms)}
        updateBillingAccountsList={() => {
          refetchFarms()
          refetchAccounts()
        }}
      />
    </Layout>
  )
}

export default requireAuth(BillingAccounts, { noRef: true })
