import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'

import SelectorDropdown from '../../components/SelectorDropdown'
import { DashboardMetric, DashboardMetricType, dashboardMetricTypes, TimePeriod } from '../../data/dashboard/dashboard'
import DashboardChart from './DashboardChart'

interface ChartContainerProps {
  readonly farmId: string
  readonly metrics: (DashboardMetric | null)[]
  readonly onChange: (position: number, metric: DashboardMetric | null) => void
}

const ChartContainer = ({ farmId, metrics, onChange }: ChartContainerProps): JSX.Element => (
  <div className='dashboardChartContainer'>
    {metrics.map((metric: DashboardMetric | null, position: number) => (
      <DashboardChart
        key={position}
        farmId={farmId}
        metric={metric}
        position={position}
        onChange={(metric: DashboardMetric | null) => {
          onChange(position, metric)
        }}
      />
    ))}
  </div>
)

interface MetricSelectorProps {
  readonly selected: DashboardMetricType | null
  readonly onChange: (type: DashboardMetricType | null) => void
}

const MetricSelector = ({ selected, onChange }: MetricSelectorProps): JSX.Element => (
  <div className='dashboardMetricSelectorWrapper'>
    <SelectorDropdown
      defaultOption='Select Chart'
      options={dashboardMetricTypes.map((value) => [value, value])}
      selectedOption={selected ? selected.valueOf() : null}
      handleOptionSelected={onChange}
      deselect={() => onChange(null)}
      disabled={false}
      fullWidth={true}
    />
  </div>
)

interface TimePeriodSelectorProps {
  readonly periods: readonly TimePeriod[]
  readonly selected: TimePeriod
  readonly onChange: (period: TimePeriod) => void
}

const timePeriodSelectorStyle = {
  width: '180px',
  height: '42px',
  paddingLeft: '14px',
}

const TimePeriodSelector: React.FunctionComponent<TimePeriodSelectorProps> = ({
  periods,
  selected,
  onChange,
}): JSX.Element => (
  <Select
    style={timePeriodSelectorStyle}
    disableUnderline
    value={selected}
    onChange={(event) => onChange(event.target.value as TimePeriod)}
  >
    {periods.map((period: TimePeriod) => (
      <MenuItem key={period} value={period}>
        {period}
      </MenuItem>
    ))}
  </Select>
)

const ChartPlaceholder = (): JSX.Element => {
  return (
    <div className='dashboardChartInner'>
      <Box marginTop={5} />
      <Typography variant='subtitle1' align='center'>
        Please select a metric.
      </Typography>
    </div>
  )
}

export { ChartContainer, MetricSelector, TimePeriodSelector, ChartPlaceholder }
