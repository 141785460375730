import React, { Component } from 'react'
import styled from 'styled-components'
import { maxContainerWidth, colors } from '../../styles/styleConstants'

export default class Footer extends Component {
  render() {
    const getYear = () => new Date().getFullYear()

    return (
      <StyledFooter>
        <p>© {getYear()} On Farm Innovations Ltd. All rights reserved.</p>
      </StyledFooter>
    )
  }
}

export const StorybookFooter = () => <Footer user={{ authenticated: true }} />

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${colors.white};
  padding-top: 6px;
  color: #7d7d7d;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid ${colors.lightGrey};
  z-index: 1000;

  @media (min-width: ${maxContainerWidth + 40}px) {
    padding-left: calc(50vw - ${maxContainerWidth / 2}px);
    padding-right: calc(50vw - ${maxContainerWidth / 2}px);
  }

  p {
    display: inline-block;
    font-size: 12px;
  }
  }
`
