import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { colors } from '../../styles/styleConstants'
import DefaultCell, { SimpleCell } from './Cell'
import SubRowCell, { SimpleSubRowCell } from './SubRowCell'

export const MaterialTableRow = ({ rowKey, columns, item, ...props }) => {
  let selectableColumn = false
  if (props.selectable) {
    selectableColumn = columns.find((c) => c.selectableColumn)
  }

  return (
    <TableRow style={{ ...props.style, height: '37px' }} hover tabIndex={-1} key={rowKey}>
      {columns.map((column) => {
        if (column.render) {
          return <SimpleCell>{column.render(item)}</SimpleCell>
        }

        if (column.selectableColumn) {
          return undefined
        }
        let value = round(item[column.id], column.numeric, column.decimalPoints) || (column.numeric ? '-' : '')
        const disabled = item.rowDisabled

        let color = props.totalsRow ? 'black' : null
        if (column.isDelta && value !== '-') {
          color = value >= 0 ? '#629749' : '#ea5848'
        }

        if (typeof _.get(column, 'style.color') === 'function') {
          color = column.style.color(item)
        }

        const style = {
          ...column.style,
          color,
          borderBottom: ((!props.parentRow && column.childEmpty) || props.totalsRow) && 'none',
          borderTop: props.totalsRow && '1.5px solid rgba(182, 182, 182, 1)',
        }

        if (column.id.endsWith('_trunc')) {
          return (
            <DefaultCell
              item={item}
              columnId={column.id}
              rowKey={rowKey}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              style={style}
              value={
                value && value[0] ? (
                  <Tooltip title={<div style={{ width: 200, textAlign: 'left' }}>{value && value[2]}</div>}>
                    <TableSortLabel>{value && value[1]}</TableSortLabel>
                  </Tooltip>
                ) : (
                  value && value[2]
                )
              }
              disabled={disabled}
              actions={column.overflowColumn && props.actions}
              hasSubRows={column.overflowColumn && props.hasSubRows && !item.hideOverflow}
              expanded={props.expanded}
              expandRow={props.expandRow}
              collapseRow={props.collapseRow}
            />
          )
        } else if (column.isDelta && value !== '-') {
          return (
            <DefaultCell
              item={item}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              style={style}
              value={value && `${value}% ${value > 0 ? '↑' : '↓'}`}
              disabled={disabled}
              actions={column.overflowColumn && props.actions}
              hasSubRows={column.overflowColumn && props.hasSubRows && !item.hideOverflow}
              expanded={props.expanded}
              expandRow={props.expandRow}
              collapseRow={props.collapseRow}
            />
          )
        } else if (props.parentRow && column.expandColumn) {
          return (
            <DefaultCell
              item={item}
              padding={column.disablePadding ? 'none' : 'default'}
              onClick={() => {
                if (props.parentRow) {
                  props.onClick(rowKey)
                }
              }}
              style={style}
              value={
                <ExpandButton>
                  {props.expanded ? <ChevronLeft className='chevron' /> : <ChevronRight className='chevron' />}
                </ExpandButton>
              }
              disabled={disabled}
              actions={column.overflowColumn && props.actions}
              hasSubRows={column.overflowColumn && props.hasSubRows && !item.hideOverflow}
              expanded={props.expanded}
              expandRow={props.expandRow}
              collapseRow={props.collapseRow}
            />
          )
        } else if (!props.parentRow && column.childEmpty) {
          return (
            <DefaultCell
              item={item}
              columnId={column.id}
              style={style}
              disabled={disabled}
              hasSubRows={column.overflowColumn && props.hasSubRows && !item.hideOverflow}
              expanded={props.expanded}
              expandRow={props.expandRow}
              collapseRow={props.collapseRow}
            />
          )
        } else {
          return (
            <DefaultCell
              item={item}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              style={style}
              value={column.unit ? value && applyUnit(value, column.unit) : value}
              disabled={disabled}
              actions={column.overflowColumn && props.actions}
              hasSubRows={column.overflowColumn && props.hasSubRows && !item.hideOverflow}
              expanded={props.expanded}
              expandRow={props.expandRow}
              collapseRow={props.collapseRow}
              columnId={column.id}
              rowKey={rowKey}
            />
          )
        }
      })}
      {props.selectable && (
        <DefaultCell
          item={item}
          rowKey={rowKey}
          padding='checkbox'
          value={
            selectableColumn && item[selectableColumn.id] && !_.isBoolean(item[selectableColumn.id]) ? (
              item[selectableColumn.id]
            ) : props.switches ? (
              <Switch>
                <Input
                  checked={props.selectById ? props.selected.includes(item.id) : props.selected.includes(rowKey)}
                  onClick={() => props.handleSelect(rowKey)}
                  type='checkbox'
                />

                <Slider
                  active={props.selectById ? props.selected.includes(item.id) : props.selected.includes(rowKey)}
                />
              </Switch>
            ) : (
              <Checkbox
                checked={props.selectById ? props.selected.includes(item.id) : props.selected.includes(rowKey)}
                onClick={() => props.handleSelect(rowKey)}
                disabled={
                  props.disableAllSelection ||
                  (props.disableSelectionForRows &&
                    (props.selectById
                      ? props.disableSelectionForRows.includes(item.id)
                      : props.disableSelectionForRows.includes(rowKey))) ||
                  (props.disableIndeterminate && item.indeterminate)
                }
                indeterminate={item.indeterminate}
              />
            )
          }
          actions={selectableColumn && selectableColumn.overflowColumn && props.actions}
          hasSubRows={selectableColumn && selectableColumn.overflowColumn && props.hasSubRows && !item.hideOverflow}
          expanded={props.expanded}
          expandRow={props.expandRow}
          collapseRow={props.collapseRow}
        />
      )}
    </TableRow>
  )
}

export const MaterialTableSubRow = ({ parentId, rowKey, actionRowKey, actionSubRowKey, columns, item, ...props }) => (
  <SubRow key={rowKey}>
    <SubRowContainer maxWidth={props.maxWidth}>
      {columns.map((column) => {
        if (column.render) {
          return <SimpleSubRowCell width={column.width}>{column.render(item)}</SimpleSubRowCell>
        }

        const value = round(item[column.id], column.numeric, column.decimalPoints) || (column.numeric ? '-' : '')

        let color = null
        if (typeof _.get(column, 'style.color') === 'function') {
          color = column.style.color(item)
        }

        const style = {
          ...column.style,
          color,
        }

        return (
          <SubRowCell
            key={Math.random()}
            parentId={parentId}
            item={item}
            column={column}
            padding={column.disablePadding ? 'none' : 'default'}
            rowKey={actionRowKey}
            subRowKey={actionSubRowKey}
            disabled={item.rowDisabled}
            value={value}
            actions={column.overflowColumn && props.actions}
            style={style}
          />
        )
      })}
    </SubRowContainer>
  </SubRow>
)

const SubRow = styled.div`
  display: flex;
  height: 49px;
  font-size: 13px;
`

const SubRowContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: ${(props) => props.maxWidth || '1148px'};
  height: 49px;
  display: flex;
`

export const ExpandableTableRow = ({ key, columns, parentRow, childRows, expanded, toggle }) => {
  let rows = childRows.map((item, subkey) =>
    MaterialTableRow({
      rowKey: `${key}${subkey}`,
      columns,
      item,
      style: { display: expanded ? 'table-row' : 'none' },
    }),
  )

  rows.unshift(
    MaterialTableRow({
      rowKey: key,
      columns,
      item: parentRow,
      parentRow: true,
      expanded,
      onClick: toggle,
    }),
  )

  return rows
}

const round = (data, numeric, decimalPoints) => {
  if (data && numeric && _.isFinite(decimalPoints)) {
    return parseFloat(data.toFixed(decimalPoints))
  }

  return data
}

export const applyUnit = (data, unit) => {
  if (data === null || data === undefined) {
    return data
  }

  if (unit && data !== '-') {
    if (typeof unit === 'object' && unit.unit === '$') {
      return data.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD', minimumFractionDigits: unit.digits })
    }
    if (unit === '$') {
      return data.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD', minimumFractionDigits: 2 })
    }
    return data.toLocaleString('en-NZ') + unit
  }

  return data.toLocaleString('en-NZ')
}

const ExpandButton = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    color: purple;
    height: 24px;
    position: absolute;
    top: -10px;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
  }
`

const Switch = styled.label`
  display: inline-block;
  height: 24px;
  position: relative;
  width: 48px;
`

const Input = styled.input`
  display: none;
`

const Slider = styled.div`
  background-color: ${(props) => (props.active ? colors.green : colors.grey)};
  border: 1px solid #98a5ac;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  position: absolute;
  right: 0;
  top: 0;
  :before {
    background-color: #f9fdff;
    border: 1px solid #98a5ac;
    border-radius: 50%;
    content: '';
    height: 18px;
    margin-top: 2px;
    margin-left: 3px;
    position: absolute;
    transition: 0.2s;
    width: 18px;
    z-index: 1;
    ${(props) =>
      props.active &&
      css`
        transform: translateX(22px);
      `}
  }
`
