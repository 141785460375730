import React from 'react'
import MaterialTable from '../../../components/Table/Table'
import _ from 'lodash'
import moment from 'moment'

const formatData = (data, expandedRows, startDate, currency) => {
  let rows = []
  data.forEach((t, key) => {
    rows.push({
      ..._.pick(t, ['id', 'name']),
      ..._.fromPairs(
        t.forecast.map((v, k) => [
          startDate
            .clone()
            .add(k, 'months')
            .format('YYYY-M'),
          v.toLocaleString('en-NZ', {
            style: currency ? 'currency' : 'decimal',
            currency: 'NZD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        ]),
      ),
      hideOverflow: t.id === 'totals',
      rowKey: key,
    })
    if (expandedRows && expandedRows.includes(t.id) && t.events) {
      rows.push({
        date: 'Event Date',
        name: 'Event Name',
        class: 'Event Plan - Class',
        animals: 'Animal #',
        totalWeight: 'Total Wt. kg',
        dose: 'Dose',
        doseType: 'Dose Type',
        eventQuantity: 'Event Quantity',
        parentId: t.id,
        subRowKey: 0,
        isSubRow: true,
      })
      t.events.forEach((e, subKey) => {
        rows.push({
          ...e,
          parentId: t.id,
          rowKey: key,
          subRowKey: subKey + 1,
          isSubRow: true,
        })
      })
    }
  })

  return rows
}

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = { expandedRows: [] }
  }

  render() {
    const { data, startMonth, startYear, currency } = this.props
    const { expandedRows } = this.state

    const startDate = moment(`${startYear}-${startMonth}`, 'YYYY-M')
    const baseColumns = [{ id: 'name', label: 'Treatment', style: { minWidth: '200px' } }]

    _.times(12).forEach((n) => {
      const month = startDate.clone().add(n, 'months')
      baseColumns.push({ id: month.format('YYYY-M'), label: month.format('MMM YY'), style: { minWidth: '85px' } })
    })

    const columns = baseColumns.map((column) => ({
      ...column,
      style: { ...column.style, whiteSpace: 'nowrap' },
    }))

    columns.splice(1, 0, { id: 'expand', overflowColumn: true })

    const subRowColumns = [
      { id: 'date', style: { width: '130px' } },
      { id: 'name', style: { width: '220px' } },
      { id: 'class', style: { width: '180px' } },
      { id: 'animals', style: { width: '100px' } },
      { id: 'totalWeight', style: { width: '150px', flexGrow: 1 } },
      { id: 'dose', style: { width: '100px' } },
      { id: 'doseType', style: { width: '150px' } },
      { id: 'eventQuantity', style: { width: '150px' } },
    ].map((column) => ({
      ...column,
      style: { alignItems: 'flex-start', flexGrow: 1, ...column.style },
    }))

    subRowColumns.push({ id: 'expand', style: { flexGrow: 1, minWidth: '32px' } })

    return (
      <MaterialTable
        columns={columns}
        data={formatData(data, expandedRows, startDate, currency)}
        hasSubRows
        subRowColumns={subRowColumns}
        expandedRows={expandedRows}
        expandRow={(id) => this.setState({ expandedRows: expandedRows.concat(id) })}
        collapseRow={(id) => {
          _.remove(expandedRows, (r) => r === id)
          this.setState({ expandedRows })
        }}
        subRowMaxWidth='unset'
      />
    )
  }
}
