import { sleep } from '../../helpers/utils'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings, processFakeData } from './dashboard'

export const AnimalCountMetric: DashboardMetric = {
  type: 'Animal Count',
  animalType: AnimalType.Sheep,
  timePeriod: 'Last 3 Years',
}

export const AnimalCountChart: DashboardMetricChartSettings = {
  type: 'LineChart',
  options: {
    chartArea: { width: '80%', height: '80%' },
    legend: 'top',
    interpolateNulls: true,
  },
}

export async function calculateAnimalCount(farmId: string, metric: DashboardMetric): Promise<[[]]> {
  sleep(1000)
  return processFakeData(input)
}

const input = `Date,Lamb,Hogget,2 Tooth,Mixed Age,Total
May-16,,175,166,700,1041
Jun-16,,,,,
Jul-16,,172,165,693,1029
Aug-16,,171,164,658,993
Sep-16,1749,170,163,655,2737
Oct-16,,,,,
Nov-16,,,,,
Dec-16,1697,168,161,649,2675
Jan-17,333,167,161,519,1180
Feb-17,283,166,160,516,1126
Mar-17,245,166,159,515,1085
Apr-17,,244,165,673,
May-17,,243,164,670,1076
Jun-17,,,,,
Jul-17,,238,162,663,1063
Aug-17,,237,161,630,1028
Sep-17,1808,235,161,627,2830
Oct-17,,,,,
Nov-17,,,,,
Dec-17,1753,233,159,621,2766
Jan-18,332,232,158,497,1219
Feb-18,282,231,157,494,1164
Mar-18,234,230,157,493,1114
Apr-18,,233,228,649,
May-18,,232,227,646,1105
Jun-18,,,,,
Jul-18,,228,225,639,1092
Aug-18,,226,224,607,1057
Sep-18,1856,225,223,604,2908
Oct-18,,,,,
Nov-18,,,,,
Dec-18,1800,223,220,598,2842
Jan-19,331,222,219,479,1251
Feb-19,281,221,218,476,1197
Mar-19,226,220,217,475,1138
Apr-19,,225,219,691,
May-19,,224,217,688,1129
Jun-19,,,,,
Jul-19,,219,215,681,1116
,,,,,
,,,,,
Date,Calf,R1,R2,Mixed Age,Total
May-16,,26,25,105,156
Jun-16,,,,,
Jul-16,,26,25,104,154
Aug-16,,26,25,99,149
Sep-16,262,25,24,98,411
Oct-16,,,,,
Nov-16,,,,,
Dec-16,255,25,24,97,401
Jan-17,50,25,24,78,177
Feb-17,43,25,24,77,169
Mar-17,37,25,24,77,163
Apr-17,,37,25,101,
May-17,,36,25,100,161
Jun-17,,,,,
Jul-17,,36,24,99,159
Aug-17,,35,24,95,154
Sep-17,271,35,24,94,425
Oct-17,,,,,
Nov-17,,,,,
Dec-17,263,35,24,93,415
Jan-18,50,35,24,74,183
Feb-18,42,35,24,74,175
Mar-18,35,34,24,74,167
Apr-18,,35,34,97,
May-18,,35,34,97,166
Jun-18,,,,,
Jul-18,,34,34,96,164
Aug-18,,34,34,91,159
Sep-18,278,34,33,91,436
Oct-18,,,,,
Nov-18,,,,,
Dec-18,270,33,33,90,426
Jan-19,50,33,33,72,188
Feb-19,42,33,33,71,179
Mar-19,34,33,33,71,171
Apr-19,,34,33,104,
May-19,,34,33,103,169
Jun-19,,,,,
Jul-19,,33,32,102,167`
