import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'

import { getFilteredAnimalInfo, getMetrics, getResource } from '../../data/analysis-api'
import Filters from './Filters'
import ViewList from './ViewList'
import { metricToApiName, apiNameToMetric, metricToUnit } from './MetricsInfo'
import { filterToApiName, filterToDbValueRegex, filterToDbValueMap, idFilters, DateRangeString } from './FilterInfo'
import { colors } from '../../styles/styleConstants'
import Metrics from './Metrics'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import MultiFarmSelector from '../../components/MultiFarmSelector'
import LoadingContainer from '../../components/LoadingContainer'

class NoFarmSelectedError extends Error {
  constructor(props) {
    super(props)
    this.name = 'NoFarmSelectedError'
  }
}

const API_ERROR_MESSAGE = "There was an error retrieving the data. The team has been notified and we're working on it"
const NO_FARM_SELECTED_MESSAGE = 'Please select at least one farm.'

class Scenario extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewListDialogOpen: false,
      snackbarOpen: false,
      snackbarContent: '',
      loading: false,
    }

    this.onClickViewListButton = this.onClickViewListButton.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate() {
    this.refresh()
  }

  getResource = (resource, type, animalType) => {
    const req = { farmIds: this.props.selectedFarms.map((f) => f.id) }
    if (type) {
      req.type = type
    }
    if (animalType) {
      req.animalType = animalType
    }
    return getResource(resource, req)
  }

  convertFilters = () => {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters))
    const filterApiObj = {}
    for (const filter in filtersCopy) {
      const apiName = filterToApiName[filter] ? filterToApiName[filter] : filter.replace(/\s/g, '')
      filterApiObj[apiName] = filtersCopy[filter]

      if (apiName === 'ConditionScore') {
        filterApiObj[apiName] = this.convertConditionScoreFilter(filtersCopy[filter])
      } else {
        filterApiObj[apiName] = filtersCopy[filter]
      }

      for (const f in filterApiObj[apiName]) {
        if (filterToDbValueRegex[apiName]) {
          const values = filterApiObj[apiName][f] || []
          for (let i = 0; i < values.length; i++) {
            values[i] = values[i].replace(filterToDbValueRegex[apiName], '')
          }
        }
        if (filterToDbValueMap[apiName]) {
          const values = filterApiObj[apiName][f] || []
          for (let i = 0; i < values.length; i++) {
            values[i] = filterToDbValueMap[apiName][values[i]]
          }
        }
        if (f === 'date') {
          this.convertDate(filterApiObj[apiName].date, filterApiObj[apiName])
        }
        if (idFilters.has(filter) && f == 'value') {
          filterApiObj[apiName].value = Object.keys(filterApiObj[apiName].value)
        }
      }
    }
    return { ...filterApiObj, animalType: this.props.animalType ? [this.props.animalType] : undefined }
  }

  // FIXME temporary measure until the API is fixed
  // Adds non-decimal pairs, e.g. ['1.0', '1.5'] gets transformed to ['1.0', '1.5', '1']
  convertConditionScoreFilter = (filter) => {
    const copy = JSON.parse(JSON.stringify(filter))
    const additions = copy.value
      .filter((value) => {
        let number = parseFloat(value)
        return Number.isInteger(number)
      })
      .map((value) => {
        return value.split('.')[0]
      })
    return {
      ...filter,
      value: filter.value.concat(additions),
    }
  }

  convertDate = (date, target) => {
    if (date.date) {
      target['date'] = date.date
      delete target.date.date
    } else if (date.current === true) {
      delete target.date
      target.useLatest = true
    } else {
      target.date = DateRangeString
      delete date.current
      target.dateRange = date
    }
  }

  convertMetrics = () => {
    const metricsCopy = JSON.parse(JSON.stringify(this.props.metrics))
    const metricApiObj = {}
    for (const metric in metricsCopy) {
      const apiName = metricToApiName[metric] ? metricToApiName[metric] : metric.replace(/\s/g, '')
      metricApiObj[apiName] = metricsCopy[metric]

      if (metricApiObj[apiName].date) {
        this.convertDate(metricApiObj[apiName].date, metricApiObj[apiName])
      }

      if (apiName === 'PostBirthSurvivalRate' || apiName === 'PreBirthSurvivalRate') {
        metricApiObj[apiName]['startEvents'] = Object.keys(metricApiObj[apiName]['startEvents'])
        metricApiObj[apiName]['endEvents'] = Object.keys(metricApiObj[apiName]['endEvents'])
      }
    }
    return metricApiObj
  }

  getAnimalInfo = (resource) => {
    const filterApiObj = this.convertFilters()

    if (this.props.selectedFarms.length === 0) {
      return Promise.reject(new NoFarmSelectedError('No farm selected'))
    }
    return getFilteredAnimalInfo(resource, {
      farmIds: this.props.selectedFarms.map((f) => f.id),
      filters: filterApiObj,
    })
  }

  refresh = async () => {
    if (
      !this.state.loading &&
      (this.props.selectedFarms !== this.state.selectedFarms ||
        this.props.filters !== this.state.filters ||
        this.props.metrics !== this.state.metrics ||
        this.props.animalType !== this.state.animalType)
    ) {
      this.setState({
        selectedFarms: this.props.selectedFarms,
        filters: this.props.filters,
        metrics: this.props.metrics,
        animalType: this.props.animalType,
      })

      // api doesn't give any data until at least animal type is selected
      if (!this.props.animalType) {
        this.props.setScenarioState({ count: undefined })
        return
      }

      const promises = []

      // if both count and metrics need to be refetched, do them in parallel (this is async)
      if (_.keys(this.props.metrics).length !== 0) {
        promises.push(this.updateMetrics())
      }

      promises.push(this.updateCount())

      const handle = setTimeout(() => this.setState({ loading: true }), 500)

      Promise.all(promises).then(() => {
        clearTimeout(handle)
        this.setState({ loading: false })
      })
    }
  }

  updateCount = async () => {
    try {
      const result = await this.getAnimalInfo('count')

      if (!result.error) {
        this.props.setScenarioState({ count: result.count })
      }
    } catch (error) {
      console.error(error.message)
      this.props.setScenarioState({ count: undefined })
      this.setState({
        snackbarOpen: true,
        snackbarContent: error.name === 'NoFarmSelectedError' ? NO_FARM_SELECTED_MESSAGE : API_ERROR_MESSAGE,
      })
    }
  }

  updateMetrics = async () => {
    const filterApiObj = this.convertFilters()
    const metricApiObj = this.convertMetrics()

    try {
      const metrics = await getMetrics({
        farmIds: this.props.selectedFarms.map((f) => f.id),
        filters: filterApiObj,
        metrics: metricApiObj,
      })

      const metricsObj = {}
      for (const metric in metrics) {
        const metricName = apiNameToMetric[metric] ? apiNameToMetric[metric] : metric.split(/(?=[A-Z])/).join(' ')
        metricsObj[metricName] = metrics[metric]
        metricsObj[metricName].unit = metricToUnit[metricName] ? metricToUnit[metricName] : ''
      }
      this.props.setScenarioState({ metricsValues: metricsObj })
    } catch (error) {
      console.error(error.message)
      this.setState({
        snackbarOpen: true,
        snackbarContent: API_ERROR_MESSAGE,
      })
    }
  }

  handleDeleteFilter = (filterName) => {
    console.info('delete filter', filterName)
    let filters = JSON.parse(JSON.stringify(this.props.filters))
    delete filters[filterName]
    this.props.setScenarioState({
      filters: filters,
    })
  }

  handleAddEditFilter = (filter, editingFilterName) => {
    if (editingFilterName === null) {
      this.handleAddFilter(filter)
    } else {
      this.handleEditFilter(filter, editingFilterName)
    }
  }

  handleAddFilter = (filter) => {
    console.info('add filter', filter.name, filter.data)
    this.props.setScenarioState({
      filters: { ...this.props.filters, [filter.name]: filter.data },
    })
  }

  handleEditFilter = (filter, originalFilterName) => {
    console.info('edit filter', filter.name, filter.data)
    let filters = JSON.parse(JSON.stringify(this.props.filters))
    delete filters[originalFilterName]
    this.props.setScenarioState({
      filters: { ...filters, [filter.name]: filter.data },
    })
  }

  handleAddMetric = (metric) => {
    this.props.setScenarioState({
      metrics: { ...this.props.metrics, [metric.name]: metric.data },
    })
  }

  handleDeleteMetric = (metricName) => {
    let metrics = JSON.parse(JSON.stringify(this.props.metrics))
    delete metrics[metricName]
    this.props.setScenarioState({
      metrics: metrics,
    })
  }

  onClickViewListButton = async () => {
    const history = this.props.history
    const animalType = this.props.animalType

    if (this.props.selectedFarms.length) {
      this.props.saveState()
      history.push({
        pathname: '/analysisviewlist',
        appState: {
          animalType: animalType,
          filters: this.convertFilters(),
          farmIds: this.props.selectedFarms.map((f) => f.id),
        },
      })
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarContent: NO_FARM_SELECTED_MESSAGE,
      })
    }
  }

  render() {
    return (
      <LoadingContainer loading={this.state.loading}>
        <SectionTitle>Filters</SectionTitle>
        <MultiFarmSelector
          farms={this.props.farms}
          selectedFarms={this.props.selectedFarms}
          handleFarmSelected={this.props.handleFarmSelected}
          handleFarmDeselected={this.props.handleFarmDeselected}
          disabled={false}
        />
        <Filters
          disabled={this.props.selectedFarms.length === 0}
          filters={this.props.filters}
          reset={() => this.props.setScenarioState({ filters: {} })}
          animalType={this.props.animalType}
          selectAnimalType={(animalType) => {
            this.props.setScenarioState({ animalType, filters: {}, metrics: {} })
          }}
          addEditFilter={this.handleAddEditFilter}
          deleteFilter={this.handleDeleteFilter}
          getResource={this.getResource}
        />
        <ViewList onClickViewList={this.onClickViewListButton} count={this.props.count} />
        <SectionTitle>Metrics</SectionTitle>
        <Metrics
          metrics={this.props.metrics}
          metricsValues={this.props.metricsValues}
          resetButtonOnClick={() => this.props.setScenarioState({ metrics: {} })}
          animalType={this.props.animalType}
          addMetric={this.handleAddMetric}
          deleteMetric={this.handleDeleteMetric}
          getResource={this.getResource}
        />
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={() => this.setState({ snackbarOpen: false })}
          message={this.state.snackbarContent}
        />
      </LoadingContainer>
    )
  }
}

export default withRouter(Scenario)

const SectionTitle = styled.div`
  color: #555555;
  font-size: 18px;
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 16px;
  padding-left: 12px;
`
