import React from 'react'
import AccountDropdown, { MobileAccountDropdown } from './AccountDropdown'
import styled from 'styled-components'
import { colors } from '../../styles/styleConstants'

class Authenticated extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: undefined,
      open: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  handleClick(event) {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleRequestClose() {
    this.setState({ open: false })
  }

  render() {
    return (
      <div>
        <div className='hide-on-mobile'>
          <AccountDropdown username={this.props.user.email} storybook={this.props.storybook} path={this.props.path} />
        </div>
        <div className='hide-on-desktop'>
          <MobileAccountDropdown
            username={this.props.user.email}
            storybook={this.props.storybook}
            path={this.props.path}
          />
        </div>
      </div>
    )
  }
}

const Anonymous = () => (
  <AnonymousUser>
    {/* <Link to='/sign-up'>
      <Button className='signupLink'>Sign up</Button>
    </Link> */}
  </AnonymousUser>
)

export default ({ user, ...props }) => {
  return (
    <UserStatus>
      {!props.storybook && (user.authenticated ? <Authenticated user={user} path={props.path} /> : <Anonymous />)}
      {props.storybook && <Authenticated user={user} path={props.path} storybook />}
    </UserStatus>
  )
}

const UserStatus = styled.div`
  flex: 0;

  .icon,
  .user {
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px 0 0;
  }
`

const AnonymousUser = styled.div`
  button {
    margin: 0 0 0 15px;

    &.signupLink {
      background: ${colors.green};
      color: ${colors.white};
      &:hover {
        background: ${colors.darkGreen};
      }
    }
  }
`
