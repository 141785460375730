import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'

import { PushButton } from '../../components/Form/Form'
import { PageTitle, ActionHeader, ButtonContainer } from '../../components/PageHeaders'
import FarmSelector from '../../components/FarmSelector'
import AnimalCategoriesTable from './AnimalCategoriesTable'
import TransmissionTable from './TransmissionTable'
import ReceiverAssignmentTable from './ReceiverAssignmentTable'
import Assignment from './Assignment'
import NaitInfoDialog from './NaitInfoDialog'
import NoNaitCredsDialog from './NoNaitCredsDialog'
import WarningDialog from '../../components/Dialog/WarningDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

import _ from 'lodash'
import queryString from 'query-string'

import { getAllFarmsForUser } from '../../data/farm'
import {
  getNaitCredentialsForFarm,
  getCurrentNaitForFarm,
  postNaitForFarm,
  getNaitHistoryForFarm,
  deleteCountsForAnimalStatus,
} from '../../data/nait'

const StyledLoader = styled(CircularProgress)`
  margin-top: 20px;
`

class NaitReporting extends React.Component {
  constructor(props) {
    super(props)

    const queryParams = queryString.parse(props.location.search)

    this.state = {
      infoDialogOpen: true,
      assignmentData: {},
      selectedFarmId: queryParams.farmId,
      loading: false,
      farmNaitData: {},
    }

    this.handleFarmSelected = this.handleFarmSelected.bind(this)
    this.sendToNait = this.sendToNait.bind(this)
    this.assign = this.assign.bind(this)
    this.removeCounts = this.removeCounts.bind(this)
  }

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {
    const farms = (await getAllFarmsForUser(this.props.user.uid)).filter((f) =>
      ['Account', 'Farm'].includes(f.userRole),
    )
    this.setState({
      farms,
      selectedFarmName: this.props.match.params.farmId
        ? farms.find((farm) => farm.id === this.props.match.params.farmId).farmName
        : null,
    })

    if (this.state.selectedFarmId) {
      this.setState({
        redirect: { farmId: this.state.selectedFarmId },
      })
    }

    if (this.props.match.params.farmId) {
      this.handleFarmSelected(this.props.match.params.farmId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirectViewingAssingment && _.last(this.props.location.pathname.split('/')) === 'complete') {
      this.setState({ redirectViewingAssingment: false })
    }

    if (prevState.redirectAssigning && _.last(this.props.location.pathname.split('/')) === 'assign') {
      this.setState({ redirectAssigning: false })
    }

    if (prevState.redirectMain) {
      this.getInitialData()
      this.setState({ redirectMain: false })
    }

    if (prevState.redirect) {
      this.setState({ redirect: false })
    }
  }

  currentFarmId() {
    return this.props.match.params.farmId
  }

  currentNaitData() {
    return this.state.farmNaitData[this.currentFarmId()]
  }

  setFarmNaitData(farmId, data) {
    this.setState((current) => ({ farmNaitData: { ...current.farmNaitData, [farmId]: data } }))
  }

  getData(farmId) {
    getCurrentNaitForFarm(farmId).then((data) => this.setFarmNaitData(farmId, data))
    getNaitHistoryForFarm(farmId).then((data) => this.setState({ naitHistoryData: data }))
  }

  async handleFarmSelected(farmId, farmName = undefined) {
    const creds = await getNaitCredentialsForFarm(farmId)
    const { naitNumber } = this.state.farms.find((f) => f.id === farmId)

    if (creds && creds.username && naitNumber) {
      this.setState({
        selectedFarmName: farmName || this.state.farms.find((f) => f.id === farmId).farmName,
        selectedFarmNAITNumber: naitNumber,
        redirect: { farmId },
      })

      this.getData(farmId)
    } else {
      this.setState({ noCredsDialogOpen: true })
    }
  }

  async removeCounts(status, animalType) {
    const { farmId } = this.props.match.params
    const result = await deleteCountsForAnimalStatus(farmId, status, animalType)

    if (result.success) {
      this.setFarmNaitData(farmId, result.res)
    }
  }

  sendToNait() {
    if (
      _.isEmpty(this.state.assignmentData) &&
      this.currentNaitData().receiverRequired.filter((animal) => !animal.assigned).length > 0
    ) {
      this.setState({ redirectViewingAssingment: true })
    } else {
      this.setState({ loading: true })

      postNaitForFarm(this.props.match.params.farmId, this.state.assignmentData).then((data) => {
        if (data.success) {
          // TODO: why doesn't this work without a refecth?
          // Test initial rerender
          this.setFarmNaitData(data.res)
          this.setState({
            assignmentData: {},
            loading: false,
          })
          this.getData(this.props.match.params.farmId)
        } else {
          this.getData(this.props.match.params.farmId)
          let message = data.data.summary
          if (message === 'Access is denied.') {
            message += ' Please check the NAIT credentials for this farm.'
          }

          this.setState({
            warningDialogOpen: true,
            warning: {
              title: 'Failed',
              message,
            },
            assignmentData: {},
            loading: false,
          })
        }
      })

      this.setState({ redirectMain: true })
    }
  }

  assign(data) {
    let updatedReceiverRequired
    if (_.has(data, 'thirdParty')) {
      updatedReceiverRequired = this.currentNaitData().receiverRequired.map((animal) => ({
        ...animal,
        assigned: animal.assigned || data.thirdParty.includes(animal.eid),
      }))
    } else if (_.has(data, 'self')) {
      updatedReceiverRequired = this.currentNaitData().receiverRequired.map((animal) => ({
        ...animal,
        assigned: animal.assigned || _.values(data.self)[0].includes(animal.eid),
      }))
    }

    this.setState({
      assignmentData: _.merge(this.state.assignmentData, data),
      farmNaitData: {
        ...this.farmNaitData,
        [this.currentFarmId()]: {
          ...this.currentNaitData(),
          receiverRequired: updatedReceiverRequired,
        },
      },
    })
  }

  render() {
    const { farmId } = this.props.match.params

    if (this.state.redirectFarms && this.props.location.pathname !== '/farms') {
      return <Redirect to='/farms' />
    }

    if (
      this.state.redirect &&
      this.state.redirect.farmId &&
      this.props.location.pathname.split('/')[2] !== this.state.redirect.farmId
    ) {
      return <Redirect to={`/nait/${this.state.redirect.farmId}`} />
    }

    if (this.state.redirectViewingAssingment && _.last(this.props.location.pathname.split('/')) !== 'complete') {
      return <Redirect to={`/nait/${farmId}/complete`} />
    }
    if (this.state.redirectAssigning && _.last(this.props.location.pathname.split('/')) !== 'assign') {
      return <Redirect to={`/nait/${this.props.match.params.farmId}/assign`} />
    }
    if (this.state.redirectMain && this.props.location.pathname !== '/nait') {
      return <Redirect to={`/nait/${farmId}`} />
    }

    return (
      <Layout>
        <Route
          path='/nait/:farmId/complete'
          render={() => {
            return (
              <ReceiverAssignmentTable
                data={this.currentNaitData() && this.currentNaitData().receiverRequired}
                assignmentData={this.state.assignmentData}
                sendToNait={this.sendToNait}
                goToAssignment={(data) => {
                  this.setState({
                    redirectViewingAssingment: false,
                    redirectAssigning: true,
                    assigningData: data.filter((animal) => !animal.assigned),
                  })
                }}
              />
            )
          }}
        />
        <Route
          path='/nait/:farmId/assign'
          render={() => (
            <Assignment
              goBack={() =>
                this.setState({
                  redirectAssigning: false,
                  redirectViewingAssingment: true,
                })
              }
              reset={() => this.setState({ redirectMain: true })}
              title={this.state.assignmentTitle}
              data={this.state.assigningData}
              assign={this.assign}
              nameAndNait={`${this.state.selectedFarmName} - ${this.state.selectedFarmNAITNumber}`}
            />
          )}
        />
        <Route
          exact
          path='/nait/:farmId?'
          render={() => {
            const canSubmit =
              this.currentNaitData() &&
              this.currentNaitData().data &&
              this.currentNaitData().data.find((row) => row.numCattle > 0 || row.numDeer > 0)
            return (
              <div>
                <PageTitle>NAIT Reporting</PageTitle>
                <ActionHeader>
                  <FarmSelector
                    user={this.props.user}
                    farms={this.state.farms}
                    handleFarmSelected={this.handleFarmSelected}
                    selectedFarmName={this.state.selectedFarmName}
                    farmId={this.props.match.params.farmId}
                    sendToNait={this.sendToNait}
                    canSubmit={canSubmit}
                  />
                  <ButtonContainer align='left'>
                    {this.props.match.params.farmId && this.props.match.params.farmId.length > 0 && (
                      <>
                        <Box marginLeft={3} />
                        <PushButton disabled={!canSubmit} onClick={this.sendToNait}>
                          Send to Nait
                        </PushButton>
                      </>
                    )}
                  </ButtonContainer>
                </ActionHeader>
                {this.state.loading && <StyledLoader className='loader' />}
                {!this.state.loading && this.props.match.params.farmId && this.currentNaitData() && (
                  <AnimalCategoriesTable data={this.currentNaitData().data} removeCounts={this.removeCounts} />
                )}
                {!this.state.loading && this.props.match.params.farmId && this.state.naitHistoryData && (
                  <TransmissionTable
                    data={this.state.naitHistoryData.reports}
                    farmId={this.props.match.params.farmId}
                  />
                )}
              </div>
            )
          }}
        />
        <NaitInfoDialog
          open={this.state.infoDialogOpen}
          handleRequestClose={() => this.setState({ infoDialogOpen: false })}
        />
        <NoNaitCredsDialog
          open={this.state.noCredsDialogOpen}
          handleRequestClose={() =>
            this.setState({
              selectedFarmId: null,
              noCredsDialogOpen: false,
            })
          }
          goToFarms={() => this.setState({ redirectFarms: true })}
        />
        <WarningDialog
          open={this.state.warningDialogOpen}
          warning={this.state.warning}
          handleRequestClose={() =>
            this.setState({
              warningDialogOpen: false,
            })
          }
        />
      </Layout>
    )
  }
}

export default requireAuth(NaitReporting)
