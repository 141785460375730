import React from 'react'
import { withFormValidation, TextField, PostalCodeField, SubmitButton } from '../../../components/Form/Form'
import { CompanyNameField, AddressField, RegionField } from '../../../components/Form/CustomFields.js'
import { saveBillingAccount } from '../../../data/billing'
import { requireAuth } from '../../../components/withAuth'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from '../../../components/Dialog/DialogHeader'

import '../../../components/Dialog/Dialog.css'

const EditBillingAccountFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <CompanyNameField name='businessName' label='Business Name' value={props.businessName} required />
    </div>
    <div className='FormRow'>
      <PostalCodeField name='postalCode' label='Postal Code' value={props.postalCode} required />
    </div>
    <div className='FormRow'>
      <AddressField name='address1' label='Address Line 1' value={props.address1} required />
    </div>
    <div className='FormRow'>
      <AddressField name='address2' label='Address Line 2' value={props.address2} />
    </div>
    <div className='FormRow'>
      <TextField name='city' label='City' value={props.city} required />
    </div>
    <div className='FormRow'>
      <RegionField name='region' label='Select Region' value={props.region} required />
    </div>
    {/* <div className='FormRow'>
      <CountryField name='country' label='Select Country' value={props.country} required />
    </div> */}
    <div className='ButtonWrap'>
      <Button className='Back dialogBack' onClick={props.handleRequestClose}>
        Cancel
      </Button>
      <SubmitButton disabled={!props.canSubmit || !props.hasChanged}>Save Changes</SubmitButton>
    </div>
  </div>
)

const EditBillingAccountForm = withFormValidation(EditBillingAccountFormContent)

class EditBillingAccountDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
      hasChanged: false,
    }
  }

  validate(data) {
    const errors = {}
    if (
      data.businessName !== this.props.account.businessName ||
      data.contactName !== this.props.account.contactName ||
      data.address1 !== this.props.account.address1 ||
      data.address2 !== this.props.account.address2 ||
      data.region !== this.props.account.region ||
      data.postalCode !== this.props.account.postalCode ||
      data.country !== this.props.account.country
    ) {
      this.setState({
        validationErrors: errors,
        hasChanged: true,
      })
    } else {
      this.setState({
        validationErrors: errors,
        hasChanged: false,
      })
    }
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
      hasChanged: false,
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.handleRequestClose()
      }
    }
    saveBillingAccount(this.props.account.id, data).then(onSave)
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Edit Billing Account' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <EditBillingAccountForm
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            businessName={this.props.account.businessName}
            address1={this.props.account.address1}
            address2={this.props.account.address2}
            city={this.props.account.city}
            region={this.props.account.region}
            postalCode={this.props.account.postalCode}
            country={this.props.account.country}
            hasChanged={this.state.hasChanged}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(EditBillingAccountDialog)
