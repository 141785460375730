import React from 'react'
import { useAccount } from '../../components/AccountContext'
import { useData } from '../../data/hooks'
import Layout from '../../components/Layout'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import { requireAuth } from '../../components/withAuth'
import { SUBSCRIPTION_STATUSES } from '../../data/billing'
import { disableFarm, enableFarm } from '../../data/farm'
import FarmList from '../Farms/FarmList'
import './Farms.css'

class Farms extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      snackbarContent: null,
    }
  }

  billingAccountChoices = () =>
    this.props.accounts
      .filter((account) => account.subscriptionStatus.toLowerCase() === SUBSCRIPTION_STATUSES.VALID.toLowerCase())
      .map((account) => [account.id, account.businessName])

  toggleFarm = async (farmId, active) => {
    try {
      if (active) {
        await disableFarm(farmId)
      } else {
        await enableFarm(farmId)
      }
      this.props.refetch()
    } catch (error) {
      console.log('Error toggling farm', error)
    }
  }

  showSnackbar = (message) => {
    this.setState({
      snackbarContent: message,
    })
  }

  handleRequestCloseSnackbar = () => {
    this.setState({
      snackbarContent: null,
    })
  }

  getCurrentAccount = () => {
    const { accountId } = this.props.match.params

    if (accountId) {
      return this.props.accounts.filter((a) => String(a.id) === String(accountId))[0]
    }

    return null
  }

  getFilteredFarms = () => {
    const account = this.getCurrentAccount()

    if (account) {
      return this.props.farms.filter((f) => String(f.accountId) === String(account.id))
    }

    return this.props.farms
  }

  render() {
    const loaded = this.props.user && this.props.farms

    return (
      <Layout loaded={loaded}>
        {loaded && (
          <FarmList
            user={this.props.user}
            farms={this.getFilteredFarms()}
            billingAccountChoices={this.billingAccountChoices()}
            isAccount={this.props.accounts.length}
            allAccounts={this.props.accounts}
            updateFarmsList={() => this.props.refetch()}
            showSnackbar={this.showSnackbar}
            toggleFarm={this.toggleFarm}
            currentAccount={this.getCurrentAccount()}
            accounts={this.props.accounts}
            canAddFarm={this.props.canAddFarm}
          />
        )}
        <BottomSnackbar
          open={this.state.snackbarContent}
          handleRequestClose={this.handleRequestCloseSnackbar}
          message={this.state.snackbarContent}
        />
      </Layout>
    )
  }
}

export default requireAuth(
  (props) => {
    const { uid, user } = useAccount()
    const {
      data: { accounts },
      loaded: accountsLoaded,
    } = useData('/accounts')
    const {
      data: { farms },
      loaded: farmsLoaded,
      refetch,
    } = useData('/farms')

    if (!accountsLoaded || !farmsLoaded) {
      return null
    }

    const processedAccounts = accounts.map((a) => ({ ...a, isOwner: a.userId === uid }))
    const vetAccountRoles = ['Clinic', 'Admin']
    const standardAccountRoles = ['Account']
    const canAddFarm = !!processedAccounts.filter(
      (a) =>
        a.isOwner ||
        (a.accountType === 'Vet' && vetAccountRoles.indexOf(a.accountRole) !== -1) ||
        (a.accountType === 'Standard' && standardAccountRoles.indexOf(a.accountRole) !== -1),
    ).length
    return (
      <Farms
        {...props}
        accounts={processedAccounts}
        farms={farms}
        user={user}
        refetch={refetch}
        canAddFarm={canAddFarm}
      />
    )
  },
  {
    noRef: true,
  },
)
