import * as React from 'react'
import { useState } from 'react'
import { Chart } from 'react-google-charts'

import {
  averageScanResultChartSettings,
  AverageScanResultMetric,
  averageScanResultTimePeriods,
} from '../../data/dashboard/average_scan_result'
import { TimePeriod } from '../../data/dashboard/dashboard'
import { AnimalType } from '../../data/types'
import { AnimalTypeSelector } from './AnimalTypeSelector'
import { AverageScanResultSettingsDialog } from './AverageScanResultSettings'
import { TimePeriodSelector } from './DashboardElements'

interface AverageScanResultChartProps {
  readonly metric: AverageScanResultMetric
  readonly farmId: string
  readonly onChange: (metric: AverageScanResultMetric) => void
  readonly data: any | null
  readonly position: number
}

export const AverageScanResultChart: React.FunctionComponent<AverageScanResultChartProps> = ({
  metric,
  farmId,
  onChange,
  data,
  position,
}): JSX.Element => {
  const [settingsOpen, setSettingsOpen] = useState(false)

  const openSettings = (): void => {
    setSettingsOpen(true)
  }

  const closeSettings = (): void => {
    setSettingsOpen(false)
  }

  const onAnimalTypeChange = (animalType: AnimalType): void => {
    onChange({ ...metric, animalType })
  }

  const onTimePeriodChange = (timePeriod: TimePeriod): void => {
    onChange({ ...metric, timePeriod })
  }

  const onSettingsChange = (): void => {
    onChange({ ...metric })
  }

  return (
    <div className={'dashboardChartInner'}>
      <AnimalTypeSelector animalType={metric ? metric.animalType : AnimalType.Cattle} onSelect={onAnimalTypeChange} />

      {data ? (
        <Chart
          chartType={averageScanResultChartSettings(metric.timePeriod, position).type}
          width='100%'
          height='400px'
          options={averageScanResultChartSettings(metric.timePeriod, position).options}
          data={data}
        />
      ) : (
        <div className='dashboardEmptyChart'>
          <p>{metric ? 'Loading' : 'Select a metric to view chart data'}</p>
        </div>
      )}

      <div className='dashboardChartFooter'>
        <TimePeriodSelector
          periods={averageScanResultTimePeriods}
          selected={metric ? metric.timePeriod : 'Last 3 Years'}
          onChange={onTimePeriodChange}
        />
        <p className='dashboardChartSettings' onClick={openSettings}>
          SETTINGS
        </p>
        <AverageScanResultSettingsDialog
          farmId={farmId}
          onSave={onSettingsChange}
          onRequestClose={closeSettings}
          open={settingsOpen}
          position={position}
        />
      </div>
    </div>
  )
}
