import React from 'react'
import _ from 'lodash'
import {
  SelectField,
  CheckboxField,
  TextField,
  FormRow,
  FormField,
  PositiveNumberField,
} from '../../../../components/Form/Form'
import Autocomplete from '../../../../components/Form/components/Autocomplete'
import FormDialog from '../../../../components/Dialog/FormDialog'
import { colors } from '../../../../styles/styleConstants'
import { addHealthTreatment, editHealthTreatment, useHealthTreatmentTypes } from '../../../../data/health_treatments'
import { uomChoices } from '../../../../constants'

import '../../../../components/Dialog/Dialog.css'

const AddTreatmentForm = (props) => {
  return (
    <>
      <FormRow>
        <FormField width='100%'>
          <Autocomplete
            name='healthTreatmentTypeIds'
            disabled={props.isDefault}
            options={props.healthTreatmentTypes.map(({ id, name }) => ({ value: id, title: name }))}
            defaultValues={
              props.currentData.healthTreatmentTypeIds
                ? props.currentData.healthTreatmentTypeIds.map((id) => {
                    const title = props.healthTreatmentTypes.find((type) => type.id === id)
                    return {
                      value: id,
                      title: title ? title.name : 'N/A',
                    }
                  })
                : []
            }
            label='Product Type'
            required
            variant='input'
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField width='58%'>
          <TextField
            name='name'
            label='Name'
            value={props.currentData.name}
            disabled={props.currentData.name || props.isDefault}
            required
          />
        </FormField>
        <FormField width='20%'>
          <CheckboxField name='rvm' label='RVM' checked={props.currentData.rvm === 'Y'} disabled={props.isDefault} />
        </FormField>
        <FormField width='20%'>
          {props.currentData.uom ? (
            <SelectField
              name='uom'
              width='100%'
              choices={uomChoices}
              backgroundColor={colors.grey}
              color={colors.white}
              disabled
              value={props.currentData.uom}
              label='Unit of Measure'
              fullWidth={false}
              showPlaceholder
            />
          ) : (
            <SelectField
              name='uom'
              width='100%'
              choices={uomChoices}
              backgroundColor={colors.green}
              color={colors.white}
              defaultValue={props.currentData.uom}
              label='Unit of Measure'
              fullWidth={false}
              disabled={props.isDefault}
              required
              showPlaceholder
            />
          )}
        </FormField>
      </FormRow>
      <FormRow>
        {/* NOTE formsy doesn’t like undefined as a value, and won’t let the form
        submit until something is in the fields, even if not required */}
        <FormField>
          <PositiveNumberField
            name='meatWitholdingPeriod'
            label='Meat WHP (days)'
            value={props.currentData.meatWitholdingPeriod || ''}
            disabled={props.isDefault}
          />
        </FormField>
        <FormField>
          <PositiveNumberField
            name='milkWitholdingPeriod'
            label='Milk WHP (days)'
            value={props.currentData.milkWitholdingPeriod || ''}
            disabled={props.isDefault}
          />
        </FormField>
        <FormField>
          <PositiveNumberField
            name='produceWitholdingPeriod'
            label='Wool WHP (days)'
            value={props.currentData.produceWitholdingPeriod || ''}
            disabled={props.isDefault}
          />
        </FormField>
        <FormField>
          <PositiveNumberField
            name='packSize'
            label='Pack Size'
            value={props.currentData.packSize || ''}
            extra={{ step: '1' }}
          />
        </FormField>
        <FormField>
          <PositiveNumberField
            name='salePrice'
            label='Sale Price'
            value={props.currentData.salePrice || ''}
            extra={{ step: '0.01' }}
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField width='58%'>
          <TextField
            name='instructions'
            label='Use Instructions'
            value={props.currentData.instructions}
            multiline
            disabled={props.isDefault}
          />
        </FormField>
        <FormField width='40%'>
          <TextField
            name='label'
            type='url'
            label='Label (e.g. http://stockmanager.co.nz)'
            value={props.currentData.label}
            multiline
            disabled={props.isDefault}
          />
        </FormField>
      </FormRow>
    </>
  )
}

class AddTreatmentDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      validationErrors: {},
      hasChanged: false,
    }
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    /*this.setState({
      open: false,
      validationErrors: {},
      hasChanged: false,
    })*/
  }

  validate = (data) => {
    const errors = {}

    if (data.packSize && !data.salePrice) {
      errors.salePrice = 'Sale price required with pack size'
    }

    if (!data.packSize && data.salePrice) {
      errors.packSize = 'Pack size required with sale price'
    }

    this.setState({ validationErrors: errors, hasChanged: true })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.props.reloadTreatments()
        this.handleRequestClose()
      }
    }

    const formattedData = {
      ...data,
      healthTreatmentTypeIds: data.healthTreatmentTypeIds.map((val) => val.value),
      milkWitholdingPeriod: parseInt(data.milkWitholdingPeriod, 10),
      meatWitholdingPeriod: parseInt(data.meatWitholdingPeriod, 10),
      produceWitholdingPeriod: parseInt(data.produceWitholdingPeriod, 10),
      packSize: parseInt(data.packSize, 10),
      salePrice: parseFloat(data.salePrice),
      rvm: data.rvm || false,
    }

    if (this.props.data) {
      editHealthTreatment(this.props.accountId, this.props.data.id, formattedData).then(onSave)
    } else {
      addHealthTreatment(this.props.accountId, formattedData).then(onSave)
    }
  }

  render() {
    const isDefault = _.get(this.props, 'data.isDefault') || false

    // AutoComplete is an uncontrolled component, so if initialised before the
    // default value is ready, it will not be updated later. So prevent render
    // until the values are available
    if (!this.props.healthTreatmentTypes) {
      return null
    }

    return (
      <FormDialog
        wide
        width='80vw'
        title={`${this.props.data ? 'Edit' : 'Add'}${isDefault ? ' Default' : ''} Treatment`}
        open={this.props.open}
        onClose={this.handleRequestClose}
        FormComponent={AddTreatmentForm}
        currentData={
          {
            ...this.props.data,
          } || {}
        }
        submit={this.submit}
        validate={this.validate}
        validationErrors={this.state.validationErrors}
        hasChanged={this.state.hasChanged}
        healthTreatmentTypes={this.props.healthTreatmentTypes || []}
        isDefault={isDefault}
      />
    )
  }
}

export default (props) => {
  const healthTreatmentTypes = useHealthTreatmentTypes()

  return <AddTreatmentDialog {...props} healthTreatmentTypes={healthTreatmentTypes} />
}
