import '../../components/Dialog/Dialog.css'

import _ from 'lodash'
import React, { useState } from 'react'

import FormDialog from '../../components/Dialog/FormDialog'
import { FormRow, TextField } from '../../components/Form/Form'
import {
  DamConditionMonthRange,
  DamConditionSetting,
  DamConditionSettings,
  loadDamConditionSettings,
  saveDamConditionSettings,
} from '../../data/dashboard/dam_condition'
import { months } from '../../data/dashboard/dashboard'
import { AnimalType, AnimalTypes } from '../../data/types'

function settingsTitle(animalType: AnimalType): string {
  return `${animalType} ${animalType === AnimalType.Cattle ? '(1-10)' : '(1-5)'}`
}

function settingsMax(animalType: AnimalType): number {
  switch (animalType) {
    case AnimalType.Cattle: {
      return 10
    }
    default: {
      return 5
    }
  }
}

interface SettingsFormProps {
  readonly settings: DamConditionSettings
  readonly onChange: (settings: DamConditionSettings) => void
  readonly position: number
}

const SettingsForm: React.FunctionComponent<SettingsFormProps> = ({ settings, onChange }): JSX.Element => {
  const onChangeValue = (animalType: AnimalType, month: number, low: boolean, value: string): void => {
    if (isNaN(parseFloat(value))) {
      return
    }

    const clone: DamConditionSettings = _.cloneDeep(settings)
    const typeSettings: DamConditionSetting | undefined = clone[animalType]
    if (!typeSettings) {
      return
    }

    const monthRange: DamConditionMonthRange = typeSettings.monthRanges[month]

    if (low) {
      typeSettings.monthRanges[month] = {
        low: parseFloat(value),
        high: monthRange.high,
      }
    } else {
      typeSettings.monthRanges[month] = {
        low: monthRange.low,
        high: parseFloat(value),
      }
    }

    onChange(clone)
  }

  return (
    <React.Fragment>
      {AnimalTypes.map((animalType, index) => (
        <div key={animalType}>
          {index > 0 ? <hr style={{ marginRight: 0 }} /> : null}
          <FormRow>
            <div className='dashboardSettingsHeaderColumn'>
              <p className='dashboardSettingsHeader'>{settingsTitle(animalType)}</p>
              <p className='dashboardSettingsHeaderColumnLabel'>Min Condition Score</p>
              <p className='dashboardSettingsHeaderColumnLabel'>Max Condition Score</p>
            </div>
            <div className='dashboardSettingsHorizontalContainer'>
              {months.map((month, idx) => (
                <div key={month} className='dashboardSettingsContentColumn'>
                  <p className='dashboardSettingsHeader'>{month}</p>
                  <div className='dashboardSettingsTextFieldWrapper'>
                    <TextField
                      validations={`withinRange: [${0}, ${settingsMax(animalType)}]`}
                      validationErrors={{ withinRange: `` }}
                      name={month + '-min'}
                      label=''
                      value={settings[animalType].monthRanges[idx].low}
                      extra={{ style: { textAlign: 'center' } }}
                      onChange={(changed: string) => {
                        onChangeValue(animalType, idx, true, changed)
                      }}
                    />
                  </div>
                  <div className='dashboardSettingsTextFieldWrapper'>
                    <TextField
                      validations={`withinRange: [${0}, ${settingsMax(animalType)}]`}
                      validationErrors={{ withinRange: `` }}
                      name={month + '-max'}
                      label=''
                      value={settings[animalType].monthRanges[idx].high}
                      extra={{ style: { textAlign: 'center' } }}
                      onChange={(changed: string) => {
                        onChangeValue(animalType, idx, false, changed)
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </FormRow>
        </div>
      ))}
    </React.Fragment>
  )
}

interface Props {
  readonly open: boolean
  readonly farmId: string
  readonly onSave: () => void
  readonly onRequestClose: () => void
}

export const DamConditionSettingsDialog: React.FunctionComponent<Props> = (props): JSX.Element => {
  const [data, setData] = useState<DamConditionSettings>()
  const [hasChanged, setHasChanged] = useState(false)

  const handleRequestClose = (): void => {
    props.onRequestClose()
    setHasChanged(false)
    setData(undefined)
  }

  const validate = (): void => {
    if (!data) {
      setHasChanged(false)
      return
    }

    let valid = true
    data.Cattle.monthRanges.forEach((range) => {
      if (range.low < 0 || range.low > 10 || range.high < 0 || range.high > 10 || range.low > range.high) valid = false
    })
    data.Deer.monthRanges.forEach((range) => {
      if (range.low < 0 || range.low > 5 || range.high < 0 || range.high > 5 || range.low > range.high) valid = false
    })
    data.Sheep.monthRanges.forEach((range) => {
      if (range.low < 0 || range.low > 5 || range.high < 0 || range.high > 5 || range.low > range.high) valid = false
    })

    setHasChanged(valid)
  }

  const submit = (): void => {
    if (!data) return

    setHasChanged(false)
    saveDamConditionSettings(props.farmId, data, props.position).then(() => {
      props.onSave()
      handleRequestClose()
    })
  }

  const onChange = (settings: DamConditionSettings): void => {
    setData(settings)
  }

  const loadSettings = (): void => {
    loadDamConditionSettings(props.farmId, props.position).then(setData)
  }

  if (data && props.open) {
    return (
      <FormDialog
        wide
        maxWidth='md'
        width='70vw'
        title={'Settings - Dam Condition'}
        open={props.open}
        onClose={handleRequestClose}
        FormComponent={SettingsForm}
        settings={data}
        onChange={onChange}
        submit={submit}
        validate={validate}
        hasChanged={hasChanged}
      />
    )
  } else if (props.open) {
    loadSettings()
  }

  return null
}
