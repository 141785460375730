import React from 'react'
import { requireAuth } from '../../../components/withAuth'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import { getSubscriptionCost, getSubscriptionRange } from '../../Signup/components/AnimalSlider'
import '../../../components/Dialog/Dialog.css'
var moment = require('moment')

const COLUMNS_XS = {
  billingDate: 2,
  farmCount: 2,
  activeAnimalCount: 3,
  subscriptionTier: 2,
  invoiceAmount: 3,
}
const COLUMNS_SM = COLUMNS_XS

const TransactionsTable = (props) => (
  <div className='farmUserTable'>
    <Grid container spacing={12} className='dialogTable'>
      <Grid item xs={COLUMNS_XS.billingDate} sm={COLUMNS_SM.billingDate}>
        <p className='tableHeader'>Billing Date</p>
      </Grid>
      <Grid item xs={COLUMNS_XS.farmCount} sm={COLUMNS_SM.farmCount}>
        <p className='tableHeader'>Farm Count</p>
      </Grid>
      <Grid item xs={COLUMNS_XS.activeAnimalCount} sm={COLUMNS_SM.activeAnimalCount}>
        <p className='tableHeader'>Active Animal Count</p>
      </Grid>
      <Grid item xs={COLUMNS_XS.subscriptionTier} sm={COLUMNS_SM.subscriptionTier}>
        <p className='tableHeader'>Subscription Tier</p>
      </Grid>
      <Grid item xs={COLUMNS_XS.invoiceAmount} sm={COLUMNS_SM.invoiceAmount}>
        <p className='tableHeader'>Invoice Amount</p>
      </Grid>
    </Grid>
    <hr />
    {props.transactions.map((transaction, key) => (
      //FIXME: replace key with proper key data
      <div key={key}>
        <Grid container spacing={12} className='dialogTable'>
          <Grid item xs={COLUMNS_XS.billingDate} sm={COLUMNS_SM.billingDate}>
            <p className='dataContent'>{moment(transaction.billingDate).format('DD/MM/YYYY')}</p>
          </Grid>
          <Grid item xs={COLUMNS_XS.farmCount} sm={COLUMNS_SM.farmCount}>
            <p className='dataContent'>{transaction.farmCount}</p>
          </Grid>
          <Grid item xs={COLUMNS_XS.activeAnimalCount} sm={COLUMNS_SM.activeAnimalCount}>
            <p className='dataContent'>{transaction.activeAnimals}</p>
          </Grid>
          <Grid item xs={COLUMNS_XS.subscriptionTier} sm={COLUMNS_SM.subscriptionTier}>
            <p className='dataContent'>{getSubscriptionRange(transaction.subscriptionTier)}</p>
          </Grid>
          <Grid item xs={COLUMNS_XS.invoiceAmount} sm={COLUMNS_SM.invoiceAmount}>
            <p className='dataContent'>
              NZD {getSubscriptionCost(transaction.subscriptionTier).replace('per month', '')}
            </p>
          </Grid>
        </Grid>
        <hr />
      </div>
    ))}
  </div>
)

class ViewTransactionsDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
    })
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog
        onClose={this.handleRequestClose}
        {...other}
        fullWidth={true}
        maxWidth={'md'}
        className='viewTransactionsDialog'
      >
        <DialogHeader
          title='Transactions'
          headerRight='On Farm Innovations Limited IRD# 113-683-805'
          handleRequestClose={this.handleRequestClose}
        />
        <div className='dialogContent'>
          <TransactionsTable
            transactions={this.props.transactions && this.props.transactions.length > 0 ? this.props.transactions : []}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(ViewTransactionsDialog)
