// @ts-ignore
import config from 'config'
import { useCallback, useEffect, useState } from 'react'

import { get } from '../helpers/fetch'
import { getAuthHeaders } from '../helpers/firebase'

interface Data<T> {
  data: T
  loaded: boolean
  loading: boolean
  refetch: () => void
}

export const useData = <T = unknown>(url: string): Data<T> => {
  const [data, setData] = useState<T>({} as T)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  // increment this value to trigger a data refetch
  const [reloadKey, setReloadKey] = useState(1)

  useEffect(() => {
    let mounted = true
    setLoading(true)
    getAuthHeaders()
      .then((headers) => get(config.backend.url + url, headers))
      .then((data) => {
        if (mounted) {
          setData(data)
          setLoaded(true)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [url, reloadKey])

  const refetch = useCallback(() => {
    setReloadKey((current) => current + 1)
  }, [])

  return {
    loaded,
    loading,
    data,
    refetch,
  }
}
