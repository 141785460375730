import React from 'react'
import styled from 'styled-components'
import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import { getAllFarmsForUser } from '../../data/farm'
import Scenario from './Scenario'
import { PageTitle } from '../../components/PageHeaders'
import { colors } from '../../styles/styleConstants'
import Checkbox from '@material-ui/core/Checkbox'
import { CheckboxWithLabel } from '../../components/Form/CustomFields'

const defaultScenario = {
  selectedFarms: [],
  animalType: null,
  filters: {},
  count: 0,
  animalList: [],
  metrics: {},
  metricsValues: {},
}

const SESSION_KEY = 'analysis-state'

class Analysis extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copied: false,
      farms: [],
      scenario1: defaultScenario,
      scenario2: defaultScenario,
      key: Math.random(),
    }
  }

  componentDidMount() {
    if (!this.loadState()) {
      this.getFarms()
    }
  }

  getFarms = async () => {
    const farms = await getAllFarmsForUser(this.props.user.uid)
    this.setState({ farms })
  }

  setScenarioState = (scenario, state) => {
    this.setState({ [scenario]: { ...this.state[scenario], ...state } })
  }

  handleFarmSelected = (scenario, values) => {
    this.setScenarioState(scenario, { selectedFarms: values })
  }

  handleFarmDeselected = (scenario, farmIds) => {
    this.setScenarioState(scenario, {
      selectedFarms: this.state[scenario].selectedFarms.filter((farm) => !farmIds.includes(farm.id)),
    })
  }

  // don't lose state when going to view animal list
  saveState = () => {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(this.state))
  }

  loadState = () => {
    const state = sessionStorage.getItem(SESSION_KEY)

    if (state) {
      // remove before parsing in case something causes a crash and makes page unloadable
      sessionStorage.removeItem(SESSION_KEY)
      this.setState(JSON.parse(state))
      return true
    }

    return false
  }

  render() {
    return (
      <Layout key={this.state.key}>
        <PageTitle>Analysis</PageTitle>
        <Container>
          <ScenarioContainer className='--scenario1'>
            <div className='--scenario-title'>Scenario 1</div>
            <Scenario
              farms={this.state.farms}
              {...this.state.scenario1}
              setScenarioState={(state) => this.setScenarioState('scenario1', state)}
              handleFarmSelected={(values) => this.handleFarmSelected('scenario1', values)}
              handleFarmDeselected={(farmIds) => this.handleFarmDeselected('scenario1', farmIds)}
              saveState={this.saveState}
            />
          </ScenarioContainer>
          <ScenarioContainer>
            <CheckboxWithLabel
              control={
                <Checkbox
                  checked={this.state.copied}
                  onClick={(event) => {
                    if (event.target.checked) {
                      this.setState({
                        copied: true,
                        scenario2: { ...JSON.parse(JSON.stringify(this.state.scenario1)) },
                        key: Math.random(),
                      })
                    } else {
                      this.setState({
                        copied: false,
                        scenario2: defaultScenario,
                        key: Math.random(),
                      })
                    }
                  }}
                />
              }
              label='Copy Scenario 1'
              width='150px'
            />
            <div className='--scenario-title'>Scenario 2</div>
            <Scenario
              farms={this.state.farms}
              {...this.state.scenario2}
              setScenarioState={(state) => this.setScenarioState('scenario2', state)}
              handleFarmSelected={(values) => this.handleFarmSelected('scenario2', values)}
              handleFarmDeselected={(farmId) => this.handleFarmDeselected('scenario2', farmId)}
              saveState={this.saveState}
            />
          </ScenarioContainer>
        </Container>
      </Layout>
    )
  }
}

export default requireAuth(Analysis)

const Container = styled.div`
  display: flex;
  border-top: 1px solid ${colors.lightGreen};

  .MuiInput-input {
    padding-left: 15px !important;
  }

  .MuiTypography-root {
    font-size: 12px;
  }
`

const ScenarioContainer = styled.div`
  width: 50%;
  color: #555555;
  font-size: 18px;
  padding: 0 8px 0 8px;

  &.--scenario1 {
    padding-top: 34px;
    border-right: 1px solid ${colors.lightGrey};
  }

  .--scenario-title {
    margin-bottom: 32px;
    text-align: center;
  }
`
