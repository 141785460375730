import Formsy from 'formsy-react'
import React from 'react'
import styled from 'styled-components'

import { colors } from '../styles/styleConstants'
import { SELECT_PLACEHOLDER_OPTION_VALUE, SelectField } from './Form/Form'

interface Props {
  readonly options: Array<[string, string]>
  readonly defaultOption: string
  readonly selectedOption: string
  readonly handleOptionSelected: (value: string, label: string) => void
  readonly deselect: () => void
  readonly disabled: boolean
  readonly name?: string
  readonly fullWidth: boolean
}

const SelectorDropdown: React.FunctionComponent<Props> = ({
  options,
  name = 'select-field',
  defaultOption,
  selectedOption,
  handleOptionSelected,
  deselect,
  disabled,
  fullWidth,
}): JSX.Element => {
  const setOption = (value: string): void => {
    if (value === SELECT_PLACEHOLDER_OPTION_VALUE && deselect) {
      deselect()
      return
    }

    const option = options.find((option) => option[0] === value)
    if (option) {
      handleOptionSelected(option[0], option[1])
    }
  }

  // match on value first, then option text for backwards compatibility
  // if not found, will default to placeholder
  const selectedValue = options.find((choice) => {
    return choice[0] === selectedOption || choice[1] === selectedOption
  })

  return (
    <DropdownContainer>
      <Formsy>
        <SelectField
          name={name}
          choices={options}
          placeholder={defaultOption}
          showPlaceholder
          setOption={setOption}
          disabled={disabled}
          backgroundColor={colors.green}
          color={colors.white}
          value={selectedValue ? selectedValue[0] : undefined}
          fullWidth={fullWidth}
        />
      </Formsy>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  width: 100%;
`

export default SelectorDropdown
