import './MultiFarmSelector.css'

import Typography from '@material-ui/core/Typography'
import Formsy from 'formsy-react'
import React, { useState } from 'react'

import { Farm } from '../data/types'
import Autocomplete, { Option } from './Form/components/Autocomplete'

interface Props {
  readonly farms: Farm[]
  readonly selectedFarms?: Farm[]
  readonly handleFarmSelected?: (farms: Farm[]) => void
  readonly handleFarmDeselected?: (farmIds: string[]) => void
  readonly disabled: boolean
  readonly useSelectAll?: boolean
}

const MultiFarmSelector: React.FunctionComponent<Props> = ({
  farms = [],
  selectedFarms = [],
  handleFarmSelected,
  handleFarmDeselected,
  disabled,
  useSelectAll,
}) => {
  const selected = selectedFarms.map((farm) => ({ title: farm.farmName, value: farm.id }))
  const [allSelectedFarms, setAllSelectedFarms] = React.useState<Option[]>(selected)
  const options = farms.map((farm) => ({ title: farm.farmName, value: farm.id }))
  const [key, setKey] = useState(Math.random())

  const onChange = (values: Option[]): void => {
    const valueRemoved = allSelectedFarms.filter((val) => !values.includes(val))

    if (valueRemoved.length === 0 && handleFarmSelected) {
      handleFarmSelected(farms.filter((farm) => !!values.find((val) => val.value === farm.id)))
    }

    if (valueRemoved.length > 0 && handleFarmDeselected) {
      handleFarmDeselected(valueRemoved.map((removed) => removed.value.toString()))
    }

    setAllSelectedFarms(values)
  }

  const selectAll = (): void => {
    if (handleFarmSelected) handleFarmSelected(farms)
    setKey(Math.random())
  }

  return (
    <Formsy>
      <Typography variant='caption' color='textPrimary' gutterBottom>
        Farms
      </Typography>
      <Autocomplete
        key={key}
        name='farms'
        disabled={disabled}
        options={options}
        defaultValues={selected}
        onChange={onChange}
        placeholder='Select farms'
      />
      {useSelectAll ? (
        <div className='multiFarmSelectorFooter'>
          <Typography variant='caption' color='textSecondary' onClick={selectAll} gutterBottom>
            Select All
          </Typography>
        </div>
      ) : null}
    </Formsy>
  )
}

export default MultiFarmSelector
