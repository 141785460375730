import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { colors } from '../../styles/styleConstants'
import { width } from 'styled-system'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

const getAnchorProps = (data) => {
  const props = {}
  if (data.target === '_blank') {
    props.rel = 'noopener noreferrer'
  }
  return props
}

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false }
  }

  render() {
    const { category, pages, path, width = '100%', hasArrow, ...props } = this.props

    return (
      <Container onMouseLeave={() => this.setState({ open: false })} width={width}>
        <Category onMouseOver={() => this.setState({ open: true })}>
          {props.href ? (
            <a {...props} {...getAnchorProps(this.props)}>
              {category}
            </a>
          ) : (
            category
          )}
          {hasArrow && (this.state.open ? <ChevronLeft className='chevron' /> : <ChevronRight className='chevron' />)}
        </Category>
        {this.state.open && (
          <PagesContainer>
            {pages.map(({ name, ...page }) =>
              page.path ? (
                <StyledLink key={page.path} to={page.path} active={path.split('/')[1] === page.path.split('/')[1]}>
                  {name}
                </StyledLink>
              ) : (
                <StyledA key={page.path} {...page} {...getAnchorProps(page)}>
                  {name}
                </StyledA>
              ),
            )}
          </PagesContainer>
        )}
      </Container>
    )
  }
}

const Container = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  font-size: 14px;
  cursor: pointer;
  ${width}

  @media (max-width: 767px) {
    padding-right: 20px;
  }
`

const Category = styled.div`
  height: 48px;
  padding-top: 10px;
  display: inline-flex;
  width: 100%;

  svg.chevron {
    margin-top: -2px;
    transform: rotate(90deg);
  }

  .dropdown-icon {
    margin-left: calc(100% - 24px);
  }
`

const PagesContainer = styled.div`
  background: ${colors.black};
`

const linkStyle = css`
  display: block;
  height: 29px;
  padding: 5px;
  color: ${(props) => (props.active ? colors.lightGreen : colors.white)};
  white-space: nowrap;
  &:hover {
    color: #e3b333;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 1em 2em;
  }
`

const StyledLink = styled(Link)`
  ${linkStyle}
`

const StyledA = styled.a`
  ${linkStyle}
`
