const config = {
  firebase: {
    apiKey: 'AIzaSyAcmk72Emx6zIF-71yXgK13lQhYf-u6Xak',
    authDomain: 'sm-production.firebaseapp.com',
    databaseURL: 'https://sm-production.firebaseio.com'
  },

  backend: {
    url: 'https://api.stockmanager.co.nz/v1'
  }
}

export default config
