import React from 'react'
import MaterialTable from '../../../../components/Table/Table'
import { enableHealthTreatment, disableHealthTreatment } from '../../../../data/health_treatments'
import { applyUnit } from '../../../../components/Table/TableRow'
import { InlineEditLink } from '../../../../components/Table/content'

const Table = ({
  accountId,
  data,
  selected,
  updateSelected,
  editTreatment,
  disableAllSelection,
  disableSelectionForRows,
  enableEditing,
  openInstructions,
  reloadTreatments,
}) => {
  const columns = [
    { id: 'name', label: 'Treatment Name' },
    { id: 'rvm', label: 'RVM' },
    { id: 'meatWitholdingPeriod', label: 'Meat WHP', unit: ' days' },
    { id: 'milkWitholdingPeriod', label: 'Milk WHP', unit: ' days' },
    { id: 'produceWitholdingPeriod', label: 'Wool WHP', unit: ' days' },
    {
      id: 'packSize',
      label: 'Pack Size',
      render: (item) => {
        const value = applyUnit(item.packSize)
        return item.editable ? (
          <InlineEditLink
            onClick={() => {
              console.log(item)
              editTreatment(item)
            }}
          >
            {value || 'add'}
          </InlineEditLink>
        ) : (
          value
        )
      },
    },
    {
      id: 'salePrice',
      label: 'Sale Price',
      unit: '$',
      render: (item) => {
        const value = applyUnit(item.salePrice, { unit: '$' })
        return item.editable ? (
          <InlineEditLink
            onClick={() => {
              console.log(item)
              editTreatment(item)
            }}
          >
            {value || 'add'}
          </InlineEditLink>
        ) : (
          value
        )
      },
    },
    { id: 'uom', label: 'UOM' },
    { id: 'unitPrice', label: 'Unit Price', unit: { unit: '$', digits: 3 } },
    { id: 'createDate', label: 'Date Added' },
    { id: 'addToFarm', label: 'Add To Farm', selectableColumn: true, overflowColumn: true },
  ]

  return (
    <MaterialTable
      columns={columns}
      data={data}
      selectable
      selectById
      selected={selected}
      updateSelected={updateSelected}
      disableAllSelection={disableAllSelection}
      disableSelectionForRows={disableSelectionForRows}
      enableEditing={enableEditing}
      disableIndeterminate
      actions={{
        farmHealthTreatmentsActions: [
          {
            name: 'Use Instructions',
            onClick: (id) => openInstructions(data.find((t) => t.id === id).instructions),
          },
          {
            name: 'Label',
            onClick: (id) => {
              let link = data.find((t) => t.id === id).label

              if (/^http/.test(link)) {
                window.open(link, '_blank')
              } else {
                window.open(`http://${link}`, '_blank')
              }
            },
          },
        ],
        default: [
          {
            name: 'Use Instructions',
            onClick: (id) => openInstructions(data.find((t) => t.id === id).instructions),
          },
          {
            name: 'Label',
            onClick: (id) => {
              let link = data.find((t) => t.id === id).label

              if (/^http/.test(link)) {
                window.open(link, '_blank')
              } else {
                window.open(`http://${link}`, '_blank')
              }
            },
          },
          {
            name: 'Edit',
            onClick: (id) => editTreatment(data.find((t) => t.id === id)),
            disabled: (id) => {
              const treatment = data.find((t) => t.id === id)
              return treatment && !treatment.enabled
            },
          },
          {
            name: (id) =>
              data.find((t) => t.id === id) && data.find((t) => t.id === id).enabled ? 'Disable' : 'Enable',
            onClick: async (id) => {
              if (data.find((t) => t.id === id).enabled) {
                const result = await disableHealthTreatment(accountId, id)

                if (result.success) {
                  reloadTreatments()
                }
              } else {
                const result = await enableHealthTreatment(accountId, id)

                if (result.success) {
                  reloadTreatments()
                }
              }
            },
          },
        ],
      }}
    />
  )
}

export default Table
