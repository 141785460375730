import React from 'react'
import { ChevronRight, ChevronLeft, Delete } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import EventCard from './EventCard'
import { colors } from '../../../../../styles/styleConstants'

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

export default ({
  data: { name, planName, realPlanName, planId, events },
  expanded,
  onToggle,
  startMonth,
  startYear,
  setEventToEdit,
  setEventToDelete,
  onToggleComplete,
  deletePlan,
}) => {
  const startDate = moment(`${startYear}-${startMonth}`, 'YYYY-M')

  return (
    <Row>
      <Name onClick={onToggle}>
        <Tooltip title={name} enterDelay={300}>
          <p>{name}</p>
        </Tooltip>
        {expanded ? <ChevronLeft className='--chevron' /> : <ChevronRight className='--chevron' />}
        <div>
          <Delete
            onClick={(e) => {
              e.stopPropagation()
              deletePlan(planName, planId)
            }}
            className='--delete'
            style={{ color: colors.green }}
          />
          <Tooltip title={planName} enterDelay={300}>
            <p>{planName}</p>
          </Tooltip>
        </div>
      </Name>
      {expanded && (
        <React.Fragment>
          {_.times(6).map((n) => {
            const month = startDate.clone().add(n, 'months')

            return (
              <MonthContainer key={n}>
                {events
                  .filter((e) => e.dueDate.startOf('month').isSame(month.startOf('month')))
                  .sort((a, b) => {
                    // TODO remove _i hack
                    const aDueDate = moment(a.dueDate._i)
                    const bDueDate = moment(b.dueDate._i)
                    // sort by date, then by name
                    if (aDueDate.isSame(bDueDate)) {
                      return collator.compare(a.eventName, b.eventName)
                    }

                    return aDueDate.diff(bDueDate)
                  })
                  .map((e) => (
                    // eslint-disable-next-line react/jsx-key
                    <EventCard
                      data={{ ...e, planName: realPlanName }}
                      onToggle={onToggleComplete}
                      setEventToEdit={setEventToEdit}
                      setEventToDelete={setEventToDelete}
                    />
                  ))}
              </MonthContainer>
            )
          })}
        </React.Fragment>
      )}
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 36px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${colors.lightGrey};
`

const Name = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: #555555;

  p {
    margin: 0;
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    width: 100%;
    p {
      font-size: 12px;
    }
  }

  svg.--chevron {
    transform: rotate(90deg);
  }

  svg.--delete {
    float: right;
    height: 18px;
    cursor: pointer;
  }
`

const MonthContainer = styled.div`
  width: 164px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
