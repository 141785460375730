import * as React from 'react'

import BackHeading from '../../../components/BackHeading'
import Block from '../../../components/Block'
import ImportDetailsTable, { ImportDetailsTableProps } from './ImportDetailsTable'
import OrderDetailsTable, { OrderDetailsTableProps } from './OrderDetailsTable'

export interface TagImportDetailsProps extends ImportDetailsTableProps, OrderDetailsTableProps {
  readonly backURL: string
}

const TagImportDetails: React.FunctionComponent<TagImportDetailsProps> = ({
  backURL,
  tagDetailsData,
  orderDetails,
}) => (
  <React.Fragment>
    <BackHeading withBorder text='Import Tags' backURL={backURL} />
    <Block marginTop={3} />
    <OrderDetailsTable orderDetails={orderDetails} />
    <ImportDetailsTable tagDetailsData={tagDetailsData} />
  </React.Fragment>
)

export default TagImportDetails
