import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

import {
  withFormValidation,
  TextField,
  SelectField,
  FormRow,
  FormField,
  PushButton,
} from '../../../../components/Form/Form'
import { ActionHeader } from '../../../../components/PageHeaders'
import MaterialTable from '../../../../components/Table/Table'
import { colors } from '../../../../styles/styleConstants'
import { choiceMap, formatChoices, eventTypeChoicesMap } from '../../../../constants'
import AddEventDialog from './AddEventDialog'
import DeleteEventDialog from './DeleteEventDialog'
import ViewEventDetailsDialog from '../../ManageEvents/components/AddEventDialog'

import { addTemplateForAccount } from '../../../../data/manage_templates'
import { getEventsForAccount } from '../../../../data/manage_events'
import { getHealthTreatmentsForAccount } from '../../../../data/health_treatments'

const columns = [
  { id: 'order', label: 'Order', style: { width: '50px' } },
  { id: 'name', label: 'Event' },
  { id: 'eventTypeName', label: 'Type', style: { width: '100px' } },
  { id: 'dependentEventName', label: 'Dependent Event' },
  { id: 'leadLag', label: 'Lead (-) / Lag (+)', style: { width: '50px' } },
  { id: 'purposeText', label: 'Purpose' },
  { id: 'overflow', label: '', overflowColumn: true },
].map((column) => ({
  ...column,
  style: { ...column.style, whiteSpace: 'nowrap' },
}))

const Spacer = styled.div`
  display: block;
  height: 20px;
`

const limitTextLength = (str, limit = 10, addTooltip = true) => {
  if (str !== null && str.length > limit) {
    const shortened = str.slice(0, limit).trim() + '…'

    if (addTooltip) {
      return (
        <Tooltip title={str}>
          <div>{shortened}</div>
        </Tooltip>
      )
    }

    return shortened
  }

  return str
}

const TemplateFormContent = ({
  currentData,
  nameDisabled,
  typeChoices,
  typeDisabled,
  classChoices,
  classDisabled,
  categoryChoices,
  categoryDisabled,
  addEvent,
  addEventDisabled,
  ...props
}) => (
  <FormRow height='41px'>
    <FormField width='250px'>
      <TextField
        name='name'
        label='Template name'
        required
        value={_.get(currentData, 'name')}
        disabled={nameDisabled}
      />
    </FormField>
    <FormField width='140px'>
      <SelectField
        name='planType'
        placeholder='Plan type'
        width='100%'
        choices={typeChoices}
        backgroundColor={typeDisabled ? colors.grey : colors.green}
        color={colors.white}
        disabled={typeDisabled}
        value={_.get(currentData, 'planType')}
        required
        showPlaceholder
      />
    </FormField>
    <FormField width='140px'>
      <SelectField
        name='planClass'
        placeholder='Plan class'
        width='100%'
        choices={classChoices}
        backgroundColor={classDisabled ? colors.grey : colors.green}
        color={colors.white}
        disabled={classDisabled}
        value={_.get(currentData, 'planClass')}
        required
        showPlaceholder
      />
    </FormField>
    <FormField width='140px'>
      <SelectField
        name='planCategory'
        placeholder='Category'
        width='100%'
        choices={categoryChoices}
        backgroundColor={categoryDisabled ? colors.grey : colors.green}
        color={colors.white}
        disabled={categoryDisabled}
        value={_.get(currentData, 'planCategory')}
        required={!categoryDisabled}
        showPlaceholder
      />
    </FormField>
    <PushButton disabled={addEventDisabled} onClick={addEvent}>
      Add Event
    </PushButton>
    <PushButton type='submit' disabled={currentData.id || !props.canSubmit}>
      Save
    </PushButton>
  </FormRow>
)

const TemplateForm = withFormValidation(TemplateFormContent)

export class AddEditTable extends React.Component {
  constructor(props) {
    super(props)

    const isDefault = props.data && props.data.id && !props.data.editable
    const isDisabled = props.data && props.data.id && !props.data.enabled
    const propertiesDisabled = (_.keys(props.data).length > 0 && !props.copied) || isDisabled

    const initialSave =
      props.data.name &&
      props.data.planType &&
      props.data.planClass &&
      (props.data.planType === 'Farm' || props.data.planCategory)
    getEventsForAccount(this.props.accountId).then((events) => {
      this.setState({
        eventOptions: events
          .filter(
            (e) =>
              e.enabled &&
              ((!e.planType && !e.planClass && !e.planCategory) ||
                (e.planType == props.data.planType && !e.planClass && !e.planCategory) ||
                (e.planType == props.data.planType && e.planClass == props.data.planClass && !e.planCategory) ||
                (e.planType == props.data.planType &&
                  e.planClass == props.data.planClass &&
                  e.planCategory == props.data.planCategory)),
          )
          .map((e) => ({
            name: e.name,
            value: e.id,
          })),
      })
    })

    getHealthTreatmentsForAccount(this.props.accountId).then((data) => {
      this.setState({
        healthTreatments: data.accountHealthTreatments.reduce((o, row) => ({ ...o, [row.id]: row }), {}),
      })
    })

    // if an event is present more than once, include order number in parantheses
    const eventNameCounts = props.data.events ? _.countBy(props.data.events.map((e) => e.name)) : []
    const dependentEventOptions = props.data.events
      ? props.data.events.map((e) => ({
          name: e.name + (eventNameCounts[e.name] > 1 ? ` (${e.order})` : ''),
          value: e.id,
        }))
      : []

    this.state = {
      data: props.data,
      isDefault,
      isDisabled,
      nameDisabled: props.data.name,
      typeChoices: formatChoices(_.keys(choiceMap)),
      typeDisabled: isDefault || propertiesDisabled,
      classChoices: _.get(choiceMap, `${props.data.planType}.planClass`, []),
      classDisabled: !props.data.planType || isDefault || propertiesDisabled,
      categoryChoices: _.get(choiceMap, `${props.data.planType}.planCategory`, []),
      categoryDisabled: !props.data.planClass || props.data.planType === 'Farm' || isDefault || propertiesDisabled,
      initialSave,
      addEventDisabled: !initialSave || isDefault || isDisabled,
      addEventDialogOpen: false,
      eventOptions: [], // in callback above
      healthTreatments: {}, // in callback above
      dependentEventOptions,
      eventToEdit: {},
      eventToDelete: {},
      validationErrors: {},
    }
  }

  viewEventDetails = (id) => {
    const event = _.get(this.state, 'data.events', []).find((e) => e.id === id)

    this.setState({
      viewEventDetailsDialogOpen: true,
      eventToEdit: event,
    })
  }

  editEvent = (id) => {
    const event = _.get(this.state, 'data.events', []).find((e) => e.id === id)

    if (event) {
      this.setState({
        addEventDialogOpen: true,
        eventToEdit: event,
      })
    }
  }

  deleteEvent = (id) => {
    const event = _.get(this.state, 'data.events', []).find((e) => e.id === id)

    if (!event) return

    this.setState({
      deleteEventDialogOpen: true,
      eventToDelete: event,
      hasDependentEvents: this.state.data.events.some((e) => e.dependentEventId === event.id),
    })
  }

  validate = (data) => {
    // TODO remove when purging formsy
    if (this.state.preventLoop) {
      this.setState({ preventLoop: false })
      return
    }

    const validationErrors = {}
    let preventLoop = false

    if (data.planType !== this.state.data.planType) {
      data.planClass = undefined
      data.planCategory = undefined
      preventLoop = true
    }

    if (data.planClass !== this.state.data.planClass) {
      data.planCategory = undefined
      preventLoop = true
    }

    if (this.props.isDuplicate(data)) {
      validationErrors.name = 'Duplicate'
    }

    const noCategory = data.planType === 'Farm' && data.planClass !== 'Stock'

    if (data.planType) {
      this.setState({
        data: {
          ...this.state.data,
          ...data,
        },
        classChoices: _.get(choiceMap, `${data.planType}.planClass`, []),
        classDisabled: false,
        categoryChoices: _.get(choiceMap, `${data.planType}.planCategory`, []),
        categoryDisabled: !data.planClass || noCategory,
        addEventDisabled: !data.planClass || (!noCategory && !data.planCategory) || !this.state.initialSave,
        validationErrors,
        preventLoop,
      })
    }
  }

  /**
   * This is different from the standard formsy submit, as the
   * event data from the table needs to be submitted as well.
   * The data submitted should come from state, not the submitted
   * form data. State is updated with form data in validate.
   */
  submit = (data) => {
    const formattedData = { copyTemplateId: this.props.copiedTemplateId, ...data }

    addTemplateForAccount(this.props.accountId, formattedData).then(({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        // new template is always first
        this.props.setTemplates(result.result, result.result[0].id)
      }
    })
  }

  formatEventData = (events) => {
    const eventCounts = {} // count of instances of account events in this template
    const eventOrders = {} // map of template event ids to their order
    const eventMap = {} // map of template events to account events

    events.map((e) => {
      eventCounts[e.eventId] = (eventCounts[e.eventId] || 0) + 1
      eventOrders[e.id] = e.order
      eventMap[e.id] = e.eventId
    })

    // hard to use text-overflow: ellipsis; (table has to be fixed with, and colums too)
    // so manually trim instead
    return events.map((e) => ({
      ...e,
      name: <a onClick={() => this.viewEventDetails(e.id)}>{limitTextLength(e.name, 20, false)}</a>,
      eventTypeName: eventTypeChoicesMap[e.eventType],
      leadLag: String(e.leadLag),
      purposeText: limitTextLength(e.purpose, 55),
      dependentEventName: e.dependentEventId ? (
        <a onClick={() => this.viewEventDetails(e.dependentEventId)}>
          {limitTextLength(e.dependentEventName, 25, false) +
            (eventCounts[eventMap[e.dependentEventId]] > 1 ? ` (${eventOrders[e.dependentEventId]})` : '')}
        </a>
      ) : (
        'Template Start'
      ),
      lagTime: String(e.lagTime),
    }))
  }

  render() {
    const {
      data,
      nameDisabled,
      typeChoices,
      typeDisabled,
      classChoices,
      classDisabled,
      categoryChoices,
      categoryDisabled,
      addEventDisabled,
      isDefault,
      isDisabled,
    } = this.state

    return (
      <div>
        <ActionHeader>
          <TemplateForm
            currentData={data}
            nameDisabled={nameDisabled}
            typeChoices={typeChoices}
            typeDisabled={typeDisabled}
            classChoices={classChoices}
            classDisabled={classDisabled}
            categoryChoices={categoryChoices}
            categoryDisabled={categoryDisabled}
            addEvent={() => this.setState({ addEventDialogOpen: true, eventToEdit: null })}
            addEventDisabled={addEventDisabled}
            isDefault={isDefault}
            validate={this.validate}
            submit={this.submit}
            saveData={this.saveData}
            validationErrors={this.state.validationErrors}
          />
        </ActionHeader>
        {Object.keys(this.state.validationErrors).length > 0 && <Spacer />}
        <MaterialTable
          defaultOrderBy='order'
          defaultOrder='asc'
          columns={columns}
          data={this.formatEventData(data.events || [])}
          actions={
            !isDefault &&
            !isDisabled &&
            !this.props.copiedTemplateId && [
              {
                name: 'Edit',
                onClick: this.editEvent,
              },
              {
                name: 'Delete',
                onClick: this.deleteEvent,
                disabled: (id) => {
                  const event = data.events.find((e) => e.id === id)
                  return !(event.dependentEventId || data.events.length === 1)
                },
              },
            ]
          }
        />
        {this.state.addEventDialogOpen && (
          <AddEventDialog
            open
            onRequestClose={() => this.setState({ addEventDialogOpen: false, eventToEdit: null })}
            eventToEdit={this.state.eventToEdit}
            eventOptions={this.state.eventOptions}
            dependentEventOptions={this.state.dependentEventOptions}
            setTemplates={this.props.setTemplates}
            allEvents={this.props.data.events}
            accountId={this.props.accountId}
            templateId={this.state.data.id}
          />
        )}
        {this.state.deleteEventDialogOpen && (
          <DeleteEventDialog
            open
            onRequestClose={() => this.setState({ deleteEventDialogOpen: false })}
            eventToDelete={this.state.eventToDelete}
            hasDependentEvents={this.state.hasDependentEvents}
            dependentEventOptions={this.state.dependentEventOptions}
            accountId={this.props.accountId}
            templateId={this.state.data.id}
            setTemplates={this.props.setTemplates}
          />
        )}
        {this.state.viewEventDetailsDialogOpen && (
          <ViewEventDetailsDialog
            open
            onRequestClose={() => this.setState({ viewEventDetailsDialogOpen: false, eventToEdit: null })}
            data={this.state.eventToEdit}
            healthTreatments={this.state.healthTreatments}
            disableAll
            hasClose
          />
        )}
      </div>
    )
  }
}
