import React from 'react'
import Grid from '@material-ui/core/Grid'

import FormDialog from '../../../../components/Dialog/FormDialog'
import { TextField } from '../../../../components/Form/Form'
import Search from '../../../../components/Search'

import { deleteTemplateEventForAccount } from '../../../../data/manage_templates'

import '../../../../components/Dialog/Dialog.css'

const DeleteEventForm = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <TextField name='event' label='Event Name' disabled value={props.currentData.name} />
    </Grid>
    <Grid item xs={12}>
      {props.hasDependentEvents ? (
        <p>Select another event to replace the dependencies of other events on this event.</p>
      ) : (
        <p>No other events are dependent on this event.</p>
      )}
    </Grid>
    {props.hasDependentEvents && (
      <Grid item xs={12}>
        <Search
          name='dependentEventId'
          label='Dependent Event Name'
          placeholder='Search events'
          options={props.dependentEventOptions}
          onOptionClick={props.onDependentEventOptionClick}
        />
      </Grid>
    )}
  </Grid>
)

export default class DeleteEventDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      validationErrors: {},
      data: { ...props.eventToDelete, dependentEventId: undefined },
      // can't pick event about to be deleted
      dependentEventOptions: this.props.dependentEventOptions.filter((e) => e.value !== props.eventToDelete.id),
      canSubmit: !this.props.hasDependentEvents,
    }
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      validationErrors: {},
    })
  }

  onDependentEventOptionClick = (option) => this.validate({ dependentEventId: option.value })

  validate = (data) => {
    this.setState({
      data: { ...this.state.data, ...data },
      canSubmit: !this.props.hasDependentEvents || data.dependentEventId,
    })
  }

  submit = (data, reset, invalidate, force) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        const state = {
          error: result.error,
          validationErrors: result.data,
        }

        this.setState(state)
      } else {
        this.props.setTemplates(result.result, this.props.templateId)
        this.handleRequestClose()
      }
    }

    const formattedData = {
      dependentEventId: this.state.data.dependentEventId,
      force: force,
    }

    deleteTemplateEventForAccount(
      this.props.accountId,
      this.props.templateId,
      this.props.eventToDelete.id,
      formattedData,
    ).then(onSave)
  }

  render() {
    return (
      <div>
        <FormDialog
          wide
          width='800px'
          title='Delete Event Assignment'
          overflowVisible
          open={this.props.open}
          onClose={this.handleRequestClose}
          hasClose
          submitMessage='Delete'
          closeMessage='Cancel'
          FormComponent={DeleteEventForm}
          currentData={this.state.data}
          dependentEventOptions={this.state.dependentEventOptions}
          onDependentEventOptionClick={this.onDependentEventOptionClick}
          submit={this.submit}
          canSubmit={this.state.canSubmit}
          validate={this.validate}
          validationErrors={this.state.validationErrors}
          hasDependentEvents={this.props.hasDependentEvents}
        />
      </div>
    )
  }
}
