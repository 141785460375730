import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogHeader from './DialogHeader'
import { colors } from '../../styles/styleConstants'

import styled from 'styled-components'

export default ({ open, onClose, title, info }) => (
  <DialogContainer onClose={onClose} open={open} onBackdropClick={onClose}>
    <DialogHeader title={title} hasClose onClose={onClose} />
    <Info>{info}</Info>
  </DialogContainer>
)

const DialogContainer = styled(Dialog)`
  /* > div {
    width: 600px;
  } */
`

const Info = styled.div`
  width: 550px;
  margin: 20px 24px 24px;
  font-size: 12px;
  color: #555555;
  border-bottom: 1px solid ${colors.borderGrey};
`
