import MaterialTextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import '../../../../components/Dialog/Dialog.css'
import { SimpleFormDialog } from '../../../../components/Dialog/FormDialog'
import { FormField, FormRow } from '../../../../components/Form/Form'

export const EditFarmTreatmentDialog = ({ treatment, onRequestClose, doUpdate }) => {
  const [localTreatment, setLocalTreatment] = useState(treatment)

  const hasChanged = localTreatment !== treatment
  const valid =
    (localTreatment.packSize && localTreatment.salePrice) || (!localTreatment.packSize && !localTreatment.salePrice)

  const submit = () => {
    doUpdate(localTreatment)
  }

  return (
    <SimpleFormDialog
      wide
      width='80vw'
      title='Edit farm event Assignment'
      open={true}
      onClose={onRequestClose}
      canSubmit={hasChanged && valid}
      onSubmit={submit}
    >
      <FormRow style={{ marginBottom: '20px' }}>
        <FormField width='58%'>
          <MaterialTextField label='Name' value={treatment.name} disabled={true} />
        </FormField>
        <FormField width='20%'>
          <MaterialTextField disabled={true} value={treatment.uom} label='Unit of Measure' />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <MaterialTextField
            name='packSize'
            label='Pack Size'
            type='number'
            value={localTreatment.packSize}
            required={!!localTreatment.salePrice}
            onChange={(e) => setLocalTreatment({ ...localTreatment, packSize: e.target.value })}
          />
        </FormField>
        <FormField>
          <MaterialTextField
            name='salePrice'
            label='Sale Price'
            type='number'
            value={localTreatment.salePrice}
            required={!!localTreatment.packSize}
            onChange={(e) => setLocalTreatment({ ...localTreatment, salePrice: e.target.value })}
          />
        </FormField>
      </FormRow>
    </SimpleFormDialog>
  )
}
