import { getAuthHeaders } from '../helpers/firebase'
import { post, get, del } from '../helpers/fetch'
import config from 'config'

export const getCurrentNaitForFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/nait`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const postNaitForFarm = (farmId, data) => {
  const url = `${config.backend.url}/farm/${farmId}/nait`

  return getAuthHeaders()
    .then((headers) => post(url, data, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
        data: error.data,
      }
    })
}

export const deleteCountsForAnimalStatus = (farmId, status, animalType) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/${status}/${animalType}`

  return getAuthHeaders()
    .then((headers) => del(url, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
        data: error.data,
      }
    })
}

export const getNaitHistoryForFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/history`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const getNaitDetailsForReport = (farmId, reportId) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/${reportId}`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const getNaitCredentialsForFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/credentials`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const clearNaitCredentialsForFarm = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/credentials`

  return getAuthHeaders().then((headers) => del(url, headers))
}

export const setNaitCredentialsForFarm = (farmId, data) => {
  const url = `${config.backend.url}/farm/${farmId}/nait/credentials`

  return getAuthHeaders()
    .then((headers) => post(url, data, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}
