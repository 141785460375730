/* eslint-disable react/no-string-refs */
import React from 'react'
import DialogHeader from '../../components/Dialog/DialogHeader'
import _ from 'lodash'
import { SubmitButton } from '../../components/Form/Form'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ReviewForm from './Dialogs/ReviewDialog'
import ScrollableDialogContent from './Dialogs/ScrollableDialogContent'
import { withStyles } from '@material-ui/styles'
import { Key, EventTypeMap } from './FilterInfo'

const styles = () => ({
  dialog: {
    overflow: 'auto',
    maxHeight: '70vh',
  },
})

const reviewDialog = {
  key: Key.review,
  title: 'REVIEW',
  form: ReviewForm,
  values: {},
}

const defaultState = () => {
  return {
    open: false,
    filter: {
      name: '',
      data: {},
    },
    dialogs: [],
    index: 0,
    editingFilterName: '',
  }
}

/**
 * Dialog used for both Filters and Metrics.
 * [MetricInfo.js] and [FilterInfo.js] follow the same structure.
 */
class FilterDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      filter: {
        name: '',
        data: {},
      },
      dialogs: [props.root],
      index: 0,
      editingFilterName: '',
    }
  }

  componentDidUpdate(prevProps) {
    // required to refresh the dialogs whenever root changes
    if (JSON.stringify(prevProps.root) !== JSON.stringify(this.props.root)) {
      this.setState({
        dialogs: [this.props.root],
      })
    }
  }

  handleRequestClose = () => {
    this.props.onRequestClose()
    this.setState(defaultState())
  }

  submitDialog = () => {
    this.props.submit(this.state.filter, this.state.editingFilterName)
    this.handleRequestClose()
  }

  submitStage = (value) => {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      // todo show error
      return
    }

    if (this.isRoot()) {
      this.submitRootStage(value)
    } else {
      this.submitIntermediateStage(value)
    }
  }

  submitRootStage = (value) => {
    let data = this.state.filter.data
    if (this.state.filter.name !== value) {
      data = {}
    }

    // when no value dialogs are required (e.g. simple metrics)
    reviewDialog.values = this.collectReviewValues(value)
    let nextDialog = this.props.root.values[value][0] || reviewDialog

    this.setState({
      filter: {
        name: value,
        data: data,
      },
      dialogs: [...this.state.dialogs, nextDialog],
      index: 1,
    })
  }

  submitIntermediateStage = (value) => {
    let key = this.currentDialog().key
    let data = this.state.filter.data
    data[key] = value

    this.setState({
      filter: {
        name: this.state.filter.name,
        data: data,
      },
      dialogs: [...this.state.dialogs, this.nextDialog()],
      index: this.state.index + 1,
    })
  }

  goForward = () => {
    if (this.refs.formRef.submit) {
      this.refs.formRef.submit()
    } else if (this.refs.formRef.refs.component.submit) {
      this.refs.formRef.refs.component.submit()
    }
  }

  goBack = () => {
    if (this.isRoot()) {
      this.handleRequestClose()
      return
    }

    this.state.dialogs.pop()
    this.setState({
      index: this.state.index - 1,
    })
  }

  getItems = () => {
    return new Promise((resolve) => {
      let dialog = this.currentDialog()

      if (this.isRoot()) {
        resolve(Object.keys(dialog.values))
      } else if (dialog.resource) {
        const type = EventTypeMap[_.get(this.state, 'filter.data.type')] // event specific thing
        resolve(this.props.getResource(dialog.resource, type, this.props.animalType))
      } else {
        resolve(dialog.values)
      }
    })
  }

  render() {
    if (this.props.root === undefined) {
      return null
    }

    if (this.props.filter && this.isEmptyFilter()) {
      this.setState({
        filter: this.props.filter,
        editingFilterName: String(this.props.filter.name),
      })
      return null
    }

    let dialog = this.currentDialog()
    if (!dialog) {
      this.setState({
        dialogs: [this.props.root],
      })
      return null
    }

    const { classes } = this.props
    let onSubmit = this.isReview() ? this.submitDialog : this.submitStage
    let forwardLabel = this.isReview() ? 'COMPLETE' : 'NEXT'
    let FormName = this.currentDialog().form

    let dialogContent = (
      <FormName
        ref='formRef'
        items={this.getItems()}
        data={this.getCurrentDialogData(dialog)}
        back={false}
        submit={onSubmit}
        title={dialog.title}
        name={dialog.key}
        type={dialog.type}
        resource={dialog.resource}
      />
    )

    return (
      <Dialog onClose={this.handleRequestClose} open={this.props.open} classes={{ paper: classes.dialog }}>
        <DialogTitle>
          <DialogHeader title={dialog.title} handleRequestClose={this.handleRequestClose} />
        </DialogTitle>
        <ScrollableDialogContent>
          <div>{dialogContent}</div>
        </ScrollableDialogContent>
        <div className='ButtonWrap'>
          <Button className='Back dialogBack' onClick={this.goBack}>
            Back
          </Button>
          <SubmitButton onClick={this.goForward}>{forwardLabel}</SubmitButton>
        </div>
      </Dialog>
    )
  }

  currentDialog = () => {
    return this.state.dialogs[this.state.index]
  }

  isReview = () => {
    return this.currentDialog().key === Key.review
  }

  isRoot = () => {
    return this.currentDialog().key === Key.filter
  }

  isLast = () => {
    let filterName = this.state.filter.name
    let pages = this.props.root.values[filterName]

    return this.currentDialog().key === pages[pages.length - 1].key
  }

  isEmptyFilter = () => {
    return this.state.filter.name === ''
  }

  nextDialog = () => {
    const filterName = this.state.filter.name

    if (this.isLast()) {
      reviewDialog.values = this.collectReviewValues(filterName)
      return reviewDialog
    } else {
      let index = this.state.index
      let filter = this.props.root.values[this.state.filter.name]
      return filter[index]
    }
  }

  collectReviewValues = (filterName) => {
    const reviewValues = {
      Name: filterName,
    }
    Object.keys(this.state.filter.data).forEach((key) => {
      reviewValues[key] = this.state.filter.data[key]
    })
    return reviewValues
  }

  getCurrentDialogData = (dialog) => {
    if (this.isRoot()) {
      return this.state.filter.name
    } else {
      return this.state.filter.data[dialog.key]
    }
  }
}

export default withStyles(styles)(FilterDialog)
