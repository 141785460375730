import React from 'react'
import { withFormValidation, SelectField, SubmitButton } from '../../../components/Form/Form'
import { requireAuth } from '../../../components/withAuth'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { getAllNonIdGroupsForUser, getRecordsForNonIdGroup } from '../../../data/animal_group_history'
import { colors } from '../../../styles/styleConstants'

import '../../../components/Dialog/Dialog.css'

const AnimalTypeFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <SelectField
          name='animalType'
          label='Animal Type'
          backgroundColor={colors.green}
          color={colors.white}
          choices={props.options}
          required
          showPlaceholder
        />
      </div>

      <div className='ButtonWrap'>
        <Button disabled={true}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Continue</SubmitButton>
      </div>
    </div>
  )
}

const AnimalTypeForm = withFormValidation(AnimalTypeFormContent)

const GroupFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <SelectField
          name='group'
          label='Group'
          backgroundColor={colors.green}
          color={colors.white}
          choices={props.options}
          required
          showPlaceholder
        />
      </div>

      <div className='ButtonWrap'>
        <Button onClick={props.handleBack}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Continue</SubmitButton>
      </div>
    </div>
  )
}

const GroupForm = withFormValidation(GroupFormContent)

class GroupDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.handleSubmitGroup = this.handleSubmitGroup.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handleSubmitAnimalType = this.handleSubmitAnimalType.bind(this)
    this.handleBack = this.handleBack.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
      groups: [],
      groupNames: [],
      activeStep: 0,
    }
  }

  componentDidMount() {
    getAllNonIdGroupsForUser(this.props.farmId).then((groups) => {
      this.setState({
        groups: groups,
        groupNames: groups.map((group) => [group.id, `${group.animalType}-${group.year}-${group.name}`]),
      })
    })
  }

  validate() {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleSubmitAnimalType(data) {
    console.log(data)
    this.setState({
      activeStep: 1,
      selectedAnimalType: data.animalType,
    })
  }

  handleSubmitGroup(data) {
    var group = this.state.groups.find((group) => {
      return group.id === data.group
    })
    getRecordsForNonIdGroup(this.props.farmId, group.id).then((results) => {
      this.handleRequestClose({
        group: group,
        results: results,
      })
    })
  }

  handleRequestClose(data) {
    this.props.onRequestClose(data)

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  handleBack() {
    this.setState({
      activeStep: 0,
    })
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <AnimalTypeForm
            options={[
              ['Cattle', 'Cattle'],
              ['Deer', 'Deer'],
              ['Sheep', 'Sheep'],
            ]}
            submit={this.handleSubmitAnimalType}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      case 1:
        return (
          <GroupForm
            options={this.state.groupNames.filter((g) => g[1].split('-')[0] === this.state.selectedAnimalType)}
            submit={this.handleSubmitGroup}
            handleBack={this.handleBack}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      default:
        return ''
    }
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog className='animalGroupDialog' onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Non-ID Group' handleRequestClose={this.handleRequestClose} />

        <div className='formContent'>{this.getStepContent(this.state.activeStep)}</div>
      </Dialog>
    )
  }
}

export default requireAuth(GroupDialog)
