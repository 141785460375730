import { sleep } from '../../helpers/utils'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings } from './dashboard'

export const SurvivalRateMetric: DashboardMetric = {
  type: 'Survival Rate',
  animalType: AnimalType.Cattle,
  timePeriod: 'Last 3 Years',
}

export const SurvivalRateChart: DashboardMetricChartSettings = {
  type: 'LineChart',
  options: {
    chartArea: { width: '80%', height: '80%' },
    legend: 'top',
    curveType: 'function',
    hAxis: {
      viewWindow: {
        min: 2019,
        max: 2021,
      },
      ticks: [
        { v: 2019, f: '2019' },
        { v: 2020, f: '2020' },
        { v: 2021, f: '2021' },
      ],
    },
    colors: ['#CC0000', '#F1C232', 'green'],
  },
}

export async function calculateSurvivalRate(farmId: string, metric: DashboardMetric): Promise<[[]]> {
  sleep(1000)

  return [
    ['Year', 'Farm', 'Goal', 'Benchmark'],
    [2019, 0.735, 0.74, 0.793],
    [2020, 0.757, 0.76, 0.798],
    [2021, 0.782, 0.78, 0.802],
  ]
}
