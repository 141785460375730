import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import './Slider.css'

import RCSlider from 'rc-slider/lib/Slider'
import React from 'react'

interface SliderFieldProps {
  value: number
  setValue: (val: number) => void
  getDisplayValue: (val: number) => string
  label: string
  error?: string
}
export const SliderField = ({ value, setValue, getDisplayValue, label, error, ...props }: SliderFieldProps) => {
  const displayValue = getDisplayValue(value)

  return (
    <div className={'SliderField ' + (error ? 'error' : '')}>
      <label>{label}</label>

      <RCSlider value={value} onChange={setValue} {...props} />
      <p>{displayValue}</p>
      {error ? <p className='error'>{error}</p> : null}
    </div>
  )
}
