import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

class BottomSnackbar extends Component {
  render() {
    return (
      <Snackbar
        open={this.props.open}
        onClose={this.props.handleRequestClose}
        transition={<Slide direction={'up'} />}
        autoHideDuration={3000}
        SnackbarContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{this.props.message}</span>}
      />
    )
  }
}

export default BottomSnackbar
