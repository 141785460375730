import React from 'react'
import {
  SelectField,
  TextField,
  IntRangeField,
  FormsyDateField,
  FormRow,
  FormField,
} from '../../../../components/Form/Form'

import FormDialog from '../../../../components/Dialog/FormDialog'
import { colors } from '../../../../styles/styleConstants'
import { addPurchaseToHealthTreatment } from '../../../../data/health_treatments'
import moment from 'moment'
import { uomChoices } from '../../../../constants'

import '../../../../components/Dialog/Dialog.css'

const AddPurchaseForm = (props) => (
  <div>
    <FormRow>
      <FormField width='48%'>
        <FormsyDateField name='expiryDate' label='Expiration Date' required />
      </FormField>
      <FormField width='48%'>
        <TextField name='batchNumber' label='Batch Number' required />
      </FormField>
    </FormRow>
    <FormRow>
      <FormField width='48%'>
        <IntRangeField name='purchaseQuantity' label='Purchase Qty' required low='0' high='1000000000' step='0.01' />
      </FormField>
      <FormField width='48%'>
        <SelectField
          name='uom'
          width='100%'
          choices={uomChoices}
          backgroundColor={colors.grey}
          color={colors.white}
          disabled
          value={props.currentData.uom}
          label='Unit of Measure'
          fullWidth={false}
          showPlaceholder
        />
      </FormField>
    </FormRow>
  </div>
)

class AddPurchaseDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      validationErrors: {},
      data: {},
    }
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      validationErrors: {},
    })
  }

  validate = (data) => {
    const errors = {}

    this.setState({
      validationErrors: errors,
      data: { ...this.state.data, ...data },
    })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.props.reloadTreatments()
        this.handleRequestClose()
      }
    }

    const formattedData = {
      batchNumber: data.batchNumber,
      expiryDate: moment(data.expiryDate).format('YYYY-MM-DD'),
      purchaseQuantity: parseInt(data.purchaseQuantity, 10),
    }

    addPurchaseToHealthTreatment(this.props.farmId, this.props.treatment.id, formattedData).then(onSave)
  }

  async getInitialData() {
    // today's date is the default
    return { expiryDate: new Date() }
  }

  render() {
    return (
      <FormDialog
        wide
        width='600px'
        title='Add Purchase'
        open={this.props.open}
        onClose={this.handleRequestClose}
        FormComponent={AddPurchaseForm}
        currentData={{ uom: this.props.treatment.uom }}
        submit={this.submit}
        validate={this.validate}
        validationErrors={this.state.validationErrors}
        getInitialData={this.getInitialData}
      />
    )
  }
}

export default AddPurchaseDialog
