// @ts-ignore
import config from 'config'
import React from 'react'
import styled from 'styled-components'

import { useAccount } from '../../components/AccountContext'
import { infoSiteURL } from '../../helpers/constants'
import { maxContainerWidth } from '../../styles/styleConstants'
import Logo from '../Logo'
import UserStatus from '../UserStatus'
import Dropdown from './Dropdown'

// if initial pageload has ?ff=1 we show the feature-flagged screens
const ff = config.mode === 'development' || window.location.search.match('ff=1')  // eslint-disable-line

const Header = () => {
  const path = window.location.pathname

  const { user, hasExplicitFarmRole, hasExplicitAccountRole, hasExplicitSubscriptionType } = useAccount()

  return (
    <HeaderContainer>
      <StyledHeader>
        <Logo />
        {user?.authenticated && (
          <MenuContainer className='hide-on-mobile'>
            <Dropdown
              category='Planning'
              pages={[
                ...(hasExplicitAccountRole(['Clinic', 'Vet', 'Account']) || hasExplicitFarmRole(['Farm', 'Staff'])
                  ? [{ name: 'Farm Plans', path: '/farm-plans' }]
                  : []),
                ...(hasExplicitAccountRole(['Clinic', 'Vet', 'Retail', 'Account']) ||
                hasExplicitFarmRole(['Farm', 'Staff'])
                  ? [{ name: 'Health Treatments', path: '/health-treatments' }]
                  : []),
                ...(hasExplicitAccountRole(['Clinic', 'Admin', 'Vet'])
                  ? [{ name: 'Manage Treatments', path: '/manage-treatments' }]
                  : []),

                ...(hasExplicitAccountRole(['Clinic', 'Admin'])
                  ? [
                      { name: 'Manage Events', path: '/manage-events' },
                      { name: 'Manage Templates', path: '/manage-templates' },
                    ]
                  : []),

                ...(hasExplicitAccountRole(['Clinic', 'Vet']) ? [{ name: 'Manage Plans', path: '/manage-plans' }] : []),
              ]}
              path={path}
            />
            <Dropdown
              category='Reporting'
              pages={[
                { name: 'Dashboard', path: '/dashboard' },
                ...(hasExplicitAccountRole(['Clinic', 'Vet', 'Admin', 'Account']) ||
                hasExplicitFarmRole(['Farm', 'Staff', 'Viewer'])
                  ? [
                      { name: 'Analysis', path: '/analysis' },
                      ...(hasExplicitFarmRole(['Account', 'Farm']) ? [{ name: 'NAIT Reporting', path: '/nait' }] : []),
                      ...(hasExplicitAccountRole(['Clinic', 'Admin', 'Vet'])
                        ? [{ name: 'Forecast', path: '/forecast' }]
                        : []),
                    ]
                  : []),
              ]}
              path={path}
            />
            {(hasExplicitAccountRole(['Clinic', 'Vet', 'Admin', 'Account', 'Retail']) ||
              hasExplicitFarmRole(['Farm', 'Staff', 'Viewer'])) && (
              <Dropdown
                category='Records'
                pages={[
                  ...(hasExplicitAccountRole(['Clinic', 'Vet', 'Admin', 'Account']) ||
                  hasExplicitFarmRole(['Farm', 'Staff', 'Viewer'])
                    ? [
                        { name: 'Animals', path: '/animals' },
                        { name: 'Non-ID Groups', path: '/non-id-groups' },
                      ]
                    : []),
                  ...((hasExplicitAccountRole(['Account', 'Clinic', 'Vet']) || hasExplicitFarmRole(['Farm', 'Vet'])) &&
                  hasExplicitSubscriptionType(['Standard', 'Full'])
                    ? [{ name: 'Import Records', path: '/import-records' }]
                    : []),
                  ...(hasExplicitAccountRole(['Account', 'Clinic', 'Retail', 'Vet']) || hasExplicitFarmRole(['Farm'])
                    ? [{ name: 'Import Tags', path: '/import-tags' }]
                    : []),
                ]}
                path={path}
              />
            )}
            <Dropdown
              category='Support'
              pages={[
                { name: 'Getting Started', href: `${infoSiteURL}/appdocgetstart`, target: '_blank' },
                { name: 'Android App', href: `${infoSiteURL}/appdoc`, target: '_blank' },
                { name: 'Web Portal', href: `${infoSiteURL}/webdoc`, target: '_blank' },
                { name: 'Key Concepts', href: `${infoSiteURL}/appdockey`, target: '_blank' },
                { name: 'Use Cases', href: `${infoSiteURL}/usecasedoc`, target: '_blank' },
                // { name: 'Improve Results', href: `${infoSiteURL}/improveresults`, target: '_blank' },
                ...(hasExplicitAccountRole(['Clinic', 'Admin', 'Vet', 'Retail'])
                  ? [{ name: 'Vet Content', href: `${infoSiteURL}/vetcontent`, target: '_blank' }]
                  : []),
              ]}
              href={`${infoSiteURL}/support`}
              target='_blank'
            />
          </MenuContainer>
        )}
        {user && <UserStatus user={user} path={path} />}
      </StyledHeader>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 2;
`

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 58px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #000000;

  @media (min-width: ${maxContainerWidth + 40}px) {
    padding-left: calc(50vw - ${maxContainerWidth / 2}px);
    padding-right: calc(50vw - ${maxContainerWidth / 2}px);
  }

  @media (max-width: 767px) {
    font-size: 80%;
    padding-right: 0;
  }

  .Logo {
    flex: 0;

    @media (max-width: 767px) {
      svg {
        width: 150px;
        margin-top: 3px;
      }
    }
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 60px;
`

export default Header
