export enum AnimalType {
  Cattle = 'Cattle',
  Deer = 'Deer',
  Sheep = 'Sheep',
}

export const AnimalTypes = [AnimalType.Cattle, AnimalType.Deer, AnimalType.Sheep]

export interface Farm {
  readonly accountId: number
  readonly active: boolean
  readonly businessName: string
  readonly farmManagerName: string
  readonly farmName: string
  readonly farmPostalCode: string
  readonly id: string
  readonly naitNumber: number
  readonly subscriptionStatus: string
  readonly userRole: string
}

export interface UserAccount {
  readonly address1: string
  readonly address2: string
  readonly businessName: string
  readonly city: string
  readonly contactName: string
  readonly id: number
  readonly postalCode: string
  readonly region: string
  readonly subscriptionStatus: string
}
