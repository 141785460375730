import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'

const NoMatch = () => (
  <Layout title='Page not found' className='NoMatch'>
    <p>That page doesn&apos;t exist.</p>
    <p>
      <Link to='/'>Back to the homepage</Link>
    </p>
  </Layout>
)

export default NoMatch
