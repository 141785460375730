import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import TableCell from '@material-ui/core/TableCell'
import { colors } from '../../styles/styleConstants'
import ActionOverflow from './ActionOverflow'
import ChevronRight from '@material-ui/icons/ChevronRight'

const DefaultCell = ({ item, value, actions, ...props }) => {
  if (actions || props.hasSubRows) {
    const actionsForRow = _.get(actions, _.get(item, 'actionsOption', 'default'), actions)

    return (
      <Cell {...props}>
        <Flex>
          {value}
          {actionsForRow && <ActionOverflow actions={actionsForRow} id={item.id} />}
          {props.hasSubRows && (
            <ExpandContainer
              onClick={props.expanded ? () => props.collapseRow(item.id) : () => props.expandRow(item.id)}
            >
              <ExpandButton expanded={props.expanded}>
                <ChevronRight />
              </ExpandButton>
            </ExpandContainer>
          )}
        </Flex>
      </Cell>
    )
  }

  return <Cell {...props}>{value}</Cell>
}

export default DefaultCell

export const Cell = styled(TableCell)`
  color: ${(props) => {
    if (_.get(props, 'style.color')) {
      return props.style.color
    }

    return props.disabled ? '#b6b6b6' : '#7d7d7d'
  }} !important; // override MUI default
  border-bottom: 1px solid #e0e0e0;

  a {
    color: ${colors.lightGreen};
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const ExpandContainer = styled.div`
  cursor: pointer;
`

const ExpandButton = styled.div`
  svg {
    transform: ${(props) => (props.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
  }
`

// minimal table cell
export const SimpleCell = ({ children, ...props }) => (
  <Cell {...props}>
    <Flex>{children}</Flex>
  </Cell>
)
