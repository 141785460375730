import Formsy from 'formsy-react'
import React from 'react'

import { colors } from '../styles/styleConstants'
import DropdownContainer from './DropdownContainer'
import { SELECT_PLACEHOLDER_OPTION_VALUE, SelectField } from './Form/Form'

interface Farm {
  id: string
  farmName: string
  active?: boolean
}

interface Props {
  readonly farms: Farm[]
  readonly farmId?: string
  readonly selectedFarmName?: string
  readonly handleFarmSelected: (farmId: string, farmName: string) => void
  readonly deselectFarm?: () => void
  readonly disabled?: boolean
  readonly margin?: string
}

// caller should provide farmId, not selectedFarmName. The latter is deprecated
// but kept for backwards compatibility
const FarmSelector: React.FunctionComponent<Props> = ({
  farms,
  farmId,
  selectedFarmName,
  handleFarmSelected,
  deselectFarm,
  disabled = false,
  margin = '0px',
}): JSX.Element => {
  if (!farms) {
    return <p>Loading...</p>
  }

  const setOption = (farmId: string): void => {
    if (farmId === SELECT_PLACEHOLDER_OPTION_VALUE && deselectFarm) {
      deselectFarm()
      return
    }
    const selectedFarm = farms.find((farm) => farm.id === farmId)
    if (selectedFarm) {
      handleFarmSelected(farmId, selectedFarm.farmName)
    }
  }

  const options = farms
    .filter((farm) => farm.active === undefined || farm.active)
    .map((farm) => [farm.id, farm.farmName])

  return (
    <DropdownContainer margin={margin}>
      <Formsy>
        <SelectField
          name='farmSelector'
          choices={options}
          placeholder='Select Farm'
          showPlaceholder
          setOption={setOption}
          disabled={disabled}
          backgroundColor={colors.green}
          color={colors.white}
          value={farmId || selectedFarmName}
        />
      </Formsy>
    </DropdownContainer>
  )
}

export default FarmSelector
