import React from 'react'
import { Redirect } from 'react-router-dom'
import { addAuthHandler, removeAuthHandler } from '../helpers/auth'
import Layout from '../components/Layout'
import Loading from './Loading'

const Loader = () => <Loading />

export const AccessDenied = ({ user = null }) => (
  <Layout>
    <h3>Access denied</h3>
    <p>{user ? user.email : 'User account'} does not have permission to access this screen. </p>
  </Layout>
)

/**
 * Wrap a component to provide auth details as a props
 * @param  {Function} content render content for the component
 * @param  {React.Component} [loading=Loader] element to show while loading.
 * If null, will render the wrapped component with the user property set to
 * null instead
 * @return {React.Component} wrapped component
 */
const withAuth = (content, loading = Loader) => {
  return class WithAuth extends React.Component {
    constructor(props) {
      super(props)
      this.handleAuth = this.handleAuth.bind(this)
      this.state = {}
    }

    handleAuth(user) {
      this.setState({
        user,
      })
    }

    componentDidMount() {
      addAuthHandler(this.handleAuth)
    }

    componentWillUnmount() {
      removeAuthHandler(this.handleAuth)
    }

    render() {
      // TODO handle the user getting logged out more helpfully - show in
      // overlay?

      // console.log('>>', this.state.user)

      // Render nothing while waiting for authentication.
      // Once authentication has completed, render the component if the user
      // is logged in, or a redirect if not

      // console.log('>', this.props)

      if (this.state.user || !loading) {
        return content(this.state.user, this.props)
      } else {
        return loading()
      }
      // if (this.props.noLoader) {
      //   return (
      //     <div>
      //       { this.state.user
      //         ? content(this.state.user, this.props)
      //         : null
      //       }
      //     </div>
      //   )
      // } else {
      //   return (
      //     <LoadingWrapper loading={!this.state.user}>
      //       { this.state.user
      //         ? content(this.state.user, this.props)
      //         : null
      //       }
      //     </LoadingWrapper>
      //   )
      // }

      // return this.state.user
      //   ? (this.state.user.authenticated
      //      ? authenticated(user = this.state.user, ...this.props)
      //      : (redirect
      //           ? <Redirect to={{
      //             pathname: '/login',
      //             state: {from: this.props.location}
      //           }} />
      //           : <p>Hello</p>)
      //     )
      //   : loading()
    }
  }
}

/**
 * render a component with the current user as a prop
 * @param  {React.component} WrappedComponent
 * @param  {Object} [options={}]
 * @return {React.component}
 */
const withUser = (WrappedComponent, options = {}) => {
  // TODO get details from users node too and pass through here
  const { loading = Loader } = options

  return withAuth((user, props) => {
    return <WrappedComponent user={user} {...props} />
  }, loading)
}

/**
 * render a component with the current user as a prop, or a redirect if no
 * user is authenticated
 * @param  {React.component} WrappedComponent
 * @param  {Object} [options={}]
 * @return {React.component}
 */
const requireAuth = (WrappedComponent, options = {}) => {
  const { loading = Loader, redirectUrl = '/sign-in', testOnly = false, noRef = false } = options

  return withAuth((user, props) => {
    if (testOnly && user && user.uid !== 'PQjlgKPs8khwWQh96ekgyBxFoLs1') {
      return <AccessDenied user={user} />
    }

    if (user && !user.authenticated) {
      return (
        <Redirect
          to={{
            pathname: redirectUrl,
            state: { from: props.location },
          }}
        />
      )
    }

    if (noRef) {
      return <WrappedComponent {...props} user={user} />
    }

    //DO NOT REMOVE ref='component'! It will break a lot of filters @TODO find workaround for this deprecated method
    // eslint-disable-next-line react/no-string-refs
    return <WrappedComponent ref='component' {...props} user={user} />
  }, loading)
}

export { withUser, requireAuth }
