import React from 'react'
import {
  SelectField,
  TextField,
  CheckboxField,
  FormRow,
  FormField,
  PositiveNumberField,
} from '../../../../components/Form/Form'
import FormDialog from '../../../../components/Dialog/FormDialog'
import { colors } from '../../../../styles/styleConstants'
import { addHealthTreatmentToFarm, editHealthTreatmentForFarm } from '../../../../data/health_treatments'
import { uomChoices } from '../../../../constants'

import '../../../../components/Dialog/Dialog.css'

const AddTreatmentForm = (props) => (
  <>
    <FormRow>
      <FormField width='58%'>
        <TextField name='name' label='Name' value={props.currentData.name} disabled={props.currentData.name} required />
      </FormField>
      <FormField width='20%'>
        <CheckboxField name='rvm' label='RVM' checked={props.currentData.rvm === 'Y'} />
      </FormField>
      <FormField width='20%'>
        {props.currentData.uom ? (
          <SelectField
            name='uom'
            width='100%'
            choices={uomChoices}
            backgroundColor={colors.grey}
            color={colors.white}
            disabled
            value={props.currentData.uom}
            label='Unit of Measure'
            fullWidth={false}
            showPlaceholder
          />
        ) : (
          <SelectField
            name='uom'
            width='100%'
            choices={uomChoices}
            backgroundColor={colors.green}
            color={colors.white}
            defaultValue={props.currentData.uom}
            label='Unit of Measure'
            fullWidth={false}
            required
            showPlaceholder
          />
        )}
      </FormField>
    </FormRow>
    <FormRow>
      <FormField>
        {/* NOTE formsy doesn’t like undefined as a value, and won’t let the form
        submit until something is in the fields, even if not required */}
        <PositiveNumberField
          name='meatWitholdingPeriod'
          label='Meat WHP (days)'
          value={props.currentData.meatWitholdingPeriod || ''}
        />
      </FormField>
      <FormField>
        <PositiveNumberField
          name='milkWitholdingPeriod'
          label='Milk WHP (days)'
          value={props.currentData.milkWitholdingPeriod || ''}
        />
      </FormField>
      <FormField>
        <PositiveNumberField
          name='produceWitholdingPeriod'
          label='Wool WHP (days)'
          value={props.currentData.produceWitholdingPeriod || ''}
        />
      </FormField>
      <FormField>
        <PositiveNumberField name='alertQuantity' label='Alert Quantity' value={props.currentData.alertQuantity} />
      </FormField>
    </FormRow>
    <FormRow>
      <FormField width='58%'>
        <TextField name='instructions' label='Use Instructions' value={props.currentData.instructions} multiline />
      </FormField>
      <FormField width='40%'>
        <TextField
          name='label'
          type='url'
          label='Label (e.g. http://stockmanager.co.nz)'
          value={props.currentData.label}
          multiline
        />
      </FormField>
    </FormRow>
  </>
)

class AddTreatmentDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      hasChanged: false,
    }
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  validate = () => {
    this.setState({ hasChanged: true })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.props.reloadTreatments()
        this.handleRequestClose()
      }
    }

    const formattedData = {
      ...data,
      milkWitholdingPeriod: parseInt(data.milkWitholdingPeriod, 10),
      meatWitholdingPeriod: parseInt(data.meatWitholdingPeriod, 10),
      produceWitholdingPeriod: parseInt(data.produceWitholdingPeriod, 10),
      alertQuantity: parseInt(data.alertQuantity, 10),
    }

    if (this.props.data) {
      editHealthTreatmentForFarm(this.props.farmId, this.props.data.id, formattedData).then(onSave)
    } else {
      addHealthTreatmentToFarm(this.props.farmId, formattedData).then(onSave)
    }
  }

  render() {
    return (
      <FormDialog
        wide
        width='70vw'
        title={`${this.props.data ? 'Edit' : 'Add'} Treatment`}
        open={this.props.open}
        onClose={this.handleRequestClose}
        FormComponent={AddTreatmentForm}
        currentData={this.props.data || {}}
        submit={this.submit}
        validate={this.validate}
        hasChanged={this.state.hasChanged}
      />
    )
  }
}

export default AddTreatmentDialog
