import { getAuthHeaders } from '../helpers/firebase'
import { put, get } from '../helpers/fetch'
import config from 'config'

export const getNotificationSettings = (farmId) => {
  const url = `${config.backend.url}/farm/${farmId}/settings`

  return getAuthHeaders().then((headers) => get(url, headers).then((data) => data.notifications))
}

export const setNotificationSettings = (farmId, notifications) => {
  const url = `${config.backend.url}/farm/${farmId}/settings`

  return getAuthHeaders().then((headers) => put(url, notifications, headers).then((data) => data.notifications))
}
