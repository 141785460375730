import React from 'react'
import AnimalDialog from './Dialogs/AnimalDialog'
import GroupDialog from './Dialogs/GroupDialog'
import MaterialTable from '../../components/Table/Table.js'

import './Analysis.css'

const animalColumns = [
  { id: 'animalType', numeric: false, label: 'Animal Type' },
  { id: 'year', numeric: false, label: 'Visual Tag Year' },
  { id: 'vid', numeric: false, label: 'Visual Tag #' },
  { id: 'color', numeric: false, label: 'Tag Colour' },
  { id: 'eid', numeric: false, label: 'EID' },
]

const groupColumns = [
  { id: 'animalType', numeric: false, label: 'Animal Type' },
  { id: 'year', numeric: false, label: 'Non-ID Group Year' },
  { id: 'name', numeric: false, label: 'Non-ID Group Name' },
]

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.handleRequestCloseAnimal = this.handleRequestCloseAnimal.bind(this)
    this.handleRequestCloseGroup = this.handleRequestCloseGroup.bind(this)

    this.state = {
      selectedAnimal: 'No animal selected',
      selectedGroup: 'No non-id group selected',
      canEdit: true,
    }
  }

  handleRequestCloseAnimal(results) {
    if (results.tags) {
      let tagInfo = {
        animalType: results.tags[0].animalType,
      }
      for (var tag of results.tags) {
        if (tag.eid) {
          tagInfo.eid = tag.eid
        } else {
          tagInfo.vid = tag.vid
          tagInfo.year = tag.year
          tagInfo.color = tag.color
        }
      }
      this.setState({
        selectedAnimal: {
          animalType: tagInfo.animalType,
          year: tagInfo.year,
          vid: tagInfo.vid,
          color: tagInfo.color,
          eid: tagInfo.eid,
        },
      })
    }

    this.props.handleAnimalSelected(results)
  }

  handleRequestCloseGroup(data) {
    if (data.group) {
      this.setState({
        selectedGroup: {
          animalType: data.group.animalType,
          year: data.group.year,
          name: data.group.name,
        },
      })
    }

    this.props.handleGroupSelected(data.results)
  }

  render() {
    return (
      <div className='farmSelectorContainer'>
        {this.props.showAnimalSection ? (
          <MaterialTable expand columns={animalColumns} data={[this.state.selectedAnimal]} />
        ) : (
          ''
        )}

        {this.props.showGroupSection ? (
          <MaterialTable expand columns={groupColumns} data={[this.state.selectedGroup]} />
        ) : (
          ''
        )}

        {this.props.animalDialogOpen ? (
          <AnimalDialog
            open={true}
            onRequestClose={this.handleRequestCloseAnimal}
            farmId={this.props.farmId}
            farmData={this.props.farmData}
          />
        ) : null}

        {this.props.groupDialogOpen && this.props.farmId ? (
          <GroupDialog
            open={true}
            onRequestClose={this.handleRequestCloseGroup}
            farmId={this.props.farmId}
            farmData={this.props.farmData}
          />
        ) : null}
      </div>
    )
  }
}

export default Search
