/* eslint-disable jsx-a11y/anchor-is-valid */
import './../ImportTags.css'

import * as React from 'react'

import MaterialTable from '../../../components/Table/Table'
import Typography from '../../../components/Typography'
import { TagDetails } from '../../../data/import_tags'

const tagDetailsColumns = [
  {
    id: 'eid',
    numeric: false,
    label: 'EID',
  },
  {
    id: 'vid',
    numeric: false,
    label: 'Visual Tag',
  },
  {
    id: 'animalType',
    numeric: false,
    label: 'Animal Type',
  },
  {
    id: 'year',
    numeric: true,
    label: 'VID Year',
  },
  { id: 'color', numeric: false, label: 'Tag Colour' },
  { id: 'completedLabel', numeric: false, label: 'Status' },
  { id: 'activeLabel', numeric: false, label: 'Active' },
]

interface TagDetailsTableData extends TagDetails {
  readonly completedLabel: string
  readonly activeLabel: string
}

export interface ImportDetailsTableProps {
  readonly tagDetailsData: readonly TagDetails[]
}

const ImportDetailsTable: React.FunctionComponent<ImportDetailsTableProps> = ({ tagDetailsData }) => {
  const tableData: TagDetailsTableData[] = tagDetailsData.map((row) => ({
    ...row,
    completedLabel: row.loaded ? 'Complete' : 'Incomplete',
    activeLabel: row.used ? 'Yes' : 'No',
  }))
  return (
    <React.Fragment>
      <Typography variant='h5'>Tag Details</Typography>
      {tagDetailsData && tagDetailsData.length > 0 && <MaterialTable columns={tagDetailsColumns} data={tableData} />}
    </React.Fragment>
  )
}

export default ImportDetailsTable
