import React from 'react'
import { withFormValidation, SubmitButton } from '../../../components/Form/Form'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { requireAuth } from '../../../components/withAuth'
import DialogHeader from '../../../components/Dialog/DialogHeader'

import '../../../components/Dialog/Dialog.css'

const TransferFarmFormContent = (props) => (
  <div>
    <p>Functionality coming soon.</p>
    <div className='ButtonWrap'>
      <Button variant='contained' className='Back dialogBack' onClick={props.handleRequestClose}>
        Cancel
      </Button>
      <SubmitButton disabled={!props.canSubmit}>Request Transfer</SubmitButton>
    </div>
  </div>
)

const TransferFarmForm = withFormValidation(TransferFarmFormContent)

class TransferFarmDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
    }
  }

  validate() {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
    })
  }

  submit() {
    this.handleRequestClose()
    // const onSave = ({success, ...result}) => {
    //   if (!success) {
    //     this.setState({
    //       error: result.error
    //     })
    //   } else {
    //     this.handleRequestClose()
    //   }
    // }
  }

  render() {
    return (
      <Dialog onClose={this.handleRequestClose} open={this.props.open}>
        <DialogHeader title='Transfer Farm' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <TransferFarmForm
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(TransferFarmDialog)
