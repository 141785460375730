import React from 'react'
import { SelectField, FormsyDateField, FormRow, FormField } from '../../../../components/Form/Form'
import FormDialog from '../../../../components/Dialog/FormDialog'
import { colors } from '../../../../styles/styleConstants'
import { choiceMap, formatChoices } from '../../../../constants'
import _ from 'lodash'
import moment from 'moment'

import { getAllFarmPlansShort, createWorksheet } from '../../../../data/plans'

import '../../../../components/Dialog/Dialog.css'

const CreateWorksheetForm = ({
  currentData,
  typeChoices,
  classDisabled,
  classChoices,
  categoryDisabled,
  categoryChoices,
  templateChoices,
  templateDisabled,
}) => (
  <div>
    <FormRow mb='30px' width='100%'>
      <FormField width='24%'>
        <SelectField
          name='planType'
          placeholder='Plan type'
          width='100%'
          choices={typeChoices}
          backgroundColor={colors.green}
          color={colors.white}
          value={currentData.planType}
          required
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='planClass'
          placeholder='Plan class'
          width='100%'
          choices={classChoices}
          backgroundColor={classDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={classDisabled}
          value={currentData.planClass}
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='planCategory'
          placeholder='Category'
          width='100%'
          choices={categoryChoices}
          backgroundColor={categoryDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={categoryDisabled}
          value={currentData.planCategory}
          showPlaceholder
        />
      </FormField>
      <FormField width='24%'>
        <SelectField
          name='templateId'
          placeholder='Template'
          width='100%'
          choices={templateChoices}
          backgroundColor={templateDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={templateDisabled}
          value={currentData.templateId}
          showPlaceholder
        />
      </FormField>
    </FormRow>
    <FormRow>
      <FormField width='45%'>
        <FormsyDateField name='startDate' label='Worksheet Start Date' value={currentData.startDate} required />
      </FormField>
      <FormField width='45%'>
        <FormsyDateField name='endDate' label='Worksheet End Date' value={currentData.endDate} required />
      </FormField>
    </FormRow>
  </div>
)

export default class AddTemplateDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      hasChanged: false,
      data: {},
      typeChoices: formatChoices(_.keys(choiceMap)),
      classChoices: [],
      classDisabled: true,
      categoryChoices: [],
      categoryDisabled: true,
      templateDisabled: true,
      templateChoices: [],
      templates: [],
    }
  }

  async componentDidMount() {
    const templates = await getAllFarmPlansShort(this.props.farmId)
    this.setState({ templates })
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  validate = (data) => {
    let templateChoices = []
    const noCategory = data.planType === 'Farm' && data.planClass !== 'Stock'
    const validationErrors = {}

    if (data.planType !== this.state.data.planType) {
      data.templateId = data.planClass = undefined
    }

    if (data.planClass !== this.state.data.planClass) {
      data.templateId = data.planCategory = undefined
    }

    if (data.planCategory !== this.state.data.planCategory) {
      data.templateId = undefined
    }

    const everythingPicked = data.planType && data.planClass && (data.planCategory || noCategory)

    if (everythingPicked) {
      templateChoices = this.state.templates
        .filter(
          (row) =>
            row.planType == data.planType &&
            row.planClass == data.planClass &&
            row.planCategory == (data.planCategory || null),
        )
        .map((row) => [row.id, row.name || 'Default'])
    }

    if (data.startDate > data.endDate) {
      validationErrors.endDate = 'End date should be greater than start date.'
    }

    this.setState({
      data: {
        ...this.state.data,
        ...data,
      },
      classChoices: _.get(choiceMap, `${data.planType}.planClass`, []),
      classDisabled: false,
      categoryChoices: _.get(choiceMap, `${data.planType}.planCategory`, []),
      categoryDisabled: !data.planClass || noCategory,
      templateDisabled: !data.planClass || !data.planCategory,
      templateChoices,
      validationErrors,
    })
  }

  submit = (data) => {
    createWorksheet(
      this.props.farmId,
      {
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
        planId: data.templateId,
        ..._.pick(data, ['templateId', 'planType', 'planClass', 'planCategory']),
      },
      `${data.planType} - ${moment(data.startDate).format('YYYY-MM-DD')}.xlsx`,
    )
  }

  async getInitialData() {
    // today's date is the default
    return {
      startDate: new Date(),
      endDate: moment()
        .add('year', 1)
        .toDate(),
    }
  }

  render() {
    return (
      <FormDialog
        wide
        width='70vw'
        title='Create Worksheet(s)'
        open={this.props.open}
        onClose={this.handleRequestClose}
        hasSubmit
        FormComponent={CreateWorksheetForm}
        currentData={this.state.data}
        submit={this.submit}
        validate={this.validate}
        {..._.pick(this.state, [
          'typeChoices',
          'classDisabled',
          'classChoices',
          'categoryDisabled',
          'categoryChoices',
          'templateDisabled',
          'templateChoices',
        ])}
        getInitialData={this.getInitialData}
        validationErrors={this.state.validationErrors}
        submitMessage='Create'
        validateOnMount={true}
      />
    )
  }
}
