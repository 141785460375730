// @ts-ignore
import config from 'config'

import { get, post } from '../helpers/fetch'
import { getAuthHeaders } from '../helpers/firebase'

export interface RecordImport {
  readonly id: string
  readonly created: Date
  readonly filename: string
  // note Processing is not stored in the database, but is used in the UI
  readonly status: 'Success' | 'Failed' | 'Duplicates' | 'Processing'
  readonly numRecords: number
  readonly duplicateCount: number
}

export const getRecordImportsForFarm = async (farmId: string): Promise<{ imports: RecordImport[] }> => {
  const url = `${config.backend.url}/farm/${farmId}/imports`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const postRecordImport = async (farmId: string, data: any) => {
  const url = `${config.backend.url}/farm/${farmId}/import`

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers, true)
    })
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export interface RecordImportDuplicate {
  readonly eid: string
  readonly animalType: string
  readonly vidYear: number
  readonly vid: string
  readonly dataElement: string
  readonly value: string
  readonly date: Date
}

export const getDuplicatesForRecordImport = async (
  farmId: string,
  importId: string,
): Promise<{ records: RecordImportDuplicate[] }> => {
  const url = `${config.backend.url}/farm/${farmId}/import/${importId}/duplicates`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export interface RecordImportError {
  readonly line: number
  readonly eid: string
  readonly animalType: string
  readonly vidYear: string
  readonly vid: string
  readonly data_element: string
  readonly value: string
  readonly date: Date
  readonly time: string
  readonly errors: string
}

export const getErrorsForRecordImport = async (
  farmId: string,
  importId: string,
): Promise<{ records: RecordImportError[] }> => {
  const url = `${config.backend.url}/farm/${farmId}/import/${importId}/errors`

  return getAuthHeaders().then((headers) => get(url, headers))
}
