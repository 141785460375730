import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import _ from 'lodash'
import styled from 'styled-components'
import Typography from './Typography'
import Block from './Block'

export default ({ text, backURL, goBack = _.noop, ...props }) => {
  return (
    <Block marginTop={2}>
      <Heading className={props.withBorder ? 'withBorder' : ''}>
        <Link to={backURL} onClick={goBack}>
          <Button />
        </Link>
        <Block marginLeft={2}>
          <Typography variant='h4' color='secondary'>
            {text}
          </Typography>
        </Block>
      </Heading>
    </Block>
  )
}

const Heading = styled.div`
  display: flex;

  &.withBorder {
    /*border-bottom: 2px solid green;*/ //Commented because this css attribute was not used before
  }
`

const Button = ArrowBack
// styled(ArrowBack)`
//   width: 20px !important; // override MUI default
//   cursor: pointer;
// `
