export const infoSiteURL = 'https://stockmanager.co.nz'

export const animals = ['Cattle', 'Deer', 'Sheep']

export const tagColours = [
  'Black',
  'Blue',
  'Brown',
  'Clear',
  'DarkBlue',
  'DarkGreen',
  'DarkPink',
  'DarkPurple',
  'Grey',
  'Green',
  'LightBlue',
  'LightGreen',
  'LightPink',
  'LightPurple',
  'Orange',
  'Pink',
  'Purple',
  'Red',
  'Salmon',
  'White',
  'Yellow',
]
