import React from 'react'
import Layout from '../../components/Layout'
import { Redirect, Link } from 'react-router-dom'
import { withFormValidation, EmailField, PasswordField, SubmitButton } from '../../components/Form/Form'
import { resetPassword, verifyPasswordResetCode, confirmPasswordReset, logout, login } from '../../helpers/auth'
import { getUrlParameter } from '../../helpers/utils'
import { recoverEmail } from '../../data/user'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'

import './PasswordReset.css'

const ForgotPasswordFormContent = (props) => (
  <div>
    {props.error ? <p className='error'>{props.error}</p> : null}
    <p>A reset link will be sent to your email address.</p>
    <div className='FormRow'>
      <EmailField name='email' label='Email Address' value={props.email} required />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Reset password</SubmitButton>
    </div>
  </div>
)

const ResetPasswordFormContent = (props) => (
  <div>
    {props.error ? <p className='error'>{props.error}</p> : null}
    <p>for email {props.email}</p>
    <div className='FormRow'>
      <PasswordField name='newPassword1' label='Enter New Password' required />
      <PasswordField name='newPassword2' label='Enter New Password Again' required />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Reset password</SubmitButton>
    </div>
  </div>
)

const ResetEmailFormContent = (props) => (
  <div>
    {props.error ? <p className='error'>{props.error}</p> : null}
    <div className='FormRow'>
      <p>Your account email will be reset to its previous address. Please enter your password to confirm.</p>
      <PasswordField name='password' label='Password' required />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit}>Reset email</SubmitButton>
    </div>
  </div>
)

const ForgotPasswordForm = withFormValidation(ForgotPasswordFormContent)
const ResetPasswordForm = withFormValidation(ResetPasswordFormContent)
const ResetEmailForm = withFormValidation(ResetEmailFormContent)

class PasswordReset extends React.Component {
  constructor(props) {
    super(props)
    this.submitForgotPassword = this.submitForgotPassword.bind(this)
    this.submitResetPassword = this.submitResetPassword.bind(this)
    this.submitResetEmail = this.submitResetEmail.bind(this)
    this.handleRequestCloseSnackbar = this.handleRequestCloseSnackbar.bind(this)
    this.state = {
      isReset: false,
      isResetEmail: false,
      isResetDone: false,
      snackbarOpen: false,
    }
  }

  submitForgotPassword(data) {
    this.setState({
      error: '',
    })
    resetPassword(data.email).then(({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.setState({
          isResetDone: true,
          email: '',
          snackbarOpen: true,
          snackbarContent: 'Reset email sent',
        })
      }
    })
  }

  submitResetPassword(data) {
    var newPassword = data.newPassword1
    var newPasswordConfirm = data.newPassword2

    if (newPassword !== newPasswordConfirm) {
      alert("Your password inputs don't match")
      return
    }

    // Save the new password.
    confirmPasswordReset(this.state.actionCode, newPassword).then(({ success, ...result }) => {
      // Password reset has been confirmed and new password updated.
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.setState({
          isResetDone: true,
          snackbarOpen: true,
          snackbarContent: 'Password has been reset. Please sign in.',
        })
      }
    })
  }

  submitResetEmail(data) {
    const onSave = ({ success, email, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.setState({
          snackbarOpen: true,
          snackbarContent: 'Email has been reset.',
        })
        login(email, data.password).then(() => {
          this.setState({
            isResetEmailDone: true,
          })
        })
      }
    }

    recoverEmail(this.state.actionCode).then(onSave)
  }

  componentDidMount() {
    var actionCode = getUrlParameter(this.props.location.search, 'oobCode')
    if (actionCode === null || actionCode === '') {
      return
    }
    verifyPasswordResetCode(actionCode).then(({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: 'Invalid or expired action code! \nPlease try to reset the password again!',
        })
      } else {
        this.setState({
          isReset: !this.props.location.search.includes('recoverEmail'),
          isResetEmail: this.props.location.search.includes('recoverEmail'),
          email: result.email,
          actionCode: actionCode,
        })
      }
    })
  }

  handleRequestCloseSnackbar() {
    this.setState({
      snackbarOpen: false,
    })
  }

  renderForgetting() {
    return (
      <Layout title='Password reset' className='PasswordReset'>
        <ForgotPasswordForm submit={this.submitForgotPassword} error={this.state.error} email={this.state.email} />
        <hr />
        <p>
          <Link to='/sign-in'>Sign in</Link>
        </p>
        {/* <p><Link to="/sign-up">No account? Sign up here</Link></p> */}
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={this.handleRequestCloseSnackbar}
          message={this.state.snackbarContent}
        />
      </Layout>
    )
  }

  renderResetting() {
    return (
      <Layout title='Password reset' className='PasswordReset'>
        <ResetPasswordForm submit={this.submitResetPassword} email={this.state.email} error={this.state.error} />
        <hr />
        <p>
          <Link to='/sign-in'>Sign in</Link>
        </p>
        {/* <p><Link to="/sign-up">No account? Sign up here</Link></p> */}
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={this.handleRequestCloseSnackbar}
          message={this.state.snackbarContent}
        />
      </Layout>
    )
  }

  renderResettingEmail() {
    return (
      <Layout title='Email reset' className='PasswordReset'>
        <ResetEmailForm submit={this.submitResetEmail} email={this.state.email} error={this.state.error} />
        <hr />
        <p>
          <Link to='/sign-in'>Sign in</Link>
        </p>
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={this.handleRequestCloseSnackbar}
          message={this.state.snackbarContent}
        />
      </Layout>
    )
  }

  render() {
    if (this.state.isResetDone) {
      logout()
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: {
              email: this.state.email,
              snackbarContent: this.state.snackbarContent,
              snackbarOpen: true,
            },
          }}
        />
      )
    } else if (this.state.isResetEmailDone) {
      return <Redirect to='/dashboard' />
    }

    if (this.state.isResetEmail) {
      return this.renderResettingEmail()
    }
    return this.state.isReset ? this.renderResetting() : this.renderForgetting()
  }
}

export default PasswordReset
