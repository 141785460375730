import './Dashboard.css'

import * as React from 'react'

import { AnimalType } from '../../data/types'

interface SelectorProps {
  readonly animalType: AnimalType
  readonly selected: boolean
  readonly onSelect: (animalType: AnimalType) => void
}

const Selector: React.FunctionComponent<SelectorProps> = ({ animalType, selected, onSelect }) => {
  const onSelectAnimal = (): void => onSelect(animalType)

  return (
    <div className='dashboardAnimalTypeSelectorItem' onClick={onSelectAnimal}>
      {selected ? <div /> : null}
      <p style={selected ? { color: '#555555' } : undefined}>{animalType.toString()}</p>
    </div>
  )
}

interface AnimalTypeSelectorProps {
  readonly animalType: AnimalType
  readonly onSelect: (animalType: AnimalType) => void
}

export const AnimalTypeSelector = ({ animalType, onSelect }: AnimalTypeSelectorProps): JSX.Element => (
  <div className='dashboardAnimalTypeSelector'>
    <Selector animalType={AnimalType.Cattle} selected={animalType === AnimalType.Cattle} onSelect={onSelect} />
    <Selector animalType={AnimalType.Deer} selected={animalType === AnimalType.Deer} onSelect={onSelect} />
    <Selector animalType={AnimalType.Sheep} selected={animalType === AnimalType.Sheep} onSelect={onSelect} />
  </div>
)
