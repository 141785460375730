import React from 'react'
import MaterialTable from '../../components/Table/Table'
import TableHeading from './TableHeading'
import TransmissionDetailsDialog from './TransmissionDetailsDialog'

import { getNaitDetailsForReport } from '../../data/nait'

import moment from 'moment'
import _ from 'lodash'

import './Nait.css'

const columns = [
  { id: 'date', label: 'Date & Time', style: { width: '200px' } },
  { id: 'numRecords', label: '# of Records', style: { width: '200px' } },
  { id: 'status', label: 'Status', style: { width: '200px' } },
  { id: 'details', label: 'Details' },
]

const formatData = (data, openDetailsDialog) =>
  data.map((row, key) => {
    let status = row.status
    if (status === 'Partial') {
      status = 'Partial Success'
    } else if (status === 'Failed' && !row.transmissionFailure) {
      status = 'Failed (Records)'
    }

    return {
      ...row,
      status,
      date: moment(row.date).format('DD/MM/YY hh:mm'),
      details: row.status !== 'Processing' && <a onClick={() => openDetailsDialog(key)}>View</a>,
    }
  })

class TransmissionTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      detailsDialogOpen: false,
    }

    this.openDetailsDialog = this.openDetailsDialog.bind(this)
    this.closeDetailsDialog = this.closeDetailsDialog.bind(this)
  }

  async openDetailsDialog(key) {
    const report = this.props.data[key]
    const detailsData = _.pick(report, ['status', 'date', 'summary', 'transmissionFailure'])

    let recordsData = { records: [] }
    if (report.id) {
      recordsData = await getNaitDetailsForReport(this.props.farmId, report.id)
    }

    this.setState({
      detailsDialogOpen: true,
      transmissionDetailsData: [detailsData],
      transmissionRecordsData: recordsData.records,
    })
  }

  closeDetailsDialog() {
    this.setState({
      detailsDialogOpen: false,
      transmissionDetailsData: null,
      transmissionRecordsData: null,
    })
  }

  render() {
    return (
      <div>
        <TableHeading text='NAIT Transmission History' />
        {this.props.data && this.props.data.length > 0 && (
          <MaterialTable columns={columns} data={formatData(this.props.data, this.openDetailsDialog)} />
        )}
        <TransmissionDetailsDialog
          open={this.state.detailsDialogOpen}
          handleRequestClose={this.closeDetailsDialog}
          transmissionDetailsData={this.state.transmissionDetailsData}
          transmissionRecordsData={this.state.transmissionRecordsData}
        />
      </div>
    )
  }
}

export default TransmissionTable
