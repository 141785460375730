import React from 'react'
import Grid from '@material-ui/core/Grid'
import MaterialTextField from '@material-ui/core/TextField'

import { SimpleFormDialog } from '../../../../components/Dialog/FormDialog'
import Search from '../../../../components/Search'
import _ from 'lodash'

import { addTemplateEventForAccount, editTemplateEventForAccount } from '../../../../data/manage_templates'

import '../../../../components/Dialog/Dialog.css'

export default class AddEventDialog extends React.Component {
  constructor(props) {
    super(props)

    const data = props.eventToEdit
      ? {
          ...props.eventToEdit,
        }
      : {}

    this.state = {
      open: false,
      validationErrors: {},
      data,
      hasChanged: false,
    }
  }

  /**
   * Make sure to exclude self, and any child nodes from list of possible dependants
   * when editing an existing event. No such troubles when creating a new one.
   */
  getDependentEventOptions = () => {
    // no need to check anything for a brand new event
    if (!this.props.eventToEdit) {
      return this.props.dependentEventOptions
    }

    const optionsMap = _.fromPairs(this.props.dependentEventOptions.map((row) => [row.value, row.name]))
    const stack = [this.props.eventToEdit.id]
    const dependencyMap = {}

    // create a map of {parentId: [childId, ...], ...} to map out dependencies
    for (const e of this.props.allEvents.filter((e) => e.dependentEventId)) {
      const list = dependencyMap[e.dependentEventId] || []
      list.push(e.id)
      dependencyMap[e.dependentEventId] = list
    }

    // iterate through the the tree, deleting any nodes from list of possible options
    while (stack.length) {
      const current = stack.pop()
      delete optionsMap[current]

      stack.push(...(dependencyMap[current] || []))
    }

    // put it back into expected format of list of objects
    return Object.entries(optionsMap).map((row) => ({ value: row[0], name: row[1] }))
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
    })
  }

  submit = () => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          validationErrors: result.data,
        })
      } else {
        this.props.setTemplates(result.result, this.props.templateId)
        this.handleRequestClose()
      }
    }

    const id = this.props.eventToEdit ? this.props.eventToEdit.id : null

    const formattedData = {
      id,
      eventId: this.state.data.eventId,
      dependentEventId: this.state.data.dependentEventId,
      templateStart: this.isFirstEvent(),
      leadLag: parseInt(this.state.data.leadLag, 10),
    }

    const saveFunc = id ? editTemplateEventForAccount : addTemplateEventForAccount

    saveFunc(this.props.accountId, this.props.templateId, formattedData).then(onSave)
  }

  isFirstEvent = () => {
    return this.props.allEvents.length === 0 || (this.props.eventToEdit && !this.props.eventToEdit.dependentEventId)
  }

  render() {
    const { data } = this.state
    const valid = data.eventId && (this.isFirstEvent() || (data.dependentEventId && data.leadLag))

    const event = this.props.eventOptions.find((opt) => opt.value === this.state.data.eventId)
    const eventName = event ? event.name : ''

    const dependentEvent = this.props.dependentEventOptions.find(
      (opt) => opt.value === this.state.data.dependentEventId,
    )
    const dependentEventName = dependentEvent ? dependentEvent.name : ''

    return (
      <SimpleFormDialog
        wide
        width='800px'
        title={`${this.props.eventToEdit ? 'Edit' : 'Add'} Event Assignment`}
        open={this.props.open}
        onClose={this.handleRequestClose}
        canSubmit={this.state.hasChanged && valid}
        onSubmit={this.submit}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Search
              name='name'
              label='Event Name'
              placeholder='Search events'
              options={this.props.eventOptions}
              onOptionClick={(option) =>
                this.setState({ hasChanged: true, data: { ...this.state.data, eventId: option.value } })
              }
              value={eventName}
              autocomplete='off'
            />
          </Grid>

          {!this.isFirstEvent() && (
            <>
              <Grid item xs={6}>
                <Search
                  name='dependentEvent'
                  label='Dependent Event Name'
                  placeholder='Search events'
                  options={this.getDependentEventOptions()}
                  onOptionClick={(option) =>
                    this.setState({
                      hasChanged: true,
                      data: { ...this.state.data, dependentEventId: option.value, leadLag: undefined },
                    })
                  }
                  value={dependentEventName}
                  autocomplete='off'
                  error={this.state.validationErrors.dependentEventId}
                />
              </Grid>
              <Grid item xs={6}>
                <MaterialTextField
                  type='number'
                  label='Lead (-) or Lag (+) Days'
                  value={String(this.state.data.leadLag)}
                  onChange={(event) => {
                    const leadLag = event.target.value
                    this.setState((s) => ({ hasChanged: true, data: { ...s.data, leadLag } }))
                  }}
                  maxValue='1000'
                />
              </Grid>
            </>
          )}
        </Grid>
      </SimpleFormDialog>
    )
  }
}
