import React from 'react'
import { withFormValidation, SelectField, SubmitButton } from '../../../components/Form/Form'
import { EIDField, VisualTagField } from '../../../components/Form/CustomFields'
import { requireAuth } from '../../../components/withAuth'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { getRecordsForEID, getRecordsForVID } from '../../../data/animal_group_history'
import { colors } from '../../../styles/styleConstants'

import '../../../components/Dialog/Dialog.css'

const IDTypeFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <SelectField
          name='IDType'
          label='ID Type'
          backgroundColor={colors.green}
          color={colors.white}
          choices={props.options}
          required
          showPlaceholder
        />
      </div>

      <div className='ButtonWrap'>
        <Button disabled={true}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Continue</SubmitButton>
      </div>
    </div>
  )
}

const IDTypeForm = withFormValidation(IDTypeFormContent)

const EIDFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <EIDField name='EID' label='EID' required />
      </div>

      <div className='ButtonWrap'>
        <Button onClick={props.handleBack}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Complete</SubmitButton>
      </div>
    </div>
  )
}

const EIDForm = withFormValidation(EIDFormContent)

const TagFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <SelectField
          name='animal'
          label='Animal'
          backgroundColor={colors.green}
          color={colors.white}
          choices={props.options}
          required
          showPlaceholder
        />
      </div>

      <div className='ButtonWrap'>
        <Button onClick={props.handleBack}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Continue</SubmitButton>
      </div>
    </div>
  )
}

const TagForm = withFormValidation(TagFormContent)

const VIDYearFormContent = (props) => {
  function getRange() {
    var range = [[9999, 9999]]
    for (var i = new Date().getFullYear() + 1; i >= 2007; i--) {
      range.push([i, i])
    }
    return range
  }

  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <SelectField
          name='VIDYear'
          label='VID Year'
          backgroundColor={colors.green}
          color={colors.white}
          choices={getRange()}
          required
          showPlaceholder
        />
      </div>

      <div className='ButtonWrap'>
        <Button onClick={props.handleBack}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Continue</SubmitButton>
      </div>
    </div>
  )
}

const VIDYearForm = withFormValidation(VIDYearFormContent)

const VIDFormContent = (props) => {
  return (
    <div>
      {props.error ? <p className='error'>{props.error}</p> : null}
      <div className='FormRow'>
        <VisualTagField name='VID' label='Visual Tag #' required />
      </div>

      <div className='ButtonWrap'>
        <Button onClick={props.handleBack}>Back</Button>
        <SubmitButton disabled={!props.canSubmit}>Complete</SubmitButton>
      </div>
    </div>
  )
}

const VIDForm = withFormValidation(VIDFormContent)

class AnimalDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submitIDType = this.submitIDType.bind(this)
    this.handleSubmitEID = this.handleSubmitEID.bind(this)
    this.handleSubmitTag = this.handleSubmitTag.bind(this)
    this.handleSubmitVIDYear = this.handleSubmitVIDYear.bind(this)
    this.handleSubmitVID = this.handleSubmitVID.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handleBack = this.handleBack.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
      activeStep: 0,
    }
  }

  validate() {
    const errors = {}

    this.setState({
      validationErrors: errors,
    })
  }

  handleRequestClose(records) {
    this.props.onRequestClose(records)

    this.setState({
      open: false,
      activeStep: 0,
      errorMessage: '',
    })
  }

  handleEIDSelected() {
    this.setState({
      activeStep: 1,
    })
  }

  handleSubmitEID(data) {
    while (data.EID.length < 16) {
      data.EID = '0' + data.EID
    }
    getRecordsForEID(this.props.farmId, data.EID)
      .then((results) => {
        this.handleRequestClose(results)
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          error: 'Animal Not Found',
        })
      })
  }

  handleTagSelected() {
    this.setState({
      activeStep: 2,
    })
  }

  handleSubmitTag(data) {
    this.setState({
      activeStep: 3,
      animalType: data.animal[0].toUpperCase() + data.animal.slice(1),
    })
  }

  handleSubmitVIDYear(data) {
    this.setState({
      activeStep: 4,
      vidYear: data.VIDYear,
    })
  }

  handleSubmitVID(data) {
    getRecordsForVID(this.props.farmId, this.state.animalType, this.state.vidYear, data.VID)
      .then((results) => {
        this.handleRequestClose(results)
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          error: 'Animal Not Found',
        })
      })
  }

  submitIDType(data) {
    if (data.IDType === 'EID') {
      this.handleEIDSelected()
    } else if (data.IDType === 'Visual Tag') {
      this.handleTagSelected()
    }
  }

  handleBack() {
    this.setState({
      activeStep: this.state.activeStep === 2 ? 0 : this.state.activeStep - 1,
      error: null,
    })
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <IDTypeForm
            options={[
              ['EID', 'EID'],
              ['Visual Tag', 'Visual Tag'],
            ]}
            submit={this.submitIDType}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      case 1:
        return (
          <EIDForm
            submit={this.handleSubmitEID}
            handleBack={this.handleBack}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      case 2:
        return (
          <TagForm
            options={[
              ['Cattle', 'Cattle'],
              ['Deer', 'Deer'],
              ['Sheep', 'Sheep'],
            ]}
            submit={this.handleSubmitTag}
            handleBack={this.handleBack}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      case 3:
        return (
          <VIDYearForm
            submit={this.handleSubmitVIDYear}
            handleBack={this.handleBack}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      case 4:
        return (
          <VIDForm
            submit={this.handleSubmitVID}
            handleBack={this.handleBack}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
          />
        )
      default:
        return ''
    }
  }

  render() {
    const { ...other } = this.props

    return (
      <Dialog className='animalGroupDialog' onClose={this.handleRequestClose} {...other}>
        <DialogHeader title='Animal Type' handleRequestClose={this.handleRequestClose} />

        <div className='formContent'>{this.getStepContent(this.state.activeStep)}</div>
      </Dialog>
    )
  }
}

export default requireAuth(AnimalDialog)
