function createHeaders(extraHeaders, multipartFormData) {
  var headers = new Headers()

  headers.append('Accept', 'application/json')
  if (!multipartFormData) {
    headers.append('Content-Type', 'application/json')
  }

  if (extraHeaders) {
    Object.keys(extraHeaders).forEach((key) => {
      headers.append(key, extraHeaders[key])
    })
  }

  return headers
}

/**
 * fetch wrapper which deciphers json, and rejects on non-200 response
 * @param  {String} method
 * @param  {String} url
 * @param  {Object} data
 * @param  {Object} extraHeaders
 * @return {Promise}
 */
function fetchWrapper({ method, url, data, extraHeaders, multipartFormData = false }) {
  const params = {
    method: method,
    headers: createHeaders(extraHeaders, multipartFormData),
  }

  if (multipartFormData) {
    params.body = data
  } else if (data) {
    params.body = JSON.stringify(data)
  }

  return new Promise(function(resolve, reject) {
    window
      .fetch(url, params)
      .then((resp) => {
        const ctype = resp.headers.get('content-type')
        const isJSON = ctype && ctype.indexOf('application/json') !== -1

        // handle json response or plain text
        const promise = isJSON ? resp.json() : resp.text()

        promise.then((data) => {
          if (resp.ok) {
            resolve(data)
          } else {
            const error = Error('Fetch error')
            error.data = data
            if (error.message) {
              error.message = data.message
            }
            reject(error)
          }
        })
      })
      .catch((error) => {
        // TODO proper messages here - these are networking errors etc
        error.data = {
          message: error.message,
        }
        reject(error)
      })
  })
}

export function post(url, data, extraHeaders, multipartFormData = false) {
  return fetchWrapper({ method: 'post', url, data, extraHeaders, multipartFormData })
}

export function put(url, data, extraHeaders) {
  return fetchWrapper({ method: 'put', url, data, extraHeaders })
}

export function get(url, extraHeaders) {
  return fetchWrapper({ method: 'get', url, extraHeaders })
}

export function del(url, extraHeaders, data = null) {
  return fetchWrapper({ method: 'delete', url, data, extraHeaders })
}

export function downloadFile(url, extraHeaders) {
  const params = {
    method: 'get',
    headers: createHeaders(extraHeaders, false),
  }

  return new Promise(function(resolve, reject) {
    window
      .fetch(url, params)
      .then((resp) => {
        if (resp.ok) {
          resp.blob().then((data) => resolve(data))
        } else {
          resp.json().then((data) => {
            const error = Error('Fetch error')
            error.data = data
            reject(error)
          })
        }
      })
      .catch((error) => {
        error.data = {
          message: error.message,
        }
        reject(error)
      })
  })
}
