import React from 'react'
import { withFormValidation, PostalCodeField, SubmitButton, SelectField } from '../../../components/Form/Form'
import { FarmNameField } from '../../../components/Form/CustomFields.js'
import { saveFarm } from '../../../data/farm'
import { requireAuth } from '../../../components/withAuth'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import _ from 'lodash'

import '../../../components/Dialog/Dialog.css'
import { colors } from '../../../styles/styleConstants'

const EditFarmFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <FarmNameField name='farmName' label='Farm Name' value={props.farmName} required />
    </div>
    <div className='FormRow'>
      <PostalCodeField name='farmPostalCode' label='Postal Code' value={props.farmPostalCode} required />
    </div>
    <div className='FormRow'>
      <SelectField
        name='billingAccount'
        label='Select Billing Account'
        choices={props.billingAccountChoices}
        value={props.billingAccountChoices[0][0]}
        color={colors.white}
        backgroundColor={colors.green}
        required
        showPlaceholder
      />
    </div>
    <div className='FormRow'>
      <SelectField
        name='subscriptionType'
        label='Select Subscription Type'
        choices={props.subscriptionTypeChoices}
        value={props.subscriptionType}
        color={colors.white}
        backgroundColor={props.subscriptionTypeChoices.length === 1 ? colors.lightGrey : colors.green}
        disabled={props.subscriptionTypeChoices.length === 1}
        required
      />
    </div>

    <div className='ButtonWrap'>
      <Button variant='contained' className='Back dialogBack' onClick={props.handleRequestClose}>
        Cancel
      </Button>
      <SubmitButton disabled={!props.hasChanged || !props.canSubmit}>Save Changes</SubmitButton>
    </div>
  </div>
)

const EditFarmForm = withFormValidation(EditFarmFormContent)

class EditFarmDialog extends React.Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      validationErrors: {},
      hasChanged: false,
    }
  }

  validate(data) {
    if (
      data.farmName !== this.props.farmName ||
      data.farmPostalCode !== this.props.farmPostalCode ||
      data.subscriptionType !== this.props.subscriptionType
    ) {
      this.setState({
        hasChanged: true,
      })
    } else {
      this.setState({
        hasChanged: false,
      })
    }
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
      hasChanged: false,
    })
  }

  submit(data) {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        this.setState({
          error: result.error,
        })
      } else {
        this.handleRequestClose()
      }
    }

    saveFarm(
      this.props.farmId,
      _.pick(data, ['farmName', 'farmPostalCode', 'billingAccount', 'subscriptionType']),
    ).then(onSave)
  }

  render() {
    return (
      <Dialog onClose={this.handleRequestClose} open={this.props.open}>
        <DialogHeader title='Edit Farm' handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          <EditFarmForm
            submit={this.submit}
            handleRequestClose={this.handleRequestClose}
            validate={this.validate}
            validationErrors={this.state.validationErrors}
            error={this.state.error}
            farmName={this.props.farmName}
            farmPostalCode={this.props.farmPostalCode}
            hasChanged={this.state.hasChanged}
            billingAccountChoices={this.props.billingAccountChoices}
            subscriptionTypeChoices={this.props.subscriptionTypeChoices}
            subscriptionType={this.props.subscriptionType}
          />
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(EditFarmDialog)
