import React from 'react'
import {
  SelectField,
  FormsyDateField,
  FormRow,
  FormField,
  HiddenField,
  CheckboxField,
} from '../../../../components/Form/Form'
import Search from '../../../../components/Search'
import FormDialog from '../../../../components/Dialog/FormDialog'
import WarningDialog from '../../../../components/Dialog/WarningDialog'
import { colors } from '../../../../styles/styleConstants'
import { choiceMap, formatChoices, sortingCollator } from '../../../../constants'
import _ from 'lodash'
import moment from 'moment'

import { getTemplatesForAccount } from '../../../../data/manage_templates'
import { addTemplateToFarmPlan } from '../../../../data/plans'

import '../../../../components/Dialog/Dialog.css'

const AddTemplateForm = ({
  currentData,
  typeChoices,
  classDisabled,
  classChoices,
  categoryDisabled,
  categoryChoices,
  templateChoices,
  templateEventChoices,
  onTemplateOptionClick,
  onTemplateEventOptionClick,
  searchDisabled,
  minDate,
  maxDate,
}) => (
  <div>
    <FormRow mb='30px' width='430px'>
      <FormField width='140px'>
        <SelectField
          name='type'
          placeholder='Plan type'
          width='100%'
          choices={typeChoices}
          backgroundColor={colors.green}
          color={colors.white}
          value={_.get(currentData, 'type')}
          showPlaceholder
        />
      </FormField>
      <FormField width='140px'>
        <SelectField
          name='class'
          placeholder='Plan class'
          width='100%'
          choices={classChoices}
          backgroundColor={classDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={classDisabled}
          value={_.get(currentData, 'class')}
          showPlaceholder
        />
      </FormField>
      <FormField width='140px'>
        <SelectField
          name='planCategory'
          placeholder='Category'
          width='100%'
          choices={categoryChoices}
          backgroundColor={categoryDisabled ? colors.grey : colors.green}
          color={colors.white}
          disabled={categoryDisabled}
          value={_.get(currentData, 'planCategory')}
          showPlaceholder
        />
      </FormField>
    </FormRow>
    <FormRow mb='30px'>
      <FormField width='50%'>
        <Search
          name='templateName'
          label='Template Name'
          placeholder='Search templates'
          options={templateChoices}
          onOptionClick={onTemplateOptionClick}
          clear={!currentData.templateName}
          disabled={searchDisabled}
          noSort
        />
        <HiddenField name='templateId' value={currentData.templateId} required />
      </FormField>
      <FormField width='45%'>
        <CheckboxField
          name='repeat'
          label='Repeat Events Annually'
          checked={currentData.repeat}
          disabled={searchDisabled}
        />
      </FormField>
    </FormRow>
    <FormRow>
      <FormField width='50%'>
        <Search
          name='templateStartEvent'
          label='Template Scheduling Event'
          placeholder='Select event'
          options={templateEventChoices}
          onOptionClick={onTemplateEventOptionClick}
          clear={!currentData.templateName || !currentData.templateStartEvent}
          disabled={!currentData.templateName}
        />
        <HiddenField name='templateStartEventId' value={currentData.templateStartEventId} required />
      </FormField>
      <FormField width='45%'>
        <FormsyDateField
          name='startDate'
          label='Scheduling Event Date'
          value={currentData.startDate}
          minDate={minDate}
          maxDate={maxDate}
          disabled={searchDisabled}
          required
        />
      </FormField>
    </FormRow>
  </div>
)

export default class AddTemplateDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      hasChanged: false,
      data: {},
      typeChoices: formatChoices(_.keys(choiceMap)),
      classChoices: [],
      classDisabled: true,
      categoryChoices: [],
      categoryDisabled: true,
      searchDisabled: true,
      templates: [],
    }
  }

  async componentDidMount() {
    const templates = await getTemplatesForAccount(this.props.accountId)
    this.setState({
      templates,
      minDate: moment()
        .add(-1, 'years')
        .toDate(),
      maxDate: moment()
        .add(1, 'years')
        .toDate(),
    })
  }

  handleRequestClose = () => {
    this.props.onRequestClose()

    this.setState({
      open: false,
      hasChanged: false,
    })
  }

  validate = (data) => {
    let templateChoices = []
    let templateEventChoices = []
    const noCategory = data.type === 'Farm' && data.class !== 'Stock'

    if (data.type !== this.state.data.type) {
      data.templateId = data.templateName = data.class = undefined
    }

    if (data.class !== this.state.data.class) {
      data.templateId = data.templateName = data.planCategory = undefined
    }

    if (data.planCategory !== this.state.data.planCategory) {
      data.templateId = data.templateName = undefined
    }

    const everythingPicked = data.type && data.class && (data.planCategory || noCategory)

    if (everythingPicked) {
      templateChoices = this.state.templates
        .filter(
          (row) =>
            row.planType == data.type &&
            row.planClass == data.class &&
            row.planCategory == (data.planCategory || null) &&
            row.enabled,
        )
        .map((row) => ({
          name: row.name,
          value: row.id,
        }))
        .sort((a, b) => {
          if (a.name === 'Default') return -1
          if (b.name === 'Default') return 1

          return sortingCollator.compare(a.name, b.name)
        })
    }

    if (data.templateId) {
      templateEventChoices = this.state.templates
        .find((t) => t.id === data.templateId)
        .events.map((e) => ({
          name: `${e.order} - ${e.name}`,
          value: e.id,
        }))
    }

    if (data.type) {
      this.setState({
        data: {
          ...this.state.data,
          ...data,
        },
        classChoices: _.get(choiceMap, `${data.type}.planClass`, []),
        classDisabled: false,
        categoryChoices: _.get(choiceMap, `${data.type}.planCategory`, []),
        categoryDisabled: !data.class || noCategory,
        addEventDisabled: !data.class || !data.planCategory || !this.state.initialSave,
        templateChoices,
        templateEventChoices,
        searchDisabled: !everythingPicked,
      })
    }
  }

  onTemplateOptionClick = (selected) => {
    this.validate({ ...this.state.data, templateId: selected.value, templateName: selected.name })
  }

  onTemplateEventOptionClick = (selected) => {
    this.validate({ ...this.state.data, templateStartEventId: selected.value, templateStartEvent: selected.name })
  }

  submit = (data) => {
    const onSave = ({ success, ...result }) => {
      if (!success) {
        if (result.data.templateId) {
          this.setState({ duplicateTemplateDialogMessage: result.data.templateId[0] })
        } else {
          this.setState({ validationErrors: result.data })
        }
      } else {
        this.handleRequestClose()
      }
    }

    addTemplateToFarmPlan(
      this.props.farmId,
      data.templateId,
      data.templateStartEventId,
      moment(data.startDate).format('YYYY-MM-DD'),
      data.repeat,
    ).then(onSave)
  }

  async getInitialData() {
    // today's date is the default
    return { startDate: new Date() }
  }

  render() {
    return (
      <>
        <FormDialog
          wide
          width='70vw'
          title='Add Template'
          open={this.props.open}
          onClose={this.handleRequestClose}
          hasSubmit
          FormComponent={AddTemplateForm}
          currentData={this.state.data}
          submit={this.submit}
          validate={this.validate}
          onTemplateOptionClick={this.onTemplateOptionClick}
          onTemplateEventOptionClick={this.onTemplateEventOptionClick}
          // hasChanged={this.state.hasChanged}
          {..._.pick(this.state, [
            'typeChoices',
            'classDisabled',
            'classChoices',
            'categoryDisabled',
            'categoryChoices',
            'templateChoices',
            'templateEventChoices',
            'searchDisabled',
          ])}
          getInitialData={this.getInitialData}
          validationErrors={this.state.validationErrors}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
        />
        {this.state.duplicateTemplateDialogMessage && (
          <WarningDialog
            open
            warning={{
              title: 'Duplicate',
              message: this.state.duplicateTemplateDialogMessage,
            }}
            handleRequestClose={() => this.setState({ duplicateTemplateDialogMessage: undefined })}
            confirmButtonTitle='Dismiss'
          />
        )}
      </>
    )
  }
}
