import React from 'react'
import { Route } from 'react-router-dom'
import { requireAuth, AccessDenied } from '../../../components/withAuth'
import Layout from '../../../components/Layout'
import moment from 'moment'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'

import { ActionHeader, ButtonContainer, ActionHeaderButton, PageTitle } from '../../../components/PageHeaders'
import Search from '../../../components/Search'
import Table from './components/Table'
import AddEventDialog from './components/AddEventDialog'
import WarningDialog from '../../../components/Dialog/WarningDialog'
import AccountSelector from '../../../components/AccountSelector'

import { getEventsForAccount, disableEventForAccount, enableEventForAccount } from '../../../data/manage_events'
import { getHealthTreatmentsForAccount } from '../../../data/health_treatments'
import { CheckboxWithLabel } from '../../../components/Form/CustomFields'
import { useAccount } from '../../../components/AccountContext'
import { planningClassMap, planningCategoryMap, eventTypeChoicesMap } from '../../../constants'

class ManageEvents extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      searchStr: '',
      events: [],
      tableKey: Math.random(),
    }
  }

  componentDidMount() {
    const { accounts } = this.props
    if (accounts.length === 1) {
      this.handleAccountSelected(accounts[0].id, accounts[0].businessName)
    }
  }

  deselectAccount = () => this.setState({ accountId: null, events: [] })

  handleAccountSelected = async (accountId) => {
    const data = await getEventsForAccount(accountId)
    const healthTreatments = (await getHealthTreatmentsForAccount(accountId)).accountHealthTreatments.reduce(
      (o, row) => ({ ...o, [row.id]: row }),
      {},
    )

    this.setState({ events: data, healthTreatments, accountId })
  }

  formatData = () =>
    this.state.events
      .filter((e) => {
        let match = true
        if (this.state.searchStr.length) {
          const re = new RegExp(_.escapeRegExp(this.state.searchStr), 'i')
          match = re.test(e.name)
        }
        return match && (this.state.showDisabled || e.enabled)
      })
      .map((e) => ({
        ...e,
        eventTypeText: eventTypeChoicesMap[e.eventType],
        planTypeText: e.planType || '-',
        planClassText: e.planType && e.planClass ? planningClassMap[e.planType].get(e.planClass) : '-',
        planCategoryText: (e.planType ? planningCategoryMap[e.planType].get(e.planCategory) : '-') || '-',
        rowDisabled: !e.enabled,
        date: e.editable ? moment(e.updateDate).format('DD/MM/YY') : 'Default',
      }))

  setEvents = (events) => {
    this.setState({ events, tableKey: Math.random() })
  }

  onSave = ({ success, ...result }) => {
    if (!success) {
      this.setState({
        error: result.error,
      })
    } else {
      this.setEvents(result.result)
      this.setState({ loading: false })
    }
  }

  editEvent = (event) => {
    this.setState({
      addEventDialogOpen: true,
      eventToEdit: event,
    })
  }

  copyEvent = (event) => {
    this.setState({
      addEventDialogOpen: true,
      eventToEdit: { ...event, id: undefined, copy: true },
    })
  }

  render() {
    const data = this.formatData()

    const { accounts } = this.props
    if (accounts && accounts.length === 0) {
      return <AccessDenied />
    }

    return (
      <Layout>
        <Route
          exact
          path='/manage-events'
          render={() => (
            <div>
              <PageTitle onClick={() => this.setState({ redirect: 'main' })}>Manage Events</PageTitle>
              <ActionHeader>
                <ButtonContainer>
                  <Search
                    placeholder='Search events'
                    onChange={(event) => this.setState({ searchStr: event.target.value })}
                    flexGrow='2'
                  />
                  <CheckboxWithLabel
                    control={
                      <Checkbox
                        checked={this.state.showDisabled}
                        onClick={(event) => this.setState({ showDisabled: event.target.checked })}
                      />
                    }
                    label='Show disabled'
                  />
                  <AccountSelector
                    accounts={accounts}
                    handleAccountSelected={this.handleAccountSelected}
                    accountId={this.state.accountId}
                    deselectAccount={this.deselectAccount}
                  />
                  <ActionHeaderButton
                    onClick={() => this.setState({ addEventDialogOpen: true })}
                    disabled={!this.state.accountId}
                  >
                    Add Event
                  </ActionHeaderButton>
                </ButtonContainer>
              </ActionHeader>
              <Table
                accountId={this.state.accountId}
                data={data}
                key={this.state.tableKey}
                editEvent={this.editEvent}
                copyEvent={this.copyEvent}
                disableEvent={(id) => {
                  this.setState({
                    disableWarningDialogOpen: true,
                    eventIdToDisable: id,
                  })
                }}
                enableEvent={(id) => {
                  this.setState({ loading: true })
                  enableEventForAccount(this.state.accountId, id).then(this.onSave)
                }}
              />
              {this.state.addEventDialogOpen && (
                <AddEventDialog
                  open
                  onRequestClose={() => {
                    this.setState({ addEventDialogOpen: false, eventToEdit: null })
                  }}
                  data={this.state.eventToEdit}
                  healthTreatments={this.state.healthTreatments}
                  accountId={this.state.accountId}
                  disableAll={!!(this.state.eventToEdit && !this.state.eventToEdit.editable)}
                  setEvents={this.setEvents}
                  allEvents={this.state.events.map((e) => ({
                    eventType: e.eventType,
                    name: e.name.toLocaleLowerCase(),
                    planType: e.planType,
                    planClass: e.planClass,
                    planCategory: e.planCategory,
                    id: e.id,
                    disabled: !e.enabled,
                    editable: e.editable,
                  }))}
                  reopenEdit={(id) => {
                    this.editEvent(this.state.events.find((row) => String(row.id) === String(id)))
                  }}
                />
              )}
              {this.state.disableWarningDialogOpen && (
                <WarningDialog
                  open
                  warning={{
                    title: 'Confirm Disable',
                    message: 'Confirm disabling this event',
                    hasCancel: true,
                  }}
                  handleRequestClose={() => this.setState({ disableWarningDialogOpen: false })}
                  handleConfirm={() => {
                    this.setState({ disableWarningDialogOpen: false })
                    disableEventForAccount(this.state.accountId, this.state.eventIdToDisable).then(this.onSave)
                  }}
                />
              )}
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default requireAuth((props) => {
  const { accounts } = useAccount()
  return <ManageEvents {...props} accounts={accounts} />
})
