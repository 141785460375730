import jstz from 'jstz'
import moment from 'moment-timezone'

export function getUrlParameter(url, name) {
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  var results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const uuid4Re = /^[A-F\d]{8}-[A-F\d]{4}-[3-4][A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i

export const isValidV4UUID = (uuid) => uuid4Re.test(uuid)

const titleCaseRe = /[A-Z][a-z]*/g

export function formatDisplayProperty(str) {
  if (str && str.length > 0) {
    if (str.toLowerCase() === 'healthtreatmenttype') {
      return (str[0].toUpperCase() + str.substr(1))
        .match(titleCaseRe)
        .slice(0, 2)
        .join(' ')
    } else {
      return (str[0].toUpperCase() + str.substr(1)).match(titleCaseRe).join(' ')
    }
  } else {
    return ''
  }
}

export function formatDisplayValue(data) {
  let value = ''
  if (data.element.toLowerCase().includes('date')) {
    value = formatDateValue(data.value)
  } else if (parseFloat(data.value) && data.value.includes('.')) {
    value = parseFloat(data.value).toFixed(2)
  } else if (data.element.toLowerCase() === 'damtag' || data.element.toLowerCase() === 'siretag') {
    value = formatDamSireTag(data.value)
  } else if (data.element.toLowerCase() === 'forage' || data.element.toLowerCase() === 'feed') {
    value = formatForageFeed(data.value)
  } else if (data.element.toLowerCase().includes('drafting')) {
    value = formatDisplayProperty(data.value)
  } else {
    value = data.value
  }
  return value
}

function getTz() {
  if (!sessionStorage.getItem('timezone')) {
    var tz = jstz.determine() || 'UTC'
    sessionStorage.setItem('timezone', tz.name())
  }
  return sessionStorage.getItem('timezone')
}

export function formatTimestamp(str, tz) {
  // i.e. 2017-11-22T18:32:28.669000+00:00

  const local = moment(str).tz(tz || getTz())
  return local.format('DD/MM/YYYY HH:mm')
}

export function formatDateValue(str) {
  let components = str.split('-')
  return `${components[2]}/${components[1]}/${components[0].slice(2)}`
}

export function formatDamSireTag(str) {
  return str
}

export function formatForageFeed(str) {
  if (str && str.length > 0) {
    let components = str.match(titleCaseRe)
    return `${components[0]}-${components.slice(1).join(' ')}`
  } else {
    return ''
  }
}

export const isInternalTestUser = (id) => id === 'PQjlgKPs8khwWQh96ekgyBxFoLs1'

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
