import React from 'react'
import MaterialTable from '../../components/Table/Table'
import TableHeading from './TableHeading'
import WarningDialog from '../../components/Dialog/WarningDialog'
import Delete from '@material-ui/icons/Delete'

import './Nait.css'

const columns = [
  { id: 'status', label: 'Animal Status', style: { width: '200px' } },
  { id: 'numCattle', label: 'Cattle', style: { width: '200px' } },
  { id: 'numDeer', label: 'Deer' },
].map((column) => ({ ...column, numeric: false }))

const formatData = (data, confirmRemove) =>
  data.map((row) => ({
    ...row,
    numCattle: (
      <span className='animalCountCell'>
        {row.numCattle.toString()}{' '}
        {row.numCattle > 0 && (
          <a onClick={() => confirmRemove(row.status, 'Cattle')}>
            <Delete height='20px' />
          </a>
        )}
      </span>
    ),
    numDeer: (
      <span className='animalCountCell'>
        {row.numDeer.toString()}{' '}
        {row.numDeer > 0 && (
          <a onClick={() => confirmRemove(row.status, 'Deer')}>
            <Delete height='20px' />
          </a>
        )}
      </span>
    ),
  }))

export default class AnimalCategoriesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      warningDialogOpen: false,
    }

    this.confirmRemove = this.confirmRemove.bind(this)
  }

  confirmRemove(status, animal) {
    this.setState({
      warningDialogOpen: true,
      removeStatus: status,
      removeAnimal: animal,
    })
  }

  render() {
    return (
      <div>
        <TableHeading text='Number of animals per status category since last successful NAIT transmission' />
        {this.props.data && this.props.data.length > 0 && (
          <MaterialTable columns={columns} data={formatData(this.props.data, this.confirmRemove)} />
        )}
        <WarningDialog
          open={this.state.warningDialogOpen}
          warning={{
            message:
              'This will remove these records from NAIT reporting requirements. Please ensure that these are properly reported to NAIT through other systems.',
            hasCancel: true,
          }}
          handleRequestClose={() =>
            this.setState({
              warningDialogOpen: false,
              removeStatus: null,
              removeAnimal: null,
            })
          }
          handleConfirm={() => {
            this.props.removeCounts(this.state.removeStatus, this.state.removeAnimal)
            this.setState({
              warningDialogOpen: false,
              removeStatus: null,
              removeAnimal: null,
            })
          }}
          confirmButtonTitle='Confirm'
        />
      </div>
    )
  }
}
