import React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'

import { mobileScreenBreakpoint } from '../constants'

const allowedPaths: Array<string> = ['farm-plans', 'sign-in', '/forgot-password']

export default withRouter(({ children, ...props }: React.PropsWithChildren<RouteComponentProps<{}>>) => {
  const path = props.location.pathname

  if (
    window.innerWidth < mobileScreenBreakpoint &&
    path !== '/' &&
    !allowedPaths.some((allowed) => path.search(allowed) !== -1)
  ) {
    return <Redirect to='/farm-plans' />
  }

  return <>{children}</>
})
