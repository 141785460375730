import React from 'react'
import MaterialTable from '../../../../components/Table/Table'

const Table = ({ data, editEvent, disableEvent, enableEvent, copyEvent }) => {
  const columns = [
    { id: 'name', label: 'Event Name' },
    { id: 'eventTypeText', label: 'Event Type' },
    { id: 'planTypeText', label: 'Plan Type' },
    { id: 'planClassText', label: 'Class' },
    { id: 'planCategoryText', label: 'Category' },
    { id: 'date', label: 'Date' },
    { id: 'updateDate', label: '', style: { display: 'none' } },
    { id: 'overflow', label: '', overflowColumn: true },
  ].map((column) => ({
    ...column,
    style: { ...column.style, whiteSpace: 'nowrap' },
  }))

  return (
    <MaterialTable
      columns={columns}
      data={data}
      defaultOrderBy='name'
      defaultOrder='asc'
      actions={[
        {
          name: (id) => (data.find((e) => e.id === id).editable ? 'Edit' : 'View'),
          onClick: (id) => editEvent(data.find((e) => e.id === id)),
          disabled: (id) => data.find((e) => e.id === id) && !data.find((e) => e.id === id).enabled,
        },
        {
          name: () => 'Copy',
          onClick: (id) => copyEvent(data.find((e) => e.id === id)),
        },
        {
          name: (id) => (data.find((e) => e.id === id) && data.find((e) => e.id === id).enabled ? 'Disable' : 'Enable'),
          onClick: async (id) => {
            if (data.find((e) => e.id === id).enabled) {
              disableEvent(id)
            } else {
              enableEvent(id)
            }
          },
          hidden: (id) => !data.find((e) => e.id === id).editable,
        },
      ]}
    />
  )
}

export default Table
