import './ImportTags.css'

import * as React from 'react'
import { Link } from 'react-router-dom'

import MaterialTable from '../../components/Table/Table'
import { TagImport } from '../../data/import_tags'

const columns = [
  { id: 'supplier', label: 'Source' },
  { id: 'orderNumber', label: 'Order #' },
  { id: 'orderDate', label: 'Order Date' },
  { id: 'issueDate', label: 'Issue Date' },
  { id: 'total', numeric: true, label: '# of Tags' },
  { id: 'completed', numeric: true, label: '# Completed' },
  { id: 'inactive', numeric: true, label: '# Inactive' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
]

interface Props {
  readonly data: TagImport[]
  readonly baseLinkURL: string
}

const ImportTable: React.FunctionComponent<Props> = ({ data, baseLinkURL }) => {
  const tableData = data.map((row) => ({
    ...row,
    orderNumber: (
      <Link to={`${baseLinkURL}/${row.id}/import-details`}>{row.orderNumber ? row.orderNumber : 'Manual'}</Link>
    ),
    status: row.isComplete ? 'Imported' : 'Incomplete',
    action: row.isComplete ? '-' : <Link to={`${baseLinkURL}/${row.id}/complete`}>Add Details</Link>,
  }))

  return (
    <React.Fragment>{data && data.length > 0 && <MaterialTable columns={columns} data={tableData} />}</React.Fragment>
  )
}

export default ImportTable
