import React from 'react'
import MaterialTable from '../../../../components/Table/Table'

const TemplatesTable = ({ data, editTemplate, copyTemplate, enableTemplate, disableTemplate }) => {
  const columns = [
    { id: 'planType', label: 'Type' },
    { id: 'className', label: 'Class' },
    { id: 'categoryName', label: 'Category' },
    { id: 'name', label: 'Template Name', sortColumn: 'rawName' },
    { id: 'numEvents', label: '# Events', numeric: true },
    { id: 'date', label: 'Date' },
    { id: 'overflow', label: '', overflowColumn: true },
  ].map((column) => ({
    ...column,
    style: { ...column.style, whiteSpace: 'nowrap' },
  }))

  const disabledCheck = (id) => data.find((t) => t.id === id) && !data.find((t) => t.id === id).enabled

  return (
    <MaterialTable
      columns={columns}
      data={data}
      defaultOrderBy='name'
      defaultOrder='asc'
      actions={[
        {
          name: (id) => (data.find((t) => t.id === id) && data.find((t) => t.id === id).editable ? 'Edit' : 'View'),
          onClick: editTemplate,
          disabled: disabledCheck,
        },
        {
          name: 'Copy',
          onClick: copyTemplate,
          disabled: disabledCheck,
        },
        {
          name: (id) => (data.find((t) => t.id === id) && data.find((t) => t.id === id).enabled ? 'Disable' : 'Enable'),
          disabled: (id) => data.find((e) => e.id === id) && !data.find((e) => e.id === id).editable,
          onClick: (id) => {
            if (data.find((t) => t.id === id).enabled) {
              disableTemplate(id)
            } else {
              enableTemplate(id)
            }
          },
        },
      ]}
    />
  )
}

export default TemplatesTable
