import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { MaterialTableRow, MaterialTableSubRow } from './TableRow'
import styled from 'styled-components'
import { colors } from '../../styles/styleConstants'
import _ from 'lodash'

const applySort = (rawData, orderBy, order, hasSubRows, disableSort) => {
  if (!rawData) {
    return []
  }

  if (disableSort) {
    return rawData
  }

  let data = [...rawData.filter((d) => !d.isSubRow)]
  data =
    order === 'asc'
      ? data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
      : data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))

  if (hasSubRows) {
    let subRowsByParent = _.chain(rawData)
      .filter((d) => d.isSubRow)
      .groupBy('parentId')
      .value()

    if (_.size(subRowsByParent) > 0) {
      _.map(subRowsByParent, (subRows, parentId) => {
        const index = _.findIndex(data, (d) => d.id === parentId)

        _.chain(subRows)
          .sortBy('subRowKey')
          .reverse()
          .forEach((subRow) => data.splice(index + 1, 0, subRow))
          .value()
      })
    }
  }

  return data
}

export class TableHeader extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy, disableSort } = this.props

    return (
      <DataTableHeader>
        <TableHeaderRow>
          {this.props.columns.map((column) => {
            return (
              <StyledTableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'dense'}
                sortDirection={orderBy === column.id ? order : false}
                style={column.style}
              >
                {column.disableSort || disableSort ? (
                  this.props.tooltipText ? (
                    <Tooltip title={this.props.tooltipText[column.id]} placement='bottom-start' enterDelay={300}>
                      <span>{column.label}</span>
                    </Tooltip>
                  ) : (
                    column.label
                  )
                ) : (
                  <Tooltip title='Sort' placement='bottom-start' enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === column.id || orderBy === column.sortColumn}
                      direction={order}
                      onClick={this.createSortHandler(column.sortColumn || column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </StyledTableCell>
            )
          }, this)}
        </TableHeaderRow>
      </DataTableHeader>
    )
  }
}

export default class MaterialTable extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      order: null,
      orderBy: null,
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  getOrder = () => this.state.order || this.props.defaultOrder || 'desc'

  getOrderBy = () => this.state.orderBy || this.props.defaultOrderBy || 'createDate'

  handleRequestSort = (event, orderBy) => {
    const order = this.getOrderBy() === orderBy && this.getOrder() === 'asc' ? 'desc' : 'asc'

    this.setState({
      orderBy,
      order,
    })
  }

  sortedData() {
    return applySort(this.props.data, this.getOrderBy(), this.getOrder(), this.props.hasSubRows, this.props.disableSort)
  }

  handleSelect(key) {
    const { selected, selectById } = this.props
    const selectedIndex = selected.indexOf(key)
    let newSelected = []

    if (selectById) {
      const id = this.sortedData()[key].id
      if (selected.includes(id)) {
        _.remove(selected, (i) => i === id)
      } else {
        selected.push(id)
      }
      this.props.updateSelected(selected)
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, key)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
      }

      this.props.updateSelected(newSelected)
    }
  }

  render() {
    const order = this.getOrder()
    const orderBy = this.getOrderBy()
    const data = this.sortedData()

    const columns = this.props.columns.map((c) => ({ ...c, disablePadding: true }))

    return (
      <StyledTable className={this.props.expand ? 'expand' : ''}>
        <TableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
          disableSort={this.props.disableSort}
        />
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((item, key) =>
              item.isSubRow
                ? MaterialTableSubRow({
                    parentId: item.parentId,
                    rowKey: key,
                    actionRowKey: item.rowKey,
                    actionSubRowKey: item.subRowKey,
                    columns: this.props.subRowColumns,
                    item,
                    actions: this.props.subRowActions,
                    maxWidth: this.props.subRowMaxWidth,
                  })
                : MaterialTableRow({
                    rowKey: key,
                    actionRowKey: item.rowKey || key,
                    columns,
                    item,
                    selectable: this.props.selectable,
                    selected: this.props.selected,
                    selectById: this.props.selectById,
                    handleSelect: this.handleSelect,
                    switches: this.props.switches,
                    actions: this.props.actions,
                    disableAllSelection: this.props.disableAllSelection,
                    disableSelectionForRows: this.props.disableSelectionForRows,
                    disableIndeterminate: this.props.disableIndeterminate,
                    hasSubRows: this.props.hasSubRows,
                    expanded: _.get(this.props, 'expandedRows', []).includes(item.id),
                    expandRow: this.props.expandRow,
                    collapseRow: this.props.collapseRow,
                  }),
            )}
        </TableBody>
      </StyledTable>
    )
  }
}

const StyledTable = styled(Table)`
  ${({ theme }) => `
  margin-top: ${theme.spacing(2)}px;
  border-top: 2px solid ${colors.lightGreen};
  `}
`

const DataTableHeader = styled(TableHead)`
  border-bottom: 1px solid #b6b6b6;

  th {
    color: #2d2d2d !important;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #b6b6b6;
  }
`

const StyledTableCell = styled(TableCell)`
  color: #7d7d7d;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap;

  a {
    color: ${colors.lightGreen};
  }
`

const TableHeaderRow = styled(TableRow)`
  color: #7d7d7d;
  height: 37px;
  border-bottom: 1px solid ${colors.lightGreen};
  white-space: nowrap;
`
