import React from 'react'
import { Route } from 'react-router-dom'
import { requireAuth, AccessDenied } from '../../../components/withAuth'
import Layout from '../../../components/Layout'
import _ from 'lodash'
import moment from 'moment'

import { PageTitle } from '../../../components/PageHeaders'
import TemplatesTable from './components/TemplatesTable'
import TemplatesTableHeader from './components/TemplatesTableHeader'
import { AddEditTable } from './components/AddEditTable'
import WarningDialog from '../../../components/Dialog/WarningDialog'
import { choiceMap } from '../../../constants'

import {
  getTemplatesForAccount,
  disableTemplateForAccount,
  enableTemplateForAccount,
} from '../../../data/manage_templates'
import { useAccount } from '../../../components/AccountContext'

const CLASS_MAP = Object.assign({}, ...Object.keys(choiceMap).map((k) => ({ [k]: new Map(choiceMap[k].planClass) })))
const CATEGORY_MAP = Object.assign(
  {},
  ...Object.keys(choiceMap).map((k) => ({ [k]: new Map(choiceMap[k].planCategory) })),
)

class ManageTemplates extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      searchStr: '',
      templates: [],
      templateToEdit: {},
      tableKey: Math.random(),
    }
  }

  componentDidMount() {
    const { accounts } = this.props

    if (accounts.length === 1) {
      this.handleAccountSelected(accounts[0].id, accounts[0].businessName)
    }
  }

  deselectAccount = () => this.setState({ accountId: null, templates: [] })

  handleAccountSelected = async (accountId) => {
    const data = await getTemplatesForAccount(accountId)
    this.setState({ accountId, templates: data, tableKey: Math.random() })
  }

  formatData = () => {
    const searching = this.state.searchStr.length > 0
    const re = new RegExp(this.state.searchStr, 'i')

    return this.state.templates
      .map((t) => ({
        ...t,
        planCategory: t.planCategory || '-',
        name: <a onClick={() => this.editTemplate(t.id)}>{t.name}</a>,
        rawName: t.name, // can't sort by `name` because it's no longer a string
        date: t.editable ? moment(t.updateDate).format('DD/MM/YY') : 'Default',
        numEvents: t.events.length,
        rowDisabled: !t.enabled,
        className: CLASS_MAP[t.planType].get(t.planClass),
        categoryName: CATEGORY_MAP[t.planType].get(t.planCategory) || '-',
      }))
      .filter((t) => {
        let match = true
        if (searching) {
          match = re.test(t.rawName)
        }
        return match && (this.state.showDisabled || t.enabled)
      })
  }

  editTemplate = (id) => {
    const templateToEdit = this.state.templates.find((t) => t.id === id)

    this.setState({
      editingTemplate: true,
      templateToEdit,
      editFromCopy: false,
    })
  }

  copyTemplate = (id) => {
    const copiedTemplate = JSON.parse(JSON.stringify(this.state.templates.find((t) => t.id === id)))
    const originalId = copiedTemplate.id

    _.unset(copiedTemplate, 'name')
    _.unset(copiedTemplate, 'id')
    _.unset(copiedTemplate, 'enabled')
    _.unset(copiedTemplate, 'editable')

    this.setState({
      editingTemplate: true,
      templateToEdit: copiedTemplate,
      editFromCopy: true,
      copiedTemplateId: originalId,
    })
  }

  isDuplicate = (data) => {
    if (data.name) {
      const name = data.name.toLocaleLowerCase().trim()

      return !!this.state.templates.find((row) => {
        return (
          row.name.toLocaleLowerCase().trim() === name &&
          row.planType === data.planType &&
          row.planClass === data.planClass &&
          row.planCategory === (data.planCategory || null)
        )
      })
    }

    return false
  }

  enableTemplate = async (id) => {
    const result = await enableTemplateForAccount(this.state.accountId, id)

    if (result.success) {
      this.setTemplates(result.result)
    }
  }

  disableTemplate = async () => {
    const result = await disableTemplateForAccount(this.state.accountId, this.state.templateIdToDisable)

    if (result.success) {
      this.setTemplates(result.result)
    }

    this.setState({ disableWarningDialogOpen: false })
  }

  resetEdit = (templates) => {
    this.setState({
      creatingTemplate: false,
      editingTemplate: false,
      templateToEdit: {},
      copiedTemplateId: null,
      editFromCopy: false,
      templates: templates || this.state.templates,
    })
  }

  setTemplates = (templates, currentTemplateId) => {
    this.resetEdit(templates)

    if (currentTemplateId) {
      this.editTemplate(currentTemplateId)
    }
  }

  render() {
    const data = this.formatData()

    const { accounts } = this.props
    if (accounts && accounts.length === 0) {
      return <AccessDenied />
    }

    return (
      <Layout>
        <Route
          exact
          path='/manage-templates'
          render={() => (
            <div>
              <PageTitle onClick={() => this.resetEdit()}>Manage Templates</PageTitle>
              {!this.state.creatingTemplate && !this.state.editingTemplate && (
                <div>
                  <TemplatesTableHeader
                    showDisabled={this.state.showDisabled}
                    onSearchChange={(event) => this.setState({ searchStr: event.target.value })}
                    onDisabledToggle={(event) => this.setState({ showDisabled: event.target.checked })}
                    createTemplate={() => this.setState({ creatingTemplate: true })}
                    accounts={accounts}
                    handleAccountSelected={this.handleAccountSelected}
                    accountId={this.state.accountId}
                    deselectAccount={this.deselectAccount}
                  />
                  <TemplatesTable
                    data={data}
                    key={this.state.tableKey}
                    editTemplate={this.editTemplate}
                    copyTemplate={this.copyTemplate}
                    enableTemplate={this.enableTemplate}
                    disableTemplate={(id) => {
                      this.setState({
                        disableWarningDialogOpen: true,
                        templateIdToDisable: id,
                      })
                    }}
                  />
                </div>
              )}
              {(this.state.creatingTemplate || this.state.editingTemplate) && (
                <AddEditTable
                  data={this.state.templateToEdit}
                  copied={this.state.editFromCopy}
                  setTemplates={this.setTemplates}
                  copiedTemplateId={this.state.copiedTemplateId}
                  accountId={this.state.accountId}
                  isDuplicate={this.isDuplicate}
                />
              )}
              {this.state.disableWarningDialogOpen && (
                <WarningDialog
                  open
                  warning={{
                    title: 'Confirm Disable',
                    message: 'Confirm disabling this template',
                    hasCancel: true,
                  }}
                  handleRequestClose={() => this.setState({ disableWarningDialogOpen: false })}
                  handleConfirm={this.disableTemplate}
                />
              )}
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default requireAuth((props) => {
  const { accounts } = useAccount()
  return <ManageTemplates {...props} accounts={accounts} />
})
