import React from 'react'

import { requireAuth } from '../../components/withAuth'
import Layout from '../../components/Layout'
import AccountUpdate from './AccountUpdate'

import './Account.css'

class Account extends React.Component {
  render() {
    const loaded = this.props.user

    return (
      <Layout title='Account' loaded={loaded}>
        {loaded ? <AccountUpdate user={this.props.user} /> : ''}
      </Layout>
    )
  }
}

export default requireAuth(Account, { loading: null })
