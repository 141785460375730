import React from 'react'
import { withFormValidation, PhoneField, ReadOnlyField, SubmitButton, TextField } from '../../components/Form/Form'
import { getUser, saveUser, userNewApiKey } from '../../data/user'
import { NameField } from '../../components/Form/CustomFields'
import Button from '@material-ui/core/Button'
import ChangeEmailDialog from '../../components/Dialog/ChangeEmailDialog'
import ChangePasswordDialog from '../../components/Dialog/ChangePasswordDialog'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import Edit from '@material-ui/icons/Edit'
import Refresh from '@material-ui/icons/Refresh'

const AccountFormContent = (props) => (
  <div>
    <div className='FormRow'>
      <NameField name='name' label='Name' required />
    </div>
    <div className='FormRow'>
      <PhoneField name='mobilePhone' label='Phone' required />
    </div>
    <div className='FormRow'>
      <div className='FormRowFlex'>
        <ReadOnlyField className='Description' name='email' label='Email' value={props.newEmail} />
        <Button onClick={props.openEditEmail}>
          <Edit className='Button' />
        </Button>
      </div>
    </div>
    <div className='FormRow'>
      <div className='FormRowFlex'>
        <ReadOnlyField className='Description' name='password' label='Password' value='******' />
        <Button onClick={props.openEditPassword}>
          <Edit className='Button' />
        </Button>
      </div>
    </div>
    <div className='FormRow'>
      <div className='FormRowFlex'>
        <TextField name='apiKeyFake' label='API Key' value={props.apiKey} muiExtra={{ readOnly: true }} />
        <Button>
          <Refresh onClick={props.refreshApiKey} />
        </Button>
      </div>
    </div>

    <div className='ButtonWrap'>
      <SubmitButton disabled={!props.canSubmit || !props.hasChanged}>Save Changes</SubmitButton>
    </div>
  </div>
)

const AccountForm = withFormValidation(AccountFormContent)

class AccountUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
    this.validate = this.validate.bind(this)
    this.getInitialData = this.getInitialData.bind(this)
    this.openEditEmail = this.openEditEmail.bind(this)
    this.openEditPassword = this.openEditPassword.bind(this)
    this.handleRequestCloseEmail = this.handleRequestCloseEmail.bind(this)
    this.handleRequestClosePassword = this.handleRequestClosePassword.bind(this)
    this.handleRequestCloseSnackbar = this.handleRequestCloseSnackbar.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      changeEmailOpen: false,
      changePasswordOpen: false,
      snackbarOpen: false,
      hasChanged: false,
    }
  }

  submit(data) {
    var submitData = {
      name: data.name,
      mobilePhone: data.mobilePhone,
    }
    saveUser(this.props.user.uid, submitData).then(() => {
      this.setState({
        snackbarOpen: true,
        snackbarContent: 'Details saved',
      })
    })
  }

  getInitialData() {
    return getUser(this.props.user.uid).then((user) => {
      this.setState({ apiKey: user.apiKey })
      return user
    })
  }

  openEditEmail() {
    this.setState({
      changeEmailOpen: true,
    })
  }

  openEditPassword() {
    this.setState({
      changePasswordOpen: true,
    })
  }

  handleRequestCloseEmail(newEmail) {
    this.setState({
      newEmail,
      changeEmailOpen: false,
      snackbarOpen: true,
      snackbarContent: 'Email updated',
    })
  }

  handleRefreshApiKey = () => {
    userNewApiKey(this.props.user.uid).then((data) => {
      this.setState({
        apiKey: data.apiKey,
        snackbarOpen: true,
        snackbarContent: 'API key changed, previous one is now invalid',
      })
    })
  }

  handleRequestClose() {
    this.setState({
      changeEmailOpen: false,
      changePasswordOpen: false,
    })
  }

  handleRequestClosePassword() {
    this.setState({
      changePasswordOpen: false,
      snackbarOpen: true,
      snackbarContent: 'Password updated',
    })
  }

  handleRequestCloseSnackbar() {
    this.setState({
      snackbarOpen: false,
    })
  }

  validate(data) {
    if (data.name !== this.props.user.name || data.mobilePhone !== this.props.user.mobilePhone) {
      this.setState({
        hasChanged: true,
      })
    } else {
      this.setState({
        hasChanged: false,
      })
    }
  }

  render() {
    return (
      <div className='accountContainer'>
        <AccountForm
          submit={this.submit}
          getInitialData={this.getInitialData}
          openEditEmail={this.openEditEmail}
          openEditPassword={this.openEditPassword}
          refreshApiKey={this.handleRefreshApiKey}
          validate={this.validate}
          validationErrors={this.state.validationErrors}
          hasChanged={this.state.hasChanged}
          newEmail={this.state.newEmail}
          apiKey={this.state.apiKey}
        />
        <ChangeEmailDialog
          open={this.state.changeEmailOpen}
          onRequestCloseSuccess={this.handleRequestCloseEmail}
          onRequestClose={this.handleRequestClose}
        />
        <ChangePasswordDialog
          open={this.state.changePasswordOpen}
          onRequestCloseSuccess={this.handleRequestClosePassword}
          onRequestClose={this.handleRequestClose}
        />
        <BottomSnackbar
          open={this.state.snackbarOpen}
          handleRequestClose={this.handleRequestCloseSnackbar}
          message={this.state.snackbarContent}
        />
      </div>
    )
  }
}

export default AccountUpdate
