import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import React, { useState } from 'react'

import DialogHeader from '../../../../components/Dialog/DialogHeader'
import { DialogContainer, DialogContent } from '../../../../components/Dialog/FormDialog'
import { datePickerFormat, dosageTypeChoicesMap, eventTypeChoicesMap } from '../../../../constants'
import { completeFarmEvent, uncompleteFarmEvent } from '../../../../data/plans'

const ShowText = ({ label, value }): JSX.Element => {
  return <TextField label={label} defaultValue={value} InputProps={{ readOnly: true }} fullWidth multiline />
}

export default ({ data, onRequestClose, ...props }): JSX.Element => {
  const [completed, setCompleted] = useState(data.completed)

  const onToggleComplete = (completed): void => {
    ;(completed ? completeFarmEvent : uncompleteFarmEvent)(props.farmId, data.id)
    setCompleted(completed)
  }

  const handleRequestClose = (): void => {
    // force refresh of data when the only editable value - completed status - is different
    onRequestClose(completed !== data.completed)
  }

  const healthTreatmentName = data.healthTreatment ? props.healthTreatments[data.healthTreatment.id].name : ''
  const displayDate = moment(data.dueDate).format(datePickerFormat)

  return (
    <DialogContainer onClose={handleRequestClose} onBackdropClick={handleRequestClose} width='md' open>
      <DialogHeader
        title='View Event'
        onClose={handleRequestClose}
        hasSubmit={false}
        hasClose={true}
        closeMessage='Close'
      />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={completed} onChange={(event) => onToggleComplete(event.target.checked)} />}
              label='Event Is Completed'
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <ShowText label='Event Type' value={eventTypeChoicesMap[data.eventType]} />
          </Grid>
          <Grid item md={8} xs={12}>
            <ShowText label='Event Name' value={data.eventName} />
          </Grid>

          <Grid item xs={12}>
            <ShowText label='Purpose' value={data.purpose} />
          </Grid>

          <Grid item xs={12}>
            <ShowText label='Instructions' value={data.instructions} />
          </Grid>

          {data.eventType === 'Treatment' && (
            <>
              <Grid item xs={6}>
                <ShowText label='Health Treatment' value={healthTreatmentName} />
              </Grid>
              <Grid item xs={6}>
                <ShowText label='Dosage Qty' value={data.healthTreatment.dosage} />
              </Grid>

              <Grid item xs={6}>
                <ShowText label='Dosage UOM' value={data.healthTreatment.uom} />
              </Grid>
              <Grid item xs={6}>
                <ShowText label='Dosage Type' value={dosageTypeChoicesMap[data.healthTreatment.dosageType]} />
              </Grid>
            </>
          )}

          {data.scheduleType === 'fixed' && (
            <>
              <Grid item md={4} xs={6}>
                <ShowText label='Schedule Type' value='Fixed Date' />
              </Grid>
              <Grid item md={3} xs={6}>
                <ShowText label='Date' value={displayDate} />
              </Grid>
              <Grid item md={5} xs={12}>
                <FormControlLabel control={<Checkbox checked={data.repeat} disabled />} label='Repeat Event Annually' />
              </Grid>
            </>
          )}

          {data.scheduleType === 'dependent' && (
            <>
              <Grid item md={4} xs={12}>
                <ShowText label='Schedule Type' value='Dependent Event' />
              </Grid>
              <Grid item md={8} xs={12}>
                <ShowText label='Dependent Event Name' value={data.dependentEventName} />
              </Grid>
              <Grid item xs={12}>
                <ShowText label='Lead (-) or Lag (+) Days' value={data.leadLag} />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </DialogContainer>
  )
}
