import React from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import moment from 'moment'

import SelectorDropdown from '../../components/SelectorDropdown'
import { withFormValidation } from '../../components/Form/Form'
import { NaitField } from '../../components/Form/CustomFields'
import { PushButton } from '../../components/Form/Form'
import AssignmentTable from './AssignmentTable'
import WarningDialog from '../../components/Dialog/WarningDialog'
import { timestampFormat } from '../../constants'

import './Nait.css'

const ReceiverFieldContent = (props) => (
  <NaitField
    name='naitNumber'
    label='Enter Receiver NAIT Number (2-6 or 8 digits)'
    disabled={props.disabled}
    onChange={props.onChange}
  />
)

const ReceiverField = withFormValidation(ReceiverFieldContent)

class Assignment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOptionId: undefined,
      naitNumber: '',
      selected: [],
    }

    this.handleOptionSelected = this.handleOptionSelected.bind(this)
    this.updateSelected = this.updateSelected.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNaitNumberChanged = this.handleNaitNumberChanged.bind(this)
  }

  handleOptionSelected(id, value) {
    this.setState({
      selectedOptionId: id,
      selectedOption: value,
    })
  }

  handleNaitNumberChanged(value) {
    this.setState({
      naitNumber: value,
    })
  }

  updateSelected(selected) {
    this.setState({
      selected,
    })
  }

  handleSubmit() {
    if (this.state.selectedOptionId === '1') {
      this.props.assign({
        thirdParty: this.state.selected.map((i) => this.props.data[i].eid),
      })
    } else if (this.state.selectedOptionId === '2') {
      this.props.assign({
        self: {
          [this.state.naitNumber]: this.state.selected.map((i) => this.props.data[i].eid),
        },
      })
    }

    this.props.goBack()
  }

  canSubmit() {
    if (this.state.selectedOptionId === '1') {
      return this.state.selected.length > 0
    } else if (this.state.selectedOptionId === '2') {
      if (!isNaN(this.state.naitNumber)) {
        const length = this.state.naitNumber.length
        return ((length >= 2 && length <= 6) || length === 8) && this.state.selected.length > 0
      }
    }
    return false
  }

  formattedData = () =>
    (this.props.data || []).map((row) => ({
      ...row,
      date: moment(row.date).format(timestampFormat),
    }))

  render() {
    const data = this.formattedData()
    if (!data) {
      this.props.reset()
    }

    return (
      <div>
        <div className='headerRow assignmentHeading'>
          <section>
            <ArrowBack
              className='backButton'
              onClick={() => {
                if (this.state.selected.length > 0) {
                  this.setState({ warningDialogOpen: true })
                } else {
                  this.props.goBack()
                }
              }}
            />
          </section>
          <section>
            <div className='title'>
              {this.props.data && `${this.props.data[0].animal} ${this.props.data[0].status}`}
            </div>
          </section>
          <section>
            <PushButton disabled={!this.canSubmit()} onClick={this.handleSubmit}>
              Complete
            </PushButton>
          </section>
        </div>
        <div className='headerRow responsiblePartySection'>
          <section>
            <SelectorDropdown
              className='dropdown'
              defaultOption='Select Responsible Party'
              options={[
                ['1', '3rd Party'],
                ['2', this.props.nameAndNait],
              ]}
              selectedOption={this.state.selectedOption}
              handleOptionSelected={this.handleOptionSelected}
            />
          </section>
          <section className='receiverFieldSection'>
            <ReceiverField disabled={this.state.selectedOptionId !== '2'} onChange={this.handleNaitNumberChanged} />
          </section>
          <section />
        </div>
        <AssignmentTable data={data} updateSelected={this.updateSelected} selected={this.state.selected} />
        <WarningDialog
          open={this.state.warningDialogOpen}
          warning={{
            title: 'Warning',
            message: 'Selections have not been completed and will be lost.',
            hasCancel: true,
          }}
          handleRequestClose={() => this.setState({ warningDialogOpen: false })}
          handleConfirm={this.props.goBack}
        />
      </div>
    )
  }
}

export default Assignment
