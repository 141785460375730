import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from '../../components/Dialog/DialogHeader'

import '../../components/Dialog/Dialog.css'

const WarningDialog = ({ open, warning, handleRequestClose, handleConfirm, confirmButtonTitle = 'OK' }) => (
  <Dialog className='dialog' onClose={handleRequestClose} open={open}>
    <DialogHeader title={warning && warning.title} handleRequestClose={handleRequestClose} />
    <div className='dialogContent naitInfoDialog'>
      <p className='warningMessage'>{warning && warning.message}</p>
      {warning && warning.jsx}
      {warning && warning.hasCancel ? (
        <div className='dialogButtons'>
          <Button className='grey' onClick={handleRequestClose}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>{confirmButtonTitle}</Button>
        </div>
      ) : (
        <div className='dialogButtons'>
          <Button onClick={handleRequestClose}>{confirmButtonTitle}</Button>
        </div>
      )}
    </div>
  </Dialog>
)

export default WarningDialog
