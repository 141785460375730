// @ts-ignore
import config from 'config'
import moment from 'moment'

import { get, post, put } from '../../helpers/fetch'
import { getAuthHeaders } from '../../helpers/firebase'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings } from './dashboard'

export interface GroupWeightMetric extends DashboardMetric {
  readonly animalType: AnimalType
  readonly groupId?: string
  readonly startDate?: date
  readonly startWeight?: number
  readonly endDate?: date
  readonly endWeight?: number
  readonly stdDev: 1 | 2
}

interface GroupWeightRequest {
  readonly groupId: string
  readonly dateFrom: date
  readonly dateTo: date
  readonly timezone: string
}

export interface Group {
  readonly animalType: AnimalType
  readonly id: string
  readonly name: string
  readonly type: 'Id' | 'NonId'
  readonly year: number
}

interface GroupsResponse {
  readonly groups: Group[]
}

interface GroupWeightRow {
  readonly avg: number
  readonly count: number
  readonly date: string
  readonly max: number
  readonly min: number
  readonly std: number
}

interface GroupWeightResponse {
  readonly groupWeight: GroupWeightRow[]
}

export const groupWeightMetric: GroupWeightMetric = {
  type: 'Group Weight',
  animalType: AnimalType.Cattle,
}

export function groupWeightChartSettings(metric: GroupWeightMetric, data): DashboardMetricChartSettings {
  // for some reason auto is not doing a good job, get extremes manually
  let dataMin = metric.startWeight
  let dataMax = metric.endWeight

  data.slice(1).forEach((row) => {
    row.slice(1).forEach((num) => {
      if (typeof num === 'number') {
        dataMin = Math.min(dataMin, num)
        dataMax = Math.max(dataMax, num)
      }
    })
  })

  return {
    type: 'LineChart',
    options: {
      tooltip: { isHtml: true },
      chartArea: { width: '80%', height: '75%' },
      legend: { position: 'top', alignment: 'center' },
      interval: {
        stdDev: { style: 'area', fillOpacity: 0.5, color: '#6BB592' },
        minMax: { style: 'area', fillOpacity: 0.5, color: '#F7E87F' },
      },
      curveType: 'function',
      colors: ['#F4B400', '#F4B400', '#DB4437', '#0F9D58', '#B2D4A7'],
      series: {
        // max
        0: { visibleInLegend: false },
        // min
        1: { visibleInLegend: false },
        // goal
        2: { pointShape: 'square', pointSize: 10 },
        // avg
        3: { pointSize: 5 },
        4: { lineWidth: 20 },
      },
      hAxis: {
        viewWindow: {
          min: metric.startDate,
          max: metric.endDate,
        },
        textStyle: { color: '#919191' },
      },
      vAxis: {
        textStyle: { color: '#919191' },
        viewWindow: {
          min: dataMin - 1,
          max: dataMax + 1,
        },
      },
    },
  }
}

export async function getGroups(farmId: string, animalType: AnimalType): Promise<Group[]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/groups/${animalType}`

  const headers = await getAuthHeaders()
  const response: GroupsResponse = await get(url, headers)
  return response.groups
}

const prepareDataForChart = (metric: GroupWeightMetric, data: GroupWeightRow[]) => {
  const startTime = metric.startDate.getTime()
  const endTimeOffset = metric.endDate.getTime() - startTime
  const heightDiff = metric.endWeight - metric.startWeight
  const dateFormat = { year: 'numeric', month: 'short', day: '2-digit' }

  return [
    [
      { type: 'date', label: 'Date', id: 'date' },
      { type: 'number', label: 'Max', id: 'max' },
      { type: 'number', label: 'MinMax', role: 'interval', id: 'minMax' },
      { type: 'number', label: 'MinMax', role: 'interval', id: 'minMax' },
      { type: 'number', label: 'Min', id: 'min' },
      { type: 'number', label: 'Goal', id: 'goal' },
      { type: 'string', role: 'style' },
      { type: 'number', label: 'Average', id: 'avg' },
      { type: 'string', role: 'style' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'number', label: 'StdDev', role: 'interval', id: 'stdDev' },
      { type: 'number', label: 'StdDev', role: 'interval', id: 'stdDev' },
      // fake columns just to display extra things in legend
      // intervals don't get their own item in legend
      { type: 'number', label: `${metric.stdDev} SD Range` },
      { type: 'number', label: 'Min/Max Range' },
    ],
    [metric.startDate, null, null, null, null, metric.startWeight, null, null, null, null, null, null, null, null],
    ...data
      .map((row) => ({ ...row, date: new Date(row.date + ' 00:00:00') }))
      .map((row) => [
        row.date,
        // min/max range
        row.max,
        row.max,
        row.min,
        row.min,
        ((row.date.getTime() - startTime) / endTimeOffset) * heightDiff + metric.startWeight,
        'point { size: 0; }',
        row.avg,
        'point {stroke-color: white; stroke-width: 2;}',
        `<div style="padding: 1em; white-space: nowrap;">
      <strong>${row.date.toLocaleDateString('en-us', dateFormat)}</strong><br/>
      <strong>Average</strong>: ${row.avg}<br/>
      <strong>Count</strong>: ${row.count}
      </div>`,
        // StdDev
        row.avg + row.std * metric.stdDev,
        row.avg - row.std * metric.stdDev,
        null,
        null,
      ]),
    [metric.endDate, null, null, null, null, metric.endWeight, null, null, null, null, null, null, null, null],
  ]
}

export async function calculateGroupWeight(farmId: string, metric: GroupWeightMetric): Promise<GroupWeightRow[]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/metric/GroupWeight`

  if (!metric.groupId || !metric.startDate || !metric.endDate) {
    return null
  }

  // api doesn't accept any date in future
  // makes sense since there's no data in the future
  // but can select date because want to show goal line into future
  // UTC trick because NZ is ahead of server time which is UTC and will get rejected
  const endDate = Math.min(metric.endDate, moment.utc(new Date()).toDate())
  const request: GroupWeightRequest = {
    groupId: metric.groupId,
    dateFrom: moment.utc(metric.startDate).format('YYYY-MM-DD'),
    dateTo: moment.utc(endDate).format('YYYY-MM-DD'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const headers = await getAuthHeaders()
  const response: GroupWeightResponse = await post(url, request, headers)
  return prepareDataForChart(metric, response.groupWeight)
}

interface GroupWeightAnimalSettings {
  readonly groupId: string
  readonly startDate: string
  readonly endDate: string
  readonly startWeight: number
  readonly endWeight: number
  readonly stdDev: number
}

export interface GroupWeightSettings {
  readonly Cattle?: GroupWeightAnimalSettings
  readonly Deer?: GroupWeightAnimalSettings
  readonly Sheep?: GroupWeightAnimalSettings
}

export async function loadGroupWeightSettings(farmId: string, position: number): Promise<GroupWeightSettings> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/GroupWeight`

  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export async function saveGroupWeightSettings(
  farmId: string,
  settings: GroupWeightSettings,
  position: number,
): Promise<void> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/GroupWeight`

  const headers = await getAuthHeaders()
  await put(url, settings, headers)
}
