import React from 'react'
import MaterialTable from '../../../components/Table/Table'
import _ from 'lodash'
import moment from 'moment'

const formatData = (data, expandedRows, startDate, currency) => {
  let rows = []
  data.forEach((t, key) => {
    rows.push({
      ..._.pick(t, ['id', 'name']),
      ..._.fromPairs(
        t.forecast.map((v, k) => [
          startDate
            .clone()
            .add(k, 'months')
            .format('YYYY-M'),
          v.toLocaleString('en-NZ', {
            style: currency ? 'currency' : 'decimal',
            currency: 'NZD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) + (currency ? '' : t.uom),
        ]),
      ),
      hideOverflow: t.id === 'totals',
      rowKey: key,
    })
    if (expandedRows && expandedRows.includes(t.id) && t.farms) {
      t.farms.forEach((f, subKey) => {
        rows.push({
          id: f.farmId,
          parentId: t.id,
          name: f.farmName,
          ..._.fromPairs(
            f.forecast.map((v, k) => [
              startDate
                .clone()
                .add(k, 'months')
                .format('YYYY-M'),
              v.toLocaleString('en-NZ', {
                style: currency ? 'currency' : 'decimal',
                currency: 'NZD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) + (currency ? '' : t.uom),
            ]),
          ),
          rowKey: key,
          subRowKey: subKey,
          isSubRow: true,
        })
      })
    }
  })

  return rows
}

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = { expandedRows: [] }
  }

  render() {
    const { farmId, data, startMonth, startYear, currency } = this.props
    const { expandedRows } = this.state
    console.log('data', data)
    const startDate = moment(`${startYear}-${startMonth}`, 'YYYY-M')
    const baseColumns = [{ id: 'name', label: 'Treatment', style: { minWidth: '200px', flexGrow: 2 } }]

    _.times(12).forEach((n) => {
      const month = startDate.clone().add(n, 'months')
      baseColumns.push({ id: month.format('YYYY-M'), label: month.format('MMM YY'), style: { minWidth: '85px' } })
    })

    const columns = baseColumns.map((column) => ({
      ...column,
      style: { whiteSpace: 'nowrap', ...column.style },
    }))

    columns.splice(1, 0, { id: 'expand', overflowColumn: true })

    const subRowColumns = baseColumns.map((column) => ({
      ..._.pick(column, ['id']),
      style: { alignItems: 'flex-start', flexGrow: 1, ...column.style },
    }))

    subRowColumns.push({ id: 'expand', style: { flexGrow: 1, minWidth: '32px' } })

    return (
      <MaterialTable
        columns={columns}
        data={formatData(data, expandedRows, startDate, currency)}
        hasSubRows={!farmId}
        subRowColumns={subRowColumns}
        expandedRows={expandedRows}
        expandRow={(id) => this.setState({ expandedRows: expandedRows.concat(id) })}
        collapseRow={(id) => {
          _.remove(expandedRows, (r) => r === id)
          this.setState({ expandedRows })
        }}
        subRowMaxWidth='unset'
      />
    )
  }
}
