import React from 'react'
import { SubmitButton } from '../../../components/Form/Form'
import { updateSubscription } from '../../../data/billing'
import { requireAuth } from '../../../components/withAuth'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogHeader from '../../../components/Dialog/DialogHeader'
import AddBox from '@material-ui/icons/AddBox'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'

import '../../../components/Dialog/Dialog.css'

const COLUMNS_XS = {
  status: 1,
  paymentMethod: 2,
  paymentSource: 2,
  accountNumber: 3,
  expirationDate: 1,
  remove: 2,
}
const COLUMNS_SM = COLUMNS_XS

const EditPaymentMethodsTable = (props) =>
  props.methods && props.methods.length > 0 ? (
    <div className='farmUserTable'>
      <Grid container spacing={12} className='dialogTable'>
        <Grid item xs={COLUMNS_XS.status} sm={COLUMNS_SM.status}>
          <p className='tableHeader'>Status</p>
        </Grid>
        <Grid item xs={COLUMNS_XS.paymentMethod} sm={COLUMNS_SM.paymentMethod}>
          <p className='tableHeader'>Payment Method</p>
        </Grid>
        <Grid item xs={COLUMNS_XS.paymentSource} sm={COLUMNS_SM.paymentSource}>
          <p className='tableHeader'>Payment Source</p>
        </Grid>
        <Grid item xs={COLUMNS_XS.accountNumber} sm={COLUMNS_SM.accountNumber}>
          <p className='tableHeader'>Account Number</p>
        </Grid>
        <Grid
          item
          xs={COLUMNS_XS.expirationDate + COLUMNS_XS.remove}
          sm={COLUMNS_SM.expirationDate + COLUMNS_SM.remove}
        >
          <p className='tableHeader'>Expiration Date</p>
        </Grid>
      </Grid>
      <hr />
      {props.methods.map((method, key) => (
        //FIXME: replace key with proper key data
        <div key={key}>
          <Grid container spacing={12} className='dialogTable'>
            <Grid item xs={COLUMNS_XS.status} sm={COLUMNS_SM.status}>
              <p className='dataContent'>{method.status}</p>
            </Grid>
            <Grid item xs={COLUMNS_XS.paymentMethod} sm={COLUMNS_SM.paymentMethod}>
              <p className='dataContent'>{method.paymentMethod}</p>
            </Grid>
            <Grid item xs={COLUMNS_XS.paymentSource} sm={COLUMNS_SM.paymentSource}>
              <p className='dataContent'>{method.cardType}</p>
            </Grid>
            <Grid item xs={COLUMNS_XS.accountNumber} sm={COLUMNS_SM.accountNumber}>
              <p className='dataContent'>{method.cardNumber}</p>
            </Grid>
            <Grid item xs={COLUMNS_XS.expirationDate} sm={COLUMNS_SM.expirationDate}>
              <p className='dataContent'>
                {method.expiryMonth}/{method.expiryYear}
              </p>
            </Grid>
            <Grid item xs={COLUMNS_XS.remove} sm={COLUMNS_SM.remove}>
              <p className='dataContent'>
                {method.status === 'default' ? null : (
                  <SubmitButton
                    className='removeButton'
                    onClick={() => {
                      console.log('remove payment method')
                    }}
                  >
                    Remove
                  </SubmitButton>
                )}
              </p>
            </Grid>
          </Grid>
          <hr />
        </div>
      ))}
    </div>
  ) : (
    <div className='noPaymentMethods'>No Payment Method</div>
  )

class AddPaymentMethodFormContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.submit = this.submit.bind(this)
  }

  submit() {
    const stripe = this.props.stripe

    this.setState({
      loading: true,
    })

    stripe.createToken({ name: this.props.email }).then(({ token, error }) => {
      if (error) {
        this.setState({ error: error.message })
      } else {
        this.props.submit(token)
      }
    })
  }

  render() {
    return (
      <div className='addPaymentMethodForm'>
        <div className='FormRow card'>
          <CardElement onChange={this.props.onChange} />
        </div>
        <div className='ButtonWrap'>
          <Button variant='contained' className='Back dialogBack' onClick={this.props.back}>
            Cancel
          </Button>
          <SubmitButton disabled={!this.props.canSubmit || this.state.loading} onClick={this.submit}>
            {this.state.loading ? 'Loading...' : 'Add'}
          </SubmitButton>
        </div>
      </div>
    )
  }
}

const AddPaymentMethodForm = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => <AddPaymentMethodFormContent elements={elements} stripe={stripe} />}
    </ElementsConsumer>
  )
}

class EditPaymentMethodsDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.back = this.back.bind(this)
    this.submitNewCard = this.submitNewCard.bind(this)
  }

  handleRequestClose() {
    this.props.onRequestClose()

    this.setState({
      open: false,
      errorMessage: '',
      addPaymentMethod: false,
    })
  }

  back() {
    this.setState({
      addPaymentMethod: false,
      editPaymentMethod: false,
      complete: false,
    })
  }

  submitNewCard(token) {
    updateSubscription(this.props.accountId, {
      stripeToken: token,
    })
      .then(() => {
        this.props.updatePaymentMethods(this.props.accountId)
        this.back()
      })
      .catch((error) => {
        console.log(error)
        this.setState({ error: error.data.message })
        this.back()
      })
  }

  render() {
    const { ...other } = this.props

    const onChange = ({ complete }) => {
      this.setState({ complete: complete })
    }

    let title = 'Edit Payment Methods'
    if (this.state.addPaymentMethod) {
      title = 'Add Payment Method'
    }

    return (
      <Dialog
        onClose={this.handleRequestClose}
        {...other}
        fullWidth={!this.state.addPaymentMethod}
        maxWidth={'md'}
        className='editPaymentMethodsDialog'
      >
        <DialogHeader title={title} handleRequestClose={this.handleRequestClose} />
        <div className='dialogContent'>
          <div className='errorMessage'>{this.state.errorMessage}</div>
          {this.state.addPaymentMethod ? (
            <AddPaymentMethodForm
              back={this.back}
              onChange={onChange}
              canSubmit={this.state.complete}
              submit={this.submitNewCard}
              email={this.props.user.email}
            />
          ) : (
            <div>
              <div className='ButtonWrap addPaymentButton'>
                <Button
                  onClick={() => {
                    this.setState({
                      addPaymentMethod: true,
                    })
                  }}
                >
                  <AddBox />{' '}
                  {this.props.paymentMethods && this.props.paymentMethods.length > 0 ? 'Change payment method' : 'Add'}
                </Button>
              </div>
              <EditPaymentMethodsTable methods={this.props.paymentMethods} goToEdit={this.goToEdit} />
            </div>
          )}
        </div>
      </Dialog>
    )
  }
}

export default requireAuth(EditPaymentMethodsDialog)
