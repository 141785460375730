import React, { useState } from 'react'
import styled from 'styled-components'

import ParentRow from './ParentRow'
import { colors } from '../../../../../styles/styleConstants'

export default ({ data, startMonth, startYear, setEventToEdit, setEventToDelete, onToggleComplete, deletePlan }) => {
  const [expandedRows, setExpandedRows] = useState([])

  return (
    <TableContainer>
      {data.map((category) => (
        // eslint-disable-next-line react/jsx-key
        <ParentRow
          data={category}
          expanded={expandedRows.includes(category.name)}
          onToggle={() => {
            if (expandedRows.includes(category.name)) {
              setExpandedRows(expandedRows.filter((c) => c !== category.name))
            } else {
              setExpandedRows(expandedRows.concat(category.name))
            }
          }}
          startMonth={startMonth}
          startYear={startYear}
          setEventToEdit={setEventToEdit}
          setEventToDelete={setEventToDelete}
          onToggleComplete={onToggleComplete}
          deletePlan={deletePlan}
        />
      ))}
    </TableContainer>
  )
}

const TableContainer = styled.div`
  border-top: 1px solid ${colors.lightGrey};
  margin-top: 12px;
`
