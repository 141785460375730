// @ts-ignore
import config from 'config'

import { get } from '../helpers/fetch'
import { getAuthHeaders } from '../helpers/firebase'

export interface AccessAccount {
  readonly id: number
  readonly businessName: string
  readonly accountType: 'Vet' | 'Standard'
  readonly accountRole: 'Clinic' | 'Admin' | 'Retail' | 'Account'
  readonly accountRoleScore: number
}

export interface AccessFarm {
  readonly id: string
  readonly farmName: string
  readonly accountId: number
  readonly userRole: 'Super' | 'Clinic' | 'Admin' | 'Vet' | 'Account' | 'Staff' | 'Farm' | 'Viewer'
  readonly userRoleScore: number
  readonly subscriptionType: 'Web' | 'Full' | 'Standard'
}

export const getAccess = async (): Promise<{ accounts: AccessAccount[]; farms: AccessFarm[] }> => {
  const urlAccounts = config.backend.url + '/accounts'
  const urlFarms = config.backend.url + '/farms'
  const headers = await getAuthHeaders()

  return Promise.all([get(urlAccounts, headers), get(urlFarms, headers)]).then(([accountsPromise, farmsPromise]) => {
    return { accounts: accountsPromise.accounts, farms: farmsPromise.farms }
  })
}
