import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import * as EmailValidator from 'email-validator'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { PushButton, SelectInput } from '../../components/Form/Form'
import Layout from '../../components/Layout'
import LoadingContainer from '../../components/LoadingContainer'
import { PageTitle } from '../../components/PageHeaders'
import { withUser } from '../../components/withAuth'
import { nzRegionChoices } from '../../constants'
import { createBillingAccount } from '../../data/billing'
import { createCard } from '../../data/billing'
import { EMAIL_MAX_LENGTH, MISC_MAX_LENGTH } from '../../data/general'
import { countryChoices, createUser, saveUser } from '../../data/user'

const countryChoicesForSelect = countryChoices.map((row) => [row.value, row.label])

export const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl fullWidth={props.fullWidth || false} className='MuiTextField-root'>
      <InputLabel htmlFor='password'>{props.label}</InputLabel>
      <Input
        id='password'
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />
      {props.helperText && (
        <FormHelperText id='password' error={props.error}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

interface SignupProps {}

const SignupComponent: React.FunctionComponent<SignupProps> = (props): JSX.Element => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('NZ')
  const [region, setRegion] = useState('')
  const [disableRegion, setDisableRegion] = useState(true)
  const [validationErrors, setValidationErrors] = useState({})
  const [cardComplete, setCardComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')
  const [finished, setFinished] = useState(false)
  const [accountResult, setAccountResult] = useState(undefined)

  const stripe = useStripe()
  const elements = useElements()

  // only target country has regions
  useEffect(() => {
    setDisableRegion(country !== 'NZ')
  }, [country])

  // validation
  useEffect(() => {
    const errors = {}

    if (postalCode.length > MISC_MAX_LENGTH) errors.postalCode = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (email.length > EMAIL_MAX_LENGTH) errors.email = `Too long, max ${EMAIL_MAX_LENGTH} characters.`
    if (email && !EmailValidator.validate(email)) errors.email = 'Invalid email.'
    if (password && password.length < 8) errors.password = 'Too short, minimum 8 characters.'
    if (name.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (businessName.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (address1.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (address2.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (city.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`
    if (postalCode.length > MISC_MAX_LENGTH) errors.name = `Too long, max ${MISC_MAX_LENGTH} characters.`

    setValidationErrors(errors)
  }, [email, password, name, businessName, address1, address2, city, postalCode, country, region])

  // toggle button
  const canSubmit =
    (props.user.authenticated ||
      (_.isEmpty(validationErrors) &&
        email &&
        password &&
        name &&
        businessName &&
        address1 &&
        city &&
        postalCode &&
        country &&
        (disableRegion || region))) &&
    cardComplete

  // multistep signup process
  //
  // 1. Create account on firebase
  // 2. Wait for backend to become aware of it
  // 3. Send additional data to backend to save
  // 4. Submit credit card info for the user
  // 5. Redirect elsewhere on success
  const submit = () => {
    setLoading(true)

    const userData = { name }
    const accountData = { businessName, postalCode, contactName: name, address1, address2, city, region, country }
    const userPromise = props.user.authenticated
      ? saveUser(props.user.uid, userData)
      : createUser({ email: email, password: password, ...userData })

    userPromise
      // make firebase user
      .then(({ success, userId, ...result }) => {
        if (!success) {
          // show inline near email field
          if (result.code === 'auth/email-already-in-use') {
            setValidationErrors({ email: result.error })
            throw new Error()
          } else {
            throw new Error(result.error)
          }
        } else if (accountResult) {
          // skip step if already created, probably a payment problem
          return accountResult
        } else {
          return createBillingAccount(userId, accountData).then((result) => {
            result.accountId = result.id
            setAccountResult(result)
            return result
          })
        }
      })
      //
      .then(({ success, accountId, subscriptionStatus, ...result }) => {
        if (!success) {
          throw new Error(result.error)
        } else {
          return accountId
        }
      })
      .then(async (accountId) => {
        const cardElement = elements.getElement(CardElement)

        const { error, token } = await stripe.createToken(cardElement)

        if (error) throw new Error(error)

        const res = await createCard(accountId, { stripeToken: token })

        if (!res.success) {
          throw new Error(res.error)
        }
        setFinished(true)
      })
      .catch((error) => {
        setLoading(false)
        if (error.message) {
          setGeneralError(error.message)
        }
      })
  }

  if (finished) return <Redirect to='/farms' />

  return (
    <Layout>
      <PageTitle>Sign Up</PageTitle>

      <LoadingContainer loading={loading}>
        {generalError && <p>{generalError}</p>}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              type='email'
              label='Email*'
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              disabled={props.user.authenticated}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordField
              label='Password*'
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
              disabled={props.user.authenticated}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label='Name*'
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={!!validationErrors.name}
              helperText={validationErrors.name}
              disabled={props.user.authenticated}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Business Name*'
              fullWidth
              value={businessName}
              onChange={(event) => setBusinessName(event.target.value)}
              error={!!validationErrors.businessName}
              helperText={validationErrors.businessName}
              disabled={props.user.authenticated}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label='Address Line 1*'
              fullWidth
              value={address1}
              onChange={(event) => setAddress1(event.target.value)}
              error={!!validationErrors.address1}
              helperText={validationErrors.address1}
              disabled={props.user.authenticated}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Address Line 2'
              fullWidth
              value={address2}
              onChange={(event) => setAddress2(event.target.value)}
              error={!!validationErrors.address2}
              helperText={validationErrors.address2}
              disabled={props.user.authenticated}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label='City*'
              fullWidth
              value={city}
              onChange={(event) => setCity(event.target.value)}
              error={!!validationErrors.city}
              helperText={validationErrors.city}
              disabled={props.user.authenticated}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Postal Code*'
              fullWidth
              value={postalCode}
              onChange={(event) => setPostalCode(event.target.value)}
              error={!!validationErrors.postalCode}
              helperText={validationErrors.postalCode}
              disabled={props.user.authenticated}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              choices={countryChoicesForSelect}
              showPlaceholder
              placeholder='Country*'
              fullWidth
              value={country}
              onChange={(event) => setCountry(event.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              choices={nzRegionChoices}
              showPlaceholder
              placeholder='Region (if New Zealand)'
              fullWidth
              disabled={disableRegion || props.user.authenticated}
              value={region}
              onChange={(event) => setRegion(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CardElement onChange={(data) => setCardComplete(data.complete)} />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={6} sm={3}>
            <PushButton fullWidth>Cancel</PushButton>
          </Grid>
          <Grid item xs={6} sm={3}>
            <PushButton fullWidth disabled={!canSubmit} onClick={submit}>
              Sign Up
            </PushButton>
          </Grid>
        </Grid>
      </LoadingContainer>
    </Layout>
  )
}

export const Signup = withUser(SignupComponent)
