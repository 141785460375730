import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'

import DialogHeader from '../../components/Dialog/DialogHeader'
import { DialogContainer, DialogContent } from '../../components/Dialog/FormDialog'
import {
  DraftingEventLimits,
  DraftingEventRow,
  getDraftingLimitsWeights,
} from '../../data/dashboard/weight_distribution'

interface WeightDistributionSettingsProps {
  readonly open: boolean
  readonly handleRequestClose: () => void
  readonly onSettingsSave: (eventId: string) => void
  readonly farmId: string
  readonly events: DraftingEventRow[]
}

export const WeightDistributionSettingsView: React.FunctionComponent<WeightDistributionSettingsProps> = ({
  open,
  handleRequestClose,
  onSettingsSave,
  events,
  metric,
  farmId,
}: WeightDistributionChartProps): JSX.Element => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(undefined)
  const [limits, setLimits] = useState([])

  useEffect(() => {
    const options = events.filter((row) => row.animalType === metric.animalType)
    setOptions(options)
    setSelectedOption(options.find((row) => row.id === metric.eventId))
  }, [events, metric.animalType, metric.eventId, open])

  const onSelectedEvent = (option: DraftingEventRow) => {
    setSelectedOption(option)
    setLimits([])

    if (option) {
      getDraftingLimitsWeights(farmId, option.id).then((result: DraftingEventLimits) => {
        setLimits([result.min, result.max])
      })
    }
  }

  const onSave = () => {
    if (selectedOption) {
      onSettingsSave(selectedOption.id, limits[0], limits[1])
    } else {
      onSettingsSave(undefined, undefined, undefined)
    }
  }

  let noDataError = false

  if (selectedOption && limits.length && limits[0] === limits[1]) {
    noDataError = 'This event has no data.'
  }

  const canSave =
    (selectedOption && selectedOption.id !== metric.eventId && limits.length && !noDataError) ||
    (!selectedOption && metric.eventId)

  return (
    <DialogContainer onClose={handleRequestClose} onBackdropClick={handleRequestClose} width='md' open={open}>
      <DialogHeader
        title='Settings - Weight Distribution'
        onClose={onSave}
        hasSubmit={false}
        hasClose={true}
        closeMessage='Save'
        closeDisabled={!canSave}
      />
      <DialogContent>
        <Box marginTop={1} />
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Events'
              variant='outlined'
              autoFocus
              error={!!noDataError}
              helperText={noDataError}
            />
          )}
          noOptionsText='No events for this animal type'
          fullWidth
          onChange={(event, option) => onSelectedEvent(option ? option : undefined)}
          value={selectedOption}
        />
      </DialogContent>
    </DialogContainer>
  )
}
