import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import React from 'react'

import { PageTitle } from './PageHeaders'

interface Props {
  readonly loaded?: boolean
  readonly className?: string
  readonly title?: string
  readonly children: React.ReactNode
}

const Layout: React.FunctionComponent<Props> = ({ loaded = true, className, title, children }): JSX.Element => (
  <div className={classNames('Layout', className)}>
    {loaded ? (
      <Box>
        {title && <PageTitle>{title}</PageTitle>}
        {children}
      </Box>
    ) : (
      <div className='LayoutLoading'>
        <CircularProgress />
      </div>
    )}
  </div>
)

export default Layout
