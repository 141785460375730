import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from '../../components/Dialog/DialogHeader'

import '../../components/Dialog/Dialog.css'

export default ({ open, handleRequestClose }) => (
  <Dialog className='dialog' onClose={handleRequestClose} open={open}>
    <DialogHeader title='NAIT Reporting Scope' handleRequestClose={handleRequestClose} />
    <div className='dialogContent naitInfoDialog'>
      <p>
        The NAIT Reporting functionality contained in Stock Manager is limited to registering animals, updating
        dead/lost/recovered animals, recording animal movements, and replacing tags for animals in the Stock Manager
        application with an EID number recorded. All other transactions, including receiving animals and reporting
        animals without an EID number in the system, must be completed on the NAIT webiste.
      </p>
      <div className='dialogButtons'>
        <Button onClick={handleRequestClose}>OK</Button>
      </div>
    </div>
  </Dialog>
)
