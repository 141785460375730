// import {getSignupState} from '../Signup'

import { User, UserInfo } from 'firebase'
import _ from 'lodash'
import * as React from 'react'
import { Link, Redirect, RouteProps } from 'react-router-dom'

import { EmailField, PasswordField, SubmitButton, withFormValidation } from '../../components/Form/Form'
import Layout from '../../components/Layout'
import BottomSnackbar from '../../components/Snackbar/BottomSnackbar'
import { addAuthHandler, login, removeAuthHandler } from '../../helpers/auth'

interface LoginContentProps {
  readonly error: string
  readonly email: string
  readonly canSubmit: boolean
  readonly loading: boolean
}

const LoginFormContent = ({ error, email, canSubmit, loading }: LoginContentProps): JSX.Element => (
  <div>
    {error ? <p className='error'>{error}</p> : null}
    <div className='FormRow'>
      <EmailField name='email' label='Email Address' value={email} required></EmailField>
    </div>
    <div className='FormRow'>
      <PasswordField name='password' label='Password' required />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton disabled={!canSubmit || loading}>{loading ? 'Loading...' : 'Sign In'}</SubmitButton>
    </div>
  </div>
)

const LoginForm = withFormValidation(LoginFormContent)

// FIXME: Temporary interface for User authentication data. Should be implemented in src/helpers/auth.js after porting to TS
interface UserData extends UserInfo {
  readonly authenticated: boolean
}

// FIXME: Temporary interface for LoginOperation. Should be implemented in src/helpers/auth.js after porting to TS
interface LoginResult {
  readonly success: boolean
  readonly user: User
  readonly error: string
}

interface LoginInfo {
  readonly email: string
  readonly password: string
}

interface State {
  readonly loading?: boolean
  readonly email?: string
  readonly snackbarContent?: boolean
  readonly snackbarOpen?: boolean
  readonly authenticated?: boolean
  readonly error?: string | null
}

class Login extends React.Component<RouteProps, State> {
  private mounted = true
  constructor(props: RouteProps) {
    super(props)

    if (this.props.location && this.props.location.state) {
      this.state = {
        loading: false,
        email: this.props.location.state.email,
        snackbarContent: this.props.location.state.snackbarContent,
        snackbarOpen: this.props.location.state.snackbarOpen,
      }
    } else {
      this.state = {
        loading: false,
      }
    }
  }

  /**
   * Listen for authentication; will trigger a redirect when authenticated
   * @param  {Object} user Firebase user Object
   */
  handleAuth = (user: UserData): void => {
    if (user && user.authenticated) {
      this.setState({
        authenticated: true,
      })
    }
  }

  componentDidMount(): void {
    addAuthHandler(this.handleAuth)
  }

  componentWillUnmount(): void {
    this.mounted = false
    removeAuthHandler(this.handleAuth)
  }

  submit = async (data: LoginInfo): Promise<void> => {
    this.setState({
      loading: true,
      error: null,
    })

    const result: LoginResult = await login(data.email, data.password)
    if (!this.mounted) return

    if (!result.success) {
      this.setState({
        loading: false,
        error: result.error,
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  handleRequestCloseSnackbar = (): void => {
    this.setState({
      snackbarOpen: false,
    })
  }

  render(): JSX.Element {
    const redirect = _.get(this, 'props.location.state.from', '/dashboard')

    if (this.state.authenticated) {
      return <Redirect to={redirect} />
    } else {
      return (
        <Layout title='Sign in' className='Login'>
          <LoginForm
            submit={this.submit}
            email={this.state.email}
            error={this.state.error}
            loading={this.state.loading}
          />
          <hr />
          {/* <p><Link to="/sign-up">No account? Sign up here</Link></p> */}
          <p>
            <Link to='/forgot-password'>Forgot password?</Link>
          </p>
          <BottomSnackbar
            open={this.state.snackbarOpen}
            handleRequestClose={this.handleRequestCloseSnackbar}
            message={this.state.snackbarContent}
          />
        </Layout>
      )
    }
  }
}

export default Login
