import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogHeader from '../../components/Dialog/DialogHeader'

import '../../components/Dialog/Dialog.css'

export default ({ open, handleRequestClose, goToFarms }) => (
  <Dialog className='dialog' onClose={handleRequestClose} open={open}>
    <DialogHeader title='Missing NAIT Account Details' handleRequestClose={handleRequestClose} />
    <div className='dialogContent naitInfoDialog'>
      <p>Go to settings and enter NAIT account details for this farm.</p>
      <div className='dialogButtons'>
        <Button className='grey' onClick={handleRequestClose}>
          Cancel
        </Button>
        <Button onClick={goToFarms}>Settings</Button>
      </div>
    </div>
  </Dialog>
)
