import React from 'react'

import { withFormValidation } from '../../../components/Form/Form'
import { CurrentDateString } from '../FilterInfo'

const format = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ')
  } else if (isDate(value)) {
    return formatDate(value)
  } else if (typeof value == 'object') {
    return Object.keys(value)
      .reduce((val, key) => val.concat(value[key]), [])
      .join(', ')
  } else {
    return value.toString()
  }
}

const isDate = (value) => {
  return !!value.from || !!value.date
}

const formatDate = (value) => {
  if (value.date) {
    return value.date
  } else if (value.current === true) {
    return CurrentDateString
  } else {
    return value.from + ' to ' + value.to
  }
}

const ReviewContent = (props) => {
  return (
    <div>
      {Object.keys(props.input).map((key) => {
        return (
          <p key={key}>
            <b>{key.toUpperCase()}</b>: {format(props.input[key])}
          </p>
        )
      })}
    </div>
  )
}
const ReviewForm = withFormValidation(ReviewContent)

export default class ReviewDialog extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      items: {},
    }
    this.props.items.then((items) => {
      this.setState({
        items: items,
      })
    })
  }

  submit() {
    this.props.submit()
  }

  render() {
    return (
      <div className='dialogContent__inner'>
        <ReviewForm input={this.state.items} />
      </div>
    )
  }
}
