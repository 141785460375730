import * as React from 'react'

import { AverageScanResultMetric } from '../../data/dashboard/average_scan_result'
import { DamConditionMetric } from '../../data/dashboard/dam_condition'
import {
  calculateMetric,
  DashboardMetric,
  DashboardMetricType,
  defaultMetricForType,
  setPosition,
} from '../../data/dashboard/dashboard'
import { GroupWeightMetric } from '../../data/dashboard/group_weight'
import { WeightDistributionMetric } from '../../data/dashboard/weight_distribution'
import { AverageScanResultChart } from './AverageScanResultChart'
import { DamConditionChart } from './DamConditionChart'
import { ChartPlaceholder, MetricSelector } from './DashboardElements'
import { GroupWeightChart } from './GroupWeightChart'
import { WeightDistributionChart } from './WeightDistributionChart'

interface State {
  readonly data?: any | null
}

interface Props {
  readonly farmId: string
  readonly metric: DashboardMetric | null
  readonly onChange: (metric: DashboardMetric | null) => void
  readonly position: number
}

class DashboardChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: null,
    }

    this.calculate()
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any): void {
    if (prevProps.metric !== this.props.metric || prevProps.farmId !== this.props.farmId) {
      this.calculate()
    }
  }

  async calculate(): Promise<void> {
    if (this.props.metric === null) {
      this.setState({ data: null })
      return
    }

    const data = await calculateMetric(this.props.farmId, this.props.metric, this.props.position)

    this.setState({ data })
  }

  onMetricTypeChange = (type: DashboardMetricType | null): void => {
    this.setState({ data: null }) // clear old chart data so new chart doesn't consume it and crash
    setPosition(this.props.farmId, this.props.position, type)
    this.props.onChange(defaultMetricForType(type))
  }

  renderChart = (): JSX.Element | null => {
    const metric = this.props.metric

    if (!metric) {
      return <ChartPlaceholder />
    }

    if (metric.type === 'Dam Condition') {
      return (
        <DamConditionChart
          farmId={this.props.farmId}
          metric={metric as DamConditionMetric}
          onChange={this.props.onChange}
          data={this.state.data}
          position={this.props.position}
        />
      )
    } else if (metric.type === 'Average Scan Result') {
      return (
        <AverageScanResultChart
          farmId={this.props.farmId}
          metric={metric as AverageScanResultMetric}
          onChange={this.props.onChange}
          data={this.state.data}
          position={this.props.position}
        />
      )
    } else if (metric.type === 'Weight Distribution') {
      return (
        <WeightDistributionChart
          farmId={this.props.farmId}
          metric={metric as WeightDistributionMetric}
          onChange={this.props.onChange}
          data={this.state.data}
          position={this.props.position}
        />
      )
    } else if (metric.type === 'Group Weight') {
      return (
        <GroupWeightChart
          farmId={this.props.farmId}
          metric={metric as GroupWeightMetric}
          onChange={this.props.onChange}
          data={this.state.data}
          position={this.props.position}
        />
      )
    }

    return null
  }

  render(): JSX.Element {
    const metric = this.props.metric

    return (
      <div className='dashboardChart'>
        <MetricSelector selected={metric ? metric.type : null} onChange={this.onMetricTypeChange} />
        {this.renderChart()}
      </div>
    )
  }
}

export default DashboardChart
