import RadioForm from './Dialogs/RadioDialog'
//import CheckboxWithIdForm from './Dialogs/CheckboxWithIdDialog'
import { DateRangeDialog, DateSelectDialog } from './Dialogs/DateDialog'
import { Key } from './FilterInfo'

const DateRange = {
  key: 'dateRange',
  title: 'DATE',
  form: DateRangeDialog,
}

const AverageWeightAtFutureDate = [
  {
    key: 'date',
    title: 'DATE',
    form: DateSelectDialog,
  },
]

// const PostBirthSurvivalRate = [
//   {
//     key: 'endEvents',
//     title: 'ENDING EVENT',
//     form: CheckboxWithIdForm,
//     values: [],
//     resource: 'events',
//   },
//   {
//     key: 'endDateRange',
//     title: 'ENDING DATE',
//     form: DateRangeDialog,
//   },
//   {
//     key: 'startEvents',
//     title: 'BEGINNING EVENT',
//     form: CheckboxWithIdForm,
//     values: [],
//     resource: 'events',
//   },
//   {
//     key: 'startDateRange',
//     title: 'BEGINNING DATE',
//     form: DateRangeDialog,
//   },
// ]

// const PreBirthSurvivalRate = [
//   {
//     key: 'endEvents',
//     title: 'ENDING EVENT',
//     form: CheckboxWithIdForm,
//     values: [],
//     resource: 'events',
//   },
//   {
//     key: 'endDateRange',
//     title: 'ENDING DATE',
//     form: DateRangeDialog,
//   },
//   {
//     key: 'startEvents',
//     title: 'BEGINNING EVENT',
//     form: CheckboxWithIdForm,
//     values: [],
//     resource: 'events',
//   },
//   {
//     key: 'startDateRange',
//     title: 'BEGINNING DATE',
//     form: DateRangeDialog,
//   },
// ]

export const metricToApiName = {
  // 'Average Weight': 'averageWeight'
  'Average Velvet SA Grade': 'AverageVelvetSaGrade',
  'Post-Birth Survival Rate': 'PostBirthSurvivalRate',
  'Pre-Birth Survival Rate': 'PreBirthSurvivalRate',
}

export const apiNameToMetric = {
  // 'birthRank': 'Average Birth Rank',
  // 'averageWeight': 'Average Weight',
  // 'averageConditionScore': 'Average Condition Score'
  AverageVelvetSaGrade: 'Average Velvet SA Grade',
  PostBirthSurvivalRate: 'Post-Birth Survival Rate',
  PreBirthSurvivalRate: 'Pre-Birth Survival Rate',
}

export const metricToUnit = {
  'Average Weight At Future Date': 'kg',
  'Average Weight Gain Per Day': 'g',
}

export const metrics = {
  Cattle: {
    key: Key.filter,
    title: 'Add Metric',
    form: RadioForm,
    values: {
      'Average Animal Age': [],
      'Average Birth Rank': [],
      'Average Condition Score': [DateRange],
      'Average Scan Result': [DateRange],
      'Average Weight': [DateRange],
      'Average Weight At Future Date': AverageWeightAtFutureDate,
      'Average Weight Gain Per Day': [DateRange],
      // 'Post-Birth Survival Rate': PostBirthSurvivalRate,
      'Potential Birth Count': [DateRange],
      // 'Pre-Birth Survival Rate': PreBirthSurvivalRate
    },
  },
  Deer: {
    key: Key.filter,
    title: 'Add Metric',
    form: RadioForm,
    values: {
      'Average Animal Age': [],
      'Average Birth Rank': [],
      'Average Condition Score': [DateRange],
      'Average Scan Result': [DateRange],
      'Average Velvet Weight': [DateRange],
      'Average Weight': [DateRange],
      'Average Weight At Future Date': AverageWeightAtFutureDate,
      'Average Weight Gain Per Day': [DateRange],
      'Average Hard Antler Grade': [DateRange],
      'Average Hard Velvet Grade': [DateRange],
      // 'Post-Birth Survival Rate': PostBirthSurvivalRate,
      'Potential Birth Count': [DateRange],
      // 'Pre-Birth Survival Rate': PreBirthSurvivalRate,
      'Average Velvet Circumference': [DateRange],
      'Average Velvet Circumference Grade': [DateRange],
      'Average Velvet Damage Grade': [DateRange],
      'Average Velvet Length Grade': [DateRange],
      'Average Velvet Manufacturing Grade': [DateRange],
      'Average Velvet Overall Length': [DateRange],
      'Average Velvet Regrowth Grade': [DateRange],
      'Average Velvet SA Grade': [DateRange],
      'Average Velvet Spiker Grade': [DateRange],
      'Average Velvet Top Length': [DateRange],
    },
  },
  Sheep: {
    key: Key.filter,
    title: 'Add Metric',
    form: RadioForm,
    values: {
      'Average Animal Age': [],
      'Average Birth Rank': [],
      'Average Condition Score': [DateRange],
      'Average Scan Result': [DateRange],
      'Average Skirted Weight': [DateRange],
      'Average Weight': [DateRange],
      'Average Weight At Future Date': AverageWeightAtFutureDate,
      'Average Weight Gain Per Day': [DateRange],
      'Average Wool Colour': [DateRange],
      'Average Wool Length': [DateRange],
      'Average Wool Micron': [DateRange],
      'Average Wool Style': [DateRange],
      'Average Wool Tenderness': [DateRange],
      'Average Wool Weight': [DateRange],
      // 'Post-Birth Survival Rate': PostBirthSurvivalRate,
      'Potential Birth Count': [DateRange],
      // 'Pre-Birth Survival Rate': PreBirthSurvivalRate
    },
  },
}
