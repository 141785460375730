import React from 'react'
import { withFormValidation, SelectField, TextField, FileInput, SubmitButton } from '../../components/Form/Form'
import _ from 'lodash'
import { colors } from '../../styles/styleConstants'

const sourceOptions = ['Allflex', 'Device File', 'Zee Tags'].map((c) => [c, c])

const ImportFormContent = (props) => (
  <div className='importForm'>
    <div className='FormRow'>
      <SelectField
        name='source'
        label='Source'
        choices={sourceOptions}
        value={props.source}
        backgroundColor={colors.green}
        color={colors.white}
        required
        showPlaceholder
      />
    </div>
    <div className='FormRow' />
    <div className='FormRow'>
      <TextField
        name='orderNumber'
        label='Order Number'
        disabled={!['Allflex', 'Zee Tags'].includes(props.source)}
        required={['Allflex', 'Zee Tags'].includes(props.source)}
      />
    </div>
    <div className='FormRow'>
      <FileInput
        name='selectedFile'
        accept='.csv'
        setSelectedFile={props.setSelectedFile}
        selectedFile={props.selectedFile}
        disabled={props.source !== 'Device File'}
      />
    </div>
    <div className='ButtonWrap'>
      <SubmitButton
        disabled={!props.canSubmit}
        onClick={() => {
          if (props.source === 'Device File') {
            props.submitFile()
          }
        }}
      >
        Complete
      </SubmitButton>
    </div>
  </div>
)

const ImportForm = withFormValidation(ImportFormContent)

export default class ImportFormComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validationErrors: {},
      canSubmit: false,
    }

    this.validate = this.validate.bind(this)
  }

  validate(data) {
    this.setState({ source: data.source })
    const errors = {}
    if (['Allflex', 'Zee Tags'].includes(data.source)) {
      if (_.get(data, 'orderNumber', '').length < 1) {
        errors.orderNumber = 'Please include the order number'
      }
    } else if (data.source === 'Device File') {
      if (data.selectedFile) {
        this.setState({ selectedFile: data.selectedFile })
      } else {
        errors.selectedFile = 'Please select a file'
      }
    }

    this.setState({
      validationErrors: errors,
      canSubmit: _.isEmpty(errors),
    })

    this.props.setHasChanged()
  }

  render() {
    return (
      <ImportForm
        {...this.props}
        selectedFile={this.state.selectedFile}
        setSelectedFile={(file) => {
          this.validate({ source: 'Device File', selectedFile: file })
        }}
        source={this.state.source}
        validate={this.validate}
        validationErrors={this.state.validationErrors}
        canSubmit={this.state.canSubmit}
        submitFile={() => {
          this.props.submit({
            source: 'Device File',
            selectedFile: this.state.selectedFile,
          })
        }}
      />
    )
  }
}
