import styled from 'styled-components'

interface DropdownContainerProps {
  readonly margin: string
}

const DropdownContainer = styled.section`
  max-width: 180px;
  display: flex;
  flex-grow: 1;
  margin-right: ${(props: DropdownContainerProps) => props.margin || '20px'};
`

export default DropdownContainer
