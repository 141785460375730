// @ts-ignore
import config from 'config'

import { get, post } from '../helpers/fetch'
import { getAuthHeaders } from '../helpers/firebase'

export interface TagImport {
  readonly animalType: string | null
  readonly completed: number
  readonly id: string
  readonly inactive: number
  readonly incomplete: number
  readonly isComplete: boolean
  readonly issueDate: string | null
  readonly orderDate: string
  readonly orderNumber: string
  readonly supplier: string
  readonly total: number
  readonly vidYear: number | null
}

export const getImportsForFarm = async (farmId: string): Promise<readonly TagImport[]> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/orders`

  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export interface TagDetails {
  readonly animalType: string | null
  readonly loaded: boolean
  readonly id: string
  readonly used: boolean
  readonly year: number | null
  readonly color: string | null
  readonly eid: string
  readonly vid: string
}
export const getTagDetailsForImport = async (farmId: string, orderId: string): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/${orderId}/tags`

  return getAuthHeaders()
    .then((headers) => get(url, headers))
    .then((res) => {
      return {
        success: true,
        tags: res.tags,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export const postExternalImport = (farmId: string, data: any): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/external`

  return getAuthHeaders()
    .then((headers) => post(url, data, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export const postManualImport = (farmId: string, data: any): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/manual`

  return getAuthHeaders()
    .then((headers) => post(url, data, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}

export const postFileImport = (farmId: string, data: any): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/file`

  return getAuthHeaders()
    .then((headers) => {
      return post(url, data, headers, true)
    })
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
        details: error.data ? error.data.errors : null,
      }
    })
}

export const getDetailsForOrder = (farmId: string, orderId: string): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/${orderId}/incomplete`

  return getAuthHeaders().then((headers) => get(url, headers))
}

export const postDetailsForGroup = (farmId: string, orderId: string, data: any): Promise<any> => {
  const url = `${config.backend.url}/farm/${farmId}/tag/order/${orderId}/complete`

  return getAuthHeaders()
    .then((headers) => post(url, data, headers))
    .then((res) => {
      return {
        success: true,
        res,
      }
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      }
    })
}
