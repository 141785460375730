import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import { ActionHeader, ButtonContainer } from '../../../../components/PageHeaders'
import Search from '../../../../components/Search'
import { PushButton } from '../../../../components/Form/Form'
import AccountSelector from '../../../../components/AccountSelector'
import { CheckboxWithLabel } from '../../../../components/Form/CustomFields'

const TemplatesTableHeader = ({
  showDisabled,
  onSearchChange,
  onDisabledToggle,
  createTemplate,
  accounts,
  handleAccountSelected,
  accountId,
  deselectAccount,
}) => (
  <ActionHeader>
    <ButtonContainer>
      <Search placeholder='Search templates' onChange={onSearchChange} flexGrow='2' />
      <CheckboxWithLabel
        control={<Checkbox checked={showDisabled} onClick={onDisabledToggle} />}
        label='Show disabled'
      />
      <AccountSelector
        accounts={accounts}
        handleAccountSelected={handleAccountSelected}
        accountId={accountId}
        deselectAccount={deselectAccount}
      />
      <PushButton onClick={createTemplate} disabled={!accountId}>
        Create Template
      </PushButton>
    </ButtonContainer>
  </ActionHeader>
)

export default TemplatesTableHeader
