import React from 'react'

import LogoImage from './logo-white.svg'

const Logo = () => {
  return (
    <a href='/' className='Logo'>
      <LogoImage alt='Stock Manager' />
    </a>
  )
}

export default Logo
