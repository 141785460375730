import React from 'react'
import { requireAuth } from '../../../components/withAuth'
import { CheckboxFieldWithId, withFormValidation } from '../../../components/Form/Form'

const CheckboxFormContent = (props) => {
  const allSelected = () => {
    for (var id in props.items) {
      if (!props.items[id].selected) {
        return false
      }
    }
    return true
  }

  return (
    <div>
      <CheckboxFieldWithId
        name='Select All'
        label='Select All'
        checked={allSelected()}
        onChange={props.onChangeSelectAll}
      />
      <hr />
      {Object.keys(props.items).map((key) => (
        <CheckboxFieldWithId
          id={key}
          name={props.items[key].name}
          label={props.items[key].name}
          checked={props.items[key].selected}
          onChange={props.onChange}
          key={key}
        />
      ))}
    </div>
  )
}
const CheckboxForm = withFormValidation(CheckboxFormContent)

class CheckboxWithIdDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: {},
    }
    // set items
    this.props.items.then((items) => {
      const newItems = {}
      for (var i in items) {
        const id = items[i].id
        newItems[id] = items[i]
        newItems[id].selected = this.props.data ? (this.props.data[id] ? true : false) : false
        if (this.props.resource === 'groups') {
          newItems[id].name = newItems[id].year + ' - ' + newItems[id].name
          if (items[i].type !== 'Id') delete newItems[id]
        }
      }
      this.setState({
        items: newItems,
      })
    })
    this.onChange = this.onChange.bind(this)
    this.onChangeSelectAll = this.onChangeSelectAll.bind(this)
    this.submit = this.submit.bind(this)
  }

  onChangeSelectAll(checked) {
    this.setState((state) => {
      for (var i in state.items) {
        state.items[i].selected = checked
      }

      return state
    })
  }

  onChange(checked, id) {
    this.setState((state) => {
      state.items[id].selected = checked

      return state
    })
  }

  submit() {
    const selected = Object.keys(this.state.items)
      .filter((i) => this.state.items[i].selected)
      .reduce((selected, id) => {
        selected[id] = this.state.items[id].name
        return selected
      }, {})
    this.props.submit(selected, this.props.name)
  }

  render() {
    return (
      <div className='dialogContent__inner'>
        <CheckboxForm items={this.state.items} onChange={this.onChange} onChangeSelectAll={this.onChangeSelectAll} />
      </div>
    )
  }
}

export default requireAuth(CheckboxWithIdDialog)
