import config from 'config'

import { get, post, put } from '../../helpers/fetch'
import { getAuthHeaders } from '../../helpers/firebase'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings, TimePeriod, timePeriodToDateFrom } from './dashboard'

export interface AverageScanResultMetric extends DashboardMetric {
  readonly animalType: AnimalType
  readonly timePeriod: TimePeriod
}

export interface AverageScanResultGoal {
  readonly year: number
  readonly scanResult: number
}

export interface AverageScanResultGoals {
  readonly Cattle: AverageScanResultGoal[]
  readonly Deer: AverageScanResultGoal[]
  readonly Sheep: AverageScanResultGoal[]
}

export interface AverageScanResultSettings {
  readonly includeNonId: boolean
  readonly goals: AverageScanResultGoals
}

export interface AverageScanResultRequest {
  readonly animalType: string
  readonly yearFrom: number
  readonly yearTo: number
  readonly position: number
}

export interface AverageScanResultResult {
  readonly scanResults: readonly AverageScanResultResultRow[]
}

export interface AverageScanResultResultRow {
  readonly year: number
  readonly value: number
}

export const averageScanResultMetric: AverageScanResultMetric = {
  type: 'Average Scan Result',
  animalType: AnimalType.Cattle,
  timePeriod: 'Last 3 Years',
}

export const averageScanResultTimePeriods: readonly TimePeriod[] = ['Last 2 Years', 'Last 3 Years', 'Last 5 Years']

export function averageScanResultChartSettings(timePeriod: TimePeriod): DashboardMetricChartSettings {
  const fromYear = timePeriodToDateFrom(timePeriod).getFullYear() + 1
  const toYear = new Date().getFullYear()

  const ticks = []
  for (let i = fromYear; i <= toYear; i++) {
    ticks.push({
      v: i,
      f: i.toString(),
    })
  }

  return {
    type: 'LineChart',
    options: {
      chartArea: { width: '80%', height: '80%' },
      legend: { position: 'top', alignment: 'center' },
      pointSize: 8,
      colors: ['#CC0000', '#F1C232', 'green'],
      hAxis: {
        viewWindow: {
          min: fromYear,
          max: toYear,
        },
        ticks: ticks,
        textStyle: { color: '#919191' },
      },
      vAxis: {
        textStyle: { color: '#919191' },
      },
    },
  }
}

export async function calculateAverageScanResult(
  farmId: string,
  metric: AverageScanResultMetric,
  position: number,
): Promise<[string | object, string | number, string | number][]> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/metric/AverageScanResult`

  const dateFrom = timePeriodToDateFrom(metric.timePeriod)

  const request: AverageScanResultRequest = {
    yearFrom: dateFrom.getFullYear(),
    yearTo: new Date().getFullYear(),
    animalType: metric.animalType,
    position: position,
  }

  const headers = await getAuthHeaders()
  const result: AverageScanResultResult = await post(url, request, headers)
  const settings: AverageScanResultSettings = await loadAverageScanResultSettings(farmId, position)

  const output = [['Year', 'Average Scan Result', 'Goal']].concat(
    result.scanResults.map((row) => {
      return [{ v: row.year, f: row.year.toString() }, row.value, findGoal(row.year, metric.animalType, settings)]
    }),
  )

  return output
}

function findGoal(year: number, animalType: AnimalType, settings: AverageScanResultSettings): number {
  const result = settings.goals[animalType].find((value) => value.year === year)
  return result ? result.scanResult : 0
}

export async function loadAverageScanResultSettings(
  farmId: string,
  position: number,
): Promise<AverageScanResultSettings> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/AverageScanResult`

  const headers = await getAuthHeaders()
  return await get(url, headers)
}

export async function saveAverageScanResultSettings(
  farmId: string,
  settings: AverageScanResultSettings,
  position: number,
): Promise<void> {
  const url = `${config.backend.url}/farm/${farmId}/dashboard/settings/${position}/AverageScanResult`

  const headers = await getAuthHeaders()
  await put(url, settings, headers)
}
