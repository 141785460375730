/* eslint-disable react/no-string-refs */
import React from 'react'
import { withFormValidation } from '../../../components/Form/Form'

const NumberRangeFormContent = (props) => {
  var FieldName = props.type
  let from = props.data ? props.data.from : ''
  let to = props.data ? props.data.to : ''
  return (
    <div>
      <FieldName name={'from'} label={props.params.from} low={props.params.low} high={props.params.high} value={from} />
      <FieldName name={'to'} label={props.params.to} low={props.params.low} high={props.params.high} value={to} />
    </div>
  )
}
const NumberRangeForm = withFormValidation(NumberRangeFormContent)

export default class NumberRangeDialog extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      labels: {},
    }
    this.props.items.then((labels) => {
      this.setState({
        labels: labels,
      })
    })
  }

  submit() {
    const data = this.refs.numberRangeFormRef.refs.form.getCurrentValues()
    if (this.refs.numberRangeFormRef.state.canSubmit) {
      this.props.submit({ from: data['from'], to: data['to'] })
    }
  }

  render() {
    return (
      <div className='dialogContent__inner'>
        <NumberRangeForm
          ref='numberRangeFormRef'
          name={this.props.name}
          params={this.state.labels}
          data={this.props.data}
          back={this.props.back}
          submit={this.submit}
          type={this.props.type}
        />
      </div>
    )
  }
}
