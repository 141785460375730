import { sleep } from '../../helpers/utils'
import { AnimalType } from '../types'
import { DashboardMetric, DashboardMetricChartSettings, processFakeData } from './dashboard'

export const AverageWeightMetric: DashboardMetric = {
  type: 'Average Weight',
  animalType: AnimalType.Sheep,
  timePeriod: 'Last 3 Years',
}

export const AverageWeightChart: DashboardMetricChartSettings = {
  type: 'LineChart',
  options: {
    chartArea: { width: '80%', height: '80%' },
    legend: 'top',
  },
}

export async function calculateAverageWeight(farmId: string, metric: DashboardMetric): Promise<[[]]> {
  sleep(1000)
  return processFakeData(input)
}

const input = `Date,Weight,Low,High
May-16,100,80,120
Jun-16,101,81,121
Jul-16,102,82,122
Aug-16,103,83,123
Sep-16,104,84,124
Oct-16,105,85,125
Nov-16,106,86,126
Dec-16,107,87,127
Jan-17,108,88,128
Feb-17,109,89,129
Mar-17,110,90,130
Apr-17,111,91,131
May-17,112,92,132
Jun-17,113,93,133
Jul-17,114,94,134
Aug-17,115,95,135
Sep-17,116,96,136
Oct-17,117,97,137
Nov-17,118,98,138
Dec-17,119,99,139
Jan-18,120,100,140
Feb-18,121,101,141
Mar-18,122,102,142
Apr-18,123,103,143
May-18,124,104,144
Jun-18,125,105,145
Jul-18,126,106,146
Aug-18,127,107,147
Sep-18,128,108,148
Oct-18,129,109,149
Nov-18,130,110,150
Dec-18,131,111,151
Jan-19,132,112,152
Feb-19,133,113,153
Mar-19,134,114,154
Apr-19,135,115,155
May-19,136,116,156
Jun-19,137,117,157
Jul-19,138,118,158`
