import React, { useState } from 'react'
import { FormRow, FormField } from '../../../../components/Form/Form'
import MaterialTextField from '@material-ui/core/TextField'
import { SimpleFormDialog } from '../../../../components/Dialog/FormDialog'
import { editHealthTreatmentPurchase } from '../../../../data/health_treatments'

export const EditPurchaseDialog = ({ farmId, purchase, onRequestClose, onComplete }) => {
  const [currentQuantity, setCurrentQuantity] = useState(purchase.currentQuantity)
  const valid = currentQuantity <= purchase.purchaseQuantity && currentQuantity >= 0
  const canSubmit = valid && purchase.currentQuantity != currentQuantity

  const onSubmit = async () => {
    await editHealthTreatmentPurchase(farmId, purchase.id, { ...purchase, currentQuantity })
    onComplete()
  }

  return (
    <SimpleFormDialog
      wide
      width='400px'
      title='Edit current quantity'
      open={true}
      onClose={onRequestClose}
      canSubmit={canSubmit}
      onSubmit={onSubmit}
    >
      <FormRow>
        <FormField width='50%'>
          <MaterialTextField
            fullWidth={true}
            type='number'
            onChange={(e) => setCurrentQuantity(e.target.value)}
            name='currentQuantity'
            value={currentQuantity}
            error={!valid}
            helperText={valid ? '' : `Must be between 0 and ${purchase.purchaseQuantity}`}
            required={true}
            label='Current Qty'
          />
        </FormField>
      </FormRow>
    </SimpleFormDialog>
  )
}
