import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'

class FarmDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: undefined,
      open: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handleOpenEditFarm = this.handleOpenEditFarm.bind(this)
    this.handleOpenToggleFarm = this.handleOpenToggleFarm.bind(this)
    this.handleOpenTransferFarm = this.handleOpenTransferFarm.bind(this)
    this.handleOpenEditUsers = this.handleOpenEditUsers.bind(this)
  }

  handleClick(event) {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
    this.props.updateSelectedFarm(this.props.farm)
  }

  handleOpenEditFarm() {
    this.handleRequestClose()
    this.props.handleOpenEditFarm()
  }

  handleOpenToggleFarm() {
    this.handleRequestClose()
    this.props.handleOpenToggleFarm()
  }

  handleOpenTransferFarm() {
    this.handleRequestClose()
    this.props.handleOpenTransferFarm()
  }

  handleOpenEditUsers() {
    this.handleRequestClose()
    this.props.handleOpenEditUsers()
  }

  handleOpenNaitDetails = () => {
    this.handleRequestClose()
    this.props.handleOpenNaitDetails()
  }

  handleRequestClose() {
    this.setState({
      open: false,
    })
  }

  render() {
    return (
      <div className='FarmDropdown'>
        <Button
          aria-owns={this.state.open ? 'farm-menu' : null}
          aria-haspopup='true'
          className='menuButtonSmall right'
          onClick={this.handleClick}
        >
          <MoreVert />
        </Button>
        <Menu id='farm-menu' anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleRequestClose}>
          {this.props.canEdit && (
            <MenuItem onClick={this.handleOpenToggleFarm}>
              {this.props.farm.active ? 'Disable Farm' : 'Enable Farm'}
            </MenuItem>
          )}
          {this.props.farm.active ? (
            <div>
              {this.props.canEdit && <MenuItem onClick={this.handleOpenEditFarm}>Edit Farm</MenuItem>}
              {this.props.showNaitDetails && (
                <MenuItem onClick={this.handleOpenNaitDetails}>Edit NAIT Details</MenuItem>
              )}
              {this.props.canEdit && <MenuItem onClick={this.handleOpenEditUsers}>Edit Users</MenuItem>}
              {this.props.canEdit && <MenuItem onClick={this.handleOpenTransferFarm}>Transfer Farm</MenuItem>}
            </div>
          ) : null}
        </Menu>
      </div>
    )
  }
}

export default FarmDropdown
