import React from 'react'
import FarmDropdown from './FarmDropdown'
import EditFarmDialog from './Dialogs/EditFarmDialog'
import ToggleFarmDialog from './Dialogs/ToggleFarmDialog'
import TransferFarmDialog from './Dialogs/TransferFarmDialog'
import EditUsersDialog from './Dialogs/EditUsersDialog'
import AddFarmDialog from './Dialogs/AddFarmDialog'
import EditNaitDetailsDialog from './Dialogs/EditNaitDetailsDialog'
import MaterialTable from '../../components/Table/Table.js'
import { PageTitle, ActionHeader, ActionHeaderButton } from '../../components/PageHeaders'
import { formatChoices } from '../../constants'

const columns = [
  { id: 'billingAccount', numeric: false, label: 'Billing Account' },
  { id: 'farmName', numeric: false, label: 'Farm Name' },
  { id: 'farmPostalCode', numeric: false, label: 'Farm Postal Code' },
  { id: 'ownerEmail', numeric: false, label: 'Account Owner' },
  { id: 'subscriptionType', numeric: false, label: 'Subscription Type' },
  { id: 'active', numeric: false, label: 'Active' },
  { id: 'button', numeric: false, label: '' },
]

const subscriptionTypeChoices = {
  Vet: formatChoices(['Full', 'Web']),
  Standard: formatChoices(['Standard']),
}

class FarmList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addFarmOpen: false,
    }

    this.handleRequestCloseAddFarmDialog = this.handleRequestCloseAddFarmDialog.bind(this)
    this.handleOpenEditFarm = this.handleOpenEditFarm.bind(this)
    this.handleRequestCloseEditFarm = this.handleRequestCloseEditFarm.bind(this)
    this.handleOpenToggleFarm = this.handleOpenToggleFarm.bind(this)
    this.handleRequestCloseToggleFarm = this.handleRequestCloseToggleFarm.bind(this)
    this.handleOpenTransferFarm = this.handleOpenTransferFarm.bind(this)
    this.handleRequestCloseTransferFarm = this.handleRequestCloseTransferFarm.bind(this)
    this.handleOpenEditUsers = this.handleOpenEditUsers.bind(this)
    this.handleRequestCloseEditUsers = this.handleRequestCloseEditUsers.bind(this)
    this.updateSelectedFarm = this.updateSelectedFarm.bind(this)
  }

  updateSelectedFarm(farm) {
    this.setState({
      selectedFarm: farm,
    })
  }

  handleOpenEditFarm() {
    this.setState({
      editFarmOpen: true,
    })
  }

  handleRequestCloseEditFarm() {
    this.setState({
      editFarmOpen: false,
    })
    this.props.updateFarmsList()
  }

  handleOpenToggleFarm() {
    this.setState({
      toggleFarmOpen: true,
    })
  }

  handleRequestCloseToggleFarm() {
    this.setState({
      toggleFarmOpen: false,
    })
    this.props.updateFarmsList()
  }

  handleOpenTransferFarm() {
    this.setState({
      transferFarmOpen: true,
    })
  }

  handleRequestCloseTransferFarm() {
    this.setState({
      transferFarmOpen: false,
    })
    this.props.updateFarmsList()
  }

  handleOpenEditUsers() {
    this.setState({
      editUsersOpen: true,
    })
  }

  handleOpenNaitDetails = () => {
    this.setState({ openNaitDetails: true })
  }

  handleRequestCloseNaitDetails = () => {
    this.setState({
      openNaitDetails: false,
    })
    this.props.updateFarmsList()
  }

  handleRequestCloseEditUsers() {
    this.setState({
      editUsersOpen: false,
    })
    this.props.updateFarmsList()
  }

  getFormattedData = () => {
    return this.props.farms
      ? this.props.farms.map((farm) => {
          const account = this.props.allAccounts.find((a) => a.id === farm.accountId)
          const canEdit =
            account && (['Clinic', 'Admin', 'Account'].indexOf(account.accountRole) !== -1 || account.isOwner)

          const showNaitDetails =
            farm.userRole === 'Farm' || (farm.userRole === 'Account' && farm.accountType === 'Standard')

          return {
            billingAccount: farm.businessName,
            active: farm.active ? '✔︎' : '',
            farmName: farm.farmName,
            farmPostalCode: farm.farmPostalCode,
            subscriptionType: farm.subscriptionType,
            ownerEmail: farm.ownerEmail,
            button:
              canEdit || showNaitDetails ? (
                <div>
                  <FarmDropdown
                    farm={farm}
                    updateSelectedFarm={this.updateSelectedFarm}
                    handleOpenEditFarm={this.handleOpenEditFarm}
                    handleOpenToggleFarm={this.handleOpenToggleFarm}
                    handleOpenTransferFarm={this.handleOpenTransferFarm}
                    handleOpenEditUsers={this.handleOpenEditUsers}
                    handleOpenNaitDetails={this.handleOpenNaitDetails}
                    showNaitDetails={showNaitDetails}
                    canEdit={canEdit}
                  />
                </div>
              ) : (
                ''
              ),
          }
        })
      : []
  }

  handleRequestCloseAddFarmDialog() {
    this.setState({ addFarmOpen: false })
  }

  getSubscriptionTypeChoices = () => {
    return subscriptionTypeChoices[this.state.selectedFarm.accountType]
  }

  getAccountMap = () => {
    return Object.fromEntries(this.props.accounts.map((account) => [account.id, account]))
  }

  render() {
    const account = this.props.currentAccount
    return (
      <div className='tablePageContainer'>
        <div>
          <PageTitle>{account ? `Farms for ${account.businessName}` : 'All farms'}</PageTitle>
          <ActionHeader>
            <ActionHeaderButton onClick={() => this.setState({ addFarmOpen: true })} disabled={!this.props.canAddFarm}>
              Add Farm
            </ActionHeaderButton>
          </ActionHeader>
        </div>

        {this.props.farms.length ? (
          <MaterialTable columns={columns} data={this.getFormattedData()} />
        ) : (
          <p>You haven&apos;t added any farms yet.</p>
        )}

        <AddFarmDialog
          open={this.state.addFarmOpen}
          onRequestClose={this.handleRequestCloseAddFarmDialog}
          updateFarmsList={this.props.updateFarmsList}
          billingAccountChoices={this.props.billingAccountChoices}
          accountMap={this.getAccountMap()}
        />
        {this.state.selectedFarm && (
          <div>
            {this.state.selectedFarm.active && this.state.editFarmOpen && (
              <EditFarmDialog
                open
                farmId={this.state.selectedFarm.id}
                farmName={this.state.selectedFarm.farmName}
                farmPostalCode={this.state.selectedFarm.farmPostalCode}
                billingAccountChoices={this.props.billingAccountChoices}
                subscriptionType={this.state.selectedFarm.subscriptionType}
                subscriptionTypeChoices={this.getSubscriptionTypeChoices()}
                onRequestClose={this.handleRequestCloseEditFarm}
              />
            )}
            {this.state.toggleFarmOpen && (
              <ToggleFarmDialog
                open
                farmId={this.state.selectedFarm.id}
                active={this.state.selectedFarm.active}
                onRequestClose={this.handleRequestCloseToggleFarm}
                toggleFarm={() => this.props.toggleFarm(this.state.selectedFarm.id, this.state.selectedFarm.active)}
              />
            )}
            {this.state.selectedFarm.active && this.state.transferFarmOpen && (
              <TransferFarmDialog
                open
                farmId={this.state.selectedFarm.id}
                onRequestClose={this.handleRequestCloseTransferFarm}
              />
            )}
            {this.state.selectedFarm.active && this.state.editUsersOpen && (
              <EditUsersDialog
                open
                farmId={this.state.selectedFarm.id}
                accountType={this.state.selectedFarm.accountType}
                onRequestClose={this.handleRequestCloseEditUsers}
                showSnackbar={this.props.showSnackbar}
              />
            )}
            {this.state.openNaitDetails && (
              <EditNaitDetailsDialog
                open
                farmId={this.state.selectedFarm.id}
                farmName={this.state.selectedFarm.farmName}
                naitNumber={this.state.selectedFarm.naitNumber}
                onRequestClose={this.handleRequestCloseNaitDetails}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default FarmList
