/* eslint-disable react/no-string-refs */
import React from 'react'

import { EIDField, withFormValidation } from '../../../components/Form/Form'

const InputFormContent = (props) => {
  var FieldName = props.type
  return (
    <div>
      <FieldName name={props.name} label={props.label} value={props.value} />
    </div>
  )
}
const InputForm = withFormValidation(InputFormContent)

export default class InputDialog extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      label: '',
    }
    this.props.items.then((label) => {
      this.setState({
        label: label,
      })
    })
  }

  submit() {
    if (this.refs.inputFormRef.state.canSubmit) {
      var value = this.refs.inputFormRef.refs.form.getCurrentValues()[this.props.name]
      if (this.props.type === EIDField) {
        value = value.padStart(16, '0')
      }
      this.props.submit(value)
    }
  }

  render() {
    return (
      <div className='dialogContent__inner'>
        <InputForm
          ref='inputFormRef'
          name={this.props.name}
          label={this.state.label}
          type={this.props.type}
          value={this.props.data}
        />
      </div>
    )
  }
}
