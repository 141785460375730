import { getAuthHeaders } from '../helpers/firebase'
import { get, put, post } from '../helpers/fetch'
import config from 'config'

export const getEventsForAccount = async (accountId) => {
  const url = `${config.backend.url}/planning/events/account/${accountId}`

  try {
    const headers = await getAuthHeaders()
    return get(url, headers)
  } catch (error) {
    console.error(error)
  }
}

export const addEventForAccount = async (accountId, eventData) => {
  const url = `${config.backend.url}/planning/events/account/${accountId}`

  try {
    const headers = await getAuthHeaders()
    const result = await post(url, eventData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const editEventForAccount = async (accountId, id, eventData) => {
  const url = `${config.backend.url}/planning/events/account/${accountId}/edit/${id}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, eventData, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const disableEventForAccount = async (accountId, eventId) => {
  const url = `${config.backend.url}/planning/events/account/${accountId}/disable/${eventId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export const enableEventForAccount = async (accountId, eventId) => {
  const url = `${config.backend.url}/planning/events/account/${accountId}/enable/${eventId}`

  try {
    const headers = await getAuthHeaders()
    const result = await put(url, {}, headers)
    return {
      success: true,
      result,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}
